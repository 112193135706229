var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "grid-row",
    [
      _c(
        "draggable",
        {
          staticClass: "draggble-container",
          attrs: { tag: "v-layout", move: _vm.onMove },
          on: { end: _vm.onEnd, start: _vm.onStart },
          model: {
            value: _vm.pictures,
            callback: function($$v) {
              _vm.pictures = $$v
            },
            expression: "pictures"
          }
        },
        [
          _c(
            "transition-group",
            {
              staticClass: "d-flex draggable-wrapper",
              staticStyle: { "flex-wrap": "wrap" },
              attrs: { tag: "div" }
            },
            _vm._l(_vm.pictures, function(item, index) {
              return _c(
                "grid-column",
                {
                  key: index,
                  staticClass: "img-preview__wrapper",
                  attrs: { size: _vm.colSize }
                },
                [
                  _c("Thumb", {
                    attrs: { src: item, type: "preview", isResponsive: "" }
                  }),
                  _vm._v(" "),
                  index === 0
                    ? _c("div", { staticClass: "img-preview__label" }, [
                        _vm._v("\n          Cover\n        ")
                      ])
                    : index > 0 && index < 10
                      ? _c("div", { staticClass: "img-preview__index" }, [
                          _c("span", [_vm._v("0" + _vm._s(index))])
                        ])
                      : _c("div", { staticClass: "img-preview__index" }, [
                          _c("span", [_vm._v(_vm._s(index))])
                        ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "img-preview__buttons" },
                    [
                      _c(
                        "base-button",
                        {
                          attrs: {
                            size: "sm",
                            type: "button",
                            btnIcon: "regular/trash-alt",
                            outline: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.removePhoto(index)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Remove image")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            })
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "grid-column",
        { staticClass: "img-uploader__wrapper", attrs: { size: _vm.colSize } },
        [
          _c("ImgUploader", {
            attrs: {
              type: "thumbPrev",
              label: "Add picture",
              uploadCallback: _vm.uploadCallback,
              preview: false,
              onErrorShowAlert: "",
              isResponsive: "",
              useS3: ""
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.empty
        ? _c("div", { staticClass: "upload-picture" }, [
            _vm._v("Please upload a picture")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-14d7a604", { render: render, staticRenderFns: staticRenderFns })
  }
}