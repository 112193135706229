var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "basePagination" }, [
    _c(
      "ul",
      { staticClass: "basePagination__list-container" },
      [
        _c(
          "li",
          { staticClass: "basePagination__list-item", on: { click: _vm.back } },
          [_c("i", { staticClass: "fas fa-chevron-left" })]
        ),
        _vm._v(" "),
        _vm._l(_vm.pagesToShow, function(page, index) {
          return _c(
            "li",
            {
              key: index,
              staticClass: "basePagination__list-item",
              class: {
                "basePagination__list-item--active":
                  page.value === _vm.currentNumber + 1
              },
              on: {
                click: function($event) {
                  _vm.select(page)
                }
              }
            },
            [_vm._v("\n      " + _vm._s(page.label) + "\n    ")]
          )
        }),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "basePagination__list-item", on: { click: _vm.next } },
          [_c("i", { staticClass: "fas fa-chevron-right" })]
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-a0f01d62", { render: render, staticRenderFns: staticRenderFns })
  }
}