<doc>
    Archive Vue.js component
</doc>
<template>
    <div class="">
    <div class="header-block">
        <div class="header-search">
            
            <div class="search-realtor">
                <BaseAutoComplete
                        :showListOptions="true"
                        :showSearchButton="true"
                        :listOptions="listOptions"
                        :listItem="[]"
                        name="myarchive-autocomplete-search"
                        :hideLabel="true"
                        :placeHolder="this.placeholder"
                        :float="true"
                        marTop="0"
                        marBottom="0"
                        :paddLeft="true"
                        :isMaterial="false"
                        :fluidList="false"
                        @submit="onSubmit"
                    />
            </div>
        </div>
        <FlexBox :wrap="true" :style="{margin: searchQueries.length > 0 ? '10px 0px': ''}">
            <Badge
                class="badge-filter"
                pill
                hasClose
                v-for="(query, index) in searchQueries"
                :key="query.value + query.column"
                :label="query.value"
                :showIcon="true"
                :icon="query.icon"
                :isCapitalize="false"
                :mr="5"
                v-on:removeBadge="removeFilter(index)"
            />
        </FlexBox>
        <div class="header-export">
        <DownloadExcel 
            name="Archived Realtors"
            :fetch="getExcelData"
            :fields="dataFieldsForExport"
        >
            <BaseButton>Excel Export</BaseButton>
        </DownloadExcel>
        </div>
    </div>
    <LoaderPlaceholder v-if="isLoading" type="circle" />
    <div class="my-network-manual-content" v-else>
        <div v-for="item of realtorList" :data-invite-id="item.id" :id="`network-invite-${item.id}`" class="network-table__row network-table__row--outcoming">
            <div class="network-table__column network-table__picture">
                <img src="http://localhost/sites/all/modules/listing_flyer/images/placeholder.png" alt="Allyson Williams picture">
            </div>
            <div class="network-table__column network-table__user-info">
                <span class="network-table__user-info-title">{{ item.first_name }} {{ item.last_name }}</span>
            </div>
            <div class="network-table__column network-table__user-info">
                <span class="network-table__user-info-title">{{ item.title }}</span>
            </div>
            <div class="network-table__column network-table__user-info">
                <span class="network-table__user-info-title">{{ item.email }}</span>
            </div>
            <div class="network-table__column network-table__actions">
                
                <button :data-iid="item.id" href="#" @click="removeFromArchive(item.id)" @mouseover="show_tooltip_remove_archive(item.id)" @mouseout="hide_tooltip_remove_archive(item.id)" data-action="delete-invite" class="network-table__action network-table__action--icon btn-has-tooltip" style="cursor: pointer;">
                    <span class="loader loader-circle hide" data-type="default"></span>
                    <span class="glyphicon glyphicon-log-in" aria-hidden="true"></span>

                    <label class="tooltip-delete btn-tooltip-remove-archive" :data-iid="item.id" >Remove from Archive</label>
                </button>
                <button @mouseover="show_tooltip_edit_archive(item.id)" @mouseout="hide_tooltip_edit_archive(item.id)" :data-iid="item.id"  href="#" @click="editRealtor(item.id)" data-action="delete-invite" class="network-table__action network-table__action--icon btn-has-tooltip" style="cursor: pointer;">
                    <span class="glyphicon glyphicon-edit" aria-hidden="true"></span>
                    <label class="tooltip-delete btn-tooltip-edit-archive" :data-iid="item.id" >Edit</label>
                </button>
            </div>
        </div>
    </div>
    <BaseModal
        ref="modal"
        v-model:show-modal="showForm"
        id="manualFlyerModal"
        title="Manage Realtor"
        @ok="submitForm"
        okLabel="Save"
        @cancel="clear"
        okClass="btn btn-modern submitBtn">
        <form
            id="manual-flyer-form"
            class="form"
            ref="manualFlyerForm"
            v-on:submit.prevent
            >
            <LoaderPlaceholder v-if="isDataLoading" type="circle" />
            <RealtorForm v-else v-model="form"
                        :onInputStates="onInputStates"
            />
        </form>
    </BaseModal>
    </div>
</template>
<script>
import _find from "lodash/find"
import BaseAutoComplete from "../BaseAutocomplete"
import BaseButton  from "../BaseButton"
import DownloadExcel from "vue-json-excel"
import {
  FormStep,
  FormStepRealtor
} from "../ManualListing/ManualListingForm"
import BaseModal from "../BaseModal"
import FlexBox from "../FlexBox"
import Badge from "../Badge"
import RealtorForm from './RealtorForm'
export default {
    name: "MyNetworkArchive",
    components: {
        BaseAutoComplete,
        BaseButton,
        DownloadExcel,
        RealtorForm,
        BaseModal,
        FlexBox, 
        Badge
    },

    data: function() {
        return {
            isLoading:true,
            isArchiving:false,
            isDataLoading:false,
            showForm: false,
            realtorList:[],
            searchQueries:[],
            value:{},
            form: {
                pictures: [],
                realtorData: {}
            },
            dataFieldsForExport: {
                "First Name": "first_name",
                "Last Name": "last_name",
                "Email": "email",
                "Phone": "phone",
                "Picture": "picture",
                "Agency Name": "agency_name",
                "Agency Logo": "agency_logo",
                State: "state",
                "Title": "title"
            },
            showSaveNext: true,
            isRealtorEdit:true,
            placeholder: "Search by Realtor",
            listOptions: [
                { icon: "user", text: "Realtor Name", value: "first_name",  type: "archive" },
                { icon: "envelope", text: "Realtor Email", value: "email", type: "archive" },
                { icon: "phone", text: "Realtor Phone", value: "phone", type: "archive" },
                { icon: "user", text: "Realtor Title", value: "title", type: "archive" },
                { icon: "user", text: "Realtor Agency Name", value: "agency_name", type: "archive" },
                { icon: "edit", text: "Realtor Agency State", value: "state", type: "archive" },
            ],
        }
    },
    methods: Object.assign(
        {},
        {
            show_tooltip_remove_archive : function(id) {
                $('.btn-tooltip-remove-archive[data-iid='+id+']').show();
            },
            hide_tooltip_remove_archive : function(id) {
                $('.btn-tooltip-remove-archive[data-iid='+id+']').hide();
            },
            show_tooltip_edit_archive : function(id) {
                $('.btn-tooltip-edit-archive[data-iid='+id+']').show();
            },
            hide_tooltip_edit_archive : function(id) {
                $('.btn-tooltip-edit-archive[data-iid='+id+']').hide();
            },
            showModal: function() {
                this.showForm = !this.showForm
            },
            onInputStates(val, event) {
                this.$set(this.form, 'state', val)
                return val;
            },
            onSubmit(query) {
                let filter = {
                    icon: query.option.icon,
                    value: query.text,
                    column: query.option.value
                }
                this.isLoading= true;
                this.addFilter(filter)
            },
            async getExcelData(){
                const res = await this.runReport();
                return res;
            },
            async runReport(){
                let dt={};
                dt['limit'] = 9999;
                dt['owner'] = $("body").data("user_id");
                dt['archived']=1;
                dt['orderby'] = 'ASC';
                for(let i=0; i<this.searchQueries.length; i++){
                    let column = this.searchQueries[i].column;
                    let value = this.searchQueries[i].value;
                    dt[column] = value;
                }
                this.realtorList = [];
                this.isLoading = true;
                let that = this;
                return jQuery.ajax({
                    url: '/api/v1/manual-realtors',
                    type: "GET",
                    contentType: "application/json",
                    dataType: "json",
                    data:dt,
                    success(data) {
                        that.realtorList = data;
                        that.isLoading= false;                
                    },
                    error(xhr, ajaxOptions, thrownError) { 
                    }
                }
                )
            },
            addFilter(query) {
                if(!this.containsFilter(query)) {
                    this.searchQueries.push(query)
                    this.runReport()
                }
                else {
                    BB.Toastr.warning("Filter has already been added.")
                } 
            },
            containsFilter(query) {
                for(let i = 0; i < this.searchQueries.length; i++) {
                    if(this.searchQueries[i].value === query.value 
                    && this.searchQueries[i].column === query.column)
                    return true
                }
                return false
            },  
            removeFilter(index) {
                this.isLoading= true;
                this.searchQueries.splice(index, 1)
                this.runReport()
            },
            submitForm(){                
                const {
                    first_name,
                    last_name,
                    title,
                    email,
                    phone,
                    agency_name,
                    agency_logo,
                    picture,
                    state
                    } = this.form;

                    const newRealtor = {
                        first_name:first_name,
                        last_name:last_name,
                        title:title,
                        email:email,
                        phone:phone,
                        agency_name:agency_name,
                        agency_logo:agency_logo,
                        picture:picture,
                        state:state,
                        archived:1
                    }

                if(!this.checkForm()){
                    BB.Toastr.error('Fill in all the required fields');
                    return;
                }
                this.isDataLoading = true;
                $('.submitBtn').attr('disabled', 'disabled');
                const that = this;
                if(this.form.id != undefined && this.form.id != ''){
                    newRealtor.id = this.form.id;
                    BB.realtorManage.manual.update(newRealtor).then(function(data) {
                        if(data.status == "error"){
                            BB.Toastr.error(data.message)
                        }
                        else{
                            that.showModal();
                            that.isLoading=true
                            that.isDataLoading = false;
                            that.runReport()
                            BB.Toastr.success('Realtor Updated')
                            that.clear()
                        }
                    });
                }
                else{
                    BB.realtorManage.manual.create(newRealtor).then(function(data) {
                        if(data.status == "error"){
                            BB.Toastr.error(data.message)
                        }
                        else{
                            that.showModal();
                            that.isLoading=true
                            that.runReport()
                            BB.Toastr.success('Realtor Inserted')
                            that.clear()
                        }
                    });
                }
            },
            editRealtor(id){
                const that = this
                this.isDataLoading=true;
                this.showModal();
                const data = _find(this.realtorList, ["id", id])
                this.form = data;
                that.isDataLoading=false;
            },
            clear(){
                this.form = {};
            },
            removeFromArchive(id)
            {
                let that = this;
                let dataToSend={};
                dataToSend['id']=id;
                dataToSend['archived']=0;
                $('#network-invite-'+id+' .glyphicon-log-in').addClass('hide');
                $('#network-invite-'+id+' .loader-circle').removeClass('hide');
                jQuery.ajax({
                    url: '/api/v1/manual-realtors',
                    type: "POST",
                    contentType: "application/json",
                    dataType: "json",
                    data:JSON.stringify(dataToSend),
                    success(data) {
                        $('#network-invite-'+id+' .glyphicon-log-in').removeClass('hide');
                        $('#network-invite-'+id+' .loader-circle').addClass('hide');
                        $('#network-invite-'+id).remove();
                        BB.Toastr.success('Realtor restored');
                        that.$root.$refs.MyNetworkManualListing.runReport();
                    },
                    error(xhr, ajaxOptions, thrownError) { 
                    }
                }
                )
            },
            checkForm() {
                let count = 0
                const requiredFields = [
                "first_name",
                "last_name",
                "email",
                "phone",
                "agency_name",
                "state",
                "agency_logo",
                "picture",
                "title"
                ]
                let that = this;
                requiredFields.forEach(field => {
                    that.form[field] &&
                    that.form[field].length &&
                    count++
                })
                return (count === requiredFields.length)
            }
        }
    ),
    created : function() {
        let that = this;
        this.$root.$refs.MyNetworkArchive = this;
        jQuery.ajax({
            url: '/api/v1/manual-realtors',
            type: "GET",
            contentType: "application/json",
            dataType: "json",
            data:{ limit: 9999, owner: $("body").data("user_id"), orderby: 'ASC', archived:1 },
            success(data) {
                that.realtorList = data;
                that.isLoading= false;                
            },
            error(xhr, ajaxOptions, thrownError) { 
            }
        }
        )
    },
}
</script>
<style lang="scss">
.btn-has-tooltip {
    position: relative;
}
.btn-tooltip-archive, .btn-tooltip-edit, .btn-tooltip-remove-archive, .btn-tooltip-edit-archive {
    display: none;
    position: absolute;
    top: -25px;
    left: -21px;
    background: #eee;
    width: 52px;
    height: 22px;
    padding: 5px;
    z-index: 11111;
    max-width: 200px;
    border-radius: 5px;
    color: #999;
    font-size: 12px;
}
.btn-tooltip-remove-archive {
    width: 145px;
    left: -69px;
}
.btn-tooltip-archive:after, .btn-tooltip-edit:after, .btn-tooltip-remove-archive:after, .btn-tooltip-edit-archive:after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 22px;
    background-color: #eee;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
}
.btn-tooltip-remove-archive:after {
    left: 70px;
}
.search-realtor {
    display: flex;
    flex-flow: row-reverse;
    margin-right: 10px;
    margin-top: 5px;
}
.tabs-component-panel .search-realtor .form-group {
    margin-bottom: 0px;
}
.btn.btn-circle {
    background: #f1673e;
    border-radius: 25px;
    color: #fff;
    font-size: 20px;
    width: 44px;
    height: 44px;    
}
.header-search {
    display: flex;
    flex-flow: row-reverse;
}
.header-export {
    margin-bottom: 15px;
}
.glyphicon.glyphicon-log-in {
    transform: rotate(180Deg);
}
button .loader.loader-circle{
    width: 16px;
    height: 16px;
}
</style>