<doc>
    Archive Vue.js component
</doc>
<template>
    <div id="extraProfileDetails">
        <div class="form-group">
            <img :src="lo.profile_image" width="64" />
        </div>
        <div class="form-group">&nbsp;</div>
        <div class="form-group">
            <label class="control-label" for="inputTitle">Title</label>
            <InputText
                v-model="lo.title"
                name="title"
            />
            <span class="desc">Your title or position at your bank (example: VP, Mortgage Banker)</span>
        </div>


        <div class="form-group">
            <label class="control-label" for="inputTitle">Loan Application Link</label>
            <InputText
                v-model="lo.app_link"
                name="app_link"
            />
            <span class="desc">The link that a customer should use to apply for a loan or to get pre-approved </span>
        </div>

        <div class="form-group">
            <label class="control-label" for="inputStreet">Street Address</label>
            <InputText
                v-model="lo.office_street_addr"
                name="office_street_addr"
            />
            <span class="desc">Enter the street address of your branch/office</span>
        </div>

        <div class="form-group">
            <label class="control-label" for="inputCity">City</label>
            <InputText
                v-model="lo.office_city"
                name="office_city"
            />
            <span class="desc">Enter the city of your branch/office</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="inputState">State</label>
            <InputText
                v-model="lo.office_state"
                name="office_state"
            />
            <span class="desc">Enter the state of your branch/office</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="inputZipcode">Zipcode</label>
            <InputText
                v-model="lo.office_zipcode"
                name="office_zipcode"
            />
            <span class="desc">Enter the zipcode of your branch/office</span>
        </div>

        <div class="form-group">
            <label class="control-label" for="inputFbLink">Facebook Profile Link</label>
            <InputText
                v-model="lo.facebook_link"
                name="facebook_link"
            />
            <span class="desc">Enter URL of facebook profile</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="inputInLink">LinkedIn Profile Link</label>
            <InputText
                v-model="lo.linkedin_link"
                name="linkedin_link"
            />
            <span class="desc">Enter URL of LinkedIn profile</span>
        </div>

        <div class="form-group">
            <label class="control-label" for="inputAboutWebsite">Website</label>
            <InputText
                v-model="lo.website_link"
                name="website_link"
            />
            <span class="desc">Bank specific webpage/landing page URL</span>
        </div>

        <div class="form-group">
            <label class="control-label" for="inputSpecialDisclosureLink">Special Disclosure Link</label>
            <InputText
                v-model="lo.special_disclosure_link"
                name="special_disclosure_link"
            />
            <span class="desc">Enter the URL to the disclosure page</span>
        </div>


        <div class="form-group">
            <label class="control-label" for="inputAboutMe">About Me</label>
            <InputTextArea
                v-model="lo.about_me"
                name="about_me"
            />
            <span class="desc">"About Me" blurb to introduce yourself to homebuyers</span>
        </div>

        <div class="form-group">
            <label class="control-label" for="inputAboutBank">About Bank</label>
            <InputTextArea
                v-model="lo.about_bank"
                name="about_bank"
            />
            <span class="desc">"About Bank" blurb to introduce your bank to homebuyers</span>
        </div>







        <!-- <div class="form-group">
            <label class="control-label" for="inputDirectPhone">Direct Phone</label>
            <InputText
                v-model="lo.direct_phone"
                name="direct_phone"
            />
            <span class="desc">Enter your direct phone number</span>
        </div>
        

        <div class="form-group form-group-full">
			<div class="form-group-subtitle">Social</div>
			<hr class="separator">	
		</div>
		
		


        <div class="form-group form-group-full">
			<div class="form-group-subtitle">About</div>
			<hr class="separator">	
		</div>
        
        
        
		<div class="form-group form-group-full">
			<div class="form-group-subtitle">Office Address</div>
			<hr class="separator">			
		</div>
        
        
        
        <div class="form-group">
            <label class="control-label" for="address1">Branch Address 1</label>
            <InputText
                v-model="lo.address1"
                name="address1"
            />
            <span class="desc">Enter Branch Address 1</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="address2">Brach Address 2</label>
            <InputText
                v-model="lo.address2"
                name="address2"
            />
            <span class="desc">Enter Branch Address 2</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="city">Branch City</label>
            <InputText
                v-model="lo.city"
                name="city"
            />
            <span class="desc">Enter Branch City</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="state">Branch State</label>
            <InputText
                v-model="lo.state"
                name="state"
            />
            <span class="desc">Enter Branch State</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="postal_code">Branch Postal Code</label>
           <InputText
                v-model="lo.postal_code"
                name="postal_code"
            />
            <span class="desc">Enter Branch Postal Code</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="phone">Branch Phone</label>
            <InputText
                v-model="lo.phone"
                name="phone"
            />
            <span class="desc">Enter Branch Phone</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="inputPersonalLink">Personal Link</label>
            <InputText
                v-model="lo.personal_link"
                name="personal_link"
            />
            <span class="desc">Enter Personal Link</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="inputOtherLink">Other Link</label>
            <InputText
                v-model="lo.other_link"
                name="other_link"
            />
            <span class="desc">Enter Other Link</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="inputCustom1">Primary Agent Custom 1</label>
            <InputText
                v-model="lo.custom1"
                name="custom1"
            />
            <span class="desc">Enter Primary Agent Custom 1</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="inputCustom2">Primary Agent Custom 2</label>
            <InputText
                v-model="lo.custom2"
                name="custom2"
            />
            <span class="desc">Enter Primary Agent Custom 2</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="inputCustom3">Primary Agent Custom 3</label>
            <InputText
                v-model="lo.custom3"
                name="custom3"
            />
            <span class="desc">Enter Primary Agent Custom 3</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="inputCustom4">Primary Agent Custom 4</label>
            <InputText
                v-model="lo.custom4"
                name="custom4"
            />
            <span class="desc">Enter Primary Agent Custom 4</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="inputCustom5">Primary Agent Custom 5</label>
            <InputText
                v-model="lo.custom5"
                name="custom5"
            />
            <span class="desc">Enter Primary Agent Custom 5</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="inputCustom6">Primary Agent Custom 6</label>
            <InputText
                v-model="lo.custom6"
                name="custom6"
            />
            <span class="desc">Enter Primary Agent Custom 6</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="inputCustom7">Primary Agent Custom 7</label>
            <InputText
                v-model="lo.custom7"
                name="custom7"
            />
            <span class="desc">Enter Primary Agent Custom 7</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="inputCustom8">Custom 8</label>
            <InputText
                v-model="lo.custom8"
                name="custom8"
            />
            <span class="desc">Enter Primary Agent Custom 8</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="inputCustom9">Custom 9</label>
            <InputText
                v-model="lo.custom9"
                name="custom9"
            />
            <span class="desc">Enter Primary Agent Custom 9</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="inputCustom10">Custom 10</label>
            <InputText
                v-model="lo.custom10"
                name="custom10"
            />
            <span class="desc">Enter Primary Agent Custom 10</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="inputCustom11">Custom 11</label>
            <InputText
                v-model="lo.custom11"
                name="custom11"
            />
            <span class="desc">Enter Primary Agent Custom 11</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="inputCustom12">Custom 12</label>
            <InputText
                v-model="lo.custom12"
                name="custom12"
            />
            <span class="desc">Enter Primary Agent Custom 12</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="inputCustom13">Custom 13</label>
            <InputText
                v-model="lo.custom13"
                name="custom13"
            />
            <span class="desc">Enter Primary Agent Custom 13</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="inputCustom14">Custom 14</label>
            <InputText
                v-model="lo.custom14"
                name="custom14"
            />
            <span class="desc">Enter Primary Agent Custom 14</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="inputCustom15">Custom 15</label>
            <InputText
                v-model="lo.custom15"
                name="custom15"
            />
            <span class="desc">Enter Primary Agent Custom 15</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="inputCustom16">Custom 16</label>
            <InputText
                v-model="lo.custom16"
                name="custom16"
            />
            <span class="desc">Enter Primary Agent Custom 16</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="inputCustom17">Custom 17</label>
            <InputText
                v-model="lo.custom17"
                name="custom17"
            />
            <span class="desc">Enter Primary Agent Custom 17</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="inputCustom18">Responsibility</label>
            <InputText
                v-model="lo.responsibility"
                name="responsibility"
            />
            <span class="desc">Enter Responsibility</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="system_branch_other1">System Branch Other 1 </label>
            <InputText
                v-model="lo.system_branch_other1"
                name="system_branch_other1"
            />
            <span class="desc">Enter System Branch Other 1</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="system_branch_other2">System Branch Other 2</label>
            <InputText
                v-model="lo.system_branch_other2"
                name="system_branch_other2"
            />
            <span class="desc">Enter System Branch Other 2</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="system_branch_other3">System Branch Other 3</label>
            <InputText
                v-model="lo.system_branch_other3"
                name="system_branch_other3"
            />
            <span class="desc">Enter System Branch Other 3</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="system_branch_other4">System Branch Other 4</label>
            <InputText
                v-model="lo.system_branch_other4"
                name="system_branch_other4"
            />
            <span class="desc">Enter System Branch Other 4</span>
        </div> -->
        <div class="form-group form-group-full">
            <button class="btn btn-modern" @click="save">Save</button>
        </div>
    </div>
</template>
<script>
import _find from "lodash/find"
import { createNamespacedHelpers } from "vuex"
const { mapState, mapActions } = createNamespacedHelpers("user-profile-details")
export default {
    name: "MarketingDetail",
    components: {
    },
    data() {
    return {
        lo: [],
    }
  },
  async mounted(){
    console.log('mounted marketing', fields['lflop_applink'].value);

    this.lo.app_link=fields['lflop_applink'].value
  },
  methods: Object.assign(
    {},
        mapActions([
            
        ]),
        {
            save(){
                const data = {"extra_details":this.lo};
                $.ajax({
                type: 'POST',
                url: '/lo-manage.json',
                data: JSON.stringify(data),
                contentType: "application/json",
                dataType: "json",
                success: function (response) {
                    // Display errors.
                    if (response.status == 'success') {
                        console.log('ok');
                        BB.Toastr.success(response.message);
                    }
                    else{

                    }
                },
                error: function (xmlhttp) {
                    
                }
            });
            }
        }
    ),
    created : function() {
        //this.initTypeahead();
        console.log('created marketing');
        let that = this;
        jQuery.ajax({
            url: '/lo-manage.json?loid='+uid,
            type: "GET",
            contentType: "application/json",
            dataType: "json",
            success(data) {
                that.lo = data.lo.extra_details
                that.lo.app_link = fields['lflop_applink'].value
                that.lo.profile_image = extra_fields.profile_image 
                console.log('extra_fields[profile_image] ', extra_fields.profile_image );
            },
            error(xhr, ajaxOptions, thrownError) { 
            }
        })
    }
}
</script>
<style lang="scss" scoped>
	#extraProfileDetails {
		display: flex;
		flex-wrap: wrap;
	}
	#extraProfileDetails .form-group {
		width: 43%;
		margin-right: 2%;
	}
	#extraProfileDetails .form-group .form-group {
		width: 100%;
		margin-right: 0;
	}
	#extraProfileDetails .form-group .form-group {
		margin-top: 10px;
	}
	#extraProfileDetails .form-group.form-group-full {
		width: 100%;
		margin-right: 0%;
	}
	#extraProfileDetails textarea.form-control {
		width: 100%;
		height: 200px!important;
	}
	#extraProfileDetails label.input-label  {
		display: none;
	}
	#extraProfileDetails .form-group {
		margin-bottom: 30px;
	}
</style>