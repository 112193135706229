<doc>
  Step 1 manual flyer form Vue.js component
</doc>

<template>
  <div>
    <div v-show="!isLoading">
      <div v-if="!showRealtorForm">
        <CustomDropdown
          placeholder="Search for a realtor..."
          name="realtor"
          v-model="currentVal"
          :options="realtorList"
          reducer="id"
          :onInput="onInput"
          :addOption="toggleRealtorFields"
        />
        <!-- <BaseButton
          class="btn-edit"
          v-if="showEditBtn"
          theme="modern"
          @click="editRealtor"
          >Edit realtor</BaseButton
        > -->
        <p>
          Or
          <span class="add-new-realtor" @click="toggleRealtorFields(true)">
            create a new one
          </span>
        </p>
      </div>
      <FormRealtor
        v-else
        v-model="value"
        :editMode="isEdit"
        :onInputStates="onInputStates"
        :enableSaveNext="enableSaveNext"
        :disableSaveNext="disableSaveNext"
      />
    </div>
    <LoaderPlaceholder v-if="isLoading" type="circle" />
  </div>
</template>

<script>
import _find from "lodash/find"

import { CustomDropdown } from "../../Forms"
import FormRealtor from "./FormRealtor"

export default {
  name: "FormStep1",
  props: {
    showRealtorForm: { type: Boolean, default: false },
    value: { type: Object, required: true },
    onInputRealtor: { type: Function, required: true },
    onInputStates: { type: Function, required: true },
    toggleRealtorFields: { type: Function, required: true },
    showEditMode: { type: Function, required: true },
    isEdit: { type: Boolean, default: false },
    enableSaveNext: { type: Function, required: true },
    disableSaveNext: { type: Function, required: true }
  },
  components: { CustomDropdown, FormRealtor },
  data() {
    return {
      realtorFullList: [],
      realtorList: [],
      showEditBtn: false,
      isLoading: true,
      runOnce: false
    }
  },
  methods: {
    onInput(val, $event, id) {
      this.showEditBtn = !!val
      const rl = this.realtorList
      this.realtorList=[]
      this.realtorList = rl
      const itemId = ($event && $event.target.id) || id

      const realtor = _find(this.realtorList, ["id", val])
      this.onInputRealtor(val, realtor, itemId)
    },
    editRealtor() {
	  let realtor = _find(this.realtorFullList, ["id", this.value.realtor])
      realtor.picture = realtor.picture.includes('placeholder.png')?'':realtor.picture
      realtor.agency_logo = realtor.agency_logo.includes('realtor_logo.png')?'':realtor.agency_logo
      this.onInputRealtor(this.value.realtor, realtor, "realtor")
      this.showEditMode()
      this.toggleRealtorFields()
    },
    async reloadRealtors(){
      let that = this
      BB.realtorManage.all.get({limit:9999,owner:this.$store.state.manualListing.uid}).then(function(data) {
        that.isLoading = false
        let realtors = []
        data.forEach(function(item) {
          //  [{label: 'Canada', code: 'ca'}]
          realtors.push({
            label: `${item.first_name} ${item.last_name}`,
            id: item.id
          })
        })
        that.realtorList = realtors
        that.realtorFullList = data
      })
    }
  },
  computed: {
    currentVal: function() {
      return _find(this.realtorList, ["id", this.value.realtor])
    }
  },
  created() {
    this.reloadRealtors()
  },
  beforeMount() {
    this.showEditBtn = !!this.value.realtor
  },
  updated(){
    if(this.value.realtor && !this.runOnce){
      this.showEditBtn = !!this.value.realtor
      this.runOnce=true
      this.reloadRealtors()
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-realtor {
  color: #5dbec7;
  cursor: pointer;
}
.btn-edit {
  margin-bottom: 16px;
}
</style>
