var disposed = false
function injectStyle (context) {
  if (disposed) return
  require("!!vue-style-loader!css-loader!../../../node_modules/vue-loader/lib/style-compiler/index?{\"optionsId\":\"0\",\"vue\":true,\"id\":\"data-v-5183affc\",\"scoped\":true,\"sourceMap\":true}!sass-loader!postcss-loader!sass-resources-loader?{\"resources\":\"/home/ec2-user/bankingbridge-ui/sass/_vue-components-settings.scss\"}!../../../node_modules/vue-loader/lib/selector?type=styles&index=0!./Settings.vue")
}
/* script */
export * from "!!babel-loader!../../../node_modules/vue-loader/lib/selector?type=script&index=0!./Settings.vue"
import __vue_script__ from "!!babel-loader!../../../node_modules/vue-loader/lib/selector?type=script&index=0!./Settings.vue"
/* template */
import {render as __vue_render__, staticRenderFns as __vue_static_render_fns__} from "!!../../../node_modules/vue-loader/lib/template-compiler/index?{\"id\":\"data-v-5183affc\",\"hasScoped\":true,\"optionsId\":\"0\",\"buble\":{\"transforms\":{}}}!../../../node_modules/vue-loader/lib/selector?type=template&index=0!./Settings.vue"
/* template functional */
var __vue_template_functional__ = false
/* styles */
var __vue_styles__ = injectStyle
/* scopeId */
var __vue_scopeId__ = "data-v-5183affc"
/* moduleIdentifier (server only) */
var __vue_module_identifier__ = null
import normalizeComponent from "!../../../node_modules/vue-loader/lib/runtime/component-normalizer"
var Component = normalizeComponent(
  __vue_script__,
  __vue_render__,
  __vue_static_render_fns__,
  __vue_template_functional__,
  __vue_styles__,
  __vue_scopeId__,
  __vue_module_identifier__
)
Component.options.__file = "js/components/IntegrationSettings/Settings.vue"

/* hot reload */
if (module.hot) {(function () {
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), false)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-5183affc", Component.options)
  } else {
    hotAPI.reload("data-v-5183affc", Component.options)
  }
  module.hot.dispose(function (data) {
    disposed = true
  })
})()}

export default Component.exports
