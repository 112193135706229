"use strict"
// Vue components
require("./vue-setup")
import bbPricing from "./bb-pricing"
import bbManager from "./bb-manager"
import bbLinks from "./bb-links"
import bbRates from "./bb-rates"
import bbStaticFlyers from "./bb-static-flyers"
import bbNetworkTab from "./bb-network-tab"
import bbFlyerTemplateManager from "./bb-manage-flyer-templates"
import bbLeadWorkflowManager from "./bb-lead-workflow-manager"
import bbRateTableManager from "./bb-rate-table-manager"
import bbConfigEditorManager from "./bb-config-editor-manager"
import bbLeadRateComparison from "./bb-lead-rate-comparison"
import bbSubscriptionDashboard from "./bb-subscription-dashboard"
import bbPricingLog from "./bb-pricing-log"
import bbPricingTool from "./bb-pricing-tool"
import bbLeadLog from "./bb-lead-log"
import bbSubscriptionLog from "./bb-subscription-log"
import bbNewCustomer from "./bb-new-customer"
import bbMyArchive from "./bb-my-archive"
import bbImpersonate from "./bb-impersonate"
import bbProfile from "./bb-profile"
// API manager
import {
  bbCountyManage,
  bbLeadManage,
  bbLOManage,
  bbOrgManage,
  bbOrgActivity,
  bbBrandManage,
  bbRateflow,
  bbRealtorManage,
  bbInviteManage,
  bbListingManage,
  bbStaticFlyer,
  bbStaticFlyerTags,
  bbPreferencesManage,
  bbTemplateManage,
  bbTagManage,
  bbRateSubscription,
  bbLeadWorkflow,
  bbIntegration,
  bbEmbedMonitor,
  bbContactForm,
  bbPricingSet,
  bbRateTableConfig,
  bbTemplatePermissions,
  bbOrgBrandManage,
  bbArchives,
  bbBrandSignatureManage
} from "./bb-api/"

// Vendor
const address = require("jquery-address")
const jQueryBridget = require("jquery-bridget")
const ui = require("./vendor/jquery-ui.min.js")
require("./vendor/jquery.ui.touch-punch.min.js")
const Waypoint = require("./jquery.waypoints.min.js")
const Toastr = require("toastr")
require("slick-carousel")
const Tippy = require("./tippy.all.min.js")
const Cookie = require("js-cookie")
const uiSlider = require("nouislider")
const _ = require("lodash")
// require('slick-carousel/slick/slick.css');
// require('slick-carousel/slick/slick-theme.css');

require("./jquery.scrollTo.min.js")

// Dependencies
const detailMap = require("./bb-maps")
const bbListings = require("./bb-listings")
const bbListingDetail = require("./bb-listing-detail")
const bbImportStatus = require("./bb-import-status")
const bbManageFlyerTemplates = require("./bb-manage-flyer-templates")
const bbContactFormManage = require("./bb-contact-form-manager")
const getSelectedListings = bbListings.getSelectedListings
const bulkOps = bbListings.bulkOps
const charts = require("./bb-charts")
const bbNetwork = require("./bb-network")
const bbNetworkSearch = require("./bb-network-search")
const bbInvite = require("./bb-invite")
const bbLaunchPad = require("./bb-launchpad")
const bbSocial = require("./bb-social")
const bbIntegrations = require("./bb-integrations")
const bbDashboard = require("./bb-dashboard")

const bbHost = location.protocol + "//" + location.host + "/"

const getFormObject = function(serializedArray) {
  let formObject = {}
  $.each(serializedArray, function(index, field) {
    formObject[field.name] = $.trim(field.value)
  })
  return formObject
}

const bbChart = {
  checkForDate: function(headers) {
    for (var i = 0; i < headers.length; i++) {
      if (headers[i] == "Date") {
        return i
      }
    }
    return false
  },
  convertDate: function(data_set, col_num) {
    var new_data = []
    new_data[0] = data_set[0]
    for (var i = 1; i < data_set.length; i++) {
      var temp = []
      for (var j = 0; j < data_set[i].length; j++) {
        if (j == col_num) {
          var datevar = new Date(data_set[i][col_num])
          var parts = datevar.toDateString().split(" ")
          temp[j] = parts[1] + " " + parts[2]
        } else {
          temp[j] = data_set[i][j]
        }
      }
      new_data[i] = temp
    }
    return new_data
  },
  getData: function(params, callback) {
    var _this = this
    if (!params.profile_id) {
      console.log("profile id missing")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }
    if (!params.type) {
      params.type = "scatter_plot"
    }
    var defaultOptions = {
      title: "",
      subtitle: "",
      hAxis: {
        //   slantedText:true,
        //   slantedTextAngle:55,
        gridlines: { color: "transparent", lineWidth: 2 },
        textStyle: { color: "#a1a1a1", fontName: "Lucida Grande", fontSize: 12 }
      },
      vAxis: {
        gridlines: { color: "transparent" },
        textStyle: {
          color: "#a1a1a1",
          fontName: "Lucida Grande",
          fontSize: 14,
          bold: true
        }
      },
      legend: "none",
      interpolateNulls: true,
      series: {
        0: {
          lineWidth: 1,
          pointSize: 3
        },
        1: {
          lineWidth: 4,
          pointSize: 0,
          lineDashStyle: [10, 2]
        }
      },
      //backgroundColor:"#4053AF",
      colors: ["#4EB3E5", "#78E09C"],
      // width: chart_info.width,
      // height: chart_info.height,
      width: "100%",
      top: 0,
      chartArea: {
        left: "6%",
        top: "6%",
        bottom: "20%",
        width: "88%",
        height: "80%",
        backgroundColor: "#fff"
      }
    }
    return $.get("/api/v1/chart-data", params, function(res) {
      var options = null
      if (!res.options) {
        options = defaultOptions
      }
      if (params.type == "line_chart") {
        let overrides = {
          hAxis: {
            //   slantedText:true,
            //   slantedTextAngle:55,
            gridlines: { color: "transparent", lineWidth: 1 },
            textStyle: {
              color: "#50525a",
              fontName: "Lucida Grande",
              fontSize: 12
            }
          },
          vAxis: {
            gridlines: { color: "#ddd", lineWidth: 1 },
            minorGridlines: { color: "transparent" },
            textStyle: {
              color: "#50525a",
              fontName: "Lucida Grande",
              fontSize: 12
            }
          },
          series: {
            0: {
              lineWidth: 3,
              pointSize: 0
            },
            1: {
              lineWidth: 0,
              pointSize: 8
            },
            2: {
              lineWidth: 0,
              pointSize: 8
            },
            3: {
              lineWidth: 3,
              pointSize: 0,
              lineDashStyle: [0, 0]
            },
            4: {
              lineWidth: 0,
              pointSize: 8
            },
            5: {
              lineWidth: 0,
              pointSize: 8
            }
          },
          chartArea: {
            left: "6%",
            top: "6%",
            bottom: "15%",
            width: "88%",
            height: "80%",
            backgroundColor: "#fff"
          },
          colors: [
            "#4EB3E5",
            "#e53935",
            "#6200EE",
            "#78E09C",
            "#e53935",
            "#6200EE"
          ]
        }
        options = _.merge(defaultOptions, overrides)
      }
      res.options = options
      const dateCol = _this.checkForDate(res.data[0])
      if (dateCol !== false) {
        res.data = _this.convertDate(res.data, dateCol)
      }
      if (callback && typeof callback === "function") {
        callback.call(this, res)
      }
    })
  },
  getImage: function(params, callback) {
    if (!params.profile_id) {
      console.log("profile id missing")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }
    if (!params.type) {
      params.type = "scatter_plot"
    }
    return $.get("/api/v1/chart", params, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  }
}

// jQuery DOM ready
$(function() {
  const LOID = document.body.getAttribute("data-user_id")
  // Init  Vue.js app based on the actual page
  const path = location.pathname.substr(1)
  const args = _.split(path, "/")
  switch (args[0]) {
    case "org-manager":
      bbManager(LOID)
      break
    case "my-programs":
      bbPricing()
      break
    case "my-links":
      bbLinks()
      break
    case "my-rates":
      bbRates()
      break
    case "my-flyers":
      bbStaticFlyers()
      break
    case "my-network":
      bbNetworkTab()
      break
    case "manage-flyer-templates":
      bbFlyerTemplateManager()
      break
    case "bb_lead_workflow_admin":
      bbLeadWorkflowManager()
      break
    case "rate_table_admin":
      bbRateTableManager()
    case "config-admin":
      bbConfigEditorManager()
      break
    case "lead-details":
      bbLeadRateComparison()
      break
    case "my-subscription-dashboard":
      bbSubscriptionDashboard()
      break
    case "pricing-log":
      bbPricingLog()
      break
    case "pricing-tool":
      bbPricingTool()
      break
    case "lead-log":
      bbLeadLog()
      break
    case "subscription-log":
      bbSubscriptionLog()
      break
    case "new-customer":
      bbNewCustomer()
      break
    case "my-archive":
      bbMyArchive()
      break
	case "impersonate":
      bbImpersonate()
      break
	case "my-profile-edit":
      bbProfile()
      break
  }

  if ($("body.page-user").length) {
    let path = window.location.pathname.split("/")
    if (path[path.length - 1] && path[path.length - 2] == "user") {
      let user_id = path[path.length - 1]
      $(".content_body").append(
        "<div style='margin-top:10px;'><a href='/integrations/" +
          user_id +
          "'>Integrations</a></div>"
      )
    }
  }

  $('[data-action="copy"]').click(function(e) {
    e.preventDefault()
    $($(this).data("target")).select()
    document.execCommand("copy")
    Toastr.success("Copied")
  })

  $(".requestDemo").click(function(e) {
    e.preventDefault()
    var formVals = $(this)
      .parent()
      .parent()
      .serializeArray()
    var message = ""
    var messagePart = ""
    var messageData = {}
    $.each(formVals, function(index, value) {
      if (value.name === "inputNameFirst") {
        messageData.name = value.value
      }
      if (value.name === "inputNameLast") {
        messageData.name += " " + value.value
      }
      if (value.name === "inputEmail") {
        messagePart += "Email: " + value.value + "\n"
        //messageData.email=value.value;
      }
      if (value.name === "phone") {
        messagePart +=
          "Phone: <a href=tel:" + value.value + ">" + value.value + "</a>\n"
        //messageData.phone=value.value;
      }
      if (value.name === "inputCompany") {
        messagePart += "Company: " + value.value + "\n"
        //messageData.company=value.value;
      }
      if (value.name === "inputCity") {
        messagePart += "City: " + value.value + "\n"
        //messageData.city=value.value;
      }
      if (value.name === "inputState") {
        messagePart += "State: " + value.value + "\n"
        //messageData.state=value.value;
      }
    })
    message =
      messageData.name +
      " has requested a demo. Here is their information: \n" +
      messagePart
    var url = "bb-email.json"
    //    var data = new FormData();
    //    data.append('message', "This is the message");
    //    data.append('subject', "Request Demo");
    var data = {}
    data.message = message
    data.subject = "Request Demo"
    data.bb_sid = $(this).data("bb_sid")

    $.ajax({
      url: url,
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(data),
      success: function(data) {
        Toastr.success(
          "Thank you for contacting us. We will be in touch shortly."
        )
        $(".requestDemo")
          .parent()
          .parent()[0]
          .reset()
      },
      error: function(xhr, ajaxOptions, thrownError) {
        //On error do this
        if (xhr.status == 200) {
          Toastr.success(
            "Thank you for contacting us. We will be in touch shortly."
          )
          //alert(ajaxOptions);
        } else {
          //                    alert(xhr.status);
          //                    alert(thrownError);
        }
      }
    })
    //console.log(formVals);
  })

  $(".btn_showing").click(function(e) {
    e.preventDefault()
    $("#form1").slideDown()
    $("html, body").animate({ scrollTop: $("#form1").offset().top - 35 }, 800)
  })

  // Search && filters deeplinking initialization
  $(".listings-views").length && bbListings.init()

  $("#bbListingFlyer").length && bbListingDetail.init()

  // bbNetwork page initialization
  $(".page-my-bb-network:not(.page-my-bb-network-search)").length &&
    bbNetwork.init()

  // bbNetwork search page initialization
  $(".page-my-bb-network-search").length && bbNetworkSearch.init()

  // Listings views triggers
  $(".check_all_in_page").change(function() {
    if (this.checked) {
      $(".bbListingCheckbox").each(function() {
        $(this)
          .prop("checked", true)
          .change()
        $(this)
          .parent()
          .parent()
          .parent()
          .parent()
          .addClass("bbActive")
      })
    } else {
      $(".bbListingCheckbox").each(function() {
        $(this)
          .prop("checked", false)
          .change()
        $(this)
          .parent()
          .parent()
          .parent()
          .parent()
          .removeClass("bbActive")
      })
    }
  })
  $(".check_all").change(function() {
    if (this.checked) {
      $(".bbListingCheckbox").each(function() {
        $(this)
          .prop("checked", true)
          .change()
        $(this)
          .parent()
          .parent()
          .parent()
          .parent()
          .addClass("bbActive")
      })
    } else {
      $(".bbListingCheckbox").each(function() {
        $(this)
          .prop("checked", false)
          .change()
        $(this)
          .parent()
          .parent()
          .parent()
          .parent()
          .removeClass("bbActive")
      })
    }
    var qStr = ""
    var basePath = $(".deep_link_base_path").data("base_path")
    if (this.checked) {
      if ($.address.queryString() !== "") {
        qStr = "?" + $.address.queryString()
      }
      if (qStr) {
        qStr = qStr + "&" + "limit=200"
      } else {
        qStr = "?" + "limit=200"
      }
      $.get(basePath + ".json" + qStr, function(data) {
        Toastr.info(data.results.total + " listings have been selected.", "", {
          timeOut: "10000",
          closeButton: true,
          positionClass: "toast-top-center"
        })
      })
    }
  })
  $(".bb_fab .menu-item").click(function(e) {
    e.preventDefault()
    var operation = $(this).data("bo")
    bulkOps[operation]()
  })

  // Details map
  if ($(".property_detail").length) {
    // be sure the google API is ready
    var mapInt = setInterval(function() {
      if (typeof google === "object" && typeof google.maps === "object") {
        detailMap()
        clearInterval(mapInt)
      }
    }, 500)
  }

  //Profile
  $(".bb-loan-officer #edit-profile-loan-officer-lflop-bankname input").prop(
    "disabled",
    true
  )
  $("#edit-profile-loan-officer-lflop-originator-id input").prop(
    "disabled",
    true
  )
  $("#edit-profile-loan-officer-lflop-biz-channel input").prop("disabled", true)

  $(".loanContainer").waypoint({
    handler: function(direction) {
      //console.log('Scrolled to waypoint!');
      $(".loanTab").toggleClass("active")
      $(".propertyTab").toggleClass("active")
    },
    offset: 60
  })

  $('a[href^="#"].animateScroll').on("click", function(event) {
    var aid = $(this).attr("href")
    var target = $("a[name='" + aid.replace("#", "") + "']")
    $(window).scrollTo(target, 550)
  })

  // Actions

  // Network Tools Buttons
  var addLender = function() {
    var mtstatus = 0
    $(".btn-add-lender", ".page-my-bb-network").on("click", function() {
      mtstatus == 0 ? (mtstatus = 1) : (mtstatus = 0)
      switch (mtstatus) {
        case 1:
          $(".my-network_tools.lender").slideDown()
          $(this).text("Hide Options")
          break
        case 0:
          $(".my-network_tools.lender").slideUp()
          $(this).text("Add Lender")
          break
        default:
          console.log("Default <")
      }
    })
  }
  var addRealtor = function() {
    var mtstatus = 0
    $(".btn-add-realtor", ".page-my-bb-network").on("click", function() {
      mtstatus == 0 ? (mtstatus = 1) : (mtstatus = 0)
      switch (mtstatus) {
        case 1:
          $(".my-network_tools.realtor").slideDown()
          $(this).text("Hide Options")
          break
        case 0:
          $(".my-network_tools.realtor").slideUp()
          $(this).text("Add Realtor")
          break
        default:
          console.log("Default <")
      }
    })
  }
  $(".lender-profile").length ? addLender() : ""
  $(".realtor-profile").length ? addRealtor() : ""

  // Contact panels
  $(".close-btn", ".contact-agent-form").on("click", function(e) {
    e.preventDefault()
    $(this)
      .parent()
      .slideUp()
  })

  $(".openForm", ".agent1").on("click", function() {
    $("#form1").slideDown()
    $("html, body").animate({ scrollTop: $("#form1").offset().top - 35 }, 800)
  })

  $(".openForm", ".agent2").on("click", function() {
    $("#form2").slideDown()
    $("html, body").animate({ scrollTop: $("#form2").offset().top - 35 }, 800)
  })

  // Collapse
  $(".collapse").collapse("hide")
  ///

  // Floating sidebar
  $(".menu-toggle-btn").on("click", function() {
    $(".sidebar").toggleClass(".expanded")
  })

  // Jquery UI sortable
  if ($(".sortable").length) {
    $(".sortable").sortable({ placeholder: "ui-state-highlight" })
    $(".sortable").disableSelection()
  }

  // Heart active button
  $(".heart-btn").on("click", function(event) {
    $(this).hasClass("active")
      ? $(this).removeClass("active")
      : $(this).addClass("active")
  })

  // Navbar behaviours
  $(".navbar-collapse").hide()
  $(".navbar-toggle").click(function(event) {
    $(".navbar-collapse").toggle("in")
  })

  // Search mobile
  // open panel
  $(".search-mobile-open").on("click", function() {
    $(".mobile-filters").show()
    $(".basics").hide()
  })
  // close panel
  $(".search-mobile-close").on("click", function() {
    $(".mobile-filters").hide()
    $(".basics").show()
  })

  // Floating sidebar
  $(".menu-toggle-btn").on("click", function() {
    $(".sidebar").toggleClass("expanded")
  })

  // Select grid element
  $(".select-item").change(function() {
    this.checked
      ? $(this)
          .closest(".listing")
          .addClass("active")
      : $(this)
          .closest(".listing")
          .removeClass("active")
  })

  $("input.select-item").change(function() {
    this.checked
      ? $(this)
          .closest(".listing")
          .addClass("active")
      : $(this)
          .closest(".listing")
          .removeClass("active")
  })
  // Select all checkboxs
  $("#checkPage").change(function() {
    $("input:checkbox", ".results_list")
      .prop("checked", $(this).prop("checked"))
      .change()
  })

  // Stop propagation into dropdowns
  $(".form-control, label, .dropdown-menu", ".dropdown").on("click", function(
    e
  ) {
    e.stopPropagation()
  })

  // Sticky nav
  var mn = $(".property-nav-mobile")
  var mns = "main-nav-scrolled"
  var hdr = $("header.main").height()
  $(window).scroll(function() {
    $(this).scrollTop() > hdr ? mn.addClass(mns) : mn.removeClass(mns)
  })

  // Charts
  $(".page-my-dashboard").length ? charts.chartActions() : ""
  $(".page-my-dashboard").length ? charts.line($("#table_chart1")) : ""
  $(".page-my-dashboard").length ? charts.bar($("#table_chart2")) : ""
  $(".page-my-dashboard").length ? charts.bar($("#table_chart3")) : ""
  $(".page-my-dashboard").length ? charts.line($("#table_chart4")) : ""

  // realtor signup
  $(".page-realtor-signup .bb-signup").click(function(e) {
    e.preventDefault()
    var formVals = $(".contact-form").serializeArray()
    var keyedFormArray = getKeyedFormArray(formVals)
    if (keyedFormArray["agentid"] && keyedFormArray["mls_sc"]) {
      $(".contact-form").addClass("hide")
      $(".loader-wrapper").removeClass("hide")
      $.get(
        "/realtor-record.json?agentid=" +
          keyedFormArray["agentid"] +
          "&mls_sc=" +
          keyedFormArray["mls_sc"] +
          "&bb_sid=" +
          $(this).data("bb_sid"),
        function(data) {
          $(".loader-wrapper").addClass("hide")
          //$(".signup-container").html('<div class="center-block"><span style="font-size:80px;color:green;" class="glyphicon glyphicon-ok-circle"></span></div>');
          if (data.status.message === "success" && data.results.realtors[0]) {
            $(".confirm-page").removeClass("hide")
            var realtor = data.results.realtors[0]
            $(".name").html(realtor.name)
            if (realtor.website) {
              $(".agency").html(
                "<a href='" +
                  realtor.website +
                  "'>" +
                  realtor.agency_name +
                  "</a>"
              )
            } else {
              $(".agency").html(realtor.agency_name)
            }
            $(".phone").html(realtor.phone)
            $(".email").html(realtor.email)
            $(".email-destination").text(realtor.email)
            $(".mls_name").html(realtor.mlsid)
            $(".bb-create-account").data("rid", realtor.rid)
            $(".bb-create-account").click(function(e) {
              e.preventDefault()
              $(".confirm-page").addClass("hide")
              $(".loader-wrapper").removeClass("hide")
              $.get(
                "/create-realtor.json?record_id=" +
                  $(this).data("rid") +
                  "&bb_sid=" +
                  $(this).data("bb_sid"),
                function(data) {
                  $(".loader-wrapper").addClass("hide")
                  $(".activation-sent").removeClass("hide")
                }
              )
            })
            $(".bb-not-me").click(function() {
              $(".confirm-page").addClass("hide")
              $(".not-me").removeClass("hide")
            })
          } else if (data.message === "user already activated") {
            $(".already-activated").removeClass("hide")
          } else {
            $(".contact-form").removeClass("hide")
            Toastr.error("No Realtors were found with that information.")
          }
        }
      )
    } else {
      Toastr.error("Please fill out all fields.")
    }
  })

  jQuery.fn.extend({
	initEmailShare: function(callback) {
      var _this = this
      //$("#social_preview_modal .social-square-logo").addClass("facebook")
	  $("#social_preview_modal .modal-title").html('<span class="social-square-logo email"></span>Email RE Agent')
      $("#social_preview_modal .share-type").html("email")
      $("#social_preview_modal").modal("show")
      //event.preventDefault();
      //event.stopPropagation();
      var nid = _this.data("nid")
      BB.social.email.prepare(nid).then(function(data) {
		$("#social_preview_modal").modal("hide")
        Toastr.success("Email Sent")
        $(".share-wrapper").removeClass("hide")
        $("img.share-preview").attr("src", data.image_url)
        var shareInfo = {
          share_id: data.bb_share_id,
          nid: nid,
          type: "fb",
          payload: data.payload
        }
        $("#social_preview_modal .social-post").data("share_info", shareInfo)
        $(".downloadShareImg").attr("href", data.image_url)
        $("#social_preview_modal").data("share_id", data.bb_share_id)
        $("#social_preview_modal .social-cancel").data(
          "share_id",
          data.bb_share_id
        )
      })
    },
    initFbShare: function(callback) {
      var _this = this
      //$("#social_preview_modal .social-square-logo").addClass("facebook")
	  $("#social_preview_modal .modal-title").html('<span class="social-square-logo facebook"></span>Preview Share')
      $("#social_preview_modal .share-type").html("facebook")
      $("#social_preview_modal").modal("show")
      //event.preventDefault();
      //event.stopPropagation();
      var nid = _this.data("nid")
      BB.social.facebook.prepare(nid).then(function(data) {
        $(".share-wrapper").removeClass("hide")
        $("img.share-preview").attr("src", data.image_url)
        var shareInfo = {
          share_id: data.bb_share_id,
          nid: nid,
          type: "fb",
          payload: data.payload
        }
        $("#social_preview_modal .social-post").data("share_info", shareInfo)
        $(".downloadShareImg").attr("href", data.image_url)
        $("#social_preview_modal").data("share_id", data.bb_share_id)
        $("#social_preview_modal .social-cancel").data(
          "share_id",
          data.bb_share_id
        )
      })
    },
    initInShare: function() {
      var _this = this
      //$("#social_preview_modal .social-square-logo").addClass("linkedin")
	  $("#social_preview_modal .modal-title").html('<span class="social-square-logo linkedin"></span>Preview Share')
      $("#social_preview_modal .share-type").html("linkedin")
      $("#social_preview_modal").modal("show")
      //event.preventDefault();
      //event.stopPropagation();
      var nid = _this.data("nid")

      BB.social.linkedin.prepare(nid, function(data) {
        $(".share-wrapper").removeClass("hide")
        $("img.share-preview").attr("src", data.image_url)
        var shareInfo = {
          share_id: data.bb_share_id,
          nid: nid,
          type: "linkedin",
          payload: data.payload
        }
        $("#social_preview_modal .social-post").data("share_info", shareInfo)
        $(".downloadShareImg").attr("href", data.image_url)
        $("#social_preview_modal").data("share_id", data.bb_share_id)
        $("#social_preview_modal .social-cancel").data(
          "share_id",
          data.bb_share_id
        )
      })
    },
    isInViewport: function() {
      var elementTop = $(this).offset().top
      var elementBottom = elementTop + $(this).outerHeight()
      var viewportTop = $(window).scrollTop()
      var viewportBottom = viewportTop + $(window).height()
      return elementBottom > viewportTop && elementTop < viewportBottom
    }
  })

  $("#social_preview_modal").on("hidden.bs.modal", function() {
    $(this)
      .find(".social-post")
      .addClass("hide")
    $(this)
      .find(".loader")
      .removeClass("hide")
    $("#social_preview_modal .social-post").prop("disabled", false)
    $(".share-wrapper").addClass("hide")
    $(this)
      .find(".social-square-logo")
      .removeClass("facebook")
    $(this)
      .find(".social-square-logo")
      .removeClass("linkedin")
    if ($("#social_preview_modal .social-post").data("share_info")) {
      BB.social.delete($(this).data("share_id"), function(data) {})
    }
    $("#social_preview_modal .social-post").removeData("share_info")
  })

  $("#social_preview_modal .social-cancel").click(function(e) {
    BB.social.delete($(this).data("share_id"), function(data) {})
  })

  $("#social_preview_modal .social-post").click(function(e) {
    $(this).prop("disabled", true)
    e.preventDefault()
    const shareInfo = $(this).data("share_info")
    //console.log(shareInfo);
    if (shareInfo.type === "facebook") {
      BB.social.facebook.post(shareInfo, function(data) {
        console.log("posting to fb now")
        console.log(data)
        $("#social_preview_modal").modal("hide")
      })
    } else if (shareInfo.type === "linkedin") {
      BB.social.linkedin.post(shareInfo, function(data) {
        console.log("posting to linkedin now")
        console.log(data)
        $("#social_preview_modal").modal("hide")
      })
    }
  })

  $("#extra_details_modal").on("shown.bs.modal", function() {
    $.get(
      "/lo-manage.json?bb_sid=" +
        window.SESSION_ID +
        "&xloid=" +
        $("body").data("user_id"),
      function(data) {
        $(".loader").addClass("hide")
        $(".step-1").removeClass("hide")
        if (data.lo.extra_details) {
          //TODO: fill fields
          for (var key in data.lo.extra_details) {
            $('[name="' + key + '"]').val(data.lo.extra_details[key])
          }
        }
      }
    )
  })

  $("#extra_details_modal").on("hidden.bs.modal", function() {
    $("#extra_details_modal form")[0].reset()
    $(".loader").removeClass("hide")
    $(".step-1").addClass("hide")
  })

  $("#extra_details_modal .save-extra-details").click(function(e) {
    e.preventDefault()
    var formData = getFormObject(
      $("#extra_details_modal form").serializeArray()
    )
    formData.loid = $("body").data("user_id")
    const data = {
      extra_details: formData
    }
    $.ajax({
      url: "/lo-manage.json",
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(data),
      success: function(res) {
        console.log(res)
      },
      error: function(xhr, ajaxOptions, thrownError) {
        //On error do this
        if (xhr.status == 200) {
          //alert(ajaxOptions);
        } else {
          //                    alert(xhr.status);
          //                    alert(thrownError);
        }
      }
    })
  })

  var getKeyedFormArray = function(serializedArray) {
    var keyedFormArray = []
    $.each(serializedArray, function(index, field) {
      keyedFormArray[field.name] = field.value
    })
    return keyedFormArray
  }

  // -----------------------------------------
  //console.log("bb-app <-" );
}) // jQuery eof

module.exports = {
  social: bbSocial,
  getFormObject: getFormObject,
  Tippy: Tippy,
  Cookie: Cookie,
  Toastr: Toastr,
  uiSlider: uiSlider,
  leadManage: bbLeadManage,
  loManage: bbLOManage,
  realtorManage: bbRealtorManage,
  orgManage: bbOrgManage,
  orgActivity: bbOrgActivity,
  brandManage: bbBrandManage,
  rateflow: bbRateflow,
  staticFlyer: bbStaticFlyer,
  manualListing: bbListingManage.manual,
  staticFlyerTags: bbStaticFlyerTags,
  inviteManage: bbInviteManage,
  listing: bbListingManage,
  tag: bbTagManage,
  template: bbTemplateManage,
  programPreferences: bbPreferencesManage,
  chart: bbChart,
  subscription: bbRateSubscription,
  leadWorkflow: bbLeadWorkflow,
  integration: bbIntegration,
  embedMonitor: bbEmbedMonitor,
  contactForm: bbContactForm,
  pricingSet: bbPricingSet,
  rateTableConfig: bbRateTableConfig,
  countyManage: bbCountyManage,
  templatePermissions: bbTemplatePermissions,
  orgBrandManage: bbOrgBrandManage,
  archives: bbArchives,
  brandSignatureManage: bbBrandSignatureManage
}
