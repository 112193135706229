<doc>
  Input text Vue.js component
</doc>

<template>
  <div>
    <div :class="wrapperClass">
      <label :for="name" :class="labelClass">
        {{ label }}
      </label>
      <input
          autocomplete="off"
          ref="input"
          :class="inputClassName"
          :type="type"
          :id="name"
          :aria-describedby="describedby"
          :placeholder="placeholder"
          :readonly="readonly"
          v-bind:value="value"
          v-on:input="onInput"
          v-on:focus="onFocus"
          v-on:blur="onBlur($event)"
          :min="min || false"
          :max="max || false"
          @keyup.enter="$emit('keyupEnter', $event)"
          @keyup.esc="$emit('keyupEsc', $event)"
          @keyup.delete="$emit('keyupDelete', $event)"
      />
    </div>
    <small style="margin-top:-10px;font-size:80%;" v-if="helpText" :id="describedby" class="form-text text-muted">
      {{ helpText }}
    </small>
  </div>
</template>

<script>
import { props, methods, mounted, updated } from "./inputCommons"

export default {
  name: "InputText",
  props: props,
  computed: {
    wrapperClass: function() {
      let className = "form-group"
      if (this.isMaterial) {
        className += " material-input"
      }

      return className
    },
    labelClass: function() {
      let className = this.isMaterial ? `input-label ${this.labelClassName}` : ""
      if (this.hideLabel) {
        className += " sr-only"
      }
      return className
    },
    inputClassName: function() {
      let className = `form-control ${this.inputClass}`
      if (this.size) {
        className += ` form-control-${this.size}`
      }
      return className
    }
  },
  methods: methods,
  mounted: mounted,
  updated: updated
}
</script>

<style lang="scss" src="./Forms.scss" scoped></style>
