<doc>
  Programs Vue.js component
</doc>

<template>
  <div>
    <Tabs :initialHash="initialHash">
      <Tab name="Static Flyers" v-if="isStaticFlyerEnabled">
        <div v-if="isBankAdmin">
          <label >Brand:</label>
          <select class="selectBrand" v-model="brandId"  @change="changeSelectedBrand">
            <option disabled value="">Please select one</option>
            <option v-for="brand in availableBrands" :value="brand.id" v-model="brandId">{{brand.name}}</option>
          </select>
        </div>
        <StaticFlyerList />
      </Tab>
      <Tab name="Manual Listings">
        <ManualListingList />
      </Tab>
      <Tab v-if="listing_access" name="Shared Listings" linkHref="/my-shared-listings/grid">
      </Tab>
    </Tabs>
    <ManualListingModal />
    <StaticFlyerModal />
    <StaticFlyerPrintModal />
    <ManualListingPrintModal />
  </div>
</template>

<script>
import _isNull from "lodash/isNull"
import { createNamespacedHelpers } from "vuex"
const { mapState, mapActions } = createNamespacedHelpers("staticFlyers")

import {
  StaticFlyerModal,
  StaticFlyerList,
  Tab,
  Tabs,
  StaticFlyers,
  StaticFlyerPrintModal,
  ManualListingPrintModal,
  ManualListingModal,
  ManualListingList
} from "../components"

export default {
  name: "MyStaticFlyers",
  components: {
    Tabs,
    Tab,
    StaticFlyerModal,
    StaticFlyerPrintModal,
    StaticFlyerList,
    ManualListingPrintModal,
    ManualListingModal,
    ManualListingList
  },
  async mounted(){
    await this.getAvailableBrands()
  },
  data(){
      return {
          listing_access: ( listing_access ? listing_access : null ),
          isBankAdmin: (isBankAdmin ? isBankAdmin : false),
		  isStaticFlyerEnabled: (isStaticFlyerEnabled ? isStaticFlyerEnabled : false),
          availableBrands: null,
          brandId: ( bank_id ? bank_id : null )
      }
  },
  methods: Object.assign(
{},
      mapActions([
        "getAllBrands",
        "setBankId",
        "refreshFlyerImages"
      ]),
      {
        async getAvailableBrands() {
          // work around by getting org data directly
          let {brands} = await BB.orgManage.getById(org_id);
          this.availableBrands = brands;
        },
        changeSelectedBrand(e) {
          this.setBankId(this.brandId)
          this.refreshFlyerImages(this.brandId)
        }
      }
  ),
  computed: Object.assign({}, mapState(["originalItems"]), {
    initialHash: function() {
      return !_isNull(this.originalItems) && this.originalItems.length
        ? "my-flyers"
        : ""
    }
  })
}
</script>

<style lang="scss"></style>
