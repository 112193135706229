var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.modalTitle,
        id: "newPricingSetModal",
        okLabel: "Save",
        isLoading: _vm.isLoading
      },
      on: {
        ok: _vm.submitForm,
        cancel: _vm.hideModal,
        clickMask: _vm.hideModal
      },
      model: {
        value: _vm.showItemModal,
        callback: function($$v) {
          _vm.showItemModal = $$v
        },
        expression: "showItemModal"
      }
    },
    [
      _c("h5", [_vm._v("\n    Brand details\n  ")]),
      _vm._v(" "),
      _c("p", { staticClass: "intro" }, [
        _vm._v("Set up the details of your new company.")
      ]),
      _vm._v(" "),
      !_vm.isLoading
        ? _c("ImgUploader", {
            attrs: {
              previewAlt: "Company logo",
              editMode: true,
              previewSrc: _vm.logoSrc,
              previewClass: "company-logo",
              label: "Upload brand logo",
              uploadCallback: _vm.uploadCallback
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          ref: "brandForm",
          staticClass: "form",
          attrs: { id: "new-brand-form" },
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c("InputText", {
            attrs: { label: "Name of brand", name: "set-name" },
            model: {
              value: _vm.brand.name,
              callback: function($$v) {
                _vm.$set(_vm.brand, "name", $$v)
              },
              expression: "brand.name"
            }
          }),
          _vm._v(" "),
          _c("InputText", {
            attrs: { label: "Website of brand", name: "set-url", type: "url" },
            model: {
              value: _vm.brand.website,
              callback: function($$v) {
                _vm.$set(_vm.brand, "website", $$v)
              },
              expression: "brand.website"
            }
          }),
          _vm._v(" "),
          _c("InputText", {
            attrs: { label: "NMLS", name: "set-nmls" },
            model: {
              value: _vm.brand.nmls,
              callback: function($$v) {
                _vm.$set(_vm.brand, "nmls", $$v)
              },
              expression: "brand.nmls"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "checkboxField" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.brand.disable_subscription_emails,
                  expression: "brand.disable_subscription_emails"
                }
              ],
              attrs: { id: "disable_subscription_emails", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.brand.disable_subscription_emails)
                  ? _vm._i(_vm.brand.disable_subscription_emails, null) > -1
                  : _vm.brand.disable_subscription_emails
              },
              on: {
                change: function($event) {
                  var $$a = _vm.brand.disable_subscription_emails,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.brand,
                          "disable_subscription_emails",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.brand,
                          "disable_subscription_emails",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.brand, "disable_subscription_emails", $$c)
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "disable_subscription_emails" } }, [
              _vm._v("Disable Subscription Emails")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "checkboxField" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.brand.disable_receipt_emails,
                  expression: "brand.disable_receipt_emails"
                }
              ],
              attrs: { id: "disable_receipt_emails", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.brand.disable_receipt_emails)
                  ? _vm._i(_vm.brand.disable_receipt_emails, null) > -1
                  : _vm.brand.disable_receipt_emails
              },
              on: {
                change: function($event) {
                  var $$a = _vm.brand.disable_receipt_emails,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.brand,
                          "disable_receipt_emails",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.brand,
                          "disable_receipt_emails",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.brand, "disable_receipt_emails", $$c)
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "disable_receipt_emails" } }, [
              _vm._v("Disable Receipt Emails")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "checkboxField" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.brand.disable_itemized_fees,
                  expression: "brand.disable_itemized_fees"
                }
              ],
              attrs: { id: "disable_itemized_fees", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.brand.disable_itemized_fees)
                  ? _vm._i(_vm.brand.disable_itemized_fees, null) > -1
                  : _vm.brand.disable_itemized_fees
              },
              on: {
                change: function($event) {
                  var $$a = _vm.brand.disable_itemized_fees,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.brand,
                          "disable_itemized_fees",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.brand,
                          "disable_itemized_fees",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.brand, "disable_itemized_fees", $$c)
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "disable_itemized_fees" } }, [
              _vm._v("Disable Itemized Fees")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "checkboxField" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.brand.non_bank,
                  expression: "brand.non_bank"
                }
              ],
              attrs: { id: "non_bank", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.brand.non_bank)
                  ? _vm._i(_vm.brand.non_bank, null) > -1
                  : _vm.brand.non_bank
              },
              on: {
                change: function($event) {
                  var $$a = _vm.brand.non_bank,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.brand, "non_bank", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.brand,
                          "non_bank",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.brand, "non_bank", $$c)
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "non_bank" } }, [_vm._v("Non Bank")])
          ]),
          _vm._v(" "),
          _c("RichTextEditor", {
            attrs: { label: "About", name: "set-desc" },
            model: {
              value: _vm.brand.about,
              callback: function($$v) {
                _vm.$set(_vm.brand, "about", $$v)
              },
              expression: "brand.about"
            }
          }),
          _vm._v(" "),
          _c("InputTextArea", {
            attrs: { label: "General Disclaimer", name: "set-disclaimer" },
            model: {
              value: _vm.brand.disclaimer,
              callback: function($$v) {
                _vm.$set(_vm.brand, "disclaimer", $$v)
              },
              expression: "brand.disclaimer"
            }
          }),
          _vm._v(" "),
          _c("InputTextArea", {
            attrs: {
              label: "Static Flyer Disclaimer",
              name: "set-static_disclaimer",
              showLimit: false,
              limit: 1000
            },
            model: {
              value: _vm.brand.static_disclaimer,
              callback: function($$v) {
                _vm.$set(_vm.brand, "static_disclaimer", $$v)
              },
              expression: "brand.static_disclaimer"
            }
          }),
          _vm._v(" "),
          _c("InputTextArea", {
            attrs: {
              label: "Social Disclaimer",
              name: "set-social_disclaimer",
              showLimit: false,
              limit: 1000
            },
            model: {
              value: _vm.brand.social_disclaimer,
              callback: function($$v) {
                _vm.$set(_vm.brand, "social_disclaimer", $$v)
              },
              expression: "brand.social_disclaimer"
            }
          }),
          _vm._v(" "),
          _c("InputTextArea", {
            attrs: {
              label: "Payment Details Disclaimer",
              name: "set-static_disclaimer"
            },
            model: {
              value: _vm.brand.payment_details_disclaimer,
              callback: function($$v) {
                _vm.$set(_vm.brand, "payment_details_disclaimer", $$v)
              },
              expression: "brand.payment_details_disclaimer"
            }
          }),
          _vm._v(" "),
          _c("InputTextArea", {
            attrs: {
              label: "Lead Capture Disclaimer",
              name: "set-static_disclaimer"
            },
            model: {
              value: _vm.brand.lead_capture_disclaimer,
              callback: function($$v) {
                _vm.$set(_vm.brand, "lead_capture_disclaimer", $$v)
              },
              expression: "brand.lead_capture_disclaimer"
            }
          }),
          _vm._v(" "),
          _c("InputTextArea", {
            attrs: {
              label: "Loan Calc Disclaimer",
              name: "set-static_disclaimer"
            },
            model: {
              value: _vm.brand.loan_calc_disclaimer,
              callback: function($$v) {
                _vm.$set(_vm.brand, "loan_calc_disclaimer", $$v)
              },
              expression: "brand.loan_calc_disclaimer"
            }
          }),
          _vm._v(" "),
          !_vm.obBrand
            ? _c(
                "div",
                { staticClass: "ac-wrapper hide" },
                [
                  _c(
                    "label",
                    { staticClass: "input-label autocomplete-label" },
                    [_vm._v("\n        Select OB Channel\n      ")]
                  ),
                  _vm._v(" "),
                  _c("Autocomplete", {
                    staticClass: "form-group material-input",
                    attrs: {
                      id: "ob-channel-select",
                      "input-class": "form-control",
                      resultsFormatter: _vm.acResultsFormatter,
                      resultsDisplay: _vm.acResultsDisplay,
                      source: _vm.obBrandAutocomplete
                    },
                    on: { selected: _vm.selectOBBrand }
                  })
                ],
                1
              )
            : _c("div", [
                _c("div", { staticStyle: { "margin-top": "20px" } }, [
                  _c("img", {
                    staticStyle: {
                      height: "30px",
                      "margin-bottom": "-10px",
                      "margin-right": "10px"
                    },
                    attrs: {
                      src:
                        "https://ellisjm-private.s3.amazonaws.com/ob_logo.png"
                    }
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.obBrand.name))]),
                  _c("i", {
                    staticClass: "fas fa-times-circle",
                    on: { click: _vm.clearOBBrand }
                  })
                ])
              ]),
          _vm._v(" "),
          _vm.placeLoading
            ? _c("div", { staticStyle: { "text-align": "center" } }, [
                _c("div", {
                  staticClass: "loader vertical-align-middle loader-circle",
                  staticStyle: { "font-size": "28px" }
                })
              ])
            : _c("div", [
                !_vm.brandPlace.place_id
                  ? _c(
                      "div",
                      { staticClass: "ac-wrapper" },
                      [
                        _c(
                          "label",
                          { staticClass: "input-label autocomplete-label" },
                          [
                            _vm._v(
                              "\n          Select Google Reviews Brand\n        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("Autocomplete", {
                          staticClass: "form-group material-input",
                          attrs: {
                            id: "brand-place-search",
                            "input-class": "form-control",
                            resultsFormatter: _vm.acPlaceResultsFormatter,
                            resultsDisplay: _vm.acPlaceResultsDisplay,
                            source: _vm.placeAutocomplete
                          },
                          on: { selected: _vm.selectBrandPlace }
                        })
                      ],
                      1
                    )
                  : _c("div", [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "20px",
                            height: "20px",
                            display: "block"
                          }
                        },
                        [
                          _c("div", { staticClass: "input-logo pull-left" }, [
                            _c("img", {
                              staticStyle: { height: "30px" },
                              attrs: {
                                src:
                                  "https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "place-details pull-left" },
                            [
                              _c("div", { staticClass: "place-label" }, [
                                _vm._v(_vm._s(_vm.brandPlace.name))
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "place-location" }, [
                                _vm._v(_vm._s(_vm.brandPlace.location))
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "fas fa-times-circle pull-left",
                            on: { click: _vm.clearBrandPlace }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _vm.googleReviews
                        ? _c(
                            "div",
                            [
                              _c("ManagerReviews", {
                                attrs: {
                                  reviews: _vm.googleReviews.reviews,
                                  type: "google"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
              ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("InputText", {
                attrs: {
                  onBlur: _vm.setZillowReviews,
                  label: "Zillow Brand Name",
                  name: "set-zillow-brand-name"
                },
                model: {
                  value: _vm.brand.zillow_brand_name,
                  callback: function($$v) {
                    _vm.$set(_vm.brand, "zillow_brand_name", $$v)
                  },
                  expression: "brand.zillow_brand_name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.zillowReviews
            ? _c(
                "div",
                [
                  _c("ManagerReviews", {
                    attrs: {
                      reviews: _vm.zillowReviews.reviews,
                      type: "zillow"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("InputText", {
                attrs: {
                  onBlur: _vm.setExperienceReviews,
                  label: "Experience Account ID",
                  name: "set-experience-id"
                },
                model: {
                  value: _vm.brand.experience_id,
                  callback: function($$v) {
                    _vm.$set(_vm.brand, "experience_id", $$v)
                  },
                  expression: "brand.experience_id"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.experienceReviews
            ? _c(
                "div",
                [
                  _c("ManagerReviews", {
                    attrs: {
                      reviews: _vm.experienceReviews.reviews,
                      type: "experience"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("InputText", {
                staticClass: "accent_color_field",
                attrs: { label: "Primary Color", name: "primary_color" },
                model: {
                  value: _vm.brand.primary_color,
                  callback: function($$v) {
                    _vm.$set(_vm.brand, "primary_color", $$v)
                  },
                  expression: "brand.primary_color"
                }
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "accent_color_preview",
                style: { "background-color": _vm.brand.primary_color }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("InputText", {
                staticClass: "accent_color_field",
                attrs: { label: "Secondary Color", name: "secondary_color" },
                model: {
                  value: _vm.brand.secondary_color,
                  callback: function($$v) {
                    _vm.$set(_vm.brand, "secondary_color", $$v)
                  },
                  expression: "brand.secondary_color"
                }
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "accent_color_preview",
                style: { "background-color": _vm.brand.secondary_color }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "default-lo-field" }, [
            !_vm.defaultLO
              ? _c(
                  "div",
                  [
                    _c("InputText", {
                      attrs: {
                        label: "Default LOID",
                        name: "set-default-loid",
                        type: "text"
                      },
                      model: {
                        value: _vm.brand.default_loid,
                        callback: function($$v) {
                          _vm.$set(_vm.brand, "default_loid", $$v)
                        },
                        expression: "brand.default_loid"
                      }
                    })
                  ],
                  1
                )
              : _c("div", [
                  _c("label", [_vm._v("Default Loan Officer")]),
                  _vm._v(" " + _vm._s(_vm.defaultLO.name) + "\n        "),
                  _c("i", {
                    staticClass: "fas fa-times-circle",
                    on: { click: _vm.clearDefaultLO }
                  })
                ])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm.rateflow
              ? _c("div", [
                  _vm._v(
                    "\n        Rateflow is detected (id: " +
                      _vm._s(_vm.rateflow.id) +
                      ") "
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "toggleBtn",
                      on: { click: _vm.expandRateflow }
                    },
                    [_vm._v("Advanced")]
                  ),
                  _vm._v(" "),
                  _vm.rateflowExpanded
                    ? _c(
                        "div",
                        [
                          _c("div", { staticClass: "checkboxField" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.mortechEnabled,
                                  expression: "mortechEnabled"
                                }
                              ],
                              attrs: { id: "enable_mortech", type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.mortechEnabled)
                                  ? _vm._i(_vm.mortechEnabled, null) > -1
                                  : _vm.mortechEnabled
                              },
                              on: {
                                change: [
                                  function($event) {
                                    var $$a = _vm.mortechEnabled,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.mortechEnabled = $$a.concat([
                                            $$v
                                          ]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.mortechEnabled = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.mortechEnabled = $$c
                                    }
                                  },
                                  _vm.toggleMortech
                                ]
                              }
                            }),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "enable_mortech" } }, [
                              _vm._v("Enable Mortech")
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.mortechEnabled
                            ? _c(
                                "div",
                                [
                                  _c("InputText", {
                                    attrs: {
                                      label: "Customer ID",
                                      name: "set-customer-id"
                                    },
                                    model: {
                                      value: _vm.mortechConfig.customer_id,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.mortechConfig,
                                          "customer_id",
                                          $$v
                                        )
                                      },
                                      expression: "mortechConfig.customer_id"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("InputText", {
                                    attrs: {
                                      label: "Email",
                                      name: "set-email"
                                    },
                                    model: {
                                      value: _vm.mortechConfig.email_address,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.mortechConfig,
                                          "email_address",
                                          $$v
                                        )
                                      },
                                      expression: "mortechConfig.email_address"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("InputText", {
                                    attrs: {
                                      label: "Default State",
                                      name: "set-state"
                                    },
                                    model: {
                                      value: _vm.brand.state,
                                      callback: function($$v) {
                                        _vm.$set(_vm.brand, "state", $$v)
                                      },
                                      expression: "brand.state"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("InputText", {
                            attrs: {
                              label: "Target Price",
                              name: "set-target-price"
                            },
                            model: {
                              value: _vm.rateflow.settings.target_price,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.rateflow.settings,
                                  "target_price",
                                  $$v
                                )
                              },
                              expression: "rateflow.settings.target_price"
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "checkboxField" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.rateflow.settings.waive_escrows,
                                  expression: "rateflow.settings.waive_escrows"
                                }
                              ],
                              attrs: {
                                id: "set_waive_escrows",
                                type: "checkbox"
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.rateflow.settings.waive_escrows
                                )
                                  ? _vm._i(
                                      _vm.rateflow.settings.waive_escrows,
                                      null
                                    ) > -1
                                  : _vm.rateflow.settings.waive_escrows
                              },
                              on: {
                                change: [
                                  function($event) {
                                    var $$a =
                                        _vm.rateflow.settings.waive_escrows,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.rateflow.settings,
                                            "waive_escrows",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.rateflow.settings,
                                            "waive_escrows",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.rateflow.settings,
                                        "waive_escrows",
                                        $$c
                                      )
                                    }
                                  },
                                  _vm.setWaiveEscrows
                                ]
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "set_waive_escrows" } },
                              [_vm._v("Waive Escrows")]
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ])
              : _c("div", [
                  _c(
                    "button",
                    {
                      on: {
                        click: function($event) {
                          _vm.createRateflow()
                        }
                      }
                    },
                    [_vm._v("Create Rateflow")]
                  )
                ])
          ]),
          _vm._v(" "),
          _c("div", { staticStyle: { "margin-top": "10px" } }, [
            _vm.pricingProfiles
              ? _c("div", [
                  _vm._v(
                    "\n              Pricing Profiles are detected\n          "
                  )
                ])
              : _c("div", [
                  _c(
                    "button",
                    {
                      on: {
                        click: function($event) {
                          _vm.createPricingProfiles()
                        }
                      }
                    },
                    [_vm._v("Create Pricing Profiles")]
                  )
                ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-c7f5909a", { render: render, staticRenderFns: staticRenderFns })
  }
}