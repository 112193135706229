var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "datepicker-range no-input" },
    [
      _c("icon", {
        staticClass: "datepicker-icon",
        attrs: { name: "regular/calendar-alt" }
      }),
      _vm._v(" "),
      _c("v2-datepicker-range", {
        attrs: {
          lang: "en",
          placeholder: _vm.placeholder,
          format: _vm.dateFormat,
          "picker-options": _vm.pickerOptions
        },
        on: { change: _vm.onChange },
        model: {
          value: _vm.theModel,
          callback: function($$v) {
            _vm.theModel = $$v
          },
          expression: "theModel"
        }
      }),
      _vm._v(" "),
      _c("icon", {
        staticClass: "datepicker-chevron",
        attrs: { name: "chevron-down" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-109a4750", { render: render, staticRenderFns: staticRenderFns })
  }
}