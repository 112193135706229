<doc>
  People smartlist  Vue.js component
</doc>

<template>
  <div class="content-tools">
    <!-- <a class="pull-right flyerTemplateSelectBtn" data-toggle="modal" data-target="#flyerTemplateSelect" data-backdrop="static" style="background-image:url('https://bbridge-test-bucket.s3.amazonaws.com/flyers/test-share1570495940734-preview.jpeg')"><div id="swatchPreview" style="top:29px;right:-20px;height:20px;width:20px" class="flyer-template-picker__swatch"><div class="leftSide" style="width: 50%;height: 100%;background: #421f40;float: left;"></div><div class="rightSide" style="width: 50%;height: 100%;background: #421f40;float: right;"></div></div></a> -->
    <div class="swatch-text">Default Template Selection</div>
	<a class="pull-right flyerTemplateSelectBtn" data-toggle="modal" data-target="#flyerTemplateSelect" :data-imgurl="imgurl" data-backdrop="static" :style="preview">
      <div id="swatchPreview" :class="showborder" class="flyer-template-picker__swatch">
        <div class="leftSide" :style="primaryColor"></div>
        <div class="rightSide" :style="secondaryColor"></div>
      </div>
    </a>
    <LoaderPlaceholder v-if="isLoading" type="circle" />
  <SmartList
    v-else
    :searchQuery="searchQuery"
    :searchQueries="searchQueries"
    :filteredItems="filteredItems"
    :addQuery="addQuery"
    :removeQuery="removeQuery"
    :resetQuery="resetQuery"
    :toggleListView="toggleListView"
    :addItemAction="toggleItemModal"
    :items="items"
    :onDragEnd="onEnd"
    :gridView="gridView"
    listType="manual_flyer"
  />
  <!--- Modal Popup Flyer Preference --->
<div id="flyerTemplateSelect" class="modal jt-modal fade" role="dialog">
  <div class="modal-dialog">

      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">Flyer Template Preference</h4>
            <span class="pull-right closeBtn" data-dismiss="modal" aria-hidden="true">&times;</span>
        </div>

      <div class="modal-body">
          <div class="loader vertical-align-middle loader-circle" data-type="default" v-if="is_loading"></div>
          <ThemeSelector
            :onThemeSelect="onThemeSelect"
            :theme="theme"
            :swatch="swatch"
          />
      </div>
	  <div class="modal-footer">
		<button type="button" class="btn btn-default preview-img" data-toggle="modal" data-target="#previewImage" data-backdrop="static">Preview</button>
		<button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
	  </div>
      </div>
  </div>
</div>
<!--- Modal Popup Flyer Preference --->

<div class="modal jt-modal fade" id="previewImage">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Preview</h4>
                <span class="pull-right closeBtn" data-dismiss="modal" aria-hidden="true">&times;</span>
            </div>
            <div class="modal-body">
              <div class="img-div"><img src="" width="100%" /></div>
              <div class="flyer-template-picker__swatch"></div>
            </div>
        </div>
    </div>
</div>
</div>


</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { SmartList } from "../SmartList"
import ThemeSelector from "../ThemeSelector"
import bbRealtorManage from "../../bb-api/realtorManage"
const {
  mapState,
  mapActions,
  mapGetters,
  mapMutations
} = createNamespacedHelpers("manualListing")

export default {
  name: "ManualListingList",
  data: function() {
    return {
      is_loading:true,
      theme: '',
      swatch: '',
      d_tpl: null,
      d_swatch: null,
      preview: '',
      primaryColor: '',
      secondaryColor: '',
      showborder:'',
	    imgurl:''
    }
  },
  components: {
    SmartList: SmartList,
    ThemeSelector: ThemeSelector
  },
  computed: Object.assign(
    {},
    mapState(["gridView", "searchQueries", "searchQuery", "isLoading"]),
    mapGetters(["currentItems"]),
    {
      items: {
        get() {
          try {
            return this.currentItems || []
          } catch (e) {
            console.log(e)
          }
        },
        set(value) {
          /**
           * The "correct" way is commit the update here,
           * but vuedraggable seems to have issues with it.
           * We are doing it on the "onEnd" event
           **/
          // this.$store.commit("updateSets", value)
        }
      }
    }
  ),
  methods: Object.assign(
    {},
    mapMutations({
      toggleListView: "TOGGLE_LIST_VIEW"
    }),
    mapActions([
      "filteredItems",
      "addQuery",
      "removeQuery",
      "resetQuery",
      "toggleItemModal",
      "updateOriginalItems",
      "initManualListing"
    ]),
    {
      onEnd: function(oldIndex, newIndex) {
        this.updateOriginalItems({ oldIndex: oldIndex, newIndex: newIndex })
      },
      async onThemeSelect(val, id) {
        if (id === "swatch" && typeof val === "number") {
          console.log(id+' selected', val);
          this.swatch = val
          await BB.realtorManage.manual.setFlyerPreference({ template: this.theme+'-'+this.swatch });
          BB.Toastr.success("Template Updated")
        } else if (id === "theme") {
          console.log(id+' selected', val);
          this.theme = val;
          this.swatch = 0;
          await BB.realtorManage.manual.setFlyerPreference({ template: val+'-0'  });
          BB.Toastr.success("Theme Updated")
        }
        this.getSelectedTemplate()
      },
      async getSelectedTemplate(){
        let flyer_preference = await BB.realtorManage.manual.getFlyerPreference();
        console.log('flyer_preference',flyer_preference);
        this.d_tpl = flyer_preference.tpl;
		this.theme = flyer_preference.tpl.id;
        this.d_swatch = flyer_preference.swatch;
		
        this.preview = "background-image:url('"+this.d_tpl.preview_url+"')";
		this.imgurl = this.d_tpl.preview_url;
    if(this.d_swatch == null) {
      console.log('sdfsdf');
      this.primaryColor = '';
      this.secondaryColor = '';
      this.showborder = 'no-border';
    } else {
      console.log('else');
      this.showborder = '';
      this.swatch = flyer_preference.swatch.id;
        this.primaryColor = "background:"+this.d_swatch.primaryColor+"";
        this.secondaryColor = (this.d_swatch.secondaryColor != '')?"background:"+this.d_swatch.secondaryColor+"":this.primaryColor;
    }
        
        this.is_loading = false;
      }
    }
  ),
  created() {
    this.getSelectedTemplate()
	
	$(document).on('click','.preview-img',  function(){
      console.log('preivew clicked...');
      const src = $('.flyerTemplateSelectBtn').attr('data-imgurl');
      const swatch = $('#swatchPreview').html();
      $('#previewImage .modal-body .img-div img').attr('src', src)
      $('#previewImage .modal-body .flyer-template-picker__swatch').html(swatch)
    })
  }
}
</script>

<style lang="scss">
#flyerTemplateSelect .modal-dialog .modal-content .modal-body .row {
	overflow: auto;
	flex-wrap: nowrap;
}
#flyerTemplateSelect .modal-dialog {
	width: 1400px;
}
#flyerTemplateSelect .modal-dialog .modal-body .row .col {
	min-width: 347px;
	padding-right: 0px;
  flex-basis: 347px;
    flex-grow: 0;
    flex-shrink: 0;
}
.btn-search {
  margin-right: 10px;
}
.smartlist-view-mode-buttons {
  display: none;
}
.leftSide{
  width: 50%;
  height: 100%;
  float: left;
}
.rightSide{
  width: 50%;
  height: 100%;
  float: right;
}
#swatchPreview{
  top:29px;right:-20px;height:20px;width:20px
}
.no-border{
  border: 0 !important;
  box-shadow: 0 0 0;
} 
.swatch-text{
  position: absolute;
  top:10px;
  right: 60px;
  width: 50px;
  font-weight: 600;
  font-size: 12px;
}
</style>
