import Vue from "vue"
import _orderBy from "lodash/orderBy"
import initStore from "./store/bb-pricing"
import { MyPrograms } from "./pages"

export default function() {
  if (!document.getElementById("vue-pricing-app")) {
    return
  }
  const store = initStore()
  // initial items
  store.commit("UPDATE_ORIGINAL_ITEMS", _orderBy(pricing_sets, "weight"))
  store.commit("SET_LOID", loid)
  store.commit("SET_BANK_ID", bank_id)
  store.commit("SET_DEFAULT_SET", default_set)
  store.commit("SET_USER_DEFAULT_SET", user_default_set)
  store.commit("SET_USER_IS_ORG_MANAGER", user_is_org_manager)
  // Vuejs setup
  new Vue({
    el: "#vue-pricing-app",
    store,
    components: {
      MyPrograms: MyPrograms
    }
  })

  // Do we need this? 25/4
  window.dispatchEvent(new Event("resize"))
}

