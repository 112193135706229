var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal jt-modal fade",
      attrs: { id: "brandSignatureModal" }
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "form",
              {
                ref: "brandForm",
                staticClass: "form",
                attrs: { id: "new-brand-form" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c("input", {
                  staticClass: "hd-id",
                  attrs: { type: "hidden" }
                }),
                _vm._v(" "),
                _c("input", {
                  staticClass: "hd-brand-id",
                  attrs: { type: "hidden" }
                }),
                _vm._v(" "),
                _c("InputTextArea", {
                  staticClass: "block1",
                  attrs: {
                    label: "Signature Block-8-5 x 3",
                    name: "set-signature-block-8-5-x-2"
                  }
                }),
                _vm._v(" "),
                _c("InputTextArea", {
                  staticClass: "block2",
                  attrs: {
                    label: "Signature Block Custom 1",
                    name: "set-signature-block-custom-1"
                  }
                }),
                _vm._v(" "),
                _c("InputTextArea", {
                  staticClass: "block3",
                  attrs: {
                    label: "Signature Block Custom 2",
                    name: "set-signature-block-custom-2"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm._m(1)
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [
        _vm._v("Add/Edit Brand Signature")
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "pull-right closeBtn",
          attrs: { "data-dismiss": "modal", "aria-hidden": "true" }
        },
        [_vm._v("×")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        { staticClass: "btn btn-default btnSave", attrs: { type: "button" } },
        [_vm._v("Save")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-default",
          attrs: { type: "button", "data-dismiss": "modal" }
        },
        [_vm._v("Close")]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-85a93cee", { render: render, staticRenderFns: staticRenderFns })
  }
}