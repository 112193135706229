<doc>
  Add/edit Program Modal Vue.js component
</doc>

<template>
  <BaseModal
    ref="modal"
    v-model="showItemModalComp"
    :title="modalTitle"
    id="manualFlyerModal"
    @ok="submitForm"
    @cancel="hideModal"
    @clickMask="hideModal"
    :isLoading="isLoading"
    large
  >
    <form
      id="manual-flyer-form"
      class="form"
      ref="manualFlyerForm"
      v-on:submit.prevent
    >
      <FormStep
        :title="formFields[currentStep].title"
        :intro="formFields[currentStep].intro"
      >
        <!-- <ThemeSelector
          :onThemeSelect="onThemeSelect"
          :theme="form.theme"
          :swatch="form.swatch"
          v-show="currentStep === 0"
        /> -->
        <FormBuildMode
            v-show="currentStep === 1"
            v-model="form"
            :onInputRealtor="onInputRealtor"
            :onInputStates="onInputStates"
            :showRealtorForm="showSaveNext"
            :toggleRealtorFields="toggleRealtorFields"
            :enableSaveNext="enableSaveNext"
            :isEdit="isEdit"
            :showEditMode="showEditMode"
            v-on:onListingSelect="onListingSelect"
            v-on:onChooseManualMode="onChooseManualMode"
        />
        <FormStepRealtor
          v-show="currentStep === 2"
          v-model="form"
          :onInputRealtor="onInputRealtor"
          :onInputStates="onInputStates"
          :showRealtorForm="showSaveNext"
          :toggleRealtorFields="toggleRealtorFields"
          :enableSaveNext="enableSaveNext"
          :disableSaveNext="disableSaveNextFn"
          :isEdit="isEdit"
          :showEditMode="showEditMode"
          ref="formRef"
        />
        <FormStepAddress
          v-show="currentStep === 3"
          v-model="form"
          :onInput="onInput"
          v-on:onInputPlaces="onInputPlaces"
          :onInputStates="onInputStates"
        />
        <FormStepParams1
          v-show="currentStep === 4"
          v-model="form"
          :onInput="onInput"
        />
        <FormStepParams2
          v-show="currentStep === 5"
          v-model="form"
          :onInput="onInput"
        />
        <FormStepOverview
          v-show="currentStep === 6"
          v-model="form"
          :onInput="onInput"
        />
        <FormStepPictures
          v-show="currentStep === 7"
          :pictures="form.pictures"
          :addPhoto="addPhoto"
          :removePhoto="removePhoto"
          :setCover="setCover"
          :movePhoto="movePhoto"
        />
        <FormStepPricing
            v-show="currentStep === 8"
            :set-pricing="setPricing"
            :pricing-config="(form.pricing ? form.pricing : {})"
            :loan-info="loanInfo"
            :toggle-parent-btns="toggleFooterButtons"
        />
        <FinalStep v-show="currentStep === 9" />
      </FormStep>
    </form>
    <template slot="footer">
      <BaseButton theme="link" class="btn-cancel" @click="hideModal"
        >Cancel</BaseButton
      >
      <BaseButton
        v-if="showBack"
        @click="backStep"
        outline
        :disabled="isLoading"
        >Back</BaseButton
      >
      <BaseButton
        v-if="showNext"
        theme="modern"
        @click="nextStep"
        :disabled="disableNext"
        >Next</BaseButton
      >
      <BaseButton
        v-if="showSaveNext"
        theme="modern"
        @click="saveRealtor"
        :disabled="disableSaveNext"
        >Save & Continue</BaseButton
      >
      <BaseButton v-if="showSubmit" theme="modern" @click="submitForm">
        Save
      </BaseButton>
    </template>
    <AlertModal
      :showAlert="showAlert"
      :text="confirmTxt"
      v-on:modalConfirm="modalConfirm"
    />
  </BaseModal>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import ThemeSelector from "../ThemeSelector"
import {
  FormStep,
  FormStepRealtor,
  FormStepAddress,
  FormStepParams1,
  FormStepParams2,
  FormStepOverview,
  FormStepPictures,
  FinalStep,
  FormBuildMode,
  FormStepPricing
} from "./ManualListingForm"
import BaseModal from "../BaseModal"
import ImgUploader from "../ImgUploader"
import getDefaultData from "./modalDefaultData"

const { mapState, mapActions } = createNamespacedHelpers("manualListing")

export default {
  name: "ManualListingModal",
  components: {
    BaseModal,
    ImgUploader,
    FormStep,
    ThemeSelector,
    FormStepRealtor,
    FormStepAddress,
    FormStepParams1,
    FormStepParams2,
    FormStepOverview,
    FormStepPictures,
    FormStepPricing,
    FinalStep,
    FormBuildMode
  },
  data: getDefaultData,
  computed: Object.assign(
    {},
    mapState([
      "showItemModal",
      "loid",
      "bank_id",
      "originalItems",
      "currentEditedItem",
      "isEdit"
    ]),
    {
      showItemModalComp: {
        get() {
          if (
            this.currentEditedItem &&
            this.currentEditedItem.data &&
            !this.form.id
          ) {
            let data = this.currentEditedItem.data || {}
            data = typeof data === "object" ? data : JSON.parse(data)
            this.form = Object.assign({}, this.currentEditedItem, data)
            this.form.realtor = data.realtorData.id
            this.disableNext = false
          }
          return this.showItemModal
        },
        set(value) {
          this.toggleItemModal(value)
          this.resetData()
        }
      },
      modalTitle() {
        const prefix = this.isEdit ? "Edit" : "New"
        return `${prefix} Manual Flyer`
      },
      isLoadingOld() {
        return !this.currentEditedItem && this.isEdit
      },
      flyerImgSrc: function() {
        return this.currentEditedItem ? this.currentEditedItem.url : ""
      },
      loanInfo(){
        return {
          list_price: Math.round(parseFloat(this.form.listing_price)),
          zipcode: this.form.zipcode
        }
      }
    }
  ),
  watch: {
    form: function(newForm, oldForm) {
      this.validateStep()
    }
  },
  methods: Object.assign(
    {},
    mapActions(["toggleItemModal", "saveCustomFlyer"]),
    {
      toggleLoading: function() {
        this.isLoading = !this.isLoading
      },
      resetData: function() {
        Object.assign(this.$data, this.$options.data.call(this))
      },
      addPhoto(value) {
        this.$set(this.form, "pictures", [...this.form.pictures, value])
        this.disableNext = !this.form.pictures
      },
      removePhoto(index) {
        this.$delete(this.form.pictures, index)
        this.disableNext = !!this.form.pictures
      },
      setCover(index) {
        const cover = this.form.pictures[index]
        this.removePhoto(index)
        this.$set(this.form, "pictures", [cover, ...this.form.pictures])
      },
      setPricing(pricingConfig) {
        this.$set(this.form, "pricing", pricingConfig)
      },
      movePhoto(oldIndex,newIndex) {
        const newPic = this.form.pictures[newIndex]
        const oldPic = this.form.pictures[oldIndex]
        let pics=[...this.form.pictures]
        pics[newIndex]=oldPic;
        pics[oldIndex]=newPic;
        this.$set(this.form, "pictures", pics)
      },

      nextStep() {
        console.log("currentstep:"+this.currentStep);
		this.validateStep()
        if(this.currentStep == 4){
          this.disableNext = true
          let step4isvalid = 1
          let msgStep4 = '';
          if (!this.form.property_type || this.form.property_type == ''){
            step4isvalid = 0;
          } 
          if(step4isvalid == 0){
            this.disableNext = true
          }
          else {
            this.updateStep(this.currentStep + 1)
            this.disableNext = false
          }
        }
        else if (this.currentStep == 5){
          this.disableNext = true
          let step5isvalid = 1
          let msgStep5 = '';
          if (!this.form.sqft || this.form.sqft == 0){
            step5isvalid = 0;
            //msgStep5 += 'Please enter valid number for sqft. ';
          } 
          if (!this.form.num_beds){
            step5isvalid = 0;
            //msgStep5 += 'Please enter valid number for bed. ';
          }
          if (!this.form.num_baths || this.form.num_baths == 0){
            step5isvalid = 0;
            //msgStep5 += 'Please enter valid number for bath. ';
          }
          if (!this.form.listing_price || this.form.listing_price == 0){
            step5isvalid = 0;
            //msgStep5 += 'Please enter valid value for listing price.';
          }
          if(step5isvalid == 0){
            //console.log(msgStep5);
            this.disableNext = true
          }
          else {
            this.updateStep(this.currentStep + 1)
            this.disableNext = false
          }
        }
		else if(this.currentStep == 3){
          let step3isvalid = 1
          if (!this.form.street_addr ){
            step3isvalid = 0;
          }
          if (!this.form.city ){
            step3isvalid = 0;
          }
          if (!this.form.state ){
            step3isvalid = 0;
          }
          if (!this.form.county ){
            step3isvalid = 0;
          }
          if (!this.form.zipcode ){
            step3isvalid = 0;
          }
          if(step3isvalid){
            this.updateStep(this.currentStep + 1)
          } 
        }
        else if(this.currentStep == 7){
          console.log('this.form.pictures', this.form.pictures);
          if (this.form.pictures.length != 0){
            this.updateStep(this.currentStep + 1)
          }
        }
        else if (this.currentStep !== 2 || this.isConfirmRealtor === true) {
          this.updateStep(this.currentStep + 1)
          // skip build mode step if editing
          if(this.currentStep===1 && this.form.realtor){
            this.updateStep(this.currentStep + 1)
          }
          this.disableNext = !this.currentEditedItem
          this.isConfirmRealtor = false;
          if(this.currentStep===1)this.disableNext=true
        } else {
          this.isConfirmRealtor = true
          this.formFields[2].intro = "Set up the realtor."
          this.$refs.formRef.editRealtor()
        }
        
      },
      backStep() {
        let nextStep = this.currentStep - 1
        this.updateStep(nextStep)
        // skip build mode step if editing
        if(this.currentStep===1 && this.form.realtor){
          nextStep = this.currentStep - 1
          this.updateStep(nextStep)
        }
        if (nextStep === 0 && this.form.oldRealtor) {
          this.$set(this.form, "realtor", this.form.oldRealtor.realtor)
          this.$set(this.form, "realtorData", this.form.oldRealtor.realtorData)
        }
        this.disableNext = false
        this.isRealtorEdit = false
      },
      updateStep(nextStep) {
        const steps = this.formFields.length
        this.showBack = nextStep > 0
        if (steps - 2 === nextStep) {
          this.showSubmit = true
          this.showNext = false
        } else {
          this.showSaveNext = false
          this.showSubmit = false
          this.showNext = true
        }
        this.currentStep = nextStep
        //If on FormBuildMode
        if (this.currentStep === 1) {
          this.showNext = false
        }
        //If on FormStepRealtor
        if (this.currentStep === 2) {
          this.showBack = false
        }
      },
      validateStep() {
        if (this.currentStep > 0) {
          let count = 0
          const requiredFields = this.formFields[this.currentStep]
            .requiredFields
          requiredFields.forEach(field => {
            this.form[field] && this.form[field].length && count++
          })
          this.disableNext = count !== requiredFields.length
        }
      },
      onThemeSelect(val, id) {
        this.validateStep(val, id)
        // "template":"<template_id>-<swatch_id>"
        this.$set(this.form, id, val)

        if (id === "swatch" && typeof val === "number") {
          this.disableNext = false
          this.$set(this.form, "template", `${this.form.theme}-${val}`)
        } else {
          this.disableNext = true
          this.$set(this.form, "template", null)
        }
      },
      enableSaveNext() {
        this.disableSaveNext = false
      },
      disableSaveNextFn() {
        this.disableSaveNext = true
      },
      onInput(val, $event, id) {
        const itemId = ($event && $event.target.id) || id
        this.$set(this.form, itemId, val)
        this.disableNext = false
      },
      onInputRealtor(val, realtor, id) {
        this.$set(this.form, id, val)
        this.$set(this.form, "realtorData", realtor)
      },
      onInputStates(val, event) {
        const thatForm = event ? this.form : this.form.realtorData
        this.$set(thatForm, "state", val)
      },
      onInputPlaces(val) {
        Object.keys(val).forEach(field => {
          let item = val[field] || ""
          let fieldName
          switch (field) {
            case "street_number":
              break
            case "administrative_area_level_1":
              fieldName = "state"
              break
            case "administrative_area_level_2":
              fieldName = "county"
              break
            case "locality":
              fieldName = "city"
              break
            case "postal_code":
              fieldName = "zipcode"
              break
            case "route":
              fieldName = "street_addr"
              item = `${val.street_number || ""} ${val.route || ""}`
              break
            default:
              fieldName = field
          }
          if (fieldName) {
            this.$set(this.form, fieldName, item)
          }
        })
      },
      async onListingSelect(val){
        Object.keys(val.newListing).forEach(field => {
          let item = val.newListing[field] || ""
          switch (field) {
            case "pictures":
              item.forEach(pic=>{
                this.addPhoto(pic)
              })
              break
            default:
              this.$set(this.form, field, item)
          }
        });
        this.$set(this.form, "realtorData", val.newRealtor)
        await this.saveRealtor();
        this.$set(this.form, "oldRealtor", {
          realtor: this.form.realtor,
          realtorData: this.form.realtorData
        })
      },
      onChooseManualMode(){
        this.nextStep()
      },
      hideModal() {
        this.toggleItemModal(false)
        this.resetData()
      },
      submitForm: function() {
        const { form } = this
        const data = {
          owner: $("body").data("user_id"),
          street_addr: form.street_addr,
          city: form.city,
          state: form.state,
          zipcode: form.zipcode,
          data: {
            county: form.county,
            neighborhood: form.neighborhood,
            num_beds: form.num_beds,
            num_baths: form.num_baths,
            num_half_baths: form.num_half_baths,
            property_type: form.property_type,
            year_built: form.year_built,
            sqft: form.sqft,
            listing_price: form.listing_price,
            hoa: form.hoa,
            high_school: form.high_school,
            middle_school: form.middle_school,
            elem_school: form.elem_school,
            mls: form.mls,
            pictures: form.pictures,
            swatch: form.swatch,
            theme: form.theme,
            template: form.template,
            overview: form.overview,
            realtorData: {
              label:
                form.realtorData.label ||
                `${form.realtorData.first_name} ${form.realtorData.last_name}`,
              id: form.realtorData.id
            },
            pricing: form.pricing,
			open_date: form.open_date
          }
        }

        if (this.currentEditedItem && this.currentEditedItem.id) {
          data.id = this.currentEditedItem.id
        }

        this.saveCustomFlyer(data)
      },
      saveRealtor: async function() {
        this.toggleLoading()
        const {
          first_name,
          last_name,
          title,
          email,
          phone,
          agency_name,
          agency_logo,
          picture,
          state
        } = this.form.realtorData
        
        const newRealtor = {
          first_name,
          last_name,
          title,
          email,
          phone,
          agency_name,
          agency_logo,
          picture,
          state
        }

        if (this.form.realtorData.id) {
          newRealtor.id = this.form.realtorData.id
        }

        const action = this.form.realtorData.id ? "update" : "create"
        let existing_realtor = null;
        newRealtor.email = (email ? email : this.form.realtorData.homescout_id + "@changeme.please")
        // only check for existing realtor if we are in form build mode step creating a realtor
        // TODO: existing realtor check should use homescout rid
        if(this.form.realtorData.type === "homescout" && newRealtor.email) {
          existing_realtor = await BB.realtorManage.manual
            .get({ email: newRealtor.email, owner: $("body").data("user_id") })
        }

        if(!existing_realtor || existing_realtor.length === 0){
          let data = {}
          try {
            data = await BB.realtorManage.manual[action](newRealtor)
          } catch (error) {
            data.status = "error";
          }
          if (data.status !== "error") {
            const id = action === "update" ? newRealtor.id : data.realtor_id
            this.$set(this.form, "realtor", id)
            this.$set(this.form, "realtorData", {
              id: id,
              label: `${first_name} ${last_name}`
            })
            
            if(action === "create") {
              this.isConfirmRealtor = true
            }

            if(
                action === "update" ||
                action !="update" && this.form.realtorData.type!="homescout"
            ){
              this.nextStep()
            }
          }
          else {
            if(
                action === "update" ||
                action != "update" && this.form.realtorData.type != "homescout"
            ){
              this.confirmTxt = data.message
              this.showAlert = true
            }
            else{
              this.nextStep()
            }
          }
        }
        else{
          this.$set(this.form, "realtor", existing_realtor[0].id)
          this.$set(this.form, "realtorData", {
            id: existing_realtor[0].id,
            label: `${existing_realtor[0].first_name} ${existing_realtor[0].last_name}`
          })
          this.nextStep()
        }
        this.toggleLoading()
      },
      toggleRealtorFields: function(isNew = false) {
        if (isNew) {
          this.$set(this.form, "oldRealtor", {
            realtor: this.form.realtor,
            realtorData: this.form.realtorData
          })
          this.$set(this.form, "realtor", null)
          this.$set(this.form, "realtorData", {})
          this.isRealtorEdit = false
        }
        this.showSaveNext = true
        this.showNext = false
      },
      modalConfirm() {
        this.showAlert = false
        this.confirmTxt = ""
      },
      showEditMode() {
        this.isRealtorEdit = true
      },
      toggleFooterButtons() {
        this.showSubmit=!this.showSubmit
        this.showBack=!this.showBack
      }
    }
  )
}
</script>

<style lang="scss">
#manualFlyerModal {
  .modal-footer .btn-cancel {
    margin-right: auto;
  }
  .img-uploader--thumbPrev .loader-wrapper {
    height: 150px;
    width: 150px;
    max-width: 100%;
  }
  .loader.loader-circle {
    width: 0.5em;
    height: 0.5em;
  }
}
</style>
