<doc>
    Pricing Engine Vue.js component
</doc>
<template>
    <div id="extraProfileDetails">
        <div class="form-group">
            <label class="control-label" for="inputTitle">Pricing Engine Connected</label>
            <input
                type="checkbox"
                v-model="lo.pricing_engine_connected"
                name="pricing_engine_connected"
                disabled
            />
            <span class="desc"></span>
        </div>
        <div class="form-group">
            <label class="control-label" for="pricing_engine_name">Pricing Engine Name</label>
            <InputText
                v-model="lo.pricing_engine_name"
                name="pricing_engine_name"
                readonly
            />
            <span class="desc">Name of the pricing engine</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="originator_id">Pricing Engine User ID</label>
            <InputText
                v-model="lo.originator_id"
                name="originator_id"
                readonly
            />
            <span class="desc">Field used to identify loan officer to OB API</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="biz_channel">Pricing Engine Business Channel (if applicable)</label>
            <InputText
                v-model="lo.biz_channel"
                name="biz_channel"
                readonly
            />
            <span class="desc">Field used to identify loan officer's business channel to OB API</span>
        </div>
        <!--<div class="form-group form-group-full">-->
        <!--    <button class="btn btn-modern" @click="save">Save</button>-->
        <!--</div>-->
    </div>
</template>
<script>
import _find from "lodash/find"
import { createNamespacedHelpers } from "vuex"
const { mapState, mapActions } = createNamespacedHelpers("user-profile-details")
export default {
    name: "PricingEngine",
    components: {
    },
    data() {
    return {
        lo: [],
    }
  },
  async mounted(){
    console.log('mounted marketing', fields['lflop_applink'].value);

    this.lo.app_link=fields['lflop_applink'].value
  },
  methods: Object.assign(
    {},
        mapActions([
            
        ]),
        {
            save(){
                const data = this.lo;
                $.ajax({
                type: 'POST',
                url: '/lo-manage.json',
                data: JSON.stringify(data),
                contentType: "application/json",
                dataType: "json",
                success: function (response) {
                    // Display errors.
                    if (response.status == 'success') {
                        console.log('ok');
                        BB.Toastr.success(response.message);
                    }
                    else{

                    }
                },
                error: function (xmlhttp) {
                    
                }
            });
            }
        }
    ),
    created : function() {
        //this.initTypeahead();
        console.log('created marketing');
        let that = this;
        jQuery.ajax({
            url: '/lo-manage.json?loid='+uid,
            type: "GET",
            contentType: "application/json",
            dataType: "json",
            success(data) {
                that.lo = data.lo

                that.lo.app_link = fields['lflop_applink'].value
                that.lo.profile_image = extra_fields.profile_image 
                console.log('extra_fields[profile_image] ', extra_fields.profile_image );
            },
            error(xhr, ajaxOptions, thrownError) { 
            }
        })
    }
}
</script>
<style lang="scss" scoped>
	#extraProfileDetails {
		display: flex;
		flex-wrap: wrap;
	}
	#extraProfileDetails .form-group {
		width: 43%;
		margin-right: 2%;
		margin-bottom: 30px;
	}
	#extraProfileDetails .form-group .form-group {
		width: 100%;
		margin-right: 0;
	}
	#extraProfileDetails .form-group.form-group-full {
		width: 100%;
		margin-right: 0%;
	}
	#extraProfileDetails textarea.form-control {
		width: 100%;
		height: 200px;
	}
	#extraProfileDetails label.input-label  {
		display: none;
	}
</style>