var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "extraProfileDetails" } }, [
    _c("div", { staticClass: "form-group" }, [
      _c(
        "label",
        { staticClass: "control-label", attrs: { for: "inputTitle" } },
        [_vm._v("Pricing Engine Connected")]
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.lo.pricing_engine_connected,
            expression: "lo.pricing_engine_connected"
          }
        ],
        attrs: {
          type: "checkbox",
          name: "pricing_engine_connected",
          disabled: ""
        },
        domProps: {
          checked: Array.isArray(_vm.lo.pricing_engine_connected)
            ? _vm._i(_vm.lo.pricing_engine_connected, null) > -1
            : _vm.lo.pricing_engine_connected
        },
        on: {
          change: function($event) {
            var $$a = _vm.lo.pricing_engine_connected,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 &&
                  _vm.$set(
                    _vm.lo,
                    "pricing_engine_connected",
                    $$a.concat([$$v])
                  )
              } else {
                $$i > -1 &&
                  _vm.$set(
                    _vm.lo,
                    "pricing_engine_connected",
                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                  )
              }
            } else {
              _vm.$set(_vm.lo, "pricing_engine_connected", $$c)
            }
          }
        }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "desc" })
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c(
          "label",
          {
            staticClass: "control-label",
            attrs: { for: "pricing_engine_name" }
          },
          [_vm._v("Pricing Engine Name")]
        ),
        _vm._v(" "),
        _c("InputText", {
          attrs: { name: "pricing_engine_name", readonly: "" },
          model: {
            value: _vm.lo.pricing_engine_name,
            callback: function($$v) {
              _vm.$set(_vm.lo, "pricing_engine_name", $$v)
            },
            expression: "lo.pricing_engine_name"
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "desc" }, [
          _vm._v("Name of the pricing engine")
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c(
          "label",
          { staticClass: "control-label", attrs: { for: "originator_id" } },
          [_vm._v("Pricing Engine User ID")]
        ),
        _vm._v(" "),
        _c("InputText", {
          attrs: { name: "originator_id", readonly: "" },
          model: {
            value: _vm.lo.originator_id,
            callback: function($$v) {
              _vm.$set(_vm.lo, "originator_id", $$v)
            },
            expression: "lo.originator_id"
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "desc" }, [
          _vm._v("Field used to identify loan officer to OB API")
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c(
          "label",
          { staticClass: "control-label", attrs: { for: "biz_channel" } },
          [_vm._v("Pricing Engine Business Channel (if applicable)")]
        ),
        _vm._v(" "),
        _c("InputText", {
          attrs: { name: "biz_channel", readonly: "" },
          model: {
            value: _vm.lo.biz_channel,
            callback: function($$v) {
              _vm.$set(_vm.lo, "biz_channel", $$v)
            },
            expression: "lo.biz_channel"
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "desc" }, [
          _vm._v(
            "Field used to identify loan officer's business channel to OB API"
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-c55d809e", { render: render, staticRenderFns: staticRenderFns })
  }
}