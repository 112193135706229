var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "grid-row",
        [
          _c("grid-column", { attrs: { size: "4" } }, [
            _c(
              "div",
              [
                _c("ImgUploader", {
                  attrs: {
                    previewSrc: _vm.value.picture,
                    editMode: !!_vm.value.picture,
                    id: "picture",
                    size: "150",
                    fullRounded: "",
                    type: "thumbPrev",
                    label: "Realtor picture",
                    uploadCallback: _vm.uploadCallback,
                    onErrorShowAlert: "",
                    useS3: "",
                    isHeadshot: true
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "realtor-headshot" }, [
              _c("span", [
                _vm._v("Realtor Headshot"),
                _c("span", { staticClass: "red-text" })
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "grid-column",
            { attrs: { size: "8" } },
            [
              _c("InputText", {
                attrs: {
                  label: "First Name",
                  labelClassName: "required",
                  name: "first_name"
                },
                model: {
                  value: _vm.value.first_name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.value,
                      "first_name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "value.first_name"
                }
              }),
              _vm._v(" "),
              _c("InputText", {
                attrs: {
                  label: "Last Name",
                  labelClassName: "required",
                  name: "last_name"
                },
                model: {
                  value: _vm.value.last_name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.value,
                      "last_name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "value.last_name"
                }
              }),
              _vm._v(" "),
              _c("InputText", {
                attrs: { label: "Realtor Title", name: "title" },
                model: {
                  value: _vm.value.title,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.value,
                      "title",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "value.title"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "grid-row",
        [
          _c(
            "grid-column",
            { attrs: { size: "6" } },
            [
              _c("InputText", {
                attrs: {
                  label: "Email",
                  labelClassName: "required",
                  name: "email",
                  type: "email",
                  readonly: _vm.readonly
                },
                model: {
                  value: _vm.value.email,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.value,
                      "email",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "value.email"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "blue-text validate-helper-text-position" },
                [
                  _c("span", [
                    _vm._v("* Validate realtor's email address is accurate")
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "grid-column",
            { attrs: { size: "6" } },
            [
              _c("InputText", {
                attrs: { label: "Phone", name: "phone", type: "tel" },
                model: {
                  value: _vm.value.phone,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.value,
                      "phone",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "value.phone"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "grid-row",
        [
          _c("grid-column", { attrs: { size: "4" } }, [
            _c(
              "div",
              [
                _c("ImgUploader", {
                  attrs: {
                    previewSrc: _vm.value.agency_logo,
                    editMode: !!_vm.value.agency_logo,
                    id: "agency_logo",
                    size: "150",
                    type: "thumbPrev",
                    label: "Agency logo",
                    uploadCallback: _vm.uploadCallback,
                    onErrorShowAlert: "",
                    useS3: ""
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "agency-logo" }, [
              _c("span", [_vm._v("Agency Logo")]),
              _c("span", { staticClass: "red-text" })
            ])
          ]),
          _vm._v(" "),
          _c(
            "grid-column",
            { attrs: { size: "8" } },
            [
              _c("InputText", {
                attrs: { label: "Agency Name", name: "agency_name" },
                model: {
                  value: _vm.value.agency_name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.value,
                      "agency_name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "value.agency_name"
                }
              }),
              _vm._v(" "),
              _c("StatesDropDown", {
                attrs: {
                  label: "State",
                  name: "state",
                  onInput: _vm.onInputStatesLocal
                },
                model: {
                  value: _vm.value.state,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.value,
                      "state",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "value.state"
                }
              }),
              _vm._v(" "),
              _c("div", [
                _c("span", { staticClass: "file-type-helper" }, [
                  _vm._v("File types JPEG or PNG, size under 5 MB")
                ]),
                _c("span", { staticClass: "blue-text field-helper" }, [
                  _vm._v("* All fields required")
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-52e2809f", { render: render, staticRenderFns: staticRenderFns })
  }
}