import Vue from "vue"
import Vuex from "vuex"
import VueMq from "vue-mq"
import Icon from "vue-awesome/components/Icon"
import VueSlideoutPanel from "vue2-slideout-panel"
import {
  Table,
  ConfirmModal,
  AlertModal,
  DropDown,
  InputText,
  InputTextArea,
  FlexBox,
  AlignedItem,
  GridColumn,
  GridRow,
  BaseButton,
  SortableList,
  LoaderPlaceholder,
  Panel
} from "./components"

Vue.use(Vuex)
Vue.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    sm: 768,
    md: 992,
    lg: Infinity
  }
})

Vue.component("Table", Table)
Vue.component("DropDown", DropDown)
Vue.component("ConfirmModal", ConfirmModal)
Vue.component("AlertModal", AlertModal)
Vue.component("LoaderPlaceholder", LoaderPlaceholder)
Vue.component("InputText", InputText)
Vue.component("InputTextArea", InputTextArea)
Vue.component("GridColumn", GridColumn)
Vue.component("GridRow", GridRow)
Vue.component("SortableList", SortableList)
Vue.component("FlexBox", FlexBox)
Vue.component("AlignedItem", AlignedItem)
Vue.component("icon", Icon)
Vue.component("BaseButton", BaseButton)
Vue.component("Panel", Panel)
Vue.use(VueSlideoutPanel)
