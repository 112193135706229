var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-wrapper" }, [
    _c("div", { staticClass: "header" }, [_vm._v("\n        User\n    ")]),
    _vm._v(" "),
    _c("div", { staticClass: "body" }, [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.theUser,
              expression: "theUser"
            }
          ],
          staticClass: "form-control user-to-impersonate",
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.theUser = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            }
          }
        },
        [
          _c("option", [_vm._v("Select User to Impersonate")]),
          _vm._v(" "),
          _vm._l(_vm.users, function(user) {
            return _c(
              "option",
              { key: user.uid, domProps: { value: user.uid } },
              [_vm._v(_vm._s(user.name))]
            )
          })
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c(
        "button",
        { staticClass: "btn btn-modern", on: { click: _vm.impersonateNow } },
        [_vm._v("Submit")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7f4a15d6", { render: render, staticRenderFns: staticRenderFns })
  }
}