var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "extraProfileDetails" } }, [
    _c("div", { staticClass: "form-group" }, [
      _c("img", { attrs: { src: _vm.lo.profile_image, width: "64" } })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [_vm._v(" ")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c(
          "label",
          { staticClass: "control-label", attrs: { for: "inputTitle" } },
          [_vm._v("Title")]
        ),
        _vm._v(" "),
        _c("InputText", {
          attrs: { name: "title" },
          model: {
            value: _vm.lo.title,
            callback: function($$v) {
              _vm.$set(_vm.lo, "title", $$v)
            },
            expression: "lo.title"
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "desc" }, [
          _vm._v(
            "Your title or position at your bank (example: VP, Mortgage Banker)"
          )
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c(
          "label",
          { staticClass: "control-label", attrs: { for: "inputTitle" } },
          [_vm._v("Loan Application Link")]
        ),
        _vm._v(" "),
        _c("InputText", {
          attrs: { name: "app_link" },
          model: {
            value: _vm.lo.app_link,
            callback: function($$v) {
              _vm.$set(_vm.lo, "app_link", $$v)
            },
            expression: "lo.app_link"
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "desc" }, [
          _vm._v(
            "The link that a customer should use to apply for a loan or to get pre-approved "
          )
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c(
          "label",
          { staticClass: "control-label", attrs: { for: "inputStreet" } },
          [_vm._v("Street Address")]
        ),
        _vm._v(" "),
        _c("InputText", {
          attrs: { name: "office_street_addr" },
          model: {
            value: _vm.lo.office_street_addr,
            callback: function($$v) {
              _vm.$set(_vm.lo, "office_street_addr", $$v)
            },
            expression: "lo.office_street_addr"
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "desc" }, [
          _vm._v("Enter the street address of your branch/office")
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c(
          "label",
          { staticClass: "control-label", attrs: { for: "inputCity" } },
          [_vm._v("City")]
        ),
        _vm._v(" "),
        _c("InputText", {
          attrs: { name: "office_city" },
          model: {
            value: _vm.lo.office_city,
            callback: function($$v) {
              _vm.$set(_vm.lo, "office_city", $$v)
            },
            expression: "lo.office_city"
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "desc" }, [
          _vm._v("Enter the city of your branch/office")
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c(
          "label",
          { staticClass: "control-label", attrs: { for: "inputState" } },
          [_vm._v("State")]
        ),
        _vm._v(" "),
        _c("InputText", {
          attrs: { name: "office_state" },
          model: {
            value: _vm.lo.office_state,
            callback: function($$v) {
              _vm.$set(_vm.lo, "office_state", $$v)
            },
            expression: "lo.office_state"
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "desc" }, [
          _vm._v("Enter the state of your branch/office")
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c(
          "label",
          { staticClass: "control-label", attrs: { for: "inputZipcode" } },
          [_vm._v("Zipcode")]
        ),
        _vm._v(" "),
        _c("InputText", {
          attrs: { name: "office_zipcode" },
          model: {
            value: _vm.lo.office_zipcode,
            callback: function($$v) {
              _vm.$set(_vm.lo, "office_zipcode", $$v)
            },
            expression: "lo.office_zipcode"
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "desc" }, [
          _vm._v("Enter the zipcode of your branch/office")
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c(
          "label",
          { staticClass: "control-label", attrs: { for: "inputFbLink" } },
          [_vm._v("Facebook Profile Link")]
        ),
        _vm._v(" "),
        _c("InputText", {
          attrs: { name: "facebook_link" },
          model: {
            value: _vm.lo.facebook_link,
            callback: function($$v) {
              _vm.$set(_vm.lo, "facebook_link", $$v)
            },
            expression: "lo.facebook_link"
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "desc" }, [
          _vm._v("Enter URL of facebook profile")
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c(
          "label",
          { staticClass: "control-label", attrs: { for: "inputInLink" } },
          [_vm._v("LinkedIn Profile Link")]
        ),
        _vm._v(" "),
        _c("InputText", {
          attrs: { name: "linkedin_link" },
          model: {
            value: _vm.lo.linkedin_link,
            callback: function($$v) {
              _vm.$set(_vm.lo, "linkedin_link", $$v)
            },
            expression: "lo.linkedin_link"
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "desc" }, [
          _vm._v("Enter URL of LinkedIn profile")
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c(
          "label",
          { staticClass: "control-label", attrs: { for: "inputAboutWebsite" } },
          [_vm._v("Website")]
        ),
        _vm._v(" "),
        _c("InputText", {
          attrs: { name: "website_link" },
          model: {
            value: _vm.lo.website_link,
            callback: function($$v) {
              _vm.$set(_vm.lo, "website_link", $$v)
            },
            expression: "lo.website_link"
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "desc" }, [
          _vm._v("Bank specific webpage/landing page URL")
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c(
          "label",
          {
            staticClass: "control-label",
            attrs: { for: "inputSpecialDisclosureLink" }
          },
          [_vm._v("Special Disclosure Link")]
        ),
        _vm._v(" "),
        _c("InputText", {
          attrs: { name: "special_disclosure_link" },
          model: {
            value: _vm.lo.special_disclosure_link,
            callback: function($$v) {
              _vm.$set(_vm.lo, "special_disclosure_link", $$v)
            },
            expression: "lo.special_disclosure_link"
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "desc" }, [
          _vm._v("Enter the URL to the disclosure page")
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c(
          "label",
          { staticClass: "control-label", attrs: { for: "inputAboutMe" } },
          [_vm._v("About Me")]
        ),
        _vm._v(" "),
        _c("InputTextArea", {
          attrs: { name: "about_me" },
          model: {
            value: _vm.lo.about_me,
            callback: function($$v) {
              _vm.$set(_vm.lo, "about_me", $$v)
            },
            expression: "lo.about_me"
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "desc" }, [
          _vm._v('"About Me" blurb to introduce yourself to homebuyers')
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c(
          "label",
          { staticClass: "control-label", attrs: { for: "inputAboutBank" } },
          [_vm._v("About Bank")]
        ),
        _vm._v(" "),
        _c("InputTextArea", {
          attrs: { name: "about_bank" },
          model: {
            value: _vm.lo.about_bank,
            callback: function($$v) {
              _vm.$set(_vm.lo, "about_bank", $$v)
            },
            expression: "lo.about_bank"
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "desc" }, [
          _vm._v('"About Bank" blurb to introduce your bank to homebuyers')
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "form-group form-group-full" }, [
      _c("button", { staticClass: "btn btn-modern", on: { click: _vm.save } }, [
        _vm._v("Save")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2c695860", { render: render, staticRenderFns: staticRenderFns })
  }
}