var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition-group",
    {
      attrs: { name: "modal" },
      on: {
        "before-enter": _vm.beforeEnter,
        "after-enter": _vm.afterEnter,
        "after-leave": _vm.afterLeave
      }
    },
    [
      _c(
        "div",
        { key: "modal", staticClass: "flyer-modal", attrs: { tabindex: "-1" } },
        [
          _c("div", { staticClass: "modal-dialog flyer-modal-dialog" }, [
            _c("div", { staticClass: "modal-content flyer-modal-content" }, [
              _c("div", { staticClass: "modal-header flyer-modal-header" }, [
                _c("h4", { staticClass: "modal-title flyer-modal-title" }, [
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.title) +
                      "\n                        "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { theme: "", type: "button", "aria-label": "Close" },
                    on: { click: _vm.cancel }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "closeBtn",
                        attrs: { "aria-hidden": "true" }
                      },
                      [_vm._v("×")]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-body flyer-modal-body modal-para" },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "background-color": "#eee",
                        padding: "15px"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.size_name,
                            expression: "size_name"
                          }
                        ],
                        staticClass:
                          "txt-document-size-name inptxt form-control",
                        attrs: { type: "text", placeholder: "Name" },
                        domProps: { value: _vm.size_name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.size_name = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.width,
                            expression: "width"
                          }
                        ],
                        staticClass: "txt-document-width inptxt form-control",
                        attrs: { type: "text", placeholder: "Width" },
                        domProps: { value: _vm.width },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.width = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.height,
                            expression: "height"
                          }
                        ],
                        staticClass: "txt-document-height inptxt form-control",
                        attrs: { type: "text", placeholder: "Height" },
                        domProps: { value: _vm.height },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.height = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-modern",
                          on: { click: _vm.save }
                        },
                        [
                          _vm._v(
                            "\n                                Save\n                            "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "lists-wrapper" }, [
                    _c("table", { staticClass: "table" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("td", [_vm._v("Name")]),
                          _vm._v(" "),
                          _c("td", [_vm._v("Width")]),
                          _vm._v(" "),
                          _c("td", [_vm._v("Height")]),
                          _vm._v(" "),
                          _c("td")
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.list, function(item) {
                          return _c("tr", { key: item.id }, [
                            _c("td", [_vm._v(_vm._s(item.name))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(item.width))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(item.height))]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.edit(item.id)
                                    }
                                  }
                                },
                                [_vm._v("Edit")]
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.remove(item.id)
                                    }
                                  }
                                },
                                [_vm._v("Delete")]
                              )
                            ])
                          ])
                        })
                      )
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer flyer-modal-footer" })
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _vm.isVisible
        ? _c("div", { key: "backdrop", staticClass: "modal-backdrop in" })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-70d0f82a", { render: render, staticRenderFns: staticRenderFns })
  }
}