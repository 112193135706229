<doc>
    Profile Vue.js component
</doc>
<template>
    <div>
        <Tabs>
            <Tab name="Contact Info">
               <ContactInfo />
            </Tab>
            <!--<Tab name="Marketing Profile">-->
            <!--    <MarketingDetail />-->
            <!--</Tab>-->
			<Tab name="Pricing Engine">
                <PricingEngine />
            </Tab>
   <!--         <Tab name="Billing">-->
                
   <!--         </Tab>-->
        </Tabs>
    </div>
</template>

<script>
import _find from "lodash/find"
import { createNamespacedHelpers } from "vuex"
import {
  Tab,
  Tabs,
  MarketingDetail,
  ContactInfo,
  PricingEngine
} from "../components"
const { mapState, mapActions } = createNamespacedHelpers("user-profile")

export default {
  name: "Profile",
  components: {
    Tabs,
    Tab,
    MarketingDetail,
    ContactInfo,
	PricingEngine
  },
  async mounted(){
    
  },
  data() {
    return {
        users: null,
        theUser: null
    }
  },
  methods: Object.assign(
    {},
        mapActions([
            
        ]),
        {
            impersonateNow(){
                // let userobj = _find(this.users, ["uid", this.theUser])
                // document.location = userobj.impersonate_url;
            }
        }
    ),
    created : function() {
        //this.initTypeahead();
        let that = this;
        // jQuery.ajax({
        //     url: '/api/v1/impersonated-users',
        //     type: "GET",
        //     contentType: "application/json",
        //     dataType: "json",
        //     success(data) {
        //           that.users = data              
        //     },
        //     error(xhr, ajaxOptions, thrownError) { 
        //     }
        // })
    }
}
</script>
<style>
.main-wrapper{
    background: #fff;
}
.header{
    background:#45c2c7;
    padding: 15px;
}
.body{
    padding: 15px;
}
.footer{
    padding: 15px;
    text-align: center;
}
</style>