var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      class: _vm.className,
      style: _vm.style,
      on: {
        click: function($event) {
          _vm.$emit("click", $event)
        }
      }
    },
    [
      _vm.isIcon
        ? _c("icon", { staticClass: "badge-icon", attrs: { name: _vm.icon } })
        : _vm._e(),
      _vm._v("\n  " + _vm._s(_vm.label) + "\n  "),
      _vm.hasClose
        ? _c(
            "span",
            {
              staticClass: "btn-close",
              on: {
                click: function($event) {
                  _vm.$emit("removeBadge", { event: $event, query: _vm.label })
                }
              }
            },
            [_c("icon", { attrs: { name: "times" } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1128de35", { render: render, staticRenderFns: staticRenderFns })
  }
}