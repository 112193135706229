import Vue from "vue"
import _orderBy from "lodash/orderBy"
import initStore from "./store/bb-network-new"
import { MyNetwork } from "./pages"

export default function() {
   
  if (!document.getElementById("vue-my-network-tab-app")) {
    return
  }

  console.log('js/bb-network-new.js');
  const store = initStore()
  // initial items
  //store.dispatch("staticFlyers/initStaticFlyers", { bank_id, isBankAdmin, uid })
  //store.dispatch("manualListing/initManualListing", { bank_id, isBankAdmin, uid })
  // Vuejs setup
  new Vue({
    el: "#vue-my-network-tab-app",
    store,
    components: {
        MyNetwork
    }
  })
}