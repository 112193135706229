<template>
    <div class="my-archive-table-container">
        <div 
            v-if='isShowTopScrollBar' 
            class="scroll-bar-wrapper" 
            v-on:scroll.passive="handleScroll(true)" 
            ref="scrollWrapper"
        >
            <div ref="scrollbar"></div>
        </div>
        <div 
            class="my-archive-table-wrapper" 
            v-on:scroll.passive="handleScroll(false)" 
            ref="tableWrapper"
        >
            <div v-if="isLoading" class="my-archive-loader-wrapper text-center">
                <div
                    :style="loaderTop" 
                    class="loader loader-circle" data-type="default"></div>
            </div>
            <Table
                :bordered="true"
                :striped="true"
                class="my-archive-table"
            >
                <thead class="thead-light">
                    <tr>
                        <th v-for="(col, index) in columns"
                            :class="headerClassName(col, index)"
                            :style="{ width: col.width }"
                            @click="col.sortable && toggleSort(col, index)"
                        >
                            {{ col.label }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="reportData.length === 0">
                        <div :style="{ height: '100px' }"></div>
                    </tr>
                    <tr v-for="row in reportData">
                        <td v-for="col in columns"
                            :class="col.tdClass ? col.tdClass : ''"
                        >
                            <span v-if="col.field === 'created'">
                                {{formatDate(row.created)}}
                            </span>
                            <span v-else-if="col.field === 'nid'">
                                <a :href="'../listing_flyer/' + row.nid + '/archive'">Download</a>
                            </span>
                            <span v-else>
                                {{row[col.field]}}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    </div>
</template>

<script>
import Table from '../Table'
import moment from "moment"

export default {
    name: "MyArchiveTable",
    components: { Table },
    props: {
        isShowTopScrollBar: { type: Boolean, default: false },
        reportData: { type: Array, default: () => [] },
        onSorting: { type: Function },
        isLoading: { type: Boolean, default: false }
    },
    data() {
        return {
            scrolling: false,
            sortColumnIndex: 2,
            isAsc: false, 
            columns: [
                {
                    label: 'User',
                    field: 'username',
                    sortable: true,
                    width: '150px',
                },
                {
                    label: 'Brand',
                    field: 'brand_name',
                    sortable: true,
                    width: '150px',
                },
                {
                    label: 'Date Created',
                    field: 'created',
                    sortable: true,
                    width: '122px',
                    tdClass: 'text-center',
                },
                {
                    label: 'Flyer Type',
                    field: 'flyer_type',
                    sortable: true,
                    width: '107px',
                },
                {
                    label: 'Flyer Download',
                    field: 'nid',
                    sortable: false,
                    width: '121px',
                    tdClass: 'text-center',
                },
                {
                    label: 'Flyer Name',
                    field: 'flyer_name',
                    sortable: true,
                    width: '150px',
                },
                {
                    label: 'Address',
                    field: 'address',
                    sortable: true,
                    width: '200px',
                },
                {
                    label: 'City',
                    field: 'city',
                    sortable: true,
                    width: '108px',
                },
                {
                    label: 'State',
                    field: 'state',
                    sortable: true,
                    width: '74px',
                    tdClass: 'text-center',
                },
                {
                    label: 'Zip Code',
                    field: 'zip_code',
                    sortable: true,
                    width: '98px',
                    tdClass: 'text-center',
                },
                {
                    label: 'Realtor Name',
                    field: 'realtor_name',
                    sortable: true,
                    width: '150px',
                },
                {
                    label: 'Realtor Email',
                    field: 'realtor_email',
                    sortable: true,
                    width: '200px',
                },
            ]
        }
    },
    computed: {
        loaderTop() {
            return { top: this.reportData.length > 15 ? 'calc(50% - 220px)' : 'calc(50% - 15px)' }
        }
    },
    mounted: function () {
        // Resizeable columns table 
        // https://github.com/bootstrap-vue/bootstrap-vue/issues/1496
        var thElm
        var startOffset
        var minWidth = 70
        var maxWidth = 500
        const table = document.querySelector("table")
        const cols = document.querySelectorAll("table th")
        table.style.maxWidth = 'none'

        cols.forEach(th => {
            th.style.position = 'relative'
            th.style.maxWidth = maxWidth + 'px'
            th.style.minWidth = minWidth + 'px'
            
            var grip = document.createElement('div')
            grip.innerHTML = "&nbsp;"
            grip.style.top = 0
            grip.style.right = 0
            grip.style.bottom = 0
            grip.style.width = '5px'
            grip.style.position = 'absolute'
            grip.style.cursor = 'col-resize'
            grip.addEventListener('click', function(e) {
                e.stopPropagation()
            })
            grip.addEventListener('mousedown', function (e) {
                thElm = th
                startOffset = th.offsetWidth - e.pageX
            })

            th.appendChild(grip)
        })

        const updateTableWidth = () => {
            table.style.width = [].reduce.call(
                cols,
                (sum, col) => sum + parseInt(col.style.width),
                0
            ) + 'px'

            if(this.isShowTopScrollBar)
                this.$refs["scrollbar"].style.width = table.style.width
        }

        updateTableWidth()

        document.addEventListener('mousemove', function (e) {
            let newWidth = startOffset + e.pageX
            if (thElm && newWidth > minWidth && newWidth < maxWidth) {
                thElm.style.width = newWidth + 'px'
                updateTableWidth()
            }
        })

        document.addEventListener('mouseup', function () {
            thElm = undefined
        })
    },
    methods: {
        formatDate(date) {
            return moment(new Date(date * 1000)).format('MM/DD/YYYY')
        },
        headerClassName(col, index) {
            let thClass = col.thClass ? col.thClass : ''
            let sortable = ''
            let sorting = ''

            if(col.sortable) {
                sortable = 'custom-sortable'
                if(this.sortColumnIndex === index) {
                    sorting = this.isAsc ? 'sorting-asc' : 'sorting-desc'
                }
            } 
    
            return `font-weight-bold ${thClass} ${sortable} ${sorting}`
        },
        toggleSort(col, index) {
            if(this.sortColumnIndex === index) {
                this.isAsc = !this.isAsc
            }
            else {
                this.sortColumnIndex = index
                this.isAsc = true
            }

            this.onSorting(col.field, this.isAsc)
        },
        handleScroll(isTop) {
            if(this.scrolling || !this.isShowTopScrollBar) {
                this.scrolling = false;
                return;
            }

            this.scrolling = true;

            if(isTop)
                this.$refs["tableWrapper"].scrollLeft = this.$refs["scrollWrapper"].scrollLeft;
            else 
                this.$refs["scrollWrapper"].scrollLeft = this.$refs["tableWrapper"].scrollLeft;
        }
    }
}
</script>

<style lang="scss" scoped>
.my-archive-table-container {
    width: 100%;

    .scroll-bar-wrapper{
        width: 100%;
        height: 20px;
        overflow-x: scroll;
        margin: 10px 0px;
    }

    .my-archive-loader-wrapper {
        position: absolute;
        background-color: rgba(243, 244, 246, 0.3);
        width: 100%;
        height: 100%;
        z-index: 1;

        .loader-circle {
            position: absolute;
            font-size: 50px;
        }   
    }
    .my-archive-table-wrapper {
        position: relative;
        overflow-x: scroll;
        overflow-y: hidden;
        margin-top: 15px;
        margin-bottom: 10px;

        .my-archive-table {
            table-layout: fixed;

            tbody {
                background-color: white;
            }

            th, td {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            th.custom-sortable {
                cursor: pointer;
            }

            th.custom-sortable:before, th.custom-sortable:after {
                content: "";
                position: absolute;
                height: 0;
                width: 0;
                right: 10px;
                top: 50%;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
            }

            th.custom-sortable:before {
                margin-bottom: -7px;
                border-top: 5px solid #cfd3e0;
            }

            th.custom-sortable:after {
                margin-top: -7px;
                border-bottom: 5px solid #cfd3e0;
            }

            th.sorting-asc:after {
                border-bottom: 5px solid #409eff;
            }

            th.sorting-desc:before {
                border-top: 5px solid #409eff;
            }
        }
    }
}
</style>
  