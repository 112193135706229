var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-archive-table-container" }, [
    _vm.isShowTopScrollBar
      ? _c(
          "div",
          {
            ref: "scrollWrapper",
            staticClass: "scroll-bar-wrapper",
            on: {
              "&scroll": function($event) {
                _vm.handleScroll(true)
              }
            }
          },
          [_c("div", { ref: "scrollbar" })]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "tableWrapper",
        staticClass: "my-archive-table-wrapper",
        on: {
          "&scroll": function($event) {
            _vm.handleScroll(false)
          }
        }
      },
      [
        _vm.isLoading
          ? _c(
              "div",
              { staticClass: "my-archive-loader-wrapper text-center" },
              [
                _c("div", {
                  staticClass: "loader loader-circle",
                  style: _vm.loaderTop,
                  attrs: { "data-type": "default" }
                })
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "Table",
          {
            staticClass: "my-archive-table",
            attrs: { bordered: true, striped: true }
          },
          [
            _c("thead", { staticClass: "thead-light" }, [
              _c(
                "tr",
                _vm._l(_vm.columns, function(col, index) {
                  return _c(
                    "th",
                    {
                      class: _vm.headerClassName(col, index),
                      style: { width: col.width },
                      on: {
                        click: function($event) {
                          col.sortable && _vm.toggleSort(col, index)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(col.label) +
                          "\n                    "
                      )
                    ]
                  )
                })
              )
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              [
                _vm.reportData.length === 0
                  ? _c("tr", [_c("div", { style: { height: "100px" } })])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.reportData, function(row) {
                  return _c(
                    "tr",
                    _vm._l(_vm.columns, function(col) {
                      return _c(
                        "td",
                        { class: col.tdClass ? col.tdClass : "" },
                        [
                          col.field === "created"
                            ? _c("span", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.formatDate(row.created)) +
                                    "\n                        "
                                )
                              ])
                            : col.field === "nid"
                              ? _c("span", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "../listing_flyer/" +
                                          row.nid +
                                          "/archive"
                                      }
                                    },
                                    [_vm._v("Download")]
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(row[col.field]) +
                                      "\n                        "
                                  )
                                ])
                        ]
                      )
                    })
                  )
                })
              ],
              2
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-23b4c3dc", { render: render, staticRenderFns: staticRenderFns })
  }
}