var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "grid-row",
        [
          _c(
            "grid-column",
            { attrs: { size: "6" } },
            [
              _c("InputText", {
                attrs: {
                  type: "number",
                  label: "List Price",
                  name: "listing_price",
                  labelClassName: "required"
                },
                on: { input: _vm.onInput },
                model: {
                  value: _vm.value.listing_price,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "listing_price", _vm._n($$v))
                  },
                  expression: "value.listing_price"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "grid-column",
            { attrs: { size: "6" } },
            [
              _c("InputText", {
                attrs: { type: "number", label: "HOA Fee", name: "hoa" },
                on: { input: _vm.onInput },
                model: {
                  value: _vm.value.hoa,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "hoa", _vm._n($$v))
                  },
                  expression: "value.hoa"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "grid-row",
        [
          _c(
            "grid-column",
            { attrs: { size: "6" } },
            [
              _c("InputText", {
                attrs: {
                  min: "0",
                  type: "number",
                  label: "SqFt",
                  name: "sqft",
                  labelClassName: "required"
                },
                on: { input: _vm.onInput },
                model: {
                  value: _vm.value.sqft,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "sqft", _vm._n($$v))
                  },
                  expression: "value.sqft"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "grid-column",
            { attrs: { size: "6" } },
            [
              _c("InputText", {
                attrs: {
                  min: "0",
                  label: "Beds",
                  name: "num_beds",
                  labelClassName: "required",
                  type: "number"
                },
                on: { input: _vm.onInput },
                model: {
                  value: _vm.value.num_beds,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "num_beds", _vm._n($$v))
                  },
                  expression: "value.num_beds"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "grid-row",
        [
          _c(
            "grid-column",
            { attrs: { size: "6" } },
            [
              _c("InputText", {
                attrs: {
                  min: "0",
                  label: "Baths",
                  name: "num_baths",
                  labelClassName: "required",
                  type: "number"
                },
                on: { input: _vm.onInput },
                model: {
                  value: _vm.value.num_baths,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "num_baths", _vm._n($$v))
                  },
                  expression: "value.num_baths"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "grid-column",
            { attrs: { size: "6" } },
            [
              _c("InputText", {
                attrs: {
                  min: "0",
                  label: "Half Baths",
                  name: "num_half_baths",
                  type: "number"
                },
                on: { input: _vm.onInput },
                model: {
                  value: _vm.value.num_half_baths,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "num_half_baths", _vm._n($$v))
                  },
                  expression: "value.num_half_baths"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-76744e6d", { render: render, staticRenderFns: staticRenderFns })
  }
}