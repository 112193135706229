'use strict';

var Mustache = require("mustache");
var bbAutoComplete = require('./bb-autocomplete');

let paramArray,
	$bb_min_price,
	$bb_max_price,
	$tool_mobile,
	$tool_desktop;

// Init deep linking
var init = function(Views) {
  let base_path = $('.deep_link_base_path').data('base_path');

  $.address.autoUpdate(false)
    .state(base_path)
    .init(function(event) {
      let pathName = '',
        pathParts = window.location.pathname.split("/");
        pathName = pathParts[2];

      // set the actual view type button to active
      $('[data-btn_type=' + pathName + ']').addClass('active');

      $.address.value('/');

      if (window.location.search.length > 0) {
        $.address.queryString(window.location.search.replace("?", ""));
      }

      $.address.path(pathName);
    }).change(function(event) {
      const pathName = event.pathNames[0];
      const parameters = event.parameters;

      if (pathName !== 'map') {
        $('.' + pathName + '-view').empty();
      }

      Views.render(pathName, parameters);
    }).externalChange(function(event) {
      updateFilterTags();
    }).internalChange(function(event) {});

  $bb_min_price = $('#bb_min_price');
  $bb_max_price = $('#bb_max_price');
  $tool_mobile = $('#tool_mobile');
  $tool_desktop = $('#tool_desktop');

  bind();
  adaptLayout($(window).width() < 991);

  if (base_path == '/bb-search') {
    bbAutoComplete.init();
  }
};


// bind events to elements that uses deep link
var bind = function(){
	$('body')
		// Common filters
		.on('click bb_filter_btn_click', '.bb_filter_btn', function (e){
			e.preventDefault();
			filterBtnAction($(this));
    	})
		.on('bb_filter_btn_click', '.bb_filter_custom_btn', function (e){
			e.preventDefault();
			filterBtnAction($(this));
    	})
		// Toggle filters
		.on('change', '.bb_filter_btn_toggle', function (e){
			e.preventDefault();
			filterBtnToggle($(this));
    	})
    	// Price filters
		.on('bb_filter_price_click', function (e, ele, key, val){
			e.preventDefault();
			// If choosed any
			if(key === 'any'){
				toggleFilters('min_price', val, 'remove');
				toggleFilters('max_price', val, 'remove');
				return;
			}

			const $ele = $(ele),
				  tpl = ($ele.hasClass('bb_min_price_opt'))?$bb_min_price.data('bb_filter_label'):$bb_max_price.data('bb_filter_label');

			let label = formatPriceValue(val);
			label = setLabel(tpl, label);
			toggleFilters(key, val, 'add', label);
			togglePrices(val);
    	})
		// Price filter
		.on('bb_price_keyup', function(e, original_e, key){
			// allow arrows and tab
			if([9,37,38,39,40].indexOf(original_e.which) + 1){
				return;
			}
			const $ele = (key === 'min_price')?$bb_min_price:$bb_max_price;
			let val = $ele.val();
			$ele.val(formatPriceValue(val));
	    })
		// Price filter
		.on('bb_price_keydown', function(e, original_e, key){
			const charCode = original_e.which;

			if([8,46,37,38,39,40].indexOf(original_e.which) + 1){
				return;
			}

			// Cancel non-numeric characters
			if (charCode > 31 && (charCode < 48 || charCode > 57)) {
				original_e.preventDefault();
				return;
		    }
		    // limit the the number length to 8
		    const $ele = (key === 'min_price')?$bb_min_price:$bb_max_price;
		    if(unFormatPriceValue($ele.val()).length >= 8){
		    	original_e.preventDefault();
		    }
	    })
		.on('bb_price_change', function(e, ele, key){
			const $ele = (key === 'min_price')?$bb_min_price:$bb_max_price,
				val = unFormatPriceValue($ele.val());

			toggleFilters(key, val, 'add');
	    })
		.on('bb_price_focus', function(e, key){
			if(key === 'min_price'){
				$('.bb_min_price_opt').removeClass('hidden');
				$('.bb_max_price_opt').addClass('hidden');
			}else{
				togglePrices(unFormatPriceValue($bb_min_price.val()));
			}
	    });

	$('.bb_filter_select').change(function(){
		const $this = $(this),
			key = $this.data('bb_filter_key'),
			val = $this.val(),
			action = (val === 'any')?'remove':'add',
			tpl = $this.data('bb_filter_label');

		let label = $this.find('[value="' + $this.val() + '"]').text();
		label = setLabel(tpl, label);

 		if($this.data('bb_has_max')==''){
 			setMaxDropdown(key, val);
 		}

 		if($this.hasClass('bb_has_clone')){
 			$('.filter-tools-container [data-bb_filter_key="' + key + '"]').not($this).val(val);
 		}
		toggleFilters(key, val, action, label);
	});

    $('.bb_listing_view_btn').on('click', function(){
        var qStr = $.address.queryString(),
        	btn_type = $(this).data('btn_type');
        // Remove the class active from the rest of the buttons
        $('.bb_listing_view_btn').removeClass('active');
        // And add it to the current one
        $('[data-btn_type=' + btn_type + ']').addClass('active');
        paramArray = getParamArray($.address);
        $.address.value('/');
        // for(var i=0; i<paramArray.length; i++){
        //     $.address.parameter(paramArray[i].name, paramArray[i].value,true);
        // }
        $.address.path(btn_type);
        $.address.update();
        return false;
    });

    // adapt filter on window size changes
    $(window).resize(function(){
    	adaptLayout($(this).width() < 991);
    });
};

// Add filter tag
var addFilterTag = async function(key, tag, label){
	const $ul = $('ul.filter-tags-simple');

    // Make pretty the price string and set that to the input
    if(['min_price', 'max_price'].indexOf(key) + 1){
    	let tmpTag = formatPriceValue(tag)
    	const $ele = (key === 'min_price')?$bb_min_price:$bb_max_price;
    	label = setLabel($ele.data('bb_filter_label'), tmpTag);
    	$ele.val(tmpTag);
    }



    if(key=="nid"){
        let result=await BB.listing.get({_id:tag})
		label = ( result[0] ? result[0].fullstreetaddress : null)
	}

    // for tags without label
    label = (label)?label:tag;

    let li = ['<li><p>' + label + '</p><a', ' data-bb_filter_key="' + key + '" data-bb_filter_value="' + tag + '" data-bb_filter_action="remove" class="bb-tag-filter-' + key + ' bb_filter_btn close-btn"></a></li>'];

    // desktop
    if($($ul[0]).find('li').length < 3){
        $ul.append(li.join(' '));
    }else{
    	// Append to mobile
    	$('#appliedFilters ul.filter-tags-simple').append(li.join(' '));
    	// Append to more filters dropdown
        let $moreFilters = $('.more-filters-dropdown');
        // Workaround for bootstrap dropdowns events
        li = li[0] + ' onclick="$(this).trigger(\'bb_filter_btn_click\')" ' + li[1];
        $moreFilters.removeClass('hidden').find('.filter-tags').append(li);
        $('.more-filters-number').text($moreFilters.find('.filter-tags li').length);
    }

	// mobile
	$('.mobile-more-filters-number').text($('#appliedFilters ul.filter-tags-simple li').length);
};

// Update filter tags when one is removed
var updateFilterTags = function(key, tag){
  $('.filter-tags').empty();
  $('.more-filters-dropdown').addClass('hidden');
	$('.mobile-more-filters-number').text(0);

  paramToTag();
};

// Generates tags from the URL params
var paramToTag = function() {
  let queryString = location.search.slice(1),
    re = /([^&=]+)=([^&]*)/g,
    m;

  while (m = re.exec(queryString)) {
    let key = decodeURIComponent(m[1]),
      val = decodeURIComponent(m[2]),
      $ele = $('.filter-tools-container [data-bb_filter_key="' + key + '"]'),
      label, tpl;

    if (['checkbox', 'radio'].indexOf($ele.attr('type')) + 1 || $ele.hasClass('bb_filter_btn_tggl')) {
      let $filterEle = $ele.filter('[value="' + val + '"]').prop('checked', true);
      const $parentGroup = $filterEle.closest('.bb_btn_filter_group')

      tpl = ($parentGroup.length) ? $parentGroup.data('bb_filter_label') : $filterEle.data('bb_filter_label');
      label = $filterEle.parent().text();

      if ($ele.hasClass('bb_filter_btn_tggl')) {
        $filterEle = $ele.filter('a[data-bb_filter_value="' + val + '"]');
        $filterEle.closest('.bb_btn_filter_group').find('.active').removeClass('active');
        $filterEle.addClass('active');
      }
    } else if (['min_sqft', 'min_lot_size', 'max_sqft', 'max_lot_size', 'min_stories', 'max_stories', 'days_old_max'].indexOf(key) + 1) {
      tpl = $ele.val(val).data('bb_filter_label');
      label = $ele.find('[value="' + $ele.val() + '"]').first().text();
    }

    if (label && key !== 'sort_by') {
      label = setLabel(tpl, label);
    }

    if (key !== 'lat' && key !== 'lng' && key !== 'zoom') {
      addFilterTag(key, val, label);
    }
  }
};

var toggleFilters = function(key, val, action, label) {
  let needsUpdate = 0,
    isAdded = 0;

  if (action === 'add' && $.address.parameter(key) !== val + '') {
    $.address.parameter(key, val);
    // remmove if already exist
    if (!$('.bb-tag-filter-' + key).length) {
      addFilterTag(key, val, label);
      isAdded = 1;
    }
    needsUpdate = 1;
  } else if (action === 'remove') {
    if (['min_price', 'max_price'].indexOf(key) + 1) {
      const $ele = (key === 'min_price') ? $bb_min_price : $bb_max_price;
      $ele.val('');
    } else if (['min_sqft', 'min_lot_size', 'min_stories', 'days_old_max'].indexOf(key) + 1) {
      const $ele = $('[data-bb_filter_key="' + key + '"]');
      $ele.val('any');
      if ($ele.data('bb_has_max') == '') {
        setMaxDropdown(key, 'any');
      }
    } else if (['max_sqft', 'max_lot_size', 'max_stories'].indexOf(key) + 1) {
      $('[data-bb_filter_key="' + key + '"]').val('any');
    } else if (key == 'days_closed') {
      $('.filter-tools-container [data-bb_filter_key="' + key + '"][value="' + val + '"]').prop('checked', false);
    }

    $.address.parameter(key, null);
    needsUpdate = 1;
  }

  if (needsUpdate) {
    $.address.update();
    if (!isAdded) {
      updateFilterTags();
    }
  }
  $('#bb-listing-empty').remove();
};


// Click event handler for common filters
var filterBtnAction = function($ele){
    const key = $ele.data('bb_filter_key'),
        val = $ele.attr('data-bb_filter_value'),
        action = ($ele.data('bb_filter_action'))?$ele.data('bb_filter_action'):'add';
    let label;

    if((['checkbox', 'radio'].indexOf($ele.attr('type')) + 1  || $ele.hasClass('bb_filter_btn_tggl')) && key !== 'sort_by'){
    	const tpl = $ele.closest('.bb_btn_filter_group').data('bb_filter_label');
		label = setLabel(tpl, $ele.parent().text());
    	if($ele.hasClass('bb_filter_btn_tggl')){
    		const $aEle = $('a[data-bb_filter_key="' + key + '"][data-bb_filter_value="' + val + '"]');
			$aEle.closest('.bb_btn_filter_group').find('.active').removeClass('active');
	    	$aEle.addClass('active');
		}else if($ele.attr('type') == 'checkbox'){
			$('.filter-tools-container [data-bb_filter_key="' + key + '"][value="' + val + '"]').prop('checked', true);
		}

    }else if($ele.hasClass('close-btn') && ['owner', 'min_num_beds', 'min_num_baths'].indexOf(key) + 1){
    	// Set the default value
    	$('input[data-bb_filter_key="' + key + '"][value="any"]').prop('checked', true);
    	$('a.bb_filter_btn_tggl[data-bb_filter_key="' + key + '"][data-bb_filter_value="any"]')
    		.closest('.bb_btn_filter_group').find('.active').removeClass('active').end().end().addClass('active');
    }
    toggleFilters(key, val, action, label);
};

var filterBtnToggle = function($ele){
	const key = $ele.data('bb_filter_key'),
        value = $ele.data('bb_filter_value'),
        action = ($('.bb-tag-filter-' + key).length)?'remove':'add',
        label = $ele.data('bb_filter_label');
	toggleFilters(key, value, action, label);
};

// Format price from 50000 -> $ 50,000
var formatPriceValue = function(value){
	if(['$', '$ '].indexOf(value) + 1){
		return '$ ';
	}
	return '$ ' + parseFloat((value + '').replace(/(?:\$ )|,/g, ''))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// Unformat price from $ 50,000 -> 50000
var unFormatPriceValue = function(value){
	return (value + '').replace(/(?:\$ )|,/g, '');
};

var togglePrices = function(min){
	const tpl = '<li class="bb_max_price_opt text-right"><a onclick="$(this).trigger(\'bb_filter_price_click\', [ this, \'max_price\', {{max}}000 ])">${{max}}k</a></li>',
		$ul = $('#bb_price_filter');
	min = (min==='')?400000:min;
	let maxVal = Math.ceil(Math.floor(min / 1e3) / 5) * 5;
	Mustache.parse(tpl);

	// Hide min prices
	$ul.find('.bb_min_price_opt').addClass('hidden');

	// Remove old options
	$ul.find('.bb_max_price_opt').remove();

	// and generate new ones
	for(let i = 0; i<9; i++){
		maxVal += 50;
		var rendered = Mustache.render(tpl, {max: maxVal});
  		$ul.append(rendered);
	}
	$ul.append('<li class="bb_max_price_opt text-right"><a onclick="$(this).trigger(\'bb_filter_price_click\', [ this, \'any\', \'any\' ])">Any</a></li>');
};

var setMaxDropdown = function(key, val) {
	const $dd = $('[data-bb_filter_key="' + key + '"] + span + select');

	if(val === 'any'){
		$dd.find('[disabled]').prop('disabled', false);
		return;
	}

	const $opts = $dd.find('option'),
		  min = $dd.find('[value="' + val + '"]').index();
	val = Number(val);
	let i = 1;
	while(min > i){
		$dd.find('option:eq(' + i + ')').prop('disabled', true);
		i++;
	}
}

var setLabel = function (tpl, label){
	return Mustache.render(tpl, {value: label});
}

var adaptLayout = function(is_mobile){
	if(is_mobile){
		$('body').addClass('is-mobile');

		// move sort
		if(!$('li.sort nav', $tool_mobile).length){
			$('li.sort nav .btn', $tool_desktop).addClass('btn-small-white').removeClass('sort_btn');
			$('li.sort nav', $tool_desktop).appendTo('.tool_mobile li.sort');
			$('li.sort', $tool_desktop).empty();
		}
		// move realtor
		if(!$('#bb_realtor_ph ul').length){
			$('#realtor-filter ul').removeClass('dropdown-menu').addClass('details-filters').appendTo('#bb_realtor_ph');
		}
		// move price
		if(!$('#bb_min_price_ph input').length){
			$bb_min_price.appendTo('#bb_min_price_ph');
			$bb_max_price.appendTo('#bb_max_price_ph');
			$('.tool_desktop li.filter-range').empty();
		}
	}else{
		$('body').removeClass('is-mobile');
		// move sort
		if(!$('.tool_desktop li.sort nav').length){
			$('li.sort nav .btn', $tool_mobile).removeClass('btn-small-white').addClass('sort_btn');
			$('li.sort nav', $tool_mobile).appendTo('.tool_desktop li.sort');
			$('li.sort', $tool_mobile).empty();
		}
		// move realtor
		if(!$('#realtor-filter ul').length){
			$('#bb_realtor_ph ul').addClass('dropdown-menu').removeClass('details-filters').appendTo('#realtor-filter');
		}
		// move price
		if(!$('.tool_desktop li.filter-range input').length){
			$bb_min_price.appendTo('.tool_desktop li.filter-range');
			$bb_max_price.appendTo('.tool_desktop li.filter-range');
			$('#bb_min_price_ph, #bb_max_price_ph').empty();
		}

	}
};

var getParamArray = function(address){
    var paramNames = address.parameterNames(),
    	currentParams = [];
    for(var i=0; i<paramNames.length; i++){
        currentParams[i]={
            name:paramNames[i],
            value:address.parameter(paramNames[i])
        }
    }
    return currentParams;
};

module.exports = {
	init: init
};
