<doc>
    Connections Vue.js component
</doc>
<template>
    <div>
        <header class="network-header row">
            <div class="network-header__description">
                <p class="align-left"><span style="font-weight: bolder;">Find a realtor</span></p>
                <p class="align-left">Search by realtor MLS# (recommended) or Last Name. </p>
                <p class="align-left">More filters can be applied to the results.</p>
                <!--<p><span style="font-weight: bolder;">Note:</span> We have listings for most areas in the US. Here is a list of the MLSs we support: <a target="_blank" href="https://www.listhub.com/broker-mls-list.html">MLS List</a></p>-->
            </div>

            <form class="network-header__search network-search" method="post" action="/realtor-network-search.json">
				<input type="hidden" id="selected-state" value="" />
                
				<div class="network_dd_state_wrapper">
					<div class="network_state_label">State Filter</div>
					<div class="network_state_dd">
						<StatesDropDown
							name="state"
							v-model="state"
							:onInput="onInputSates"
						/>
					</div>
				</div>
				
				<div class="network_search_realtor_wrapper">
					
					<div class="network-search__input">
						<input type="search" class="network-typeahead" placeholder="Search by Realtor MLS ID#" id="tt-search-input">
					</div>
				</div>
				
                <button id="tt-search-btn" type="button" class="network-search__button btn btn-modern">
                <span class="glyphicon glyphicon-search"></span>
                </button>                
                <div class="network-search__loader loader loader-circle hide tt-loading"></div>
                <div class="break"></div>
                <label class="network-chk-wrapper" style="padding-left: 1em;">
                    <input type="checkbox" value="0" name="search_mls" class="search-mls" /> Search by Realtor Last Name
                </label>
            </form>
        </header>
        <div class="realtor-profile my-realtor-network row" data-loid="29">
            <div class="content_body col-xs-12">
                <div class="network-section invites">
                    <h3 class="network-section__title">Invites</h3>
                    <ul class="nav nav-pills">
						<li class="active"><a class="btn btn-modern" href="#externalTabContent" data-toggle="tab"><span class="glyphicon glyphicon-share" style="margin-right:5px;"></span>External</a></li>
                        <li><a class="btn btn-modern" href="#internalTabContent" data-toggle="tab"><span class="glyphicon glyphicon-inbox" style="margin-right:5px;"></span>Internal</a></li>
                    </ul>
                    <div class="tabContent" style="padding-left:10px;margin-top:10px;">
                        <!-- Internal Tab Content -->
                        <div id="internalTabContent" class="network-section__list network-table internalInvites tab-pane">
                            <LoaderPlaceholder v-if="isLoading" type="circle" />
                            <!--   Block Loop ----->
                            <template v-else v-if="invites.length">
                                <div id="network-user" class="network-table__row">
                                    <div class="network-table__column network-table__picture network-table__pair-modal">
                                        <div class="table-header-img"></div>
                                    </div>
                                    <div class="network-table__column network-table__user-info network-table__pair-modal">
                                        <span class="network-table__user-info-title">Name <a class="filter-by-name"><span aria-hidden="true" class="glyphicon glyphicon-option-vertical"></span></a>
                                            <div class="filter-wrapper d-none">
                                                <div class="filter-header">
                                                <input type="text" class="filter-text form-control" placeholder="Type to filter" />
                                                </div>
                                                <div class="filter-body">
                                                    <template v-for="invite_detail of invites">
                                                        <label><input type="checkbox" :data-name="invite_detail.user.name" :data-realtor-id="invite_detail.iid" :value="invite_detail.user.name" />{{ invite_detail.user.name }}</label>
                                                    </template>
                                                </div>
                                                <div class="filter-footer">
                                                <button class="btn btn-default btn-name-filter">Filter</button>
                                                <button class="btn btn-secondary btn-clear">Clear</button>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                    <div class="network-table__column hidden-xs">
                                        <span class="network-table__user-info-title">State <a class="filter-by-state"><span aria-hidden="true" class="glyphicon glyphicon-option-vertical"></span></a>
                                            <div class="filter-wrapper d-none">
                                                <div class="filter-header">
                                                <input type="text" class="filter-text form-control" placeholder="Type to filter" />
                                                </div>
                                                <div class="filter-body">
                                                    
                                                </div>
                                                <div class="filter-footer">
                                                <button class="btn btn-default btn-state-filter">Filter</button>
                                                <button class="btn btn-secondary btn-clear clear-state">Clear</button>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                    <div class="network-table__column hidden-xs">
                                        <span class="network-table__user-info-title">Agency <a class="filter-by-agency-name"><span aria-hidden="true" class="glyphicon glyphicon-option-vertical"></span></a>
                                            <div class="filter-wrapper d-none">
                                                <div class="filter-header">
                                                <input type="text" class="filter-text form-control" placeholder="Type to filter" />
                                                </div>
                                                <div class="filter-body">
                                                    
                                                </div>
                                                <div class="filter-footer">
                                                <button class="btn btn-default btn-agency-filter">Filter</button>
                                                <button class="btn btn-secondary btn-clear clear-agency">Clear</button>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                    <div class="network-table__column hidden-xs">
                                        <span class="network-table__user-info-title">MLS <a class="filter-by-agency-mls"><span aria-hidden="true" class="glyphicon glyphicon-option-vertical"></span></a>
                                            <div class="filter-wrapper d-none">
                                                <div class="filter-header">
                                                <input type="text" class="filter-text form-control" placeholder="Type to filter" />
                                                </div>
                                                <div class="filter-body">
                                                    
                                                </div>
                                                <div class="filter-footer">
                                                <button class="btn btn-default btn-mls-filter">Filter</button>
                                                <button class="btn btn-secondary btn-clear clear-mls">Clear</button>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                    <div class="network-table__column network-table__actions">
                                    
                                    </div>
                                </div>
                            </template>
                            <template v-for="invite_detail of invites">
                                <template v-if="invite_detail.user">
                                    
                                    <div :id="`network-invite-${invite_detail.iid}`" :class="['network-table__row', 'network-row',(invite_detail.rid == invite_detail.sender)?'network-table__row--incoming':'network-table__row--outcoming']" :data-invite-id="invite_detail.iid" :data-realtor-id="invite_detail.iid" :data-agency-name="invite_detail.user.agency" :data-state="invite_detail.user.state">
                                        <div class="network-table__column network-table__picture">
                                            <img :src="`${invite_detail.user.picture}`" :alt="invite_detail.user.name">
                                        </div>
                                        <div class="network-table__column network-table__user-info">
                                            <span class="network-table__user-info-title">{{invite_detail.user.name}}</span>
                                            <span class="network-table__user-info-subtitle visible-xs-block">{{invite_detail.user.agency}}</span>
                                        </div>
                                        <div class="network-table__column hidden-xs network-table__state">{{invite_detail.user.state}}</div>
                                        <div class="network-table__column hidden-xs network-table__agency">{{invite_detail.user.agency}}</div>
                                        <div class="network-table__column hidden-xs network-table__mls">
                                            <span class="tooltip-hover" v-for="mls_board of invite_detail.user.mls_board">
                                                <span class="mls-key">{{ mls_board.key }}</span> 
                                            <label class="btn-tooltip-label" style="display: none;">{{mls_board.value}}</label>
                                            </span>
                                        </div>
                                        <div class="network-table__column network-table__actions">
                                            <button style="cursor:pointer;" v-if="(invite_detail.rid == invite_detail.sender)" :data-iid="invite_detail.iid" class="btn btn-modern network-table__action" @click="accept_invite(invite_detail.iid)" data-action="approve-invite">
                                                <span class="glyphicon glyphicon-plus" aria-hidden="true"></span> Accept Invite
                                            </button>
                                            <span v-else class="network-table__action network-table__action--invitation-tag">Invitation sent</span>
                                            <button style="cursor:pointer;" :data-iid="invite_detail.iid" href="#" data-action="delete-invite" class="network-table__action network-table__action--icon" @click="delete_invite_internal(invite_detail.iid)" >
                                            <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                                            </button>
                                        </div>
                                    </div>
                                </template>
                            </template>
                            <!--  Block Loop ------>
                        </div>
                        <!-- Internal Tab Content -->
                        <!-- External Tab Content -->
                        <div v-if="external_invites" id="externalTabContent" class="network-section__list network-table tab-pane active">
							<LoaderPlaceholder v-if="isLoading" type="circle" />
                            <div v-if="external_invites.length" id="network-user"  class="network-table__row">
                        <div class="network-table__column network-table__picture network-table__pair-modal">
                            <div class="table-header-img"></div>
                        </div>
                        <div class="network-table__column network-table__user-info network-table__pair-modal">
                            <span class="network-table__user-info-title">Name <a class="filter-by-name"><span aria-hidden="true" class="glyphicon glyphicon-option-vertical"></span></a>
                                <div class="filter-wrapper d-none">
                                    <div class="filter-header">
                                    <input type="text" class="filter-text form-control" placeholder="Type to filter" />
                                    </div>
                                    <div class="filter-body">
                                        <template v-for="invite_info of external_invites">
                                            <label v-if="invite_info.realtor"><input type="checkbox" :data-name="invite_info.realtor.name" :data-realtor-id="invite_info.external_iid" :value="invite_info.realtor.name" />{{ invite_info.realtor.name }}</label>
                                        </template>
                                    </div>
                                    <div class="filter-footer">
                                    <button class="btn btn-default btn-name-filter">Filter</button>
                                    <button class="btn btn-secondary btn-clear clear-name">Clear</button>
                                    </div>
                                </div>
                            </span>
                        </div>
						<div class="network-table__column hidden-xs">
                            <span class="network-table__user-info-title">State <a class="filter-by-state"><span aria-hidden="true" class="glyphicon glyphicon-option-vertical"></span></a>
                                <div class="filter-wrapper d-none">
                                    <div class="filter-header">
                                    <input type="text" class="filter-text form-control" placeholder="Type to filter" />
                                    </div>
                                    <div class="filter-body">
                                        
                                    </div>
                                    <div class="filter-footer">
                                    <button class="btn btn-default btn-state-filter">Filter</button>
                                    <button class="btn btn-secondary btn-clear clear-state">Clear</button>
                                    </div>
                                </div>
                            </span>
                        </div>
                        <div class="network-table__column hidden-xs">
                            <span class="network-table__user-info-title">Agency <a class="filter-by-agency-name"><span aria-hidden="true" class="glyphicon glyphicon-option-vertical"></span></a>
                                <div class="filter-wrapper d-none">
                                    <div class="filter-header">
                                    <input type="text" class="filter-text form-control" placeholder="Type to filter" />
                                    </div>
                                    <div class="filter-body">
                                        <template v-for="invite_info of external_invites">
                                            <label v-if="invite_info.realtor"><input type="checkbox" :data-agency-name="invite_info.realtor.agency_name" :value="invite_info.realtor.agency_name" />{{ invite_info.realtor.agency_name }}</label>
                                        </template>
                                    </div>
                                    <div class="filter-footer">
                                    <button class="btn btn-default btn-agency-filter">Filter</button>
                                    <button class="btn btn-secondary btn-clear clear-agency">Clear</button>
                                    </div>
                                </div>
                            </span>
                        </div>
						<div class="network-table__column hidden-xs">
                            <span class="network-table__user-info-title">MLS <a class="filter-by-agency-mls"><span aria-hidden="true" class="glyphicon glyphicon-option-vertical"></span></a>
                                <div class="filter-wrapper d-none">
                                    <div class="filter-header">
                                    <input type="text" class="filter-text form-control" placeholder="Type to filter" />
                                    </div>
                                    <div class="filter-body">
                                        
                                    </div>
                                    <div class="filter-footer">
                                    <button class="btn btn-default btn-mls-filter">Filter</button>
                                    <button class="btn btn-secondary btn-clear clear-mls">Clear</button>
                                    </div>
                                </div>
                            </span>
                        </div>
                        <div class="network-table__column network-table__actions">
                        
                        </div>
                    </div>
                            <!-- Loop -->
                            <template v-for="invite_info of external_invites">
                                <template v-if="invite_info.realtor">
                                    <div :id="`network-invite-${invite_info.external_iid}`" class="network-table__row network-table__row--external network-table__row--outcoming" :data-realtor-id="invite_info.external_iid" :data-agency-name="invite_info.realtor.agency_name" :data-state="invite_info.realtor.state">
                                        <div class="network-table__column network-table__picture">
                                            <img src="/sites/all/modules/listing_flyer/images/placeholder.png" :alt="invite_info.realtor.name" />
                                        </div>
                                        <div class="network-table__column network-table__user-info">
                                        <span class="network-table__user-info-title">{{invite_info.realtor.name}}</span>
                                        <span class="network-table__user-info-subtitle visible-xs-block">{{invite_info.realtor.agency_name}}</span>
                                        </div>
										<div class="network-table__column hidden-xs network-table__state">{{invite_info.realtor.state}}</div>
                                        <div class="network-table__column hidden-xs network-table__agency">{{invite_info.realtor.agency_name}}</div>
										<div class="network-table__column hidden-xs network-table__mls">
                                            <span class="tooltip-hover" v-for="mls_board of invite_info.realtor.mls_board">
                                                <span class="mls-key">{{ mls_board.key }}</span> 
                                            <label class="btn-tooltip-label" style="display: none;">{{mls_board.value}}</label>
                                            </span>
                                        </div>
                                        <div class="network-table__column network-table__actions">
                                            <span style="cursor:pointer;" class="network-table__action network-table__action--invitation-tag external-invite-info-button" @click="get_invitation_details(invite_info.external_iid)" :data-iid="invite_info.external_iid">Invitation {{get_email_status(invite_info.email_status)}}</span>
                                            <button style="cursor:pointer;" :data-iid="invite_info.external_iid" href="#" data-action="resend-invite" @click="resend_invitation(invite_info.external_iid)" class="network-table__action network-table__action--icon">
                                            <span class="glyphicon glyphicon-refresh" aria-hidden="true"></span>
                                            </button>
                                            <button style="cursor:pointer;" :data-iid="invite_info.external_iid" href="#" data-action="delete-invite" @click="delete_invitation(invite_info.external_iid)" class="network-table__action network-table__action--icon">
                                            <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                                            </button>
                                        </div>
                                    </div>
                                </template>
                            </template>
                            <!-- Loop -->
                        </div>
                        <!-- External Tab Content -->
                    </div>
                </div>
                <!------ Net section Invites -->
                <h3 class="network-section__title" v-if="!isLoading">
                    Network
                    <div class="pull-right"> 
                        <a :href="`/realtor-export.csv?loid=${user.uid}`">
                            <i style="color:#aaa;" class="fas fa-download"></i>
                        </a>
                    </div>
                </h3>
                <div class="network-section__list network-table">

                    <div v-if="pairs.length" id="network-user"  class="network-table__row">
                        <div class="network-table__column network-table__picture network-table__pair-modal">
                            <div class="table-header-img"></div>
                        </div>
                        <div class="network-table__column network-table__user-info network-table__pair-modal">
                            <span class="network-table__user-info-title">Name <a class="filter-by-name"><span aria-hidden="true" class="glyphicon glyphicon-option-vertical"></span></a>
                                <div class="filter-wrapper d-none">
                                    <div class="filter-header">
                                    <input type="text" class="filter-text form-control" placeholder="Type to filter" />
                                    </div>
                                    <div class="filter-body">
                                        <template v-for="pair_info of pairs">
                                            <label><input type="checkbox" :data-name="pair_info.user.name" :data-realtor-id="pair_info.pid" :value="pair_info.user.name" />{{ pair_info.user.name }}</label>
                                        </template>
                                    </div>
                                    <div class="filter-footer">
                                    <button class="btn btn-default btn-name-filter">Filter</button>
                                    <button class="btn btn-secondary btn-clear clear-name">Clear</button>
                                    </div>
                                </div>
                            </span>
                        </div>
                        <div class="network-table__column hidden-xs">
                            <span class="network-table__user-info-title">State <a class="filter-by-state"><span aria-hidden="true" class="glyphicon glyphicon-option-vertical"></span></a>
                                <div class="filter-wrapper d-none">
                                    <div class="filter-header">
                                    <input type="text" class="filter-text form-control" placeholder="Type to filter" />
                                    </div>
                                    <div class="filter-body">
                                        
                                    </div>
                                    <div class="filter-footer">
                                    <button class="btn btn-default btn-state-filter">Filter</button>
                                    <button class="btn btn-secondary btn-clear clear-state">Clear</button>
                                    </div>
                                </div>
                            </span>
                        </div>
                        <div class="network-table__column hidden-xs">
                            <span class="network-table__user-info-title">Agency <a class="filter-by-agency-name"><span aria-hidden="true" class="glyphicon glyphicon-option-vertical"></span></a>
                                <div class="filter-wrapper d-none">
                                    <div class="filter-header">
                                    <input type="text" class="filter-text form-control" placeholder="Type to filter" />
                                    </div>
                                    <div class="filter-body">
                                        <template v-for="pair_info of pairs">
                                            <label v-if="!!pair_info.user.agency"><input type="checkbox" :data-agency-name="pair_info.user.agency" :value="pair_info.user.agency" />{{ pair_info.user.agency }}</label>
                                        </template>
                                    </div>
                                    <div class="filter-footer">
                                    <button class="btn btn-default btn-agency-filter">Filter</button>
                                    <button class="btn btn-secondary btn-clear clear-agency">Clear</button>
                                    </div>
                                </div>
                            </span>
                        </div>
                        <div class="network-table__column hidden-xs">
                            <span class="network-table__user-info-title">MLS <a class="filter-by-agency-mls"><span aria-hidden="true" class="glyphicon glyphicon-option-vertical"></span></a>
                                <div class="filter-wrapper d-none">
                                    <div class="filter-header">
                                    <input type="text" class="filter-text form-control" placeholder="Type to filter" />
                                    </div>
                                    <div class="filter-body">
                                        
                                    </div>
                                    <div class="filter-footer">
                                    <button class="btn btn-default btn-mls-filter">Filter</button>
                                    <button class="btn btn-secondary btn-clear clear-mls">Clear</button>
                                    </div>
                                </div>
                            </span>
                        </div>
                        <div class="network-table__column network-table__actions">
                        
                        </div>
                    </div>
                    <div v-for="pair_info of pairs" :id="`network-user-${pair_info.pid}`" :data-realtor-id="pair_info.pid" :data-state="pair_info.user.state" :data-agency-name="pair_info.user.agency" class="network-table__row">
                        <div class="network-table__column network-table__picture network-table__pair-modal" :data-pid="pair_info.pid">
                        <img :src="pair_info.user.picture" :alt="pair_info.user.name">
                        </div>
                        <div class="network-table__column network-table__user-info network-table__pair-modal" :data-pid="pair_info.pid">
                        <span class="network-table__user-info-title">{{pair_info.user.name}}</span>
                        <span class="network-table__user-info-subtitle visible-xs-block">{{pair_info.user.agency}}</span>
                        </div>
                        <div class="network-table__column hidden-xs network-table__state">{{pair_info.user.state}}</div>
                        <div class="network-table__column hidden-xs network-table__agency">{{pair_info.user.agency}}</div>
                        <div class="network-table__column hidden-xs network-table__mls">
                            <span class="tooltip-hover" v-for="mls_board of pair_info.user.mls_board">
                                <span class="mls-key">{{ mls_board.key }}</span> 
                            <label class="btn-tooltip-label" style="display: none;">{{mls_board.value}}</label>
                            </span>
                        </div>
                        <div class="network-table__column network-table__actions">
                        <button :data-iid="pair_info.user.id" href="#" @click="editRealtor(pair_info.pid)" data-action="edit" class="network-table__action network-table__action--icon btn-has-tooltip" style="cursor: pointer;">
                            <span class="glyphicon glyphicon-edit" aria-hidden="true"></span>
                            <label class="tooltip-edit btn-tooltip-edit" :data-iid="pair_info.user.id" >Edit</label>
                        </button>
						<button style="cursor:pointer;" :data-pid="pair_info.pid" data-action="delete-pair" @click="delete_pair(pair_info.pid)" class="network-table__action network-table__action--icon">
                            <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                        </button>
                        </div>
                    </div>
                </div>
                <!-- Network section invites -->
            </div>
            <!--- Modal Popup - External Invite Detail --->
            <div class="modal fade jt-modal" id="invite-info-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                <div class="modal-dialog modal-sm" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Invite Details</h4>
                            <span class="pull-right closeBtn" data-dismiss="modal" aria-hidden="true">&times;</span>
                        </div>
                        <div class="modal-body">
                            <div class="modal_loading hide loader loader-circle tt-loading"></div>
                            <div class="modal_no-results hide">
                                There are no details to show.
                            </div>
                            <div class="content" style="overflow-x:scroll;">

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <!--- Modal Popup - External Invite Detail --->

            <!--- Modal Popup Accept Invite --->
            <div id="confirm_msa_modal" class="modal jt-modal fade" role="dialog">
                <div class="modal-dialog">

                    <!-- Modal content-->
                    <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Confirm Invite</h4>
                        <span class="pull-right closeBtn" data-dismiss="modal" aria-hidden="true">&times;</span>
                    </div>

                    <div class="modal-body">
                        <div class="loader vertical-align-middle loader-circle" data-type="default"></div>
                        <div class='myInviteConfirmContent'>
                            <p>is a marketing network that is intended to allow Loan Officers with <strong>existing</strong> relationships to create compelling co-branded marketing media.</p>
                            <form id="myInviteConfirm">
                                <div class="checkbox">
                                    <label>
                                    <input type="checkbox" value="">
                                    I personally know this realtor.
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <label>
                                    <input type="checkbox" value="">
                                    The realtor is expecting an invite from me to connect.
                                    </label>
                                </div>
                                <div style="margin-bottom:15px;" class="send-method">
                                    <label class="radio-inline">
                                        <input style="top:-4px" type="radio" name="send-method" data-type="email" value="email" checked>Email
                                    </label>
                                </div>
                                <div class="form-group send-input send-email">
                                <label class="control-label" for="inputEmail">Recipient Email</label>
                                <input type="text" class="form-control" id="inputEmail">
                                <span id="email">Enter the email of the realtor to whom you would like us to send the invite.</span>
                                </div>
                                <div class="form-group send-input send-sms hide">
                                    <label class="control-label" for="inputSMSNumber">Recipient SMS Number</label>
                                    <input type="text" class="form-control" id="inputSMSNumber">
                                    <span id="sms-desc">Enter the mobile phone number of the realtor to whom you would like us to send the invite SMS.</span>
                                </div>
                                <div class="form-group has-success">
                                <label class="control-label" for="confirmInitials">Initials</label>
                                <input type="text" class="form-control" id="confirmInitials" aria-describedby="initials">
                                <span id="initials">Confirm your answers with your initials</span>
                                </div>
                                <div class="progress">
                                    <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;">
                                        <span class="sr-only">0% Complete</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="msa_content">
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" data-dismiss="modal" class="btn btn-modern btn-flat msaDeny">Cancel</button>
                        <button type="button" class="btn btn-modern msaNext disabled">Next</button>
                        <button type="button" class="btn btn-modern msaConfirm hide">Accept</button>
                    </div>
                    </div>
                </div>
            </div>
            <!--- Modal Popup Accept Invite --->
			<BaseModal
                ref="modal"
                v-model:show-modal="showForm"
                id="sharedFlyerModal"
                title="Manage Realtor"
                @ok="submitForm"
                okLabel="Save"
                @cancel="clear"
                okClass="btn btn-modern submitBtn"
                >
                <form
                    id="manual-flyer-form"
                    class="form"
                    ref="sharedFlyerForm"
                    v-on:submit.prevent
                    >
                    <LoaderPlaceholder v-if="isDataLoading" type="circle" />
                    <RealtorForm v-else v-model="form" :onInputStates="onInputStates" ref="formRef" :readonly="true"/>
                </form>
            </BaseModal>
        </div>
    </div>
</template>
<script>
const bbNetwork = require('../../bb-network')
import _find from "lodash/find"
import BaseButton  from "../BaseButton"
import BaseModal from "../BaseModal"
import RealtorForm from './RealtorForm'
import { bbCountyManage } from "../../bb-api"
import { StatesDropDown } from "../Forms"
export default {
    name: "MyNetworkConnections",
	components: {
        BaseButton,
        BaseModal,
        RealtorForm,
		StatesDropDown
    },
    data() {
        return {
            isLoading:true,
            invites:[],
            external_invites:[],
            pairs:[],
            user:[],
			showForm: false,
            isDataLoading:false,
            form: {
                pictures: [],
                realtorData: {}
            },
            networkStates:[],
			county: null,
            state:null
        }
    },
    methods: {
		selectCounty({ selectedObject }) {
            this.county = null
            const [name, state] = selectedObject.split(", ")

            this.isLoading = true
            const that = this
            bbCountyManage.get({ name, state }, county => {
                that.county = county
                that.isLoading = false
            })
        },
        onInputSates(val) {            
            this.county = Object.assign({}, this.county, {
                state: val
            })
            this.state = val;
            $('#selected-state').val(val)
        },
        hasNewtorkStates(state){
            console.log('s:',state);
            if(this.networkStates.includes(state)){
                return false;
            }
            this.networkStates.push(state)
            return true;
        },
        get_email_status(mail_status) {
            if(mail_status==0 ||mail_status==null) return 'pending';
            if(mail_status==1) return 'sent';
            if(mail_status==2) return 'opened';
            if(mail_status==-1) return 'failed';
        },
        get_invitation_details(invite_id){
                const inviteInfoTpl=$('#invite-info-tpl').html();
                let modal=$("#invite-info-modal");
                modal.modal('show');
                modal.find('.loader').removeClass('hide');
                BB.inviteManage.external.get(invite_id)
                .then(data=>{
                    modal.find('.loader').addClass('hide');
                    let email_recipient="";
                    if(data.email_details){
                        email_recipient=data.email_details.recipient;
                    }
                    let infoObj={
                        date: new Date(data.sent_timestamp*1000).toLocaleDateString("en-US"),
                        time: new Date(data.sent_timestamp*1000).toLocaleTimeString("en-US"),
                        send_type: ( data.sms ? "SMS/Text" : "Email" ),
                        recipient: ( data.sms ? data.sms : email_recipient )
                    };
                    const body = '<table class="table">'+
                                        '<tr><td>Time Sent</td><td>'+infoObj.date+' '+infoObj.time+'</td></tr>'+
                                        '<tr><td>Send Method</td><td>'+infoObj.send_type+'</td></tr>'+
                                        '<tr><td>Recipient</td><td>'+infoObj.recipient+'</td></tr>'+
                                    '</table>';
                    modal.find('.modal-body .content').html(body);
                });
        },
        resend_invitation(invite_id){
            let that = this;
            var $button = $('[data-action="resend-invite"][data-iid="'+invite_id+'"]')
            $button.find(".glyphicon").addClass("gly-spin")
            var url = "/manage-external-invites.json?id=" + invite_id + "&action=resend"
            jQuery.ajax({
                url: url,
                type: "GET",
                contentType: "application/json",
                dataType: "json",
                success: function(data) {
                    BB.Toastr.success("Invite Resent")
                    $button.find(".glyphicon").removeClass("gly-spin")
                },
                error: function(xhr, ajaxOptions, thrownError) {
                    if (xhr.status == 200) {
                    $button.find(".glyphicon").removeClass("gly-spin")
                    } else {
                    $button.find(".glyphicon").removeClass("gly-spin")
                    }
                }
            })
        },
        delete_invitation(invite_id){
            var $button = $(this)
            var currentBtn = this
            var url = "/manage-external-invites.json?id=" + invite_id
            $.ajax({
                url: url,
                type: "DELETE",
                contentType: "application/json",
                dataType: "json",
                success: function(data) {
                    BB.Toastr.success("Invite Deleted")
                    $("#externalTabContent #network-invite-" + invite_id).hide()
                },
                error: function(xhr, ajaxOptions, thrownError) {
                    if (xhr.status == 200) {
                    } else {
                    }
                }
            })
        },
        delete_invite_internal(invite_id) {
            var $button = $(this)
            var currentBtn = this
            $.get("/listing_flyer/rlopair/decline_invite/"+invite_id, function(data) {
                BB.Toastr.success("Invite Deleted")
                $("#network-invite-" + invite_id).remove()
            })
        },
        delete_pair(pairId) {
            var $button = $(this)
            var currentBtn = this
            $.get("/listing_flyer/rlopair/delete/"+pairId, function(data) {
                BB.Toastr.success("Invite Deleted")
                $("#network-user-" + pairId).remove();
            })
        },
        accept_invite(invite_id){
            this.confirmMSA(undefined, undefined, invite_id);
        },
        confirmMSA(loid, rid, iid, type = "internal") {
            var modalEl = $("#confirm_msa_modal")

            const confirmMsaModal = $("#confirm_msa_modal")

            $(".msaNext").click(function() {
                var iid = $(this).data("iid")
                var rid = $(this).data("rid")
                var loid = $(this).data("loid")
                var to_email = $(this).data("to_email")

                var skip = false
                // if this msa modal is being used to accept an invite, or send an internal invite, the extra confirm step can be skipped
                if ($(".bb-realtor .msaNext, .acceptInvite .msaNext, .internalInvite .msaNext").length) {
                skip = true
                }
                // validate initials field
                if (!skip && !$("#confirmInitials").val().length) {
                $(".msaNext").addClass("disabled")
                return null
                }
                if (!skip && $(this).hasClass("disabled")) {
                return null
                }
                var to_email = $("#inputEmail").val()
                var to_sms = $("#inputSMSNumber").val()
                if (!skip && to_email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(to_email)) {
                $("#inputEmail")
                    .parent()
                    .addClass("has-error")
                return null
                } else {
                $("#inputEmail")
                    .parent()
                    .removeClass("has-error")
                }
                if (!skip && to_sms && !/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(to_sms)) {
                    $("#inputSMSNumber")
                        .parent()
                        .addClass("has-error")
                    return null
                } else {
                    $("#inputSMSNumber")
                        .parent()
                        .removeClass("has-error")
                }
                $(".myInviteConfirmContent").addClass("hide")
                $(this).addClass("hide")
                $(".msaConfirm").removeClass("hide")
                const confirmButton = modalEl.find(".msaConfirm")
                const content = modalEl.find(".msa_content")

                confirmButton.data("iid", iid)
                confirmButton.data("rid", rid)
                confirmButton.data("loid", loid)
                confirmButton.attr("disabled", true)
                confirmButton.data("email", to_email)
                confirmButton.data("sms", to_sms)

                modalEl.find(".loader").show()

                content.html("")
                var type = ""
                if (iid < 1) type = "/external"

                $.get(`/listing_flyer/get_msa/${loid}/${rid}${type}`, data => {
                modalEl.find(".loader").hide()
                confirmButton.attr("disabled", false)
                if (!data["msa_final"]) {
                    confirmButton.trigger("click")
                } else {
                    content.html(data["msa_final"].replace(/(?:\r\n|\r|\n)/g, "<br />"))
                }
                })
            })

            
            
            var nextBtn = $(".msaNext")
            modalEl.find(".msa_content").html("")
            modalEl.modal("show")
            $(".myInviteConfirmContent").removeClass("hide")
            $("#myInviteConfirm .progress-bar").attr("aria-valuenow", "0")
            modalEl.addClass(type + "Invite")
            //if realtor, accepting invite, or sending internal invite - skip validate step
            var skip = false
            nextBtn.data("iid", iid)
            nextBtn.data("rid", rid)
            nextBtn.data("loid", loid)
            nextBtn.attr("disabled", true)
            if ($(".bb-realtor .msaNext, .acceptInvite .msaNext, .internalInvite .msaNext").length) {
                skip = true
                $(".msaNext")
                .removeClass("disabled")
                .trigger("click")
            } else {
                nextBtn.removeClass("hide")
            }

            
        },
		showModal: function() {
            this.showForm = !this.showForm
            
        },
        onInputStates(val, event) {
            const thatForm = event ? this.form : this.form
            this.$set(thatForm, 'state', val);
        },
        clear(){
            this.form = {}
        },
        checkForm() {
            let count = 0
            const requiredFields = [
            "first_name",
            "last_name",
            "email",
            //"phone",
            // "agency_name",
            // "state",
            // "agency_logo",
            // "picture",
            // "title"
            ]
            let that = this;
            requiredFields.forEach(field => {
                that.form[field] &&
                that.form[field].length &&
                count++
            })
            return (count === requiredFields.length)
        },
        editRealtor(pid){
            console.log('pid', pid);
            console.log(this.pairs);
            this.isDataLoading=true;
            this.showModal();
            this.clear()
			let data = _find(this.pairs, ["pid", pid])
            data.user.picture = data.user.picture.includes('placeholder.png')?'':data.user.picture
            data.user.logo = (data.user.logo && data.user.logo.includes('realtor_logo.png'))?'':data.user.logo
            data.user.agency_logo = (data.user.agency_logo && data.user.agency_logo.includes('realtor_logo.png'))?'':data.user.agency_logo
            this.form = data.user;
            this.form.pid=pid;
            this.isDataLoading=false;
        },
        submitForm(){                
            const {
                first_name,
                last_name,
                title,
                email,
                phone,
                agency_name,
                agency_logo,
                picture,
                state,
                pid
                } = this.form;

                const newRealtor = {
                    first_name:first_name,
                    last_name:last_name,
                    title:title,
                    email:email,
                    phone:phone,
                    agency_name:agency_name,
                    agency_logo:agency_logo,
                    picture:picture,
                    state:state,
                    pid:pid
                }

            if(!this.checkForm()){
                 BB.Toastr.error('Fill in all the required fields');
                 return;
             }
            this.isDataLoading = true;
            $('.submitBtn').attr('disabled', 'disabled');
            const that = this;
            if(this.form.id != undefined && this.form.id != ''){
                newRealtor.id = this.form.id;
                BB.realtorManage.shared.update(newRealtor).then(function(data) {
                    if(data.status == "error"){
                        BB.Toastr.error(data.message)
                    }
                    else{
                        that.showModal();
                        that.isLoading=true
                        this.isDataLoading = false;
                        that.runReport()
                        $('.submitBtn').removeAttr('disabled');
                        BB.Toastr.success('Realtor Updated')
                        that.clear()
                    }
                });
            }
            else{
                BB.realtorManage.shared.create(newRealtor).then(function(data) {
                    if(data.status == "error"){
                        BB.Toastr.error(data.message)
                    }
                    else{
                        that.showModal();
                        that.isLoading=true
                        that.runReport()
                        BB.Toastr.success('Realtor Updated')
                        that.clear()
                    }
                });
            }
        },
		runReport() {
			//this.initTypeahead();
			let that = this;
			jQuery.ajax({
				url: '/my-bb-network',
				type: "POST",
				contentType: "application/json",
				dataType: "json",
				success(data) {
					that.invites = data.invites;
					that.external_invites = data.external_invites;
					that.pairs = data.pairs;
					that.user = data.user;
					that.isLoading= false;                
				},
				error(xhr, ajaxOptions, thrownError) { 
				}
			})
		}
    },
    created : function() {
        //this.initTypeahead();
        let that = this;
        jQuery.ajax({
            url: '/my-bb-network',
            type: "POST",
            contentType: "application/json",
            dataType: "json",
            success(data) {
                that.invites = data.invites;
                that.external_invites = data.external_invites;
                that.pairs = data.pairs;
                that.user = data.user;
                that.isLoading= false;                
            },
            error(xhr, ajaxOptions, thrownError) { 
            }
        }
        )
    },
    mounted(){
        bbNetwork.init();
    }
}

</script>
<style>
    #tt-search-input::placeholder {
        font-size: 15px;
    }
    .table-header-img{
        width: 56px;
    }
    .network-table__column{
    position: relative;
  }

  .filter-wrapper{
    position: absolute;
    top: 30px;
    background: #fff;
    z-index: 10;
    border: 1px solid #ccc;
    padding: 10px;
  }
  .table-header-img{
    width:56px;
  }
  .filter-body{
    max-height: 400px;
    overflow-y: auto;
  }
  .filter-header{
    margin-bottom: 10px;
  }
  .filter-footer{
    margin-top: 10px;
  }
  a.filter-by-name, a.filter-by-state, a.filter-by-agency-name{
    cursor: pointer;
    color: #666;
  }
  .filter-body label{
    padding: 2px 0;
    display: block;
	font-size: 14px;
	padding-left: 20px;
  }
  .filter-body label input{
    margin-right: 3px;
    margin-left: -17px;
  }
  .network-table{
    /* min-height: 550px; */
  }
  #network-user .network-table__user-info-title{
    font-size: 18px !important;
    font-weight: 800;
  }
  input.search-mls {
	margin-right: 6px;
  }
  .tooltip-hover{
	  position:relative;
  }
  .mls-key {
    background: #eee;
    padding: 5px;
    display: inline-block;
    margin-bottom: 5px;
  }
  .btn-tooltip-label {
    position: absolute;
    top: 27px;
    left: 0px;
    background: #eee;
    padding: 5px;
    z-index: 11111;
    min-width: 200px;
    color: #999;
    font-size: 12px;
    overflow: unset;
    border: 1px solid #ccc;
    max-width: none;
    border-radius: 3px;
    font-weight: 200;
  }
  .header-state, .header-agency-name, .header-agency-mls {
    font-size: 18px;
    font-weight: 800;
  }
  .network-header .network-header__description {
	width: 29%;
	flex: unset;
	padding-right: 10px;
  }
  .network-header .network-header__search.network-search {
	width: 69%;
  }
  .network_state_dd .drop-down {
	margin-top: 8px!important;
	margin-right: 16px;
	width: 160px;	
  }
  .network_state_dd .drop-down:before {
	display: none;
  }
  .network_state_dd .drop-down #state {
	height: 50px;
	border: 1px solid #ddd;
	border-radius: 4px;
	padding-left: 10px;
  }
  p.align-left {
	text-align: left;
  }
  .network-chk-wrapper {
	width: 220px;
  }
  .network_state_label {
	font-weight: 400;
  }
</style>