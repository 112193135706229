<doc>
    My Archive Search Bar Vue.js component
</doc>
  
<template>
    <FlexBox 
        class="my-archive-search-wrapper"
        alignment="end" 
    >   
        <BaseAutoComplete
            :showListOptions="true"
            :showSearchButton="true"
            :listOptions="listOptions"
            :listItem="[]"
            name="myarchive-autocomplete-search"
            :hideLabel="true"
            :placeHolder="this.placeholder"
            :float="true"
            marTop="0"
            marBottom="0"
            :paddLeft="true"
            :isMaterial="false"
            :fluidList="false"
            @submit="onSubmit"
            @changePlaceholderByType="changePlaceholderByType"
        />
    </FlexBox>
</template>
  
<script>
import BaseAutoComplete from "../BaseAutocomplete"
import FlexBox from "../FlexBox"
  
export default {
    name: "MyArchiveSearchBar",
    components: {
        FlexBox,
        BaseAutoComplete
    },
    props: {
        addFilter: { type: Function },
        hasSort: { type: Boolean, default: false },
    },
    data: function() {
        return {
            placeholder: "Search by User",
            listOptions: [
                { icon: "user", text: "User", value: "username",  type: "archive" },
                { icon: "file-alt", text: "Flyer Name", value: "flyer_name", type: "archive" },
                { icon: "user-tie", text: "Realtor Name", value: "realtor_name", type: "archive" },
                { icon: "envelope", text: "Realtor Email", value: "realtor_email", type: "archive" },
            ],
        }
    },
    methods: {
        onSubmit(query) {
            let filter = {
                icon: query.option.icon,
                value: query.text,
                column: query.option.value
            }
            this.addFilter(filter)
        },
        changePlaceholderByType(listOptionText) {
            this.placeholder = listOptionText 
                ? "Search by " + listOptionText
                : "Search by " + this.listOptions[0].text
        }
    },
}
</script>

<style lang="scss">
.my-archive-search-wrapper {
    position: relative;
    width: 250px;
    margin-bottom: 1px;

    .autoComplete {
        width: 100%;
        border-radius: 5px;
        border: 1px solid #e8e8e8;
    }

    .autoComplete__input-container {
        height: 38px;
        border-radius: 5px;
    }

    #myarchive-autocomplete-search {
        height: 38px;
        border-radius: 5px;
        font-size: 14px;
    }
}
</style>
  