<template>
  <div class="app-wrapper">
    <LoaderPlaceholder v-if="isLoading" type="circle" />
    <div v-else>
      <div class="btn-group dateRanges" role="group" aria-label="...">
        <button type="button" @click="setRange(30)" class="btn btn-default" :class="{active : rangeDays==30}">30 days</button>
        <button type="button" @click="setRange(7)" class="btn btn-default" :class="{active : rangeDays==7}">7 days</button>
        <button type="button" @click="setRange(1)" class="btn btn-default" :class="{active : rangeDays==1}">1 day</button>
      </div>
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :row-style-class="rowStyleClassFn"
        :pagination-options="pagination_options"
        :search-options="{
          enabled: true,
          skipDiacritics: true
        }"
      >
        styleClass="vgt-table striped" >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'after'">
            <span class="viewDetails" @click="showPanelFn(props.row)"
              ><i class="fas fa-info-circle"></i
            ></span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
    <slideout-panel></slideout-panel>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table"
import { PricingLogDetails } from "../components/PricingLog"
import { PricingPanel } from "../components"

export default {
  name: "PricingLog",
  components: {
    VueGoodTable,
    PricingLogDetails,
    PricingPanel
  },
  async mounted() {
    await this.setRange(this.rangeDays)
  },
  data() {
    return {
      isLoading: false,
      rangeDays: 1,
      jsonOptions: {
        height: "500px",
        mode: "view",
        plus: true
      },
      columns: [
        {
          label: "ID",
          field: "id",
          hidden: true
        },
        {
          label: "Brand",
          field: "brand_name"
        },
        {
          label: "Loan Officer",
          field: "lo_name"
        },
        {
          label: "Profile",
          field: "ob_profile_name",
          formatFn: val => (val ? val : "Custom Quote")
        },
        {
          label: "Date/Time",
          field: "timestamp",
          type: "date",
          dateInputFormat: "t",
          dateOutputFormat: "MMM do, h:mm a"
        },
        {
          label: "",
          field: "after",
          sortable: false,
          globalSearchDisabled: true,
          tdClass: "infoColumn"
        }
      ],
      rows: [],
      pagination_options:{
          enabled: true,
          mode: 'records',
          perPage: 25,
          position: 'bottom',
          perPageDropdown: [25, 50, 100],
          dropdownAllowAll: false,
          setCurrentPage: 1,
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page', // for 'pages' mode
          allLabel: 'All',
      }
    }
  },
  methods: {
    rowStyleClassFn(row) {
      return parseInt(row.base_rate) == 0 ? "failed" : "valid"
    },
    onError(e) {},
    async showPanelFn(item) {
      if (item.full_response && typeof item.full_response != "object") {
        item.full_response = JSON.parse(item.full_response)
      }
      if (item.formatted_pricing && typeof item.formatted_pricing != "object") {
        item.formatted_pricing = JSON.parse(item.formatted_pricing)
      }

      this.$showPanel({
        component: PricingPanel,
        openOn: "right",
        props: {
          options: this.jsonOptions,
          onCreated: () => {
            return this.getRowDetails(item)
          }
        }
      })
    },
    async getRowDetails(row) {
      let lo = await BB.loManage.get(row.loid)
      let log_entry = await BB.rateflow.log(row.id)
      let result = log_entry
      result.lo = lo.lo
      if(log_entry.ob_profile_id=="-1"){
          // attempt to get lead by quote_id (row.id)
          let leadSearch = (await BB.leadManage.get({quote_id: row.id}))
          let lead=( leadSearch[0] ? leadSearch[0] : null )
          result.lead = lead
      }
      return result
    },
    async setRange(rangeDays){
        this.rangeDays=rangeDays
        this.isLoading = true
        let search_criteria={
            min_created: Math.round(((+ new Date())/1000)-(24*3600*rangeDays)),
            limit:10000
        }
        if(brand_id)search_criteria.bank_id=brand_id
        if(loid)search_criteria.loid=loid
        let results = await BB.rateflow.logSearch(search_criteria)
        this.rows = results.data
        this.isLoading = false
        return
    }
  }
}
</script>

<style lang="scss">
@import "../../node_modules/vue-good-table/dist/vue-good-table.css";
.app-wrapper {
  margin-top: 20px;
}
tr.failed {
  background-color: #ffebee;
}
.viewDetails {
  cursor: pointer;
}
.infoColumn {
  text-align: center;
}
.slideout-panel {
  z-index: 1000;
  position: relative;
}
.dateRanges{
  float: right;
  margin-top: -75px;
}
@media (max-width: 991px) {
  .dateRanges {
    margin-top: -65px;
  }
}
.vgt-table{
  td{
    font-size:14px;
    padding:10px;
  }
}
</style>
