var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "editorContainer" }, [
    _c(
      "form",
      {
        ref: "integrationForm",
        staticClass: "form",
        attrs: { id: "integration-form" },
        on: {
          submit: function($event) {
            $event.preventDefault()
          }
        }
      },
      [
        _c(
          "fieldset",
          { staticClass: "generalSettings" },
          [
            _c("InputText", {
              attrs: { label: "Title", name: "title" },
              on: { onBlur: _vm.updateField },
              model: {
                value: _vm.integration.title,
                callback: function($$v) {
                  _vm.$set(_vm.integration, "title", $$v)
                },
                expression: "integration.title"
              }
            }),
            _vm._v(" "),
            _c("InputText", {
              attrs: { label: "URL", name: "url" },
              on: { onBlur: _vm.updateField },
              model: {
                value: _vm.integration.url,
                callback: function($$v) {
                  _vm.$set(_vm.integration, "url", $$v)
                },
                expression: "integration.url"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "integration-domains" }, [
              _vm.integration.domains && _vm.integration.domains.length
                ? _c(
                    "div",
                    _vm._l(_vm.integration.domains, function(domain, index) {
                      return _c(
                        "div",
                        {
                          key: domain.id || index,
                          staticClass: "integration-domains__row"
                        },
                        [
                          _c("InputText", {
                            attrs: {
                              label: "Domain",
                              name: "domain-" + domain.id,
                              placeholder: "Ex. https://www.google.com",
                              hideLabel: ""
                            },
                            on: {
                              onBlur: function($event) {
                                _vm.updateDomain(index)
                              }
                            },
                            model: {
                              value: domain.domain_pattern,
                              callback: function($$v) {
                                _vm.$set(domain, "domain_pattern", $$v)
                              },
                              expression: "domain.domain_pattern"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "BaseButton",
                            {
                              staticClass:
                                "btn btn-default integration-domains__remove",
                              attrs: {
                                outline: "",
                                size: "xs",
                                btnIcon: "minus"
                              },
                              on: {
                                click: function($event) {
                                  _vm.removeDomain(domain, index)
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "sr-only" }, [
                                _vm._v("remove domain")
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    })
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-between mb-3 integration-domains__footer"
                },
                [
                  _c("small", { staticClass: "form-text text-muted" }, [
                    _vm._v(
                      "\n            Enter base URL of site that will be integrated.\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "BaseButton",
                    {
                      staticClass: "btn btn-default add-domian-btn",
                      attrs: { outline: "", size: "xs" },
                      on: { click: _vm.addDomain }
                    },
                    [_vm._v("\n            Add a new domain\n          ")]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("InputText", {
                  staticClass: "accent_color_field",
                  attrs: { label: "Primary Color", name: "accent_color" },
                  on: { onBlur: _vm.updateField },
                  model: {
                    value: _vm.extraSettings.accent_color,
                    callback: function($$v) {
                      _vm.$set(_vm.extraSettings, "accent_color", $$v)
                    },
                    expression: "extraSettings.accent_color"
                  }
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "accent_color_preview",
                  style: { "background-color": _vm.extraSettings.accent_color }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("InputText", {
                  staticClass: "accent_color_field",
                  attrs: {
                    label: "Secondary Color",
                    name: "secondary_accent_color"
                  },
                  on: { onBlur: _vm.updateField },
                  model: {
                    value: _vm.extraSettings.secondary_accent_color,
                    callback: function($$v) {
                      _vm.$set(_vm.extraSettings, "secondary_accent_color", $$v)
                    },
                    expression: "extraSettings.secondary_accent_color"
                  }
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "accent_color_preview",
                  style: {
                    "background-color": _vm.extraSettings.secondary_accent_color
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("InputText", {
                  staticClass: "ga_tag_field",
                  attrs: { label: "Google Analytics Tag", name: "ga_tag" },
                  on: { onBlur: _vm.updateField },
                  model: {
                    value: _vm.extraSettings.ga_tag,
                    callback: function($$v) {
                      _vm.$set(_vm.extraSettings, "ga_tag", $$v)
                    },
                    expression: "extraSettings.ga_tag"
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "btn-group btn-group-xs ga-switch" },
                  [
                    _c(
                      "BaseButton",
                      {
                        attrs: {
                          outline: _vm.extraSettings.ga_mode !== "internal",
                          size: "xs"
                        },
                        on: {
                          click: function($event) {
                            _vm.setGaMode("internal")
                          }
                        }
                      },
                      [_vm._v("\n            Internal\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "BaseButton",
                      {
                        attrs: {
                          outline: _vm.extraSettings.ga_mode !== "external",
                          size: "xs"
                        },
                        on: {
                          click: function($event) {
                            _vm.setGaMode("external")
                          }
                        }
                      },
                      [_vm._v("\n            External\n          ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm.integration.type != "api"
          ? _c(
              "fieldset",
              [
                _vm.integration.type != "standalone" &&
                _vm.integration.type != "api"
                  ? _c("ImgUploader", {
                      attrs: {
                        previewAlt: "Header Background Image",
                        editMode: true,
                        previewSrc: _vm.extraSettings.header_bg_img,
                        previewClass: "generalSettings__bg-preview",
                        label: "Header Background Image",
                        uploadCallback: _vm.uploadCallback,
                        labelTop: true,
                        hasRemove: true,
                        removePhoto: _vm.removePhoto
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("fieldset", [
          _c("div", { staticClass: "checkboxField" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.integration.app_settings.mort_calc,
                  expression: "integration.app_settings.mort_calc"
                }
              ],
              attrs: { id: "mort_calc", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.integration.app_settings.mort_calc)
                  ? _vm._i(_vm.integration.app_settings.mort_calc, null) > -1
                  : _vm.integration.app_settings.mort_calc
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.integration.app_settings.mort_calc,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.integration.app_settings,
                            "mort_calc",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.integration.app_settings,
                            "mort_calc",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.integration.app_settings, "mort_calc", $$c)
                    }
                  },
                  _vm.updateField
                ]
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "mort_calc" } }, [
              _vm._v("Enable Mortgage Calculator")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "checkboxField" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.integration.app_settings.afford_calc,
                  expression: "integration.app_settings.afford_calc"
                }
              ],
              attrs: { id: "afford_calc", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.integration.app_settings.afford_calc)
                  ? _vm._i(_vm.integration.app_settings.afford_calc, null) > -1
                  : _vm.integration.app_settings.afford_calc
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.integration.app_settings.afford_calc,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.integration.app_settings,
                            "afford_calc",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.integration.app_settings,
                            "afford_calc",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.integration.app_settings, "afford_calc", $$c)
                    }
                  },
                  _vm.updateField
                ]
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "afford_calc" } }, [
              _vm._v("Enable Affordability Calculator")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "checkboxField" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.integration.app_settings.lead_workflow,
                  expression: "integration.app_settings.lead_workflow"
                }
              ],
              attrs: { id: "lead_workflow_enabled", type: "checkbox" },
              domProps: {
                checked: Array.isArray(
                  _vm.integration.app_settings.lead_workflow
                )
                  ? _vm._i(_vm.integration.app_settings.lead_workflow, null) >
                    -1
                  : _vm.integration.app_settings.lead_workflow
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.integration.app_settings.lead_workflow,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.integration.app_settings,
                            "lead_workflow",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.integration.app_settings,
                            "lead_workflow",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.integration.app_settings,
                        "lead_workflow",
                        $$c
                      )
                    }
                  },
                  _vm.updateField
                ]
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "lead_workflow_enabled" } }, [
              _vm._v("Enable Lead Workflow")
            ]),
            _vm._v(" "),
            _vm.integration.app_settings.lead_workflow && _vm.availableWorkflows
              ? _c("div", { staticClass: "selectWrapper" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.integration.app_settings.lead_workflow,
                          expression: "integration.app_settings.lead_workflow"
                        }
                      ],
                      staticClass: "select-workflow",
                      attrs: { id: "lead_workflow" },
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.integration.app_settings,
                              "lead_workflow",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.updateField
                        ]
                      }
                    },
                    [
                      _c("option", { attrs: { disabled: "", value: "" } }, [
                        _vm._v("Please select one")
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.availableWorkflows, function(wf) {
                        return _c(
                          "option",
                          { key: wf.id, domProps: { value: wf.id } },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(wf.name) +
                                "\n            "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.integration.app_settings.lead_workflow &&
                  _vm.integration.app_settings.lead_workflow !== true
                    ? _c("div", { staticClass: "selectEditLink" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href:
                                "/bb_lead_workflow_admin?id=" +
                                _vm.integration.app_settings.lead_workflow
                            }
                          },
                          [_vm._v("edit")]
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "checkboxField" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.extraSettings.contactForm,
                  expression: "extraSettings.contactForm"
                }
              ],
              attrs: { id: "contactFormEnable", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.extraSettings.contactForm)
                  ? _vm._i(_vm.extraSettings.contactForm, null) > -1
                  : _vm.extraSettings.contactForm
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.extraSettings.contactForm,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.extraSettings,
                            "contactForm",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.extraSettings,
                            "contactForm",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.extraSettings, "contactForm", $$c)
                    }
                  },
                  _vm.updateField
                ]
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "contactFormEnable" } }, [
              _vm._v("Enable Contact Form")
            ]),
            _vm._v(" "),
            _vm.extraSettings &&
            _vm.extraSettings.contactForm &&
            _vm.availableContactForms
              ? _c("div", { staticClass: "selectWrapper" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.extraSettings.contactForm,
                          expression: "extraSettings.contactForm"
                        }
                      ],
                      staticClass: "select-contact-form",
                      attrs: { id: "contactForm" },
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.extraSettings,
                              "contactForm",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.updateField
                        ]
                      }
                    },
                    [
                      _c("option", { attrs: { disabled: "", value: "" } }, [
                        _vm._v("Please select one")
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.availableContactForms, function(cf) {
                        return _c(
                          "option",
                          { key: cf.id, domProps: { value: cf.id } },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(cf.name) +
                                "\n            "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.extraSettings.contactForm &&
                  _vm.extraSettings.contactForm !== true
                    ? _c("div", { staticClass: "selectEditLink" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href:
                                "/contact_form_admin?id=" +
                                _vm.extraSettings.contactForm
                            }
                          },
                          [_vm._v("edit")]
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "checkboxField" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.integration.app_settings.pricing,
                  expression: "integration.app_settings.pricing"
                }
              ],
              attrs: { id: "pricing", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.integration.app_settings.pricing)
                  ? _vm._i(_vm.integration.app_settings.pricing, null) > -1
                  : _vm.integration.app_settings.pricing
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.integration.app_settings.pricing,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.integration.app_settings,
                            "pricing",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.integration.app_settings,
                            "pricing",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.integration.app_settings, "pricing", $$c)
                    }
                  },
                  _vm.updateField
                ]
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "pricing" } }, [
              _vm._v("Enable Pricing Cards")
            ]),
            _vm._v(" "),
            _vm.integration.app_settings.pricing
              ? _c("div", { staticClass: "selectWrapper" }, [
                  _vm.availablePricingSets !== null
                    ? _c("div", [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.integration.app_settings.pricing_set_id,
                                expression:
                                  "integration.app_settings.pricing_set_id"
                              }
                            ],
                            staticClass: "select-contact-form",
                            attrs: { id: "pricing_set_id" },
                            on: {
                              change: [
                                function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.integration.app_settings,
                                    "pricing_set_id",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                _vm.updateField
                              ]
                            }
                          },
                          [
                            _c(
                              "option",
                              { attrs: { disabled: "", value: "" } },
                              [_vm._v("Please select one")]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.availablePricingSets, function(ps) {
                              return _c(
                                "option",
                                { key: ps.id, domProps: { value: ps.id } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(ps.name) +
                                      "\n              "
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.integration.app_settings.pricing_set_id
                          ? _c("div", { staticClass: "selectEditLink" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    target: "_blank",
                                    href:
                                      "/my-programs?id=" +
                                      _vm.integration.app_settings
                                        .pricing_set_id +
                                      "#my-programs"
                                  }
                                },
                                [_vm._v("edit")]
                              )
                            ])
                          : _vm._e()
                      ])
                    : _c("div", [_vm._v("Loading...")])
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _vm.integration.type != "api"
          ? _c("fieldset", [
              _c("div", { staticClass: "checkboxField" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.extraSettings.loReviews,
                      expression: "extraSettings.loReviews"
                    }
                  ],
                  attrs: { id: "loReviews", type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.extraSettings.loReviews)
                      ? _vm._i(_vm.extraSettings.loReviews, null) > -1
                      : _vm.extraSettings.loReviews
                  },
                  on: {
                    change: [
                      function($event) {
                        var $$a = _vm.extraSettings.loReviews,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.extraSettings,
                                "loReviews",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.extraSettings,
                                "loReviews",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.extraSettings, "loReviews", $$c)
                        }
                      },
                      _vm.updateField
                    ]
                  }
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "loReviews" } }, [
                  _vm._v("Enable LO Reviews")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "checkboxField" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.extraSettings.brandReviews,
                      expression: "extraSettings.brandReviews"
                    }
                  ],
                  attrs: { id: "brandReviews", type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.extraSettings.brandReviews)
                      ? _vm._i(_vm.extraSettings.brandReviews, null) > -1
                      : _vm.extraSettings.brandReviews
                  },
                  on: {
                    change: [
                      function($event) {
                        var $$a = _vm.extraSettings.brandReviews,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.extraSettings,
                                "brandReviews",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.extraSettings,
                                "brandReviews",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.extraSettings, "brandReviews", $$c)
                        }
                      },
                      _vm.updateField
                    ]
                  }
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "brandReviews" } }, [
                  _vm._v("Enable Brand Reviews")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "checkboxField" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.extraSettings.rateTable,
                      expression: "extraSettings.rateTable"
                    }
                  ],
                  attrs: { id: "rateTable", type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.extraSettings.rateTable)
                      ? _vm._i(_vm.extraSettings.rateTable, null) > -1
                      : _vm.extraSettings.rateTable
                  },
                  on: {
                    change: [
                      function($event) {
                        var $$a = _vm.extraSettings.rateTable,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.extraSettings,
                                "rateTable",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.extraSettings,
                                "rateTable",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.extraSettings, "rateTable", $$c)
                        }
                      },
                      _vm.updateField
                    ]
                  }
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "rateTable" } }, [
                  _vm._v("Enable Rate Table")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "checkboxField" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.extraSettings.aboutBoxes,
                      expression: "extraSettings.aboutBoxes"
                    }
                  ],
                  attrs: { id: "aboutBoxes", type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.extraSettings.aboutBoxes)
                      ? _vm._i(_vm.extraSettings.aboutBoxes, null) > -1
                      : _vm.extraSettings.aboutBoxes
                  },
                  on: {
                    change: [
                      function($event) {
                        var $$a = _vm.extraSettings.aboutBoxes,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.extraSettings,
                                "aboutBoxes",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.extraSettings,
                                "aboutBoxes",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.extraSettings, "aboutBoxes", $$c)
                        }
                      },
                      _vm.updateField
                    ]
                  }
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "aboutBoxes" } }, [
                  _vm._v("Enable About Boxes")
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.integration.type == "api" ? _c("fieldset") : _vm._e(),
        _vm._v(" "),
        _c("fieldset", { staticClass: "advancedWrapper" }, [
          _c(
            "button",
            { staticClass: "toggleBtn", on: { click: _vm.expandAdvanced } },
            [_vm._v("Advanced")]
          ),
          _vm._v(" "),
          _vm.advancedExpanded
            ? _c("div", { staticClass: "advancedSettings" }, [
                _c("div", { staticClass: "checkboxField" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.extraSettings.disableCalcCards,
                        expression: "extraSettings.disableCalcCards"
                      }
                    ],
                    attrs: { id: "disableCalcCards", type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.extraSettings.disableCalcCards)
                        ? _vm._i(_vm.extraSettings.disableCalcCards, null) > -1
                        : _vm.extraSettings.disableCalcCards
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.extraSettings.disableCalcCards,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.extraSettings,
                                  "disableCalcCards",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.extraSettings,
                                  "disableCalcCards",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.extraSettings, "disableCalcCards", $$c)
                          }
                        },
                        _vm.updateField
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "disableCalcCards" } }, [
                    _vm._v("Disable Loan Calc Cards")
                  ])
                ]),
                _vm._v(" "),
                _vm.integration && _vm.integration.owner && _vm.availableOwners
                  ? _c("div", { staticClass: "selectWrapper" }, [
                      _vm.changingOwner
                        ? _c("div", [
                            _c("label", { attrs: { for: "owner" } }, [
                              _vm._v("Select Owner")
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.integration.owner,
                                    expression: "integration.owner"
                                  }
                                ],
                                staticClass: "select-owner",
                                attrs: { id: "owner" },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.integration,
                                        "owner",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    _vm.updateField
                                  ]
                                }
                              },
                              [
                                _c(
                                  "option",
                                  { attrs: { disabled: "", value: "" } },
                                  [_vm._v("Please select one")]
                                ),
                                _vm._v(" "),
                                _vm._l(_vm.availableOwners, function(o) {
                                  return _c(
                                    "option",
                                    { key: o.uid, domProps: { value: o.uid } },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(o.name) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          ])
                        : _c("div", [
                            _c(
                              "button",
                              {
                                attrs: { id: "changeOwner" },
                                on: {
                                  click: function($event) {
                                    _vm.toggleChangingOwner()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              Change Owner\n            "
                                )
                              ]
                            )
                          ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("InputText", {
                      attrs: { label: "POST Url", name: "postUrl" },
                      on: { onBlur: _vm.updateField },
                      model: {
                        value: _vm.extraSettings.postUrl,
                        callback: function($$v) {
                          _vm.$set(_vm.extraSettings, "postUrl", $$v)
                        },
                        expression: "extraSettings.postUrl"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", [
                  _vm.hasUTM
                    ? _c("div", [
                        _vm._v(
                          "\n            UTM Tracking is configured\n          "
                        )
                      ])
                    : _c("div", [
                        _c("button", { on: { click: _vm.addUTMTracking } }, [
                          _vm._v("Add UTM Tracking")
                        ])
                      ])
                ])
              ])
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5183affc", { render: render, staticRenderFns: staticRenderFns })
  }
}