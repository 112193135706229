<doc>
	Company detials details Vue.js component
</doc>

<template>
  <div :class="className">
    <div class="company-details-img">
      <img :src="item.logo" :alt="`${item.name} logo`" />
        <SmartListItemOptions
          :itemIndex="itemIndex"
          :itemId="item.id"
          :onDragEnd="onDragEnd"
          :isLast="isLast"
          :gridView="gridView"
          :showEditItemModal="showEditItemModal"
          :listType="listType"
          :hide-options="hideOptions"
          :removeItem="deleteBrand"
        />
    </div>
    <div class="company-details">
      <div class="company-details-title">{{ item.name }}</div><icon :class="statusClassName" name="circle"></icon>
      <div class="company-details-meta company-id">
        <a target="_blank">ID: {{ item.id }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import SmartListItemOptions from "../SmartList/SmartListItemOptions"
import { cleanURL } from "../../helpers"

const { mapState, mapActions } = createNamespacedHelpers("organization")

export default {
  name: "CompanyDetails",
  components: {
    SmartListItemOptions: SmartListItemOptions
  },
  data() {
    return {
      listType: "brands",
      hideOptions: [
          "masquerade"
      ]
    }
  },
  async mounted(){
  },
  updated(){},
  props: {
    item: { type: Object, required: true },
    itemIndex: { type: Number, required: true },
    isLast: { type: Boolean, default: false },
    onDragEnd: { type: Function }
  },
  computed: Object.assign({}, mapState(["gridView"]), {
    className: function() {
      let className = "company"
      if (!this.gridView) {
        className += " list-view d-flex"
      }
      if (this.$mq == "sm" || this.$mq == "md") {
        className += " not-desktop"
      }
      return className
    },
    website: function() {
      return cleanURL(this.item.website)
    },
    statusClassName: function() {
      let className =
          this.item.pricing_status === "active" ? "text-success" : "text-danger"
      return `${className} brand-pricing-status hide`
    },
  }),
  methods: Object.assign(
    {},
    mapActions(["showEditProgramDetailsModal", "showEditItemModal", "deleteItem"]),
    {
      showModal() {
        this.showEditProgramDetailsModal(this.item.id)
      },
	  deleteBrand(itemId){
        console.log('branddeleted', itemId);
        let that = this
        $.ajax({
          url: '/bank/manage.json?id='+itemId.id,
          type: "DELETE",
          success: function(data) {
            that.deleteItem(itemId)
            BB.Toastr.success(
              "Brand deleted."
            )            
          },
          error: function(xhr, ajaxOptions, thrownError) {
            BB.Toastr.error(
              "Delete Failed"
            )              
          }
        })
      }
    }
  )
}
</script>

<style lang="scss" scoped>
.company {
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.17);
  margin: 15px 0;

  &.list-view {
    border-left: 4px solid #58bec8;
    margin: 0 0 10px;
    padding: 8px 5px;
    background: #fff;

    .company-details {
      padding: 0;
      display: flex;
      align-items: center;
      &-title {
        margin-bottom: 0;
        padding: 0 80px 0 40px;
        min-width: 185px;
      }

      &-img {
        position: unset;
      }
    }
    .company-overlay {
      opacity: 1;
      background: none;
    }

    img {
      margin: 0;
      height: 38px;
    }

    .program-buttons-wrapper {
      top: 6px;
      right: 15px;
    }
  }

  img {
    object-fit: contain;
    max-width: 100%;
    display: block;
    margin: 0 auto;
    height: 102px;
    .grid-view & {
      padding: 12px;
    }
  }
  &-details {
    background: #fff;
    padding: 20px 16px 22px;
    &-img {
      background: #fff;
      position: relative;
    }
    &-title {
      color: #595959;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 9px;
    }
    &-meta {
      color: #abc0d3;
      font-size: 11px;
      a {
        color: #abc0d3;
        font-size: 12px;
      }
    }
  }
  &-overlay {
    z-index: 6;
    opacity: 0;
    transition: opacity 0.25s ease;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    background: rgba(53, 64, 82, 0.75);
  }

  &:hover {
    .company-overlay {
      body:not(.dragging) & {
        opacity: 1;
      }
    }
  }
}
.btn-view-program {
  top: calc(50% - 16px);
  position: relative;
  color: #fff;
  border-color: #fff;
  &:hover {
    color: #fff;
  }
}

.not-desktop {
  .company-overlay {
    opacity: 1;
    background: rgba(0, 0, 0, 0);
  }
}
.company-details-meta {
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0px;
  }
}
.company-details-title{
  display: inline-block;
}
.brand-pricing-status{
  margin-left: 5px;
  margin-bottom: -1px;
}
</style>
