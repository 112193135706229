<doc>
  Add/edit Companies Modal Vue.js component
</doc>

<template>
  <BaseModal
    ref="modal"
    class="member-modal"
    v-model="showItemModal"
    :title="member.name"
    id="memberModal"
    @ok="submitForm"
    @cancel="hideModal"
    @clickMask="hideModal"
    okLabel="Save"
    :closeWhenOK="true"
    :isLoading="isLoading"
  >
    <Card>
      <h5>About {{ member.name }}</h5>
      <div class="thumb-wrapper d-flex">
        <Thumb fullRounded :src="photoSRC" :alt="alt" class="item-image" />

        <div class="thumb-actions">
          <input
            id="fileUpload"
            name="picture"
            type="file"
            class="hide"
            accept="image/png, image/jpeg, image/jpg"
            @change="onFileChange"
          />
          <BaseButton type="button" v-on:click="() => {}" theme="link" size="sm"
            >Delete</BaseButton
          >
          <BaseButton type="button" v-on:click="onFileClick" size="sm">{{
            photoLabel
          }}</BaseButton>
        </div>
      </div>
      <form id="member-form" class="form" ref="memberForm" v-on:submit.prevent>
        <fieldset>
          <div v-if="!obLO">
            <div v-if="obLoLoading">
              Loading…
            </div>
            <div v-else>
              <autocomplete
                placeholder="Search LO & Connect Pricing Engine"
                class="form-group material-input"
                input-class="form-control"
                :resultsFormatter="acResultsFormatter"
                :resultsDisplay="acResultsDisplay"
                :source="obLOAutocomplete"
                @selected="selectOBLO"
              />
            </div>
          </div>
          <div v-else>
            <div style="margin-top:20px;">
              <img
                style="height:30px;margin-bottom:-10px;margin-right:10px;"
                src="https://ellisjm-private.s3.amazonaws.com/ob_logo.png"
              />
              <span>{{ obLO.firstName }} {{ obLO.lastName }}</span>
              <i @click="clearOBLO" class="fas fa-times-circle"></i>
            </div>
          </div>
          <InputText
            v-model="member.first_name"
            label="First Name"
            labelClassName="required"
            name="first_name"
            v-on:input="onInput"
          />
          <InputText
            v-model="member.last_name"
            label="Last Name"
            labelClassName="required"
            name="last_name"
            v-on:input="onInput"
          />
          <InputText
            v-model="extra_details.title"
            label="Title"
            name="title"
            v-on:input="onInput"
          />

          <RichTextEditor
            label="About Me"
            name="about_me"
            v-on:input="
              () => {
                this.onInputCustom('about_me')
              }
            "
            v-model="extra_details.about_me"
          />

          <InputText
            v-model="member.email"
            label="Email Address"
            labelClassName="required"
            name="email"
            type="email"
            v-on:input="onInput"
          />
          <InputText
            v-model="member.nmls"
            labelClassName="required"
            label="NMLS"
            name="nmls"
            v-on:input="onInput"
          />
          <InputText
            v-model="member.app_link"
            label="Pre-App Link"
            name="app_link"
            v-on:input="onInput"
          />
          <InputText
            v-model="extra_details.mobile_phone"
            label="Mobile Number"
            name="mobile_phone"
            :isPhone="true"
            v-on:input="onInput"
          />
          <InputText
            v-model="extra_details.office_phone"
            label="Office number"
            name="office_phone"
            :isPhone="true"
            v-on:input="onInput"
          />
          <InputText
            v-model="member.phone"
            label="Primary Phone Number (optional)"
            name="phone"
            :isPhone="true"
            v-on:input="onInput"
          />
          <InputText
            v-model="extra_details.special_disclosure_link"
            label="Special Disclosure Link"
            name="special_disclosure_link"
            v-on:input="onInput"
          />
          <InputTextArea
            v-model="extra_details.disclaimer"
            label="User Disclaimer"
            name="disclaimer"
            v-on:input="onInput"
            v-bind:showLimit="false"
            :limit="2000"
          />
        </fieldset>
        <fieldset>
          <legend>Social:</legend>
          <InputText
            v-model="extra_details.website_link"
            label="Website"
            name="website_link"
            type="url"
            v-on:input="onInput"
          />
          <InputText
            v-model="extra_details.facebook_link"
            label="Facebook link"
            name="facebook_link"
            type="url"
            v-on:input="onInput"
          />
          <InputText
            v-model="extra_details.linkedin_link"
            label="Linkedin link"
            name="linkedin_link"
            type="url"
            v-on:input="onInput"
          />
        </fieldset>
        <fieldset>
          <legend>Office:</legend>
          <InputText
            v-model="extra_details.office_street_addr"
            label="Office address"
            name="office_street_addr"
            v-on:input="onInput"
          />
          <InputText
            v-model="extra_details.office_city"
            label="Office city"
            name="office_city"
            v-on:input="onInput"
          />
          <InputText
            v-model="extra_details.office_state"
            label="Office state"
            name="office_state"
            v-on:input="onInput"
          />
          <InputText
            v-model="extra_details.office_zipcode"
            label="Office zipcode"
            name="office_zipcode"
            v-on:input="onInput"
          />
          <InputText
            v-model="extra_details.branch_nmls"
            label="Branch NMLS"
            name="branch_nmls"
            v-on:input="onInput"
          />
        </fieldset>
        <fieldset>
          <legend style="margin-bottom:15px;">CRM Intergration:</legend>
          <label class="v-select">CRM Type</label>
          <vSelect
            v-model="member.crm_type"
            :options="crmTypes"
            :reduce="crmTypes => crmTypes.code"
          />
          <InputText
            v-model="member.crm_id"
            label="Unique CRM ID"
            name="crm_id"
          />
        </fieldset>
      </form>
    </Card>
    <Card class="company-details" v-if="!loBrand">
      <Dropdown
        :outline="false"
        class="dd-actions required"
        label="brand"
        theme="link"
        size="sm"
        :options="brands"
        v-on:dropDownChange="onInput"
      />
    </Card>
    <Card class="company-details" v-else>
      <div class="d-flex justify-content-between align-items-center">
        <h5>Brand</h5>
        <i @click="clearLOBrand" class="fas fa-times-circle"></i>
      </div>
      <div class="thumb-wrapper d-flex">
        <img :src="loBrand.logo" :alt="alt" class="company-image" />
        <div class="thumb-details">
          <h6>{{ loBrand.name }}</h6>
        </div>
      </div>
    </Card>
    <Card class="reviews">
      <h5>Zillow Reviews</h5>
      <div v-if="reviewData && reviewData.zillow && reviewData.zillow.reviews">
        <ManagerReviews :reviews="reviewData.zillow.reviews" type="zillow" />
      </div>
    </Card>
  </BaseModal>
</template>

<script>
import _isEmpty from "lodash/isEmpty"
import { createNamespacedHelpers } from "vuex"
import vSelect from "vue-select"
import BaseModal from "../BaseModal"
import Card from "../Card"
import Thumb from "../Thumb"
import Dropdown from "../Dropdown"
import RichTextEditor from "../Forms/RichTextEditor"
import Autocomplete from "vuejs-auto-complete"
import ManagerReviews from "./ManagerReviews"
import FaIcon from "vue-awesome/components/Icon"
import { InputTextArea } from "../Forms"

const { mapState, mapActions } = createNamespacedHelpers("members")

export default {
  name: "CompanyPeopleModal",
  components: {
    FaIcon,
    BaseModal,
    Card,
    Thumb,
    Dropdown,
    Autocomplete,
    ManagerReviews,
    vSelect,
    RichTextEditor,
    InputTextArea
},
  data() {
    return {
      extra_details: {
        about_me: "",
        mobile_phone: "",
        website_link: "",
        linkedin_link: "",
        facebook_link: ""
      },
      edited_items: [],
      obLoLoading: false,
      obLOFailed: false,
      obLO: null,
      loBrand: null,
      crm_entry: {
        crm_type: "",
        crm_id: ""
      },
      reviewData: null,
      photoSRC: undefined
    }
  },
  async updated() {
    if (
      this.member.originator_id &&
      this.member.biz_channel &&
      !this.obLO &&
      !this.obLoLoading
    ) {
      this.getOBLO({
        originator_id: this.member.originator_id,
        biz_channel: this.member.biz_channel
      })
    }
    if (this.member.nid && this.reviewData == null) {
      // debugger;
      this.reviewData = []
      this.reviewData = await BB.loManage.reviews(this.member.nid)
    }
  },
  computed: Object.assign(
    {},
    mapState(["loid", "currentEditedItem", "isEdit"]),
    {
      showItemModal: {
        get() {
          return this.$store.state.members.showItemModal
        },
        set(value) {
          this.toggleItemModal(value)
        }
      },
      photoLabel() {
        return this.photoSRC ? "Change" : "Add"
      },
      member() {
        let member = {}

        if (!_isEmpty(this.currentEditedItem)) {
          member = Object.assign({}, this.currentEditedItem)
          this.extra_details = Object.assign(
            {},
            this.currentEditedItem.extra_details
          )
          this.loBrand = this.$store.state.organization.org.brands.find(
            item => item.id == this.currentEditedItem.bank_nid
          )
          // support for legacy schema, can be removed in the future
          if(member.crm_ids){
            this.crm_entry = member.crm_ids[0]
                ? Object.assign({}, member.crm_ids[0])
                : {}
          }
        } else {
          this.obLO = null
          this.crm_entry = {}
        }
        if (member.picture) {
          this.photoSRC = member.picture
        }

        return member
      },
      isLoading() {
        return !this.currentEditedItem && this.isEdit
      },
      alt: function() {
        return `${this.member.name} thumb`
      },
      altLogo: function() {
        return `${this.member.bank} logo`
      },
      brands() {
        if (
          !this.$store.state.organization.org ||
          !this.$store.state.organization.org.brands
        )
          return []
        let brandList = []
        let _this = this
        this.$store.state.organization.org.brands.forEach(brand => {
          brand.label = brand.name
          brand.action = item => {
            _this.loBrand = item
            //debugger;
          }
          brandList.push(brand)
        })
        return brandList
      },
      crmTypes() {
        let _this = this
        let result = []
        let types = [
          "velocify",
          "insellerate",
          "jungo",
          "salesforce",
          "set_shape",
          "total_expert"
        ]
        types.forEach(type => {
          result.push({
            label: this.humanize(type),
            code: type
          })
        })
        return result
      }
      // showLOSearch(){
      //     debugger;
      //     if(this.member){
      //         return false;
      //  }
      //  if(this.obLO){
      //      return false;
      //  }
      //  return true;
      // }
      // crm_entry(){
      //     let crm_entry={}
      //     if(!this.member.crm_ids)return crm_entry;
      //     crm_entry=this.member.crm_ids[0]
      //  return crm_entry;
      // }
    }
  ),
  methods: Object.assign(
    {},
    mapActions(["toggleItemModal", "saveMember", "updateMemberLocal"]),
    {
      onInput(val, $event) {
        const id = $event.target.id
        if (!this.edited_items.includes(id)) {
          this.edited_items.push(id)
        }
      },
      onInputCustom(id) {
        if (!this.edited_items.includes(id)) {
          this.edited_items.push(id)
        }
      },
      hideModal() {
        this.toggleItemModal(false)
        this.resetForm()
      },
      resetForm() {
        this.clearOBLO()
        this.clearLOBrand()
        this.$refs.memberForm.reset()
        this.photoSRC = undefined
        this.extra_details = {}
        this.reviewData = null
      },
      obLOAutocomplete(input) {
        //debugger;
        let org_id = this.$store.state.organization.orgID
        return "/api/v1/find-ob-lo?name=" + input + "&org_id=" + org_id
      },
      getOBLO(ob_info) {
        if (!this.obLOFailed && ob_info.biz_channel && ob_info.originator_id) {
          let org_id = this.$store.state.organization.orgID
          this.obLoLoading = true
          fetch(
            "/api/v1/find-ob-lo?originator_id=" +
              this.member.originator_id +
              "&biz_channel=" +
              this.member.biz_channel +
              "&org_id=" +
              org_id,
            {
              method: "GET",
              credentials: "same-origin"
            }
          )
            .then(res => {
              return res.json()
            })
            .then(data => {
              this.obLO = data[0]
              this.obLoLoading = false
            })
            .catch(err => {
              this.obLoLoading = false
              console.error(err)
            })
            .finally(() => {
              this.obLoLoading = false
              if(!this.obLO || !this.obLO.index){
                this.obLOFailed=true
              }
            })
        }
      },
      acResultsFormatter(data) {
        return data
      },
      acResultsDisplay(data) {
        return (
          `
          <div class='resultWrapper'>
            <div>
              <span class="name">` +
          data.firstName +
          ` ` +
          data.lastName +
          `</span>
            </div>
            <div class='details'>
              <span style='font-weight:400;color:#aaa;'>` +
          data.email +
          `</span>
            </div>
            <div class='details'>
              <span style='font-weight:400;color:#888;'>` +
          data.biz_channel.name +
          `</span>
            </div>
          </div>
      `
        )
      },
      selectOBLO(data) {
        //debugger;
        //this.currentEditedItem.first_name=data.selectedObject.firstName;
        this.obLO = data.selectedObject
        // run the following only if creating a new member
        if (!this.member.nid) {
          this.member.email = data.selectedObject.email
          this.member.first_name = data.selectedObject.firstName
          this.member.last_name = data.selectedObject.lastName
          this.member.originator_id = data.selectedObject.index
          this.member.biz_channel = data.selectedObject.biz_channel.id
        }
      },
      humanize(str) {
        let i,
          frags = str.split("_")
        for (i = 0; i < frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
        }
        return frags.join(" ")
      },
      clearOBLO() {
        this.obLO = null
        this.obLOFailed = false
        this.member.originator_id = ""
        this.member.biz_channel = ""
        if (!this.member.nid) {
          this.$refs.memberForm.reset()
        }
        this.reviewData = null
        this.edited_items.push("originator_id")
        this.edited_items.push("biz_channel")
      },
      clearLOBrand() {
        this.loBrand = null
      },
      submitForm: function() {
        /*
      POST
      {
      loid*
      picture_fid
      email
      all_notifications
      bank_nid
      first_name
      last_name
      extra_details:
        {
           about_bank
           about_me
           facebook_link
           linkedin_link
           mobile_phone
           office_city
           office_phone
           office_state
           office_street_addr
           website_link
           special_disclosure_link
           disclaimer
        }
      }*/
        const vm = this

        const editMemberId = vm.member.uid || null
        const data = { extra_details: {} }
        if (editMemberId) {
          const edited_items = this.edited_items

          for (var i = edited_items.length - 1; i >= 0; i--) {
            if (
              [
                "first_name",
                "last_name",
                "email",
                "picture_fid",
                "app_link",
                "nmls",
                "phone",
                "biz_channel",
                "originator_id"
              ].includes(edited_items[i])
            ) {
              data[edited_items[i]] = vm.member[edited_items[i]]
            } else {
              data.extra_details[edited_items[i]] =
                vm.extra_details[edited_items[i]]
            }
          }
          data.loid = parseInt(editMemberId, 10)
        } else {
          for (var key in vm.member) {
            if (
              [
                "first_name",
                "last_name",
                "email",
                "picture_fid",
                "app_link",
                "nmls",
                "phone"
              ].includes(key)
            ) {
              data[key] = vm.member[key]
            }
          }
          data.extra_details = vm.extra_details
        }
        data.originator_id = ""
        data.biz_channel = ""
        if (this.obLO) {
          data.originator_id = this.obLO.index
          data.biz_channel = this.obLO.biz_channel.id
        }
        if (this.crm_entry) {
          let crm_entries = []
          crm_entries[0] = this.crm_entry
          data.crm_entries = crm_entries
        }
        if (this.loBrand && this.loBrand.id) data.brand_id = this.loBrand.id

        if (_isEmpty(data.extra_details)) {
          delete data.extra_details
        }
        //console.log("data", data)
        data.pricing_status =
          !data.originator_id && !data.biz_channel ? "inactive" : "active"
        this.saveMember({ data: data, isEdit: editMemberId }).then(data => {})
        this.resetForm()
      },
      onFileClick(e) {
        $("#fileUpload").click()
      },
      onFileChange(e) {
        const file =
          e.target.files && e.target.files[0] ? e.target.files[0] : null
        const minimumHeight = 150;
        const minimumWidth = 150;

        if (file) {
          const data = new FormData()
          const reader = new FileReader()
          var vm = this
        
          data.append("files[img]", e.target.files[0])
          data.append("bb_sid", window.SESSION_ID)

          reader.onload = readerEvent => {
            //$("#memberModal .item-image").attr("src", e.target.result)
            var image = new Image()
            image.src = readerEvent.target.result

            //Validate the File Height and Width.
            image.onload = _e => {
              var height = image.height
              var width = image.width

              if (height < minimumHeight || width < minimumWidth) {
                e.target.value = ""
                BB.Toastr.error("Image must be at least " + minimumWidth + "x" + minimumHeight + ".")
              }
              else {
                fetch("/bb-file-upload.json", {
                  method: "POST",
                  body: data,
                  credentials: "same-origin"
                })
                .then(res => {
                  return res.json()
                })
                .then(data => {
                  vm.member.picture_fid = data.message.fid
                  vm.edited_items.push("picture_fid")
                })
                .catch(err => {
                  console.error(err)
                  // loader.addClass('hide');
                })

                vm.photoSRC = image.src
                vm.updateMemberLocal({
                  loid: vm.member.nid,
                  picture: image.src
                })
              }
            };
          }

          reader.readAsDataURL(file)

          // submitButton.attr('disabled', true);
          // loader.removeClass('hide');
          // text.html('Uploading');
        }
      }
    }
  )
}
</script>

<style lang="scss" src="./CompanyPeopleModalStyles.scss"></style>
<style>
  .required::after {
    content: " *";
    color: red;
  }
</style>
