var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "FlexBox",
        {
          staticClass: "my-archive-multiselect-container",
          attrs: { justify: "end", wrap: true }
        },
        [
          _c("MyArchiveSearchBar", {
            attrs: { addFilter: _vm.addFilter, onSorting: _vm.onSorting }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "multiselect-wrapper" },
            [
              _c("label", { staticClass: "my-archive-label" }, [
                _vm._v("Flyer Type")
              ]),
              _vm._v(" "),
              _c("Multiselect", {
                attrs: {
                  "max-height": 250,
                  value: _vm.flyerTypeValue,
                  options: _vm.flyerTypeOptions,
                  multiple: true,
                  "close-on-select": false,
                  "clear-on-select": false,
                  "preserve-search": false,
                  placeholder: "Search",
                  selectLabel: "Click to select",
                  deselectLabel: "Click to remove",
                  label: "name",
                  "track-by": "value",
                  "preselect-first": true
                },
                on: {
                  input: _vm.onFilterChange,
                  select: _vm.selectFlyerType,
                  remove: _vm.removeFlyerType
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(ref) {
                      var values = ref.values
                      var search = ref.search
                      var isOpen = ref.isOpen
                      return values.length > 2 && !isOpen
                        ? [
                            _c("span", { staticClass: "multiselect__single" }, [
                              _vm._v(
                                _vm._s(values.length) + " options selected"
                              )
                            ])
                          ]
                        : undefined
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "multiselect-wrapper" },
            [
              _c("label", { staticClass: "my-archive-label" }, [
                _vm._v("State")
              ]),
              _vm._v(" "),
              _c("Multiselect", {
                attrs: {
                  "max-height": 250,
                  value: _vm.stateValue,
                  options: _vm.states,
                  multiple: true,
                  "close-on-select": false,
                  "clear-on-select": false,
                  "preserve-search": false,
                  placeholder: "Search",
                  selectLabel: "Click to select",
                  deselectLabel: "Click to remove",
                  label: "name",
                  "track-by": "value",
                  "preselect-first": true
                },
                on: {
                  input: _vm.onFilterChange,
                  select: _vm.selectState,
                  remove: _vm.removeState
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(ref) {
                      var values = ref.values
                      var search = ref.search
                      var isOpen = ref.isOpen
                      return values.length > 1 && !isOpen
                        ? [
                            _c("span", { staticClass: "multiselect__single" }, [
                              _vm._v(
                                _vm._s(values.length) + " options selected"
                              )
                            ])
                          ]
                        : undefined
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "date-created-wrapper" },
            [
              _c("label", { staticClass: "my-archive-label" }, [
                _vm._v("Date Created")
              ]),
              _vm._v(" "),
              _c("DatePickerRange", {
                attrs: {
                  onChange: _vm.onFilterChange,
                  defaultValue: _vm.dateRange
                },
                model: {
                  value: _vm.dateRange,
                  callback: function($$v) {
                    _vm.dateRange = $$v
                  },
                  expression: "dateRange"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "multiselect-wrapper" },
            [
              _c("label", { staticClass: "my-archive-label" }, [
                _vm._v("Brand")
              ]),
              _vm._v(" "),
              _c("Multiselect", {
                attrs: {
                  "max-height": 250,
                  value: _vm.brandValue,
                  options: _vm.brandOptions,
                  multiple: true,
                  "close-on-select": false,
                  "clear-on-select": false,
                  "preserve-search": false,
                  placeholder: "Search",
                  selectLabel: "Click to select",
                  deselectLabel: "Click to remove",
                  label: "name",
                  "track-by": "id",
                  "preselect-first": true
                },
                on: {
                  input: _vm.onFilterChange,
                  select: _vm.selectBrand,
                  remove: _vm.removeBrand
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(ref) {
                      var values = ref.values
                      var search = ref.search
                      var isOpen = ref.isOpen
                      return values.length > 1 && !isOpen
                        ? [
                            _c("span", { staticClass: "multiselect__single" }, [
                              _vm._v(
                                _vm._s(values.length) + " options selected"
                              )
                            ])
                          ]
                        : undefined
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "FlexBox",
        {
          style: { margin: _vm.searchQueries.length > 0 ? "10px 0px" : "" },
          attrs: { wrap: true }
        },
        _vm._l(_vm.searchQueries, function(query, index) {
          return _c("Badge", {
            key: query.value + query.column,
            staticClass: "badge-filter",
            attrs: {
              pill: "",
              hasClose: "",
              label: query.value,
              showIcon: true,
              icon: query.icon,
              isCapitalize: false,
              mr: 5
            },
            on: {
              removeBadge: function($event) {
                _vm.removeFilter(index)
              }
            }
          })
        })
      ),
      _vm._v(" "),
      _c(
        "FlexBox",
        {
          staticClass: "my-archive-buttons-container",
          attrs: { justify: "between", alignment: "center" }
        },
        [
          _c(
            "FlexBox",
            { attrs: { justify: "between", alignment: "center" } },
            [
              _c("MyArchiveExcelExport", {
                attrs: { fetchData: _vm.fetchData }
              }),
              _vm._v(" "),
              _vm.isFilterChange
                ? _c(
                    "div",
                    { staticClass: "warning-message" },
                    [
                      _c("icon", { attrs: { name: "exclamation-triangle" } }),
                      _vm._v(
                        "Filters have been modified. Run Report with new filters\n      "
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("BaseButton", { on: { click: _vm.runReport } }, [
            _vm._v("Run Report")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("MyArchiveTable", {
        attrs: {
          isShowTopScrollBar: true,
          reportData: _vm.reportData,
          onSorting: _vm.onSorting,
          isLoading: _vm.isLoading
        }
      }),
      _vm._v(" "),
      _vm.isPagination
        ? _c("BasePagination", {
            key: _vm.totalItems + "-" + _vm.paginationKey,
            attrs: {
              total: _vm.totalItems,
              maxItemsPerPage: _vm.maxItems,
              toOffset: _vm.maxItems
            },
            on: { onPagination: _vm.onPageChange }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-8f50a0da", { render: render, staticRenderFns: staticRenderFns })
  }
}