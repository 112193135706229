<doc>
    Listing Pricing Set Tool Vue.js component
</doc>

<template>
    <grid-row v-if="!defaultScenarios">
        <grid-column>
            <h3>Pricing is not configured</h3>
        </grid-column>
    </grid-row>
    <grid-row v-else>
        <grid-column v-if="currentPage==='scenarios'">
            <grid-row>
              <grid-column>
                <h3>Set Loan Program Order</h3>
                <p>
                  Set the order of the programs you want on the flyer and landing page. System will match to the closest best execution based on the Mortgage Points and Program. If match does not exist, offers will be requested in the order defined below until the number of programs required for the flyer / landing page are returned.
                </p>
              </grid-column>
            </grid-row>
            <grid-row class="target-price-tool">
                <grid-column>
                    <InputText
                        v-model.trim="points"
                        label="Discount Points"
                        labelClassName="required"
                        name="target_price"
                        @onBlur="onChange"
                    />
                    <span>
                        <button class="increment-tp-btn" @click="incrementTP('plus')"><i class="fas fa-plus"></i></button>
                        <button class="increment-tp-btn" @click="incrementTP('minus')"><i class="fas fa-minus"></i></button>
                    </span>
                    <span class="preview-btn-wrapper">
                        <button @click="previewPricing">Preview</button>
                    </span>
              </grid-column>
            </grid-row>

            <div v-if="scenarios">
                <scenarios-list :initial-scenarios="scenarios" :save-list="saveList" :select-scenario="selectScenario" />
            </div>
            <div>
                <button type="button" class="btn btn-small-turquesa btn-sm btn-reset" @click="reset">Reset</button>
            <div style="clear: both"></div>
        </div>
        </grid-column>
        <grid-column v-else-if="currentPage==='details'">
            <button @click="clearScenario">Back</button>
            <div v-if="previewLoading || previewLoading===null">Loading...</div>
            <div v-else-if="previewLoading===false && scenarioPricingData && scenarioPricingData.cards">
                <h3>
                  {{scenarioPricingData.label}}
                </h3>
                <p>
                  Below is the Best Fit and two other closest pricing options for this program and the discount points you entered.
                </p>
                <div class="col-sm-4 list-cards" v-for="(card, index) in scenarioPricingData.cards" :key="index">
                    <CalcCard
                        :rate="card.rate"
                        :price="card.price"
                        :term="parseInt(card.amortizationTerm)"
                        :listPrice="scenarioPricingData.listPrice"
                        :fee="(card.discount ? {title: 'Discount',amount: card.discount} : {title: 'Rebate',amount: card.rebate})"
                        :type="card.productName"
                        :principalAndInterest="card.principalAndInterest"
                        :investor="card.investor"
                        :active="(index === 0)"
                        :label="(index === 0 ? 'Best Fit' : '')"
                    />
                </div>
            </div>
            <div v-else>
              <p>No offers currently, try again later.</p>
            </div>
        </grid-column>
        <grid-column v-else-if="currentPage==='preview'">
          <button class="pricing-set-tool-back-btn" @click="clearPreview">Back</button>
          <div v-if="previewLoading || previewLoading===null">Loading...</div>
          <div v-else-if="previewLoading===false && previewPricingData">
            <h3>Preview of Loan Programs</h3>
            <p>Based on the order you selected and Discount Points entered, these are the Loan Programs that would appear on the home flyer and landing page at this time per the pricing engine results.</p>
            <div class="col-sm-3 list-cards" v-for="(card, index) in previewPricingData" :key="index">
              <div class="preview-card-header">
                <strong>{{card.label}}</strong>
              </div>
              <div>
                {{card.rate}}% | {{card.apr}}% ({{card.price}})
              </div>
            </div>
          </div>
          <div v-else>
            <p>No offers currently, try again later.</p>
          </div>
        </grid-column>
    </grid-row>
</template>

<script>
    import draggable from "vuedraggable"
    import ScenariosList from "./ScenariosList";
    import CalcCard from "../CalcCard"

    export default {
    name: "PricingSetTool",
    components: {
        ScenariosList,
        draggable,
        CalcCard
    },
    data: function() {
        return {
            imgLimit: 5,
            colSize: 12 / 6,
            scenarios: null,
            targetPrice: 100,
            defaultScenarios: null,
            runOnce: false,
            selectedScenario: null,
            scenarioPricingData: null,
            points: 0,
            currentPage: "scenarios",
            previewPricingData: null,
            previewLoading: null
        }
    },
    props: {
        setPricing: { type: Function, required: true },
        pricingConfig: { type: Object, required: true },
        loanInfo: { type: Object, required: true },
        toggleParentBtns: { type: Function }
    },
    mounted() {
        this.initialize()
    },
    updated(){
        if(!this.runOnce && this.pricingConfig && this.pricingConfig.scenarios && this.pricingConfig.scenarios.length>0 && this.defaultScenarios){
            this.scenarios=this.pricingConfig.scenarios.map((id)=>{
                return {id,"name":this.getScenarioName(id)}
            })
            this.targetPrice=( this.pricingConfig.targetPrice ? this.pricingConfig.targetPrice : 100 )
            this.points=100-this.targetPrice
            this.runOnce=true
        }
    },
    methods: {
        onMove(e){
            // console.log(e)
        },
        onStart(e){
            // console.log(e)
        },
        onEnd(e){
            console.log(e)
        },
        onChange(e){
            let idList = []
            this.scenarios.map((item)=>{
              idList.push(item.id)
            })
            this.targetPrice=100-this.points
            this.setPricing({
              scenarios: idList,
              targetPrice: this.targetPrice
            })
        },
        async initialize(){
            let result=await $.get("/api/v1/default-scenarios?loid="+$("body").data("user_id"))
            if(result && result.scenarios){
                this.defaultScenarios=result.scenarios
                this.scenarios=this.defaultScenarios
            }
        },
        getScenarioName(id){
            for(let i=0; i<this.defaultScenarios.length; i++){
                if(this.defaultScenarios[i].id == id){
                    return this.defaultScenarios[i].name
                }
            }
        },
        saveList(updatedList){
            this.scenarios=updatedList
            this.onChange(null)
        },
        async reset(){
            await this.initialize()
            this.onChange(null)
        },
        async selectScenario(scenario){
            this.selectedScenario=scenario
            if (typeof this.toggleParentBtns !== "undefined")this.toggleParentBtns()
            // load scenario details
            this.currentPage="details"
            await this.loadScenarioDetails(scenario.id)
        },
        clearScenario(){
            this.selectedScenario=null
            this.scenarioPricingData=null
            this.currentPage="scenarios"
            if (typeof this.toggleParentBtns !== "undefined")this.toggleParentBtns()
            this.previewLoading=null
        },
        clearPreview(){
            this.previewPricingData=null
            this.currentPage="scenarios"
            if (typeof this.toggleParentBtns !== "undefined")this.toggleParentBtns()
            this.previewLoading=null
        },
        async previewPricing(){
            this.currentPage="preview"
            if (typeof this.toggleParentBtns !== "undefined")this.toggleParentBtns()
            await this.loadPreviewPricingData()
        },
        async loadPreviewPricingData(){
            this.previewLoading=true
            let idList = []
            this.scenarios.map((item)=>{
                idList.push(item.id)
            })
            let preview_pricing_response=await (await fetch(`/rateflow/price.json?max_results=4&zipcode=${this.loanInfo.zipcode}&list_price=${this.loanInfo.list_price}&target_price=${this.targetPrice}&ob_profile_ids=${idList.join(",")}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json"
                },
                credentials: "same-origin"
            })).json()
            if(preview_pricing_response && preview_pricing_response.results && preview_pricing_response.results.length>0){
                this.previewPricingData=preview_pricing_response.results;
            }
            this.previewLoading=false
        },
        async loadScenarioDetails(scenario_id){
            this.previewLoading=true
            let scenario_pricing_response=await (await fetch(`/rateflow/price.json?zipcode=${this.loanInfo.zipcode}&list_price=${this.loanInfo.list_price}&target_price=${this.targetPrice}&ob_profile_id=${scenario_id}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json"
                },
                credentials: "same-origin"
            })).json()

            if(!scenario_pricing_response || !scenario_pricing_response.results || scenario_pricing_response.results.length<1){
                this.previewLoading=false
                return
            }
            let response=await (await fetch(`/api/v1/rateflow-log?include_request=true&id=${scenario_pricing_response.results[0].rateflow_log_id}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json"
                },
                credentials: "same-origin"
            })).json()
            if(!response || !response.base_rate){
                this.previewLoading=false
                return
            }
            this.scenarioPricingData = {
                cards: response.formatted_pricing.slice(0,3),
                targetPrice: response.target_price,
                listPrice: scenario_pricing_response.results[0].list_price,
                loanAmount: scenario_pricing_response.results[0].loan_amount,
                creditScore: 740,
                label: scenario_pricing_response.results[0].label
            }
            this.previewLoading=false
      },
      incrementTP(mode){
          let increment = .125
          this.points=Math.round(this.points * 8) / 8;
          if(mode==="plus"){
              this.points+=increment
          }
          if(mode==="minus"){
              this.points-=increment
          }
          this.onChange()
      }
    }
}
</script>

<style lang="scss" scoped>
    ul.steps {
      li {
        background: lightslategray;
        color: white;
        padding: 10px 0px 10px 10px;
        margin-bottom: 5px;
        font-size: 14px;
        width: 400px;
        max-width: 100%;

        .dragBtn {
          cursor: grab;
        }
        .listId {
          margin-left: 10px;
          margin-right: 10px;
        }
        .editBtn {
          float: right;
          margin-right: 10px;
        }
      }
    }
    .btn-reset{
      float: right;
    }
    .list-cards{
      margin-top: 20px;
    }
    .label{
      color: #666;
    }
    .increment-tp-btn{
      padding: 3px 25px;
    }
    .target-price-tool{
      margin-bottom: 20px;
    }
    .preview-btn-wrapper{
      float: right;
      button{
        padding: 5px 25px;
      }
    }
    .preview-card-header{
      margin-bottom: 5px;
    }
    button{
      cursor: pointer;
    }
</style>