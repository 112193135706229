<doc>
  Archives Vue.js component
</doc>

<template>
  <div>
    <FlexBox 
      class="my-archive-multiselect-container"
      justify="end"
      :wrap="true"
    >
      <MyArchiveSearchBar 
        :addFilter="addFilter"
        :onSorting="onSorting"
      />
      <div class="multiselect-wrapper">
        <label class="my-archive-label">Flyer Type</label>
        <Multiselect 
          :max-height="250"
          :value="flyerTypeValue" 
          :options="flyerTypeOptions" 
          :multiple="true" 
          :close-on-select="false" 
          :clear-on-select="false" 
          :preserve-search="false" 
          placeholder="Search" 
          selectLabel="Click to select"
          deselectLabel="Click to remove"
          label="name"
          track-by="value"
          :preselect-first="true"
          @input="onFilterChange"
          @select="selectFlyerType"
          @remove="removeFlyerType"
        >
          <template 
            slot="selection" 
            slot-scope="{ values, search, isOpen }"
            v-if="values.length > 2 && !isOpen"
          >
            <span class="multiselect__single">{{values.length}} options selected</span>
          </template>
        </Multiselect>
      </div>
      <div class="multiselect-wrapper">
        <label class="my-archive-label">State</label>
        <Multiselect 
          :max-height="250"
          :value="stateValue" 
          :options="states" 
          :multiple="true" 
          :close-on-select="false" 
          :clear-on-select="false" 
          :preserve-search="false" 
          placeholder="Search" 
          selectLabel="Click to select"
          deselectLabel="Click to remove"
          label="name" 
          track-by="value" 
          :preselect-first="true"
          @input="onFilterChange"
          @select="selectState"
          @remove="removeState"
        >
          <template 
            slot="selection" 
            slot-scope="{ values, search, isOpen }"
            v-if="values.length > 1 && !isOpen"
          >
            <span class="multiselect__single">{{values.length}} options selected</span>
          </template>
        </Multiselect>
      </div>
      <div class="date-created-wrapper">
        <label class="my-archive-label">Date Created</label>
        <DatePickerRange v-model="dateRange" :onChange="onFilterChange" :defaultValue="dateRange"/>
      </div>
      <div class="multiselect-wrapper">
        <label class="my-archive-label">Brand</label>
        <Multiselect 
          :max-height="250"
          :value="brandValue" 
          :options="brandOptions" 
          :multiple="true" 
          :close-on-select="false" 
          :clear-on-select="false" 
          :preserve-search="false" 
          placeholder="Search" 
          selectLabel="Click to select"
          deselectLabel="Click to remove"
          label="name" 
          track-by="id" 
          :preselect-first="true"
          @input="onFilterChange"
          @select="selectBrand"
          @remove="removeBrand"
        >
          <template 
            slot="selection" 
            slot-scope="{ values, search, isOpen }"
            v-if="values.length > 1 && !isOpen"
          >
            <span class="multiselect__single">{{values.length}} options selected</span>
          </template>
        </Multiselect>
      </div>
    </FlexBox>

    <FlexBox :wrap="true" :style="{margin: searchQueries.length > 0 ? '10px 0px': ''}">
      <Badge
        class="badge-filter"
        pill
        hasClose
        v-for="(query, index) in searchQueries"
        :key="query.value + query.column"
        :label="query.value"
        :showIcon="true"
        :icon="query.icon"
        :isCapitalize="false"
        :mr="5"
        v-on:removeBadge="removeFilter(index)"
      />
    </FlexBox>

    <FlexBox
      class="my-archive-buttons-container"
      justify="between"
      alignment="center"
    >
      <FlexBox justify="between" alignment="center">
        <MyArchiveExcelExport :fetchData="fetchData" />
        <div v-if="isFilterChange" class="warning-message"> 
          <icon name="exclamation-triangle" />Filters have been modified. Run Report with new filters
        </div>
      </FlexBox>
      
      <BaseButton @click="runReport">Run Report</BaseButton>
    </FlexBox>

    <MyArchiveTable 
      :isShowTopScrollBar="true"
      :reportData="reportData" 
      :onSorting="onSorting"
      :isLoading="isLoading"
    />

    <BasePagination
      :key="totalItems + '-' + paginationKey"
      v-if="isPagination"
      :total="totalItems"
      :maxItemsPerPage="maxItems"
      :toOffset="maxItems"
      @onPagination="onPageChange"
    />
  </div>
</template>

<script>
import "vue-awesome/icons"
import { USStates } from "../helpers"
import DatePickerRange from "../components/Forms/DatePickerRange.vue"
import { 
  BaseButton, 
  BasePagination, 
  FlexBox, 
  Badge, 
  MyArchiveTable, 
  MyArchiveSearchBar, 
  MyArchiveExcelExport 
} from "../components"
import Multiselect from "vue-multiselect"

export default {
  name: "MyArchive",
  components: { 
    Multiselect, 
    DatePickerRange, 
    BaseButton, 
    BasePagination,
    FlexBox, 
    Badge,
    MyArchiveTable,
    MyArchiveSearchBar,
    MyArchiveExcelExport
  },
  async mounted(){
    try {
      this.archives = await BB.archives.get({dateRange: this.dateRange, limit: this.maxItems})
      await this.getAvailableBrands()
      this.totalItems = parseInt(this.archives.total)
      this.reportData = this.archives.data
    }
    catch(error) {
      BB.Toastr.error("Error retrieving data")
    }
    finally {
      this.isLoading = false
    }
  },
  data(){
    const USStatesArray = [{value:'All', name: 'All'}]
    Object.entries(USStates).forEach(state => {
      USStatesArray.push({value: state[0], name: state[1]})
    })
    
    const startDate = new Date(1);
    const endDate = new Date();

    return {
      flyerTypeValue: [],
      flyerTypeOptions: [
        {value: "All", name: "All"},
        {value: "Static Flyer", name: "Static"},
        {value: "Home Listing", name: "Home Listing"}
      ],
      stateValue: [],
      states: USStatesArray,
      dateRange: [startDate, endDate],
      brandValue: [],
      brandOptions: [],
      isBankAdmin: ( isBankAdmin ? isBankAdmin : false ),
      isFilterChange: false,
      isLoading: true,
      totalItems: 0,
      reportData: [],
      archives: null,
      maxItems: 25,
      paginationKey: 0,
      searchQueries: [],
      sortOption: {
        column: 'created',
        isAsc: false
      },
    }
  },
  methods: {
    async getAvailableBrands() {
      // work around by getting org data directly
      if(this.isBankAdmin) {
        let { brands } = await BB.orgManage.get(org_id)
        this.brandOptions = [{name: "All", id: "All"}, ...brands]
      } else {
        this.brandOptions = [{name: "All", id: "All"}]
        this.archives.brand.forEach(brand => {
          this.brandOptions.push({
            name: brand.title,
            id: brand.vid
          })
        })
      }
      this.brandValue = [...this.brandValue, this.brandOptions[0]]
    },
    addFilter(query) {
      if(!this.containsFilter(query)) {
        this.searchQueries.push(query)
        this.runReport()
      }
      else {
        BB.Toastr.warning("Filter has already been added.")
      } 
    },
    containsFilter(query) {
      for(let i = 0; i < this.searchQueries.length; i++) {
        if(this.searchQueries[i].value === query.value 
          && this.searchQueries[i].column === query.column)
          return true
      }
      return false
    },  
    removeFilter(index) {
      this.searchQueries.splice(index, 1)
      this.runReport()
    },
    onSorting(column, isAsc) {
      this.sortOption = {
        column: column,
        isAsc: isAsc
      }
      this.runReport()
    },
    onFilterChange() {
      if(this.reportData.length > 0) {
        this.isFilterChange = true
      }
    },
    selectFlyerType(option) {
      this.flyerTypeValue = option.value === 'All' || !this.flyerTypeValue.length || this.flyerTypeValue[0].value === 'All'
        ? [option]
        : [...this.flyerTypeValue, option]
    },
    removeFlyerType(option) {
      this.flyerTypeValue = this.flyerTypeValue.filter(type => type.value !== option.value)
    },
    selectBrand(option) {
      this.brandValue = option.id === 'All' || !this.brandValue.length || this.brandValue[0].id === 'All'
        ? [option]
        : [...this.brandValue, option]
      
    },
    removeBrand(option) {
      this.brandValue = this.brandValue.filter(type => type.id !== option.id)
    },
    selectState(option) {
      this.stateValue = option.name === 'All' || !this.stateValue.length || this.stateValue[0].name === 'All'
        ? [option]
        : [...this.stateValue, option]
    },
    removeState(option) {
      this.stateValue = this.stateValue.filter(type => type.value !== option.value)
    },
    inputValidation() {
      let isInputValid = true
      if(this.dateRange.length < 2) {
        BB.Toastr.error("Please select date created")
        isInputValid = false
      }
      if(this.brandValue.length === 0) {
        BB.Toastr.error("Please select brands")
        isInputValid = false
      }
      if(this.flyerTypeValue.length === 0) {
        BB.Toastr.error("Please select flyer types")
        isInputValid = false
      }
      if(this.stateValue.length === 0) {
        BB.Toastr.error("Please select US States")
        isInputValid = false
      }

      return isInputValid
    },
    runReport() {
      if(this.inputValidation()) {
        this.isFilterChange = false
        this.isLoading = true
        this.paginationKey += 1
        this.fetchData(0, this.maxItems)
      }
    },
    onPageChange(e) {
      const { offset } = e
      this.isLoading = true
      this.fetchData(offset, this.maxItems)
    },
    async fetchData(offset = undefined, limit = undefined) {
      try {
        let archives = await BB.archives.get({
          flyer_type: this.flyerTypeValue,
          state: this.stateValue,
          dateRange: this.dateRange,
          brands: this.brandValue,
          offset: offset,
          limit: limit,
          searchQueries: this.searchQueries,
          sortOption: this.sortOption
        })

        if(archives.data.length === 0) {
          BB.Toastr.warning("Try refining your filters and try again.", "No Data Found")
        }
        
        //run report
        if(limit) {
          this.archives = archives
          this.totalItems = parseInt(this.archives.total)
          this.reportData = this.archives.data
        }
        //excel export
        else {
          archives.data.forEach(element => {
            element.created = new Date(element.created * 1000)
          })

          return archives.data
        }
      }
      catch (error) {
        console.error(error)
        BB.Toastr.error("Error retrieving data")
      }
      finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    isPagination: function () {
      return this.totalItems >= this.maxItems && !this.isFilterChange
    }
  }
}
</script>

<style lang="scss">
@import "../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";

.my-archive-multiselect-container {
  gap: 10px;
  margin-top: 10px;

  .multiselect-wrapper{
    position: relative;
    width: 245px;
    height: 60px;
  }

  .date-created-wrapper {
    width: 220px;
    height: 60px;

    .datepicker-range {
      min-height: 40px;
      padding: 4px 0px 0px 8px;
      background-color: white;
      border-radius: 5px;
      border: 1px solid #e8e8e8;
    }

    .datepicker-chevron {
      display: none;
    }

    .v2-date-wrap .v2-date-clear {
      right: 1px;
    }
  }

  .my-archive-label {
    margin-bottom: 5px;
  }

  .multiselect {
    position: absolute;
  }

  .multiselect__tags {
    padding-right: 25px;
  }
}

.my-archive-buttons-container {
  flex-wrap: wrap-reverse;
  margin: 10px 0px;
  gap: 10px;

  .warning-message {
    color: #ff9933;

    .fa-icon {
      margin: 0 5px;
    }
  }
}
</style>
  