/**
 * Brands API wrapper
 */
const ENDPOINT = "/api/v1/template-manager"

const bbTemplateManage = {
	get(params, callback = null) {
		return $.get(ENDPOINT+"?"+$.param( params ), function(data) {
			if (callback && typeof callback === "function") {
				callback.call(this, data)
			}
		})
	},
    add(templateData, callback) {
        if (!templateData.name) {
            console.log("template name missing")
            return new Promise((resolve, reject) => {
                reject("error")
            })
        }

        return $.ajax({
            url: ENDPOINT,
            type: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(templateData),
            success(data) {
                if (callback && typeof callback === "function") {
                    callback.call(this, data)
                }
            },
            error(xhr, ajaxOptions, thrownError) {
                if (callback && typeof callback === "function") {
                    callback.call(this, thrownError)
                }
            }
        })
    },
	update(templateData, callback) {
		if (!templateData.id) {
			console.log("template id missing")
			return new Promise((resolve, reject) => {
				reject("error")
			})
		}

		return $.ajax({
			url: ENDPOINT,
			type: "POST",
			contentType: "application/json",
			dataType: "json",
			data: JSON.stringify(templateData),
			success(data) {
				if (callback && typeof callback === "function") {
					callback.call(this, data)
				}
			},
			error(xhr, ajaxOptions, thrownError) {
				if (callback && typeof callback === "function") {
					callback.call(this, thrownError)
				}
			}
		})
	},
	remove(templateData, callback){
		if (!templateData.id) {
			console.log("template id missing")
			return new Promise((resolve, reject) => {
				reject("error")
			})
		}

		return $.ajax({
			url: ENDPOINT+"?id="+templateData.id,
			type: "DELETE",
			success(data) {
				if (callback && typeof callback === "function") {
					callback.call(this, data)
				}
			},
			error(xhr, ajaxOptions, thrownError) {
				if (callback && typeof callback === "function") {
					callback.call(this, thrownError)
				}
			}
		})
	}
}

export default bbTemplateManage
