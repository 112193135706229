<template>
  <div :class="['autoComplete', this.listClasses]">
    <div
      :class="[
        'autoComplete__input-container',
        inputContainerTag,
        inputContainerPaddLeft
      ]"
    >
      <icon name="tag" v-if="optionTag" />
      <InputText
        :isMaterial="this.isMaterial"
        :style="this.inputStyle"
        class="autoComplete__input"
        ref="search"
        :label="this.label"
        :name="this.name"
        v-model="text"
        :hideLabel="this.hideLabel"
        :placeholder="this.placeHolder"
        @keyupEnter="submit"
        @onFocus="focusInput"
        @onBlur="focusInput"
        @keyupDelete="clear"
        @keyupEsc="resetAll"
      />
    </div>
    <ul
      :class="[
        'autoComplete__list-container',
        this.floatList,
        this.isFluidList
      ]"
      v-if="showAutocomplete"
    >
      <li 
        :class="['autoComplete__list-options-title']"
        v-if="showListOptions && listOptions.length > 0"
      >
        <div>Search Options</div>
      </li>
      <li
        v-for="(item, index) in loadItems"
        :key="index"
        :class="[
          'autoComplete__list-item',
          `autoComplete__list-item--${item.icon}`
        ]"
        @click="selectedItem(item, index)"
      >
        <icon :name="item.icon" />
        <span 
          :class="[
            'autoComplete__list-text',
            index === optionIndex ? 'font-weight-bold' : ''
          ]"
        >
          {{ item.text }}
        </span>
      </li>
    </ul>
    <input type="text" ref="inputHidden" class="sr-only" />
    <BaseButton
      v-if="showSearchButton"
      type="button"
      theme="link"
      btnIcon="search"
      class="autoComplete__btn-search"
      @click="submit"
    >
      <span class="sr-only">Search</span>
    </BaseButton>
  </div>
</template>

<script>
import "vue-awesome/icons"
export default {
  name: "BaseAutocomplete",
  props: {
    listItem: { type: Array, required: true, default: () => [] },
    minLength: { type: Number, default: 2 },
    showListOptions: { type: Boolean, default: false },
    showSearchButton: { type: Boolean, default: false },
    listOptions: {
      type: Array,
      default: () => {
        return [
          {
            icon: "search",
            text: "Press Enter or click to search",
            type: "submit"
          },
          { icon: "tags", text: "Tags", type: "tags" }
        ]
      }
    },
    name: { type: String, required: true },
    hideLabel: { type: Boolean, default: false },
    placeHolder: { type: String },
    label: { type: String, default: "" },
    showInput: { type: Boolean, default: true },
    hidden: { type: Boolean, default: false },
    float: { type: Boolean, default: false },
    marBottom: { type: String, default: null },
    marTop: { type: String, default: null },
    paddLeft: { type: Boolean, default: false },
    hasTagSearch: { type: Boolean, default: false },
    isMaterial: { type: Boolean, default: true },
    fluidList: { type: Boolean, default: true }
  },
  data() {
    return {
      text: "",
      items: [],
      focused: false,
      optionTag: false,
      optionIndex: 0,
      onSelectOption: false
    }
  },
  created() {
    //this.loadItems()
  },

  watch: {
    text: function(oldValue, newValue) {
      if (this.text.length >= 2) {
        this.$emit("text", { isTag: this.optionTag, text: this.text })
      }

      if (this.text.length === 0) {
        this.$emit("emptyText")
      }
    },
    showInput: function(val) {
      if (val) this.$refs.search.setFocus()
    }
  },
  computed: {
    showAutocomplete() {
      if (this.showListOptions && this.focused) return true
    },
    inputContainerTag() {
      if (this.optionTag) {
        return "autoComplete__input-container--tag"
      }
    },
    inputContainerPaddLeft() {
      if (this.paddLeft) return "autoComplete__input-container--padding-left"
    },
    loadItems() {
      if (this.showListOptions && this.listOptions.length > 0) {
        return [...this.listOptions, ...this.listItem]
      } else {
        return this.listItem
      }
    },
    listClasses() {
      let className = ""
      if (this.hidden) className += "autoComplete--hidden"

      if (this.showInput) {
        className += " autoComplete--visible"
      }

      return className
    },
    floatList() {
      if (this.float) {
        return "autoComplete__list-container--float"
      }
    },
    isFluidList() {
      if (this.fluidList) {
        return "autoComplete__list-container--fluid"
      } else {
        return "autoComplete__list-container--no-fluid"
      }
    },
    inputStyle() {
      let style = {}

      if (this.marTop) style.marginTop = this.marTop

      if (this.marBottom) style.marginBottom = this.marBottom

      return style
    }
  },
  methods: {
    submit() {
      if (this.text.length >= 2) {
        this.$emit(
          "submit", 
          { 
            isTag: this.optionTag, 
            text: this.text, 
            option: this.listOptions[this.optionIndex] 
          })
        this.text = ""
        this.onSelectOption = false
        this.focused = false
        this.$refs.inputHidden.focus()
      }
      else {
        BB.Toastr.warning("Filter must be at least 2 characters.")
      }
    },
    // loadItems() {
    //     if(this.showListOptions && this.listOptions.length > 0) {
    //         this.items = [...this.listOptions, ...this.listItem]
    //     } else {
    //         this.items = this.listItem
    //     }
    // },
    selectedItem(item, index) {
      if(index < this.listOptions.length) {
        this.optionIndex = index
      }

      this.onSelectOption = true
      switch (item.type) {
        case "submit":
          this.submit()
          break
        case "tags":
          // it is in case option Tags is selected
          this.$emit("changePlaceholderByType", item.type)
          this.tagOption()
          break
        case "title":
          this.$emit("changePlaceholderByType", item.type)
          this.titleOption()
          break
        case "archive":
          this.$emit("changePlaceholderByType", item.text)
        default:
          this.text = ""
          this.$emit("selectedItem", item)
          break
      }
    },
    focusInput({ type }) {
      switch (type) {
        case "onFocus":
          this.focused = true
          break
        default:
          setTimeout(() => {
            if (this.onSelectOption) {
              this.$refs.search.setFocus()
              this.onSelectOption = false
              return false
            }

            this.focused = false
          }, 300)
          break
      }
    },
    tagOption() {
      this.text = ""
      this.optionTag = true
      this.focused = true
      this.$refs.search.setFocus()
    },
    titleOption() {
      this.text = ""
      this.optionTag = false
    },
    clear() {
      if (this.optionTag && this.text == "") {
        this.$emit("changePlaceholderByType", "")
        this.optionTag = false
      }
    },
    resetAll() {
      this.focused = false
      this.optionTag = false
      this.optionIndex = 0
      this.text = ""
      this.$refs.inputHidden.focus()
      this.$emit("changePlaceholderByType", "")
      this.$emit("keyEsc")
    }
  }
}
</script>

<style lang="scss" src="./BaseAutocomplete.scss"></style>
