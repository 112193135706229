<doc>
  Programs details Vue.js component
</doc>

<template>
  <div :class="`${className}${classSize}`">
    <div class="dropdown">
      <base-button
          class="program-toggle-btns d-lg-block dropdown-toggle"
          type="button"
          theme="link"
          v-on:click="toogleButtons"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          id="dropdownMenu2"
      >
        <icon name="ellipsis-v"></icon>
      </base-button>
      <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
        <li
            v-if="inOptionsList('integrations')"
            v-on:click="integrations"
        >
          <a style="cursor: pointer;">Embeds</a>
        </li>

        <li
            v-if="inOptionsList('masquerade')"
            v-on:click="masquerade"
        >
          <a style="cursor: pointer;">Masquerade</a>
        </li>

        <li
            v-if="inOptionsList('pricingLog')"
            v-on:click="pricingLog"
        >
          <a style="cursor: pointer;">Pricing Log</a>
        </li>

        <li
            v-if="inOptionsList('subscriptionLog')"
            v-on:click="subscriptionLog"
        >
          <a style="cursor: pointer;">Subscription Log</a>
        </li>

        <li
            v-if="inOptionsList('leadLog')"
            v-on:click="leadLog"
        >
          <a style="cursor: pointer;">Lead Log</a>
        </li>

        <li
            v-if="inOptionsList('obProfiles')"
            v-on:click="obProfiles"
        >
          <a style="cursor: pointer;">OB Profiles</a>
        </li>

        <li
            v-if="inOptionsList('exportMembers')"
        >
          <a style="cursor: pointer;" :href="'/lo-export.csv?brand_id='+itemId" target="_blank">Export LOs</a>
        </li>

       <li v-if="inItemList('edit')" v-on:click="showModal"
        >
          <a style="cursor: pointer;">Edit  </a>
        </li> 

        <li v-if="inItemList('delete')"
            v-on:click="deleteSetAction"
        >
          <a style="cursor: pointer;">Delete</a>
        </li>

		<li v-if="inOptionsList('signatureBlocks')" v-on:click="showSignatureModal"
        >
          <a style="cursor: pointer;">Signature Blocks  </a>
        </li>
		
        <li v-if="inOptionsList('setDefault')"
            v-on:click="setDefaultAction"
        >
          <a style="cursor: pointer;">{{ defaultDisplay }}</a>
        </li>
      </ul>
    </div>
    <ConfirmModal
      :showConfirm="showConfirm"
      :text="confirmTxt"
      v-on:modalConfirm="deleteSet"
      v-on:modalCancel="cancelConfirm"
    />
  </div>
</template>

<script>
export default {
  name: "SmartListItemOptions",
  props: {
    gridView: { type: Boolean, required: true },
    showEditItemModal: { type: Function, required: true },
    setDefault: { type: Function },
    cloneItem: { type: Function },
    removeItem: { type: Function, required: true },
    itemIndex: { type: Number, required: true },
    itemId: { type: String, required: true },
    pricing_status: { type: String },
    isLast: { type: Boolean, default: false },
    hasTogglePricing: { type: Boolean, default: false },
    onDragEnd: { type: Function },
    toggleMemberPricing: { type: Function },
    optionsList: { type: Array },
    itemList: { type: Array },
    hideOptions: {
      type: Array,
      default: () => {
        return ["masquerade", "integrations"]
      }
    },
    listType: { type: String },
   
  },
  data: function() {
    return {
      className: "program-buttons-wrapper",
      arrowClassName: "program-action d-inline-block d-lg-none",
      arrowDisabledClassName: " program-action-disabled",
      showConfirm: false,
      confirmTxt: "Do you really want to delete this item?",
      showActionBtn: false
    }
  },
  computed: {
    moveUpClassName: function() {
      let className = this.arrowClassName
      if (this.itemIndex === 0) {
        className += this.arrowDisabledClassName
      }
      return className
    },
    moveDownClassName: function() {
      let className = this.arrowClassName
      if (this.isLast) {
        className += this.arrowDisabledClassName
      }
      return className
    },
    classNameInner: function() {
      let className = "program-buttons"
      if (!this.gridView) {
        className += " list-view"
      }
      if (!this.inOptionsList("move")) {
        className += " is-not-move"
      }
      return className
    },
    classSize: function() {
      let className = ""
      if (this.$mq == "sm" || this.$mq == "md") {
        className += " not-desktop"
      }
      return className
    },
    droneMode(){
      return $("body").hasClass("droneMode")
    },
    defaultDisplay: function(){
      return (this.$store.state.user_is_org_manager == 1) ? 'Set as org default' : 'Set as my default';
    }
  },
  methods: {
    toogleButtons: function() {
      return
      this.className =
        this.className === "program-buttons-wrapper"
          ? `${this.className} open`
          : "program-buttons-wrapper"
    },
    pricingLog: function(){
        if(this.listType=="brands"){
            window.location = "/pricing-log?brand_id="+this.itemId
        }
        else{
            this.$store
                .dispatch("members/getMemberLocal", this.itemId)
                .then(member => {
                    window.location = "/pricing-log?loid="+member.uid
                    //debugger;
                })
        }
    },
    subscriptionLog: function(){
      if(this.listType=="brands"){
        window.location = "/subscription-log?brand_id="+this.itemId
      }
      else{
        this.$store
            .dispatch("members/getMemberLocal", this.itemId)
            .then(member => {
              window.location = "/subscription-log?loid="+member.uid
              //debugger;
            })
      }
    },
    leadLog: function(){
      if(this.listType=="brands"){
        window.location = "/lead-log?brand_id="+this.itemId
      }
      else{
        this.$store
            .dispatch("members/getMemberLocal", this.itemId)
            .then(member => {
              window.location = "/lead-log?loid="+member.uid
              //debugger;
            })
      }
    },
    obProfiles: function(){
      if(this.listType==="brands" && $('body').hasClass('bb-admin')){
        window.location = "/listing_flyer/manage-ob-profiles/"+this.itemId
      }
      else{
        window.location = "/pricing_profiles"
      }
      return null;
    },
    togglePricing: function() {
      const activate_pricing = this.pricing_status == "active"
      this.showActionBtn = false
      this.toggleMemberPricing({ loid: this.itemId })
      this.toogleButtons()
    },
    masquerade: function() {
      this.$store
        .dispatch("members/getMemberLocal", this.itemId)
        .then(member => {
          window.location = member.masq_link
          //debugger;
        })
    },
    integrations: function() {
      let url = "/integrations/" + this.itemId
      if (this.listType == "brands") url = "/brand-integrations/" + this.itemId
      var win = window.open(url, "_blank")
      win.focus()
    },
    clone: function() {
      this.cloneItem(this.itemId)
      this.toogleButtons()
    },
    showModal: function() {
      this.showEditItemModal(this.itemId)
      this.toogleButtons()
    },
	showSignatureModal: async function(){
      let signatures = await BB.brandSignatureManage.get(this.itemId)
      $('#brandSignatureModal .hd-brand-id').val(this.itemId)
      if(signatures) {
        $('#brandSignatureModal .hd-id').val(signatures.id)
        $('#brandSignatureModal .block1 textarea').val(signatures.block1)
        $('#brandSignatureModal .block2 textarea').val(signatures.block2)
        $('#brandSignatureModal .block3 textarea').val(signatures.block3)
      }

      $('#brandSignatureModal').modal('show')
    },
    cancelConfirm: function() {
      this.showConfirm = false
    },
    deleteSet: function() {
      this.cancelConfirm()
      this.removeItem({ id: this.itemId, index: this.itemIndex })
    },
    deleteSetAction: function() {
      this.toogleButtons()
      this.showConfirm = true
    },
    move: function(moveUp) {
      if ((this.itemIndex === 0 && moveUp) || (!moveUp && this.isLast)) {
        return
      }
      const dif = moveUp ? -1 : 1
      this.onDragEnd(this.itemIndex, this.itemIndex + dif)
    },
    inOptionsList: function(option) {
      if (this.hideOptions.indexOf(option) >= 0) {
        return false
      }
      if (!this.optionsList) {
        return true
      }
      var found = false
      this.optionsList.forEach(listItem => {
        if (listItem == option) {
          found = true
          return true
        }
      })
      return found
    },
    inItemList: function(option){
      if (!this.itemList) {
          return true
        }
        let found = false
        this.itemList.forEach(listItem => {
          if (listItem == option) {
            found = true
            return true
          }
        })
        return found
    },
    setDefaultAction: function(){
      this.setDefault(this.itemId);
      //console.log('Set Default');
    }
  },
  mounted() {
    this.showActionBtn =
      this.hasTogglePricing && this.pricing_status !== "active"

  }
}
</script>

<style lang="scss" src="./SmartListItemOptionsStyle.scss" scoped></style>
