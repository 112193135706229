var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.isLoading,
          expression: "!isLoading"
        }
      ],
      staticClass: "fts"
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "selected-tags" },
        _vm._l(_vm.selectedTags, function(tag) {
          return _c("span", { key: tag.id }, [
            _vm._v(_vm._s(tag.name)),
            _c(
              "a",
              {
                staticClass: "remove-tag",
                attrs: { href: "javascript:void" },
                on: {
                  click: function($event) {
                    _vm.removeTag(tag.id)
                  }
                }
              },
              [_vm._v("x")]
            )
          ])
        })
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mf-wrapper" },
        [
          _c("div", { staticClass: "tags-selctor" }, [
            _c(
              "ul",
              _vm._l(_vm.tags, function(tag) {
                return _c("li", { key: tag.id }, [
                  _c("label", { attrs: { for: "tag-" + tag.id } }, [
                    _c("input", {
                      staticClass: "chk-tag",
                      attrs: {
                        id: "tag-" + tag.id,
                        type: "checkbox",
                        "data-id": tag.id,
                        "data-name": tag.name
                      },
                      on: {
                        change: function($event) {
                          _vm.selectTag($event)
                        }
                      }
                    }),
                    _vm._v(
                      "\n            " +
                        _vm._s(tag.name) +
                        "(" +
                        _vm._s(tag.tcount) +
                        ")\n          "
                    )
                  ])
                ])
              })
            )
          ]),
          _vm._v(" "),
          _c(
            "grid-row",
            _vm._l(_vm.templates, function(template) {
              return _c(
                "grid-column",
                { key: template.id, class: "item-template " + template.show },
                [
                  _c("div", { staticClass: "template-names" }, [
                    _vm._v(
                      _vm._s(
                        template.alias == null ? template.name : template.alias
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: _vm.wrapperClassName(template.id),
                      on: {
                        click: function($event) {
                          _vm.setTemplate(template.id)
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "fts__thumb",
                        attrs: { src: template.preview_url }
                      }),
                      _vm._v(" "),
                      template.id === _vm.themeSelected
                        ? _c("icon", {
                            staticClass: "fts__check-icon",
                            attrs: { name: "check" }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "template-tags" },
                    _vm._l(template.tags, function(t) {
                      return _c("span", { key: t.id }, [_vm._v(_vm._s(t.name))])
                    })
                  )
                ]
              )
            })
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.swatches
        ? _c(
            "div",
            { staticClass: "fts__color-selector" },
            _vm._l(_vm.swatches, function(swatch, index) {
              return _c(
                "div",
                {
                  key: index,
                  class: _vm.swatchClassName(index),
                  on: {
                    click: function($event) {
                      _vm.setSwatch(index)
                    }
                  }
                },
                [
                  _c("span", {
                    style: "background-color: " + swatch.primaryColor
                  }),
                  _vm._v(" "),
                  _c("span", {
                    style:
                      "background-color: " +
                      (swatch.secondaryColor
                        ? swatch.secondaryColor
                        : swatch.primaryColor)
                  }),
                  _vm._v(" "),
                  index === _vm.swatchSelected
                    ? _c("icon", {
                        staticClass: "fts__check-icon is-small",
                        attrs: { name: "check" }
                      })
                    : _vm._e()
                ],
                1
              )
            })
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading
        ? _c("LoaderPlaceholder", { attrs: { type: "circle" } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tags-title" }, [
      _c("h2", [_vm._v("Tag Filter Options")])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-732ba61a", { render: render, staticRenderFns: staticRenderFns })
  }
}