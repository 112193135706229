import Vue from "vue"
import Vuex from "vuex"

import { createLogger } from "../../helpers"
import _merge from "lodash/merge"

const initStore = () => {
  Vue.use(Vuex)
  const debug = process.env.NODE_ENV !== "production"
  
  return new Vuex.Store({
    modules: {
      
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
  })
}
export default initStore
