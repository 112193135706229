<doc>
    Document Size Modal Vue.js component
  </doc>

<template>
    <transition-group
        name="modal"
        v-on:before-enter="beforeEnter"
        v-on:after-enter="afterEnter"
        v-on:after-leave="afterLeave"
    >
        <div 
            key="modal" 
            class="flyer-modal"
            tabindex="-1"
        >
            <div class="modal-dialog flyer-modal-dialog">
                <div class="modal-content flyer-modal-content">
                    <div class="modal-header flyer-modal-header">
                        <h4 class="modal-title flyer-modal-title">
                            {{title}}
                        </h4>
                        <button
                            class="close"
                            theme=""
                            type="button"
                            aria-label="Close"
                            v-on:click="cancel"
                            >
                            <span class="closeBtn" aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body flyer-modal-body modal-para">
						<div style="background-color: #eee; padding: 15px;">
							<input type="text" class="txt-document-size-name inptxt form-control" placeholder="Name" v-model="size_name" />
                            <input type="text" class="txt-document-width inptxt form-control" placeholder="Width" v-model="width" />
                            <input type="text" class="txt-document-height inptxt form-control" placeholder="Height" v-model="height" />
                            <button class="btn btn-modern" @click="save">
                                Save
                            </button>
						</div>

                        <div class="lists-wrapper">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <td>Name</td>
                                        <td>Width</td>
                                        <td>Height</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in list" :key="item.id">
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.width }}</td>
                                        <td>{{ item.height }}</td>
                                        <td><button @click="edit(item.id)">Edit</button></td>
                                        <td><button @click="remove(item.id)">Delete</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer flyer-modal-footer">
                        
                    </div>
                </div>
            </div>
        </div>
        <div key="backdrop" class="modal-backdrop in" v-if="isVisible"></div>
    </transition-group>
</template>

<script>
import _find from "lodash/find"
import { createNamespacedHelpers } from "vuex"

  
const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
"documentSizeModal"
)

export default {
    name: "FlyerTemplateDocumentSizeModal",
    components: {  },
    data() {
        return {
            isVisible: true,
            list:[],
            size_name:"",
            width:"",
            height:"",
            size_id:"",
            document_size: null
        }
    },
    props: {
        title: { type: String }
    },
    mounted(){
        this.isVisible = true;
    },
    async created() {
        console.log('created flyer template tags modal');
        let that = this
        $.ajax({
                type: 'GET',
                url: '/api/v1/template-manager-document-size',
                contentType: "application/json",
                dataType: "json",
                success: function (response) {
                    console.log(response);
                    // Display errors.
                    if (response.status != 'error') {
                        // that.active_tags = response.active_tags;
                        // that.inactive_tags = response.inactive_tags;
                        //that.set_order_tags(response)
                        that.list = response
                    }
                    else{

                    }
                },
                error: function (xmlhttp) {
                    
                }
            });


    },
    methods: Object.assign(
    {
        cancel() {
            this.$emit("hideDocumentSizeModal")
            this.isVisible = false;
        },
    },
    {
        async save(){
            this.document_size={
                id:this.size_id,
                name:this.size_name,
                width: this.width,
                height: this.height
            }
            console.log(this.document_size);
            let that = this
            $.ajax({
                type: 'POST',
                url: '/api/v1/template-manager-document-size',
                data: JSON.stringify(this.document_size),
                contentType: "application/json",
                dataType: "json",
                success: function (response) {
                    console.log(response);
                    // Display errors.

                    if (response.status === 'success') {
                        //that.$emit("updateTemplatePreview", {template_id:that.templateId, preview_url: that.fileUrl} )
                        //that.cancel()
                        if(that.size_id){//update
                            that.list.map(function(item){
                                if(that.size_id == item.id){
                                    item.name = that.size_name
                                    item.width = that.width
                                    item.height = that.height
                                }
                                return item;
                            })
                        }
                        else{
                            that.document_size.id = response.data
                            that.list.push(that.document_size)
                        }
                        that.$emit("updateDocumentSizes", that.list )
                        that.clear()
                        
                        BB.Toastr.success('Upload Successful');
                    }
                    else{
                        BB.Toastr.error('Something went wrong');
                    }
                },
                error: function (xmlhttp) {
                    BB.Toastr.error('Something went wrong!');
                }
            });
            
        },
        edit(id){
            const data = _find(this.list, ["id", id])
            this.size_id = id
            this.size_name = data.name
            this.width = data.width
            this.height = data.height
        },
        remove(id){
            let that = this
            $.ajax({
                type: 'POST',
                url: '/api/v1/template-manager-document-size',
                data: JSON.stringify({id:id,status:"0"}),
                contentType: "application/json",
                dataType: "json",
                success: function (response) {
                    console.log(response);
                    // Display errors.

                    if (response.status === 'success') {
                        //that.$emit("updateTemplatePreview", {template_id:that.templateId, preview_url: that.fileUrl} )
                        //that.cancel()
                        
                        var filtered = that.list.filter(function(item){
                            return id != item.id
                                                                
                        })                            
                        that.list = filtered
                        that.$emit("updateDocumentSizes", filtered )
                            
                        BB.Toastr.success('Upload Successful');
                    }
                    else{
                        BB.Toastr.error('Something went wrong');
                    }
                },
                error: function (xmlhttp) {
                    BB.Toastr.error('Something went wrong!');
                }
            });
        },
        clear(){
            this.size_id = ""
            this.size_name = ""
            this.width = ""
            this.height = ""
        },
        beforeEnter() {
            this.$emit("beforeEnter")
            document.body.classList.add("modal-open")
        },
        afterEnter() {
            this.$emit("afterEnter")
        },
        afterLeave() {
            document.body.classList.remove("modal-open")
        },
    }
    ),
    watch: {
        
    }
}
</script>
<style lang="scss">
.inptxt.form-control{
    width: 28%;
    display: inline-block;
}
</style>