/**
 * Brand Signatures API wrapper
 */
const ENDPOINT = "/api/v1/brand-signature"

const bbBrandSignatureManage = {
	get(id = null, callback = null) {
		var params = {}
		if (id) {
			params = { brand_id: id }
		}
		return $.get(ENDPOINT, params, function(data) {
			if (callback && typeof callback === "function") {
				callback.call(this, data)
			}
		})
	},
    add(brandData, callback) {
        if (!brandData.brand_id) {
            console.log("brand name missing")
            return new Promise((resolve, reject) => {
                reject("error")
            })
        }

        return $.ajax({
            url: ENDPOINT,
            type: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(brandData),
            success(data) {
                if (callback && typeof callback === "function") {
                    callback.call(this, data)
                }
            },
            error(xhr, ajaxOptions, thrownError) {
                if (callback && typeof callback === "function") {
                    callback.call(this, thrownError)
                }
            }
        })
    },
	update(brandData, callback) {
		if (!brandData.id) {
			console.log("brand id missing")
			return new Promise((resolve, reject) => {
				reject("error")
			})
		}
console.log(brandData);
console.log(JSON.stringify(brandData));
		return $.ajax({
			url: ENDPOINT,
			type: "POST",
			contentType: "application/json",
			dataType: "json",
			data: JSON.stringify(brandData),
			success(data) {
				if (callback && typeof callback === "function") {
					callback.call(this, data)
				}
			},
			error(xhr, ajaxOptions, thrownError) {
				if (callback && typeof callback === "function") {
					callback.call(this, thrownError)
				}
			}
		})
	}
}

export default bbBrandSignatureManage
