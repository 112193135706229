import Vue from "vue"
import _orderBy from "lodash/orderBy"
import initStore from "./store/bb-impersonate"
import { Impersonate } from "./pages"

export default function() {
  if (!document.getElementById("vue-my-impersonate-app")) {
    return
  }
  const store = initStore()
  // initial items
  //store.dispatch("staticFlyers/initStaticFlyers", { bank_id, isBankAdmin, uid })
  //store.dispatch("manualListing/initManualListing", { bank_id, isBankAdmin, uid })
  // Vuejs setup
  new Vue({
    el: "#vue-my-impersonate-app",
    store,
    components: {
        Impersonate
    }
  })
}