<doc>
  Static Flyer details Vue.js component
</doc>

<template>
  <div :class="className">
    <div class="program-details-img">
      <img
        :src="details.pictures[0]"
        :alt="`${item.street_addr} flyer thumb`"
      />
      <div class="program-overlay">
        <BaseButton outline class="btn-view-program" v-on:click="showPrintModal"
          >Print Flyer</BaseButton
        >
        <SmartListItemOptions
          :gridView="gridView"
          :itemIndex="itemIndex"
          :itemId="item.id"
          :onDragEnd="onDragEnd"
          :isLast="isLast"
          :showEditItemModal="showEditItemModal"
          :options-list="optList"
          :removeItem="removeItem"
        />
      </div>
    </div>
    <div :class="['program-details', this.programDetailsStyle]">
      <div class="program-details-title mb-3">
        <h4>{{ item.street_addr }}</h4>
        <div class="d-flex">
          <p>${{ new Intl.NumberFormat("en-EN").format(details.listing_price) }}</p>
          <p>MLSID: {{ details.mls }}</p>
        </div>
        <p>{{ details.realtorData && details.realtorData.label }}</p>
      </div>
      <ManageBadge toggleable :items="tagsItem" showIcon isTag :limit="3" />
	  <span class="manual-share">
        <div class="dropup">
          <a class="btn btn-small-white glyphicon glyphicon-share dropdown-toggle" type="button" id="dropdownMenuShare" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><div class="loader vertical-align-middle loader-circle hide" data-type="default" style="top: 2px; font-size: 14px; display: none;"></div><span>Share</span>
          </a>
          <ul class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuShare">
            <li><a role="button" data-toggle="modal" data-target="#social_preview_modal" v-on:click="shareListing(item.id,item.owner,'facebook')">Share on Facebook</a></li>
            <li><a role="button" data-toggle="modal" data-target="#social_preview_modal" v-on:click="shareListing(item.id,item.owner,'linkedin')">Share on Linkedin</a></li>
          </ul>
        </div>
      </span>
      <span class="agentconnect-extra-tools">
        <div class="dropup">
          <a class="btn btn-small-white glyphicon glyphicon-option-vertical dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          </a>
          <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
            <li><a role="button" v-on:click="copyListingUrl(`/listing?manual_listing_id=${item.id}&lo=${item.owner}`)">Copy Listing Url</a></li>
            <li><a :href="`/listing?manual_listing_id=${item.id}&lo=${item.owner}`" target="_blank">View Listing</a></li>
          </ul>
        </div>
      </span>
    </div>
	<div id="social_preview_modal" class="modal jt-modal fade" role="dialog">
      <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title"><span :class="`social-square-logo ${share_for}`"></span>Preview Share</h4>
            <span class="pull-right closeBtn" data-dismiss="modal" aria-hidden="true">&times;</span>
          </div>

          <div class="modal-body">
            <div class="row">
                <div class="col-lg-12" style="margin-bottom: 20px;">
                    <p>Below is the share image that you can post to your <span class="share-type"></span> newsfeed.</p>
                </div>
            </div>

            <div class="row share-wrapper">
				<div class="inner-loading" style="text-align: center;display:block;"><div class="loader vertical-align-middle loader-circle" data-type="default"></div></div>
                <div class="col-lg-12 image-wrapper">
                    <img class="share-preview" style="width:100%;" :src="download_url">
                </div>
                <div class="col-lg-12">
                    <p class="share-desc"></p>
                </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" data-dismiss="modal" class="btn btn-modern btn-flat social-cancel">Close</button>
            <a :href="download_url" type="button" class="btn btn-modern btn-flat downloadShareImg" download="Social Share">Download</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import SmartListItemOptions from "../SmartList/SmartListItemOptions"
import ManageBadge from "../ManageBadge"

const { mapState, mapActions } = createNamespacedHelpers("manualListing")

import _ from "lodash"
export default {
  name: "ManualListingDetails",
  components: {
    SmartListItemOptions,
    ManageBadge
  },
  props: {
    item: { type: Object, required: true },
    itemIndex: { type: Number, required: true },
    isLast: { type: Boolean, default: false },
    onDragEnd: { type: Function },
    displayFlex: { type: Boolean, default: false }
  },
  data:function(){
    return {
      share_for:'',
      download_url:'#',
	  is_loading: true
    }
  },
  computed: Object.assign({}, mapState(["gridView"]), {
    details: function() {
      return typeof this.item.data === "object"
        ? this.item.data
        : JSON.parse(this.item.data)
    },
    className: function() {
      let className = "program"
      if (!this.gridView) {
        className += " list-view d-flex"
      }
      if (this.$mq == "sm" || this.$mq == "md") {
        className += " not-desktop"
      }
      return className
    },
    programDetailsStyle: function() {
      if (this.displayFlex) {
        return "program-details--flex"
      }
    },
    imgSrc: function() {
      let src = [
        "/sites/all/themes/bankingbridge_ui/images/programs",
        "-mini",
        ".png"
      ]
      if (this.gridView) {
        src.splice(1, 1)
      }
      return src.join("")
    },
    isDefault: function() {
      if (!this.item || !this.$store.state.default_set) return false
      return this.item.id == this.$store.state.default_set.id
    },
    optList: function() {
      return ["none"]
    },
    tagsItem: function() {
      if (this.item.hasOwnProperty("tags")) {
        if (Array.isArray(this.item.tags)) {
          return this.item.tags
        } else {
          return []
        }
      } else {
        return []
      }
    }
  }),
  methods: Object.assign(
    {
      copyListingUrl: function copyListingUrl(url) {
        var copyText = window.location.origin + url;
        navigator.clipboard.writeText(copyText);
      },
	  shareListing: async  function(manual_listing_id, owner, sharefor){
		  $('.inner-loading').css('display', 'block');
		  $('.image-wrapper').css('display', 'none');
		  $('.share-preview').attr('src', '#');
		  $('.downloadShareImg').attr('href', '#');
		  
		this.share_for= sharefor
		$('.social-square-logo').removeClass('facebook').removeClass('linkedin').addClass(sharefor);
        let data = await BB.realtorManage.manual.share_flyer('manual_listing_flyer/share_img_callback/'+sharefor+'/'+manual_listing_id);
        this.download_url=data.image_url
		this.is_loading = false
		$('.downloadShareImg').attr('href', data.image_url);
		$('.share-preview').attr('src', data.image_url)		
	    $('.inner-loading').css('display', 'none');
		$('.image-wrapper').css('display', 'block');  
      }
    },
    mapActions([
      "showEditItemModal",
      "showPrintManualListingModal",
      "removeManualListing"
    ]),
    {
      showPrintModal() {
        this.showPrintManualListingModal(this.item.id)
      },
      removeItem() {
        this.removeManualListing({ id: this.item.id, index: this.itemIndex })
      }
    }
  )
}
</script>

<style lang="scss" src="./ManualListingDetailsStyles.scss" scoped></style>
<style>
	#social_preview_modal img.share-preview {
		height: auto;
	}
	.program-details .manual-share {
		position: absolute;
		left: 10px;
		bottom: 10px;	
	}
</style>
