<doc>
    My Archive Excel Export Vue.js component
</doc>
  
<template>
    <DownloadExcel 
        :fetch="fetchData"
        :fields="dataFields"
        name="my-archive"
    >
        <BaseButton>Excel Export</BaseButton>
    </DownloadExcel>
</template>
  
<script>

import BaseButton  from "../BaseButton"
import DownloadExcel from "vue-json-excel"
  
export default {
    name: "MyArchiveExcelExport",
    components: {
        BaseButton,
        DownloadExcel
    },
    props: {
        fetchData: { type: Function },
    },
    data: function() {
        return {
            dataFields: {
                User: "username",
                Brand: "brand_name",
                "Date Created": "created",
                "Flyer Type": "flyer_type",
				"Download Link":"download_link",
                "Flyer Name": "flyer_name",
                Address: "address",
                City: "city",
                State: "state",
                "Zip Code": "zip_code",
                "Realtor Name": "realtor_name",
                "Realtor Email": "realtor_email" 
            }
        }
    }
}
</script>

<style lang="scss" scoped></style>
  