import Vue from "vue";

var baseRoute = "/sites/all/themes/bankingbridge_ui/"
// var baseRoute = "/sites/dev.bankingbridge.com/themes/bankingbridge-ui/";
var bbHost = location.protocol + "//" + location.host + "/"
import {ListingPricingSetModal} from "./components/ListingPricingSetTool";
import {IntegrationSettingsModal} from "./pages";
const gMapsKey = "AIzaSyD7iMMl6caMKICxEgDqKCr7HvHObGygYNo"
var Mustache = require("mustache")
var Flickity = require("flickity")
var FliNav = require("flickity-as-nav-for")
var Toastr = require("toastr")
var debounce = require("lodash/debounce")
var bbDeepLinking = require("./bb-deep-linking")

var view_type, basePath, viewRender, parsed, receivedObject, apiURL, appendBool, addressParams, map_results, needsUpdate

var iconPath = `${baseRoute}images/location-icon.png`
var iconPathHover = `${baseRoute}images/location-icon-hover.png`

const min_last_seen=(+ new Date() - 2*24*3600*1000)
// Render Listings
var bbListings = {
  spinner: {
    on: function() {
      $(".listings-views .loader:not(.tt-loading)").show()
    },
    off: function() {
      $(".listings-views .loader:not(.tt-loading)").hide()
    }
  },
  init: function() {
    // var viewRender, parsed, receivedObject, apiURL;
    // Search && filters deeplinking initialization
    bbDeepLinking.init(Views)
  },
  // callback : function(){},
  renderListings: function(ext_elements, callback) {
    receivedObject = ext_elements
    apiURL = receivedObject.service
    // TODO: get the real endpoint
    apiURL = apiURL.replace("bb-search", "listings")
    bbListings.spinner.on()
    appendBool = false
    addressParams = getParamArray($.address)
    //if(addressParams['offset']>0){
    if (apiURL.indexOf("offset") > -1 && apiURL.indexOf("offset=0") == -1) {
      appendBool = true
    }
    $.get(apiURL, function(data) {
      var processParams = {
        data: data,
        append: appendBool
      }
      bbListings.processData(processParams)
      bbListings.spinner.off()
      if (callback) {
        callback(data)
      }
    })
  },
  processData: function(processParams) {
    var listings = ( processParams.data.listings ? processParams.data.listings : [] ),
      mTemplate

      if(listings && listings[0])listings=convertMongoListings(listings)
    // Set total number for mobile
    let totalTplm = $("#search-mobile_view").data("tpl"),
      hasDesktopPager = $("#properties-pager").length,
      totalTpld
    if (hasDesktopPager && view_type != "map") {
      // And for desktop
      totalTpld = $("#properties-pager").data("tpl")
    }

    // show no result message if there is nothing to show
    if (processParams.data == "empty" || !processParams.data.results.total) {
      mTemplate = require("./tpl/no-results.tpl.html")
      $(receivedObject.targets[view_type]).before(mTemplate)
      $(".content-tools").addClass("hide")

      totalTplm = Mustache.render(totalTplm, { numResults: 0 })
      $("#search-mobile_view").text(totalTplm)
      if (hasDesktopPager && view_type != "map") {
        totalTpld = Mustache.render(totalTpld, {
          items: 0,
          numResults: 0
        })
        $("#properties-pager")
          .text(totalTpld)
          .removeClass("hide")
      }
      return
    }

    totalTplm = Mustache.render(totalTplm, { numResults: processParams.data.results.total })
    $("#search-mobile_view").text(totalTplm)

    $(".content-tools").removeClass("hide")

    bbListings.spinner.on()

    $.each(receivedObject.targets, function(n, t) {
      let name = n,
        target = t,
        tplPrefix = basePath == "/bb-search" ? "bb-search" : "my-listings"

      mTemplate = require("./tpl/" + tplPrefix + "-" + name + "-element.html")
      var age = {
        isNew: true,
        createdDate: function() {
          var t = new Date(convertEpoch(this.created)) // Epoch
          var datePart1 = t.toLocaleDateString("en-US", { timeZone: "America/New_York" })
          var datePart2 = t.toLocaleTimeString([], {
            timeZone: "America/New_York",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false
          })
          return "<span>" + datePart1 + "</span>, " + "<span>" + datePart2 + "</span>"
        }
      }
      var isRealtor = this === "#my-listings-grid ul";

      parsed = mTemplate({
        listing: listings,
        cdate: function() {
          if (this.last_seen < min_last_seen) {
            var t = new Date(convertEpoch(this.last_seen)) // Epoch
          } else {
            var t = new Date(convertEpoch(this.created)) // Epoch
          }
          var datePart1 = t.toLocaleDateString("en-US", { timeZone: "America/New_York" })
          var datePart2 = t.toLocaleTimeString([], {
            timeZone: "America/New_York",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false
          })
          return "<span>" + datePart1 + "</span>, " + "<span>" + datePart2 + "</span>"
        },
        ctime: age.createdTime,
        formattedPrice: function() {
          return parseInt(this.listing_price).toLocaleString()
        },
        baseURL: baseRoute,
        daysAgo: function() {
          let msPerDay = 86400000,
            elapsed = new Date() - new Date(convertEpoch(this.created)),
            days = Math.round(elapsed / msPerDay)
          if (days > 1) {
            days += " days ago"
          } else if (days) {
            days += " day ago"
          } else {
            days = " today"
          }
          return days
        },
        getHalfbaths: function() {
          if (this.num_halfbaths && this.num_halfbaths.length) {
            return this.num_halfbaths
          }
          return 0
        },
        isClosed: function() {
          if (this.last_seen < min_last_seen) {
            return true
          }
          return false
        },
        isNew: function() {
          var currentTime = Math.floor(Date.now() / 1000)
          var limit = currentTime - 5 * 24 * 60 * 60
          if (convertEpoch(this.created) > convertEpoch(limit)) {
            return true
          }
          return false
        },
        securePictures: function(){
          return this.pictures.map(picture=>{
            if(!picture)return "";
            if(!picture.path)return "";
            return {
              timestamp: picture.timestamp,
              url: ( $(".securePicUrl").data("path") ? $(".securePicUrl").data("path") : "" )+"?id="+picture.path.replace("http://","").split("/")[1]
            }
          })
        },
        isRealtor: isRealtor,
      })
      viewRender = Mustache.render(parsed)
      bbListings.drawTemplate(viewRender, target, processParams.append)
    })
    if (hasDesktopPager && view_type != "map") {
      totalTpld = Mustache.render(totalTpld, {
        items: $(".bb_listing_element").length,
        numResults: processParams.data.results.total
      })
      $("#properties-pager")
        .text(totalTpld)
        .removeClass("hide")
    }
    bbListings.spinner.off()
  },
  drawTemplate: function(viewRender, target, appendBool) {
    if (appendBool) {
      $(target).append(viewRender)
    } else {
      $(target).html(viewRender)
    }
    $(target)
      .add(".view." + view_type)
      .show()
  }
}

var vueApp=null
const initVueApp = () => {
  vueApp = new Vue({
    el: "#vue-listing-pricing-set-tool",
    components: {
      ListingPricingSetModal,
    },
    methods:{}
  })
}

var scrollLoad = false
var lastView = null
var Views = {
  clearActive: function() {
    $(".listings-views .view").hide()
    $("#properties-pager").addClass("hide")
  },
  render: function(v_type, parameters, update) {
    initVueApp()
    const loadMoreBtn=$('.loadMoreBtn');
    view_type = v_type
    basePath = $(".deep_link_base_path").data("base_path")
    var qStr = "",
      targetId = "#" + basePath.replace("/", ""),
      renderParams

    qStr = $.address.queryString() !== "" ? "?" + $.address.queryString() : ""

    if (view_type === "map" && lastView !== "map") {
      Views.clearActive()
    }

    lastView = view_type
    switch (view_type) {
      case "grid":
        if (map_results) {
          google.maps.event.trigger(map_results, "clear_data")
        }
      case "list":
        loadMoreBtn.removeClass('hide');
        if (map_results) {
          google.maps.event.trigger(map_results, "clear_data")
        }
        Views.clearActive()
        renderParams = {
          targets: {},
          service: basePath + ".json" + qStr
        }
        renderParams.targets[view_type] = targetId + "-" + view_type + " ul";
        loadMoreBtn.addClass('hide');
        bbListings.renderListings(renderParams, function(data) {
          if(data.listings[0] && data.listings[0]._id) {
            data.listings=convertMongoListings(data.listings);
          }
          loadMoreBtn.removeClass('hide');
          loadFlickity(data)
          initListingElement()
          scrollLoad = true
        })
        var loadMore=function() {
            // check to see if the nid tag is active
            if($(".filter-tags .bb-tag-filter-nid").length>0){
              loadMoreBtn.addClass('hide');
              return;
            }
            if (
                (
                    $(targetId + "-" + view_type + " > ul").is(":visible") &&
                    scrollLoad &&
                    $(window).scrollTop() == $(document).height() - $(window).height() &&
                    $("li.bb_listing_element").length > 0
                ) ||
                $(this).hasClass('loadMoreBtn')
            ) {
                scrollLoad = false
                qStr = $.address.queryString() !== "" ? "?" + $.address.queryString() : ""

                let offset = $("li.bb_listing_element").length

                $(".bb_new_element").removeClass("bb_new_element")

                let offsetStr = "?offset=" + offset
                if (qStr.length) {
                    offsetStr = "&offset=" + offset
                }
                renderParams.targets[view_type] = targetId + "-" + view_type + " > ul"
                renderParams.service = basePath + ".json" + qStr + offsetStr
                loadMoreBtn.addClass('hide');
                bbListings.renderListings(renderParams, function(data) {
                    if(data.listings && data.listings[0] && data.listings[0]._id) {
                      data.listings=convertMongoListings(data.listings)
                    }
                    if (data.results.count == 0) {
                        loadMoreBtn.addClass('hide');
                        scrollLoad = false
                        Toastr.warning("No more listings!")
                    } else {
                        loadMoreBtn.removeClass('hide');
                        loadFlickity(data)
                        initListingElement()
                        scrollLoad = true
                    }
                })
            }
        };
        $(window).scroll(loadMore);
        loadMoreBtn.click(loadMore);
        break
      case "table":
        if (map_results) {
          google.maps.event.trigger(map_results, "clear_data")
        }
        Views.clearActive()
        var limit = 50
        var limitStr = "?limit=" + limit
        if (qStr.length) {
          limitStr = "&limit=" + limit
        }
        renderParams = {
          targets: { table: targetId + "-table" },
          service: basePath + ".json" + qStr + limitStr
        }
        bbListings.renderListings(renderParams, function(data) {
          initListingElement()
        })
        break
      /*case 'list':
              Views.clearActive();
              renderParams = {
                  targets :{list: targetId+"-list"},
                  service: basePath+".json"+qStr
              };
              bbListings.renderListings(renderParams);
          break;*/
      case "map":
        if (map_results) {
          google.maps.event.trigger(map_results, "refresh_data")
          $(".view.map").show()
          return
        }
        // console.log('MAP RENDER');

        var lat = parameters.lat || null
        var lng = parameters.lng || null
        var zoom = parameters.zoom || 5
        var center = lat && lng ? { lat: lat, lng: lng } : null

        var mapInterval = setInterval(function() {
          if (typeof google === "object" && typeof google.maps === "object") {
            bbListings.spinner.off()
            myListingsMap(center, zoom)
            clearInterval(mapInterval)
            $(".view.map").show()
          }
        }, 500)
        break
      default:
        if (map_results) {
          google.maps.event.trigger(map_results, "clear_data")
        }
        console.log("Default behavior. The view_type " + view_type + " didn't match")
    }
  }
}

var getParamArray = function(address) {
  var paramNames = address.parameterNames()
  var currentParams = []
  for (var i = 0; i < paramNames.length; i++) {
    currentParams[i] = {
      name: paramNames[i],
      value: address.parameter(paramNames[i])
    }
  }
  return currentParams
}

var loadFlickity = function(data) {
  $.each(data.listings, function(n, t) {
    if (t.pictures) {
      let prevNextButtons = basePath === "/bb-search",
        flkty = new Flickity("div.carousel.carousel-main.listing-carousel-" + t.id, {
          pageDots: false,
          prevNextButtons: prevNextButtons
        })
      if ($("div.carousel.carousel-nav.listing-carousel-nav-" + t.id).length) {
        let flktyNav = new Flickity("div.carousel.carousel-nav.listing-carousel-nav-" + t.id, {
          asNavFor: ".listing-carousel-" + t.id,
          contain: true,
          pageDots: false
        })
      }
    }
  })
}

var initListingElement = function() {
  $(".bb_new_element")
    .on("click", " .bb_coords_update", function() {
      var url = $(this).data("bb_url")
      $.get(url, function(data) {
        Toastr.success("Listing Updated!")
      })
    })
    .on("change", ".bbListingCheckbox", function(e) {
      var listings = getSelectedListings(),
        $parent = $(this).closest(".bb_listing_element")
      if (listings.length > 0) {
        $(".bb_fab").show()
        $parent.addClass("bbActive")
      } else {
        $(".bb_fab").hide()
        $(".bb_fab #menu-open").prop("checked", false)
        $parent.removeClass("bbActive")
      }

      if (!$(this).prop("checked")) {
        $parent.removeClass("bbActive")
      }
    })
    .on("click", ".bb_listing_edit", async function() {
      //let listing=await BB.listing.get({"_id":$(this).data("nid")});
      //listing=listing[0]
      $("#editModal").data("mls_sc", $(this).data("mls_sc"))
      $("#editModal").data("mls_id", $(this).data("mls_id"))
      $("#editModal").data("nid", $(this).data("nid"))

      //$("#vueIntegrationModal").data("id", $(this).data("id"))
      // vueApp.$options.components.ListingPricingSetModal.methods.openModal({listing_id: $(this).data("nid"), listing_type: "homescout"})
      vueApp.$refs.listingPricingSetModal.openModal({listing_id: $(this).data("nid"), zipcode: $(this).data("zipcode"), list_price: parseInt($(this).data("listing_price"))})

    })
    .on("click", ".bb_manage_guest", function(e) {
      e.preventDefault()
      $("#manageRealtorGuest").modal("show")
      var listing_id = $(this).data("listing_id")
      $(".bb-select-guest-form").data("listing_id", listing_id)
      $(".saveRealtorGuest").data("listing_id", listing_id)
      var agencyid = $(this).data("agency_id")
      var mls_sc = $(this).data("mls_shortcode")
      var bb_sid = $(".bb-sid-embed").data("bb_sid")
      $("#manageRealtorGuest .loader").show()
      $(".saveRealtorGuest").show()
      //$('.bb-select-guest-form').hide();
      $(".select-guest-wrapper").html("")
      $(".guest-details-wrapper").html("")
      const guestFormTpl = $("#manage-guest-select").html()
      $.get("/guest-record-manage.json?listing_id=" + listing_id, function(data) {
        const guestFormTpl = $("#manage-guest-select").html()
        $("#manageRealtorGuest .loader").hide()
        if (data.realtor_info && data.guest_record) {
          const guestDetailsTpl = $("#guest-details-template").html()
          const rendered = Mustache.render(guestDetailsTpl, data.realtor_info)
          $(".guest-details-wrapper").html(rendered)
          //$('.bb-select-guest-form').hide();
          $(".guest-details").show()
          $(".clearRealtorGuest").data("record_id", data.guest_record.id)
          $(".clearRealtorGuest").data("listing_id", data.guest_record.listing_id)
          $(".clearRealtorGuest").click(function(e) {
            var listing_id = $(".clearRealtorGuest").data("listing_id")
            var url = "/guest-record-manage.json?id=" + $(".clearRealtorGuest").data("record_id")
            $.ajax({
              url: url,
              type: "DELETE",
              contentType: "application/json",
              dataType: "json",
              success: function(data) {
                //On ajax success do this
                //$('.bb-select-guest-form')[0].reset();
                const guestFormTpl = $("#manage-guest-select").html()
                $.get("/guest-record-manage.json?listing_id=" + listing_id, function(data) {
                  const guestFormTpl = $("#manage-guest-select").html()
                  $("#manageRealtorGuest .loader").hide()
                  if (data.available_guests && data.available_guests.length) {
                    const rendered = Mustache.render(guestFormTpl, data)
                    $("#manageRealtorGuest .modal-body .select-guest-wrapper").html(rendered)
                    $(".bb-select-guest-form select").change(function(value) {
                      //console.log($(this).val());
                      $(".saveRealtorGuest").data("guest_id", $(this).val())
                      //$(".saveRealtorGuest").data('listing_id',$('.bb-select-guest-form').data('listing_id'));
                      $(".saveRealtorGuest").data("owner", $("body").data("user_id"))
                      $(".saveRealtorGuest").data("agency_id", $(".bb-data-embed").data("group_id"))
                    })
                    //console.log(rendered);
                  } else {
                    $("#manageRealtorGuest .modal-body").html("<p>No guest realtors available for this listing.</p>")
                    $(".saveRealtorGuest").hide()
                  }
                })
                $(".guest-details").hide()
                $("#" + listing_id + " .bb-has-guest").addClass("hide")
              },
              error: function(xhr, ajaxOptions, thrownError) {
                //On error do this
                if (xhr.status == 200) {
                  //alert(ajaxOptions);
                } else {
                  //                    alert(xhr.status);
                  //                    alert(thrownError);
                }
              }
            })
          })
        } else {
          if (data.available_guests && data.available_guests.length) {
            const rendered = Mustache.render(guestFormTpl, data)
            $("#manageRealtorGuest .modal-body .select-guest-wrapper").html(rendered)
            $(".bb-select-guest-form select").change(function(value) {
              //console.log($(this).val());
              $(".saveRealtorGuest").data("guest_id", $(this).val())
              //$(".saveRealtorGuest").data('listing_id',$('.bb-select-guest-form').data('listing_id'));
              $(".saveRealtorGuest").data("owner", $("body").data("user_id"))
              $(".saveRealtorGuest").data("agency_id", $(".bb-data-embed").data("group_id"))
            })
            //console.log(rendered);
          } else {
            $("#manageRealtorGuest .modal-body").html("<p>No guest realtors available for this listing.</p>")
            $(".saveRealtorGuest").hide()
          }

          //$('.guest-details').hide();
          //$('.bb-select-guest-form').show();
        }
        //console.log(data);
      })
    })
    .on("click", ".bb_refresh_listing", async function(e) {
        e.preventDefault();
      BB.Toastr.options={
          timeOut: 0,
          extendedTimeOut: 0,
      }
      BB.Toastr.info("Refreshing Listing...")
      await BB.listing.refresh({
          mls_sc: $(this).data("mls_sc"),
          mls_id: $(this).data("mls_id"),
          state: $(this).data("state"),
      })
      BB.Toastr.clear()
      BB.Toastr.options={};
    })
	.on("click", ".share-with-email", function(e) {
      e.preventDefault();
    $(this).initEmailShare()
    })
    .on("click", ".facebook-square", function(e) {
        e.preventDefault();
      $(this).initFbShare()
    })
    .on("click", ".inShareBtn", function(e) {
        e.preventDefault();
      $(this).initInShare()
    })
    .on("click", ".printFlyer", async function(e) {
      console.log("printing flyer...")
      var _this = $(e.currentTarget)
      //show loader
      BB.Toastr.options={
          timeOut: 0,
          extendedTimeOut: 0,
          positionClass: "toast-bottom-center"
      }
      BB.Toastr.info("Generating Flyer...")
      $(
        ".bb_listing_element #" +
        _this.data("listing_id") +
        " .listing-item-tools .printFlyer .loader"
      )
      .removeClass("hide")
      .css("display", "inline-block")
      $(
        ".bb_listing_element #" +
        _this.data("listing_id") +
        " .listing-item-tools .printFlyer span"
      ).addClass("hide")
      let api_url = $(e.currentTarget).data("api_url")
      let result
      try {
        result = await $.get(api_url)
      } catch (err) {
        console.log(err)
      }
      // hide loader
      BB.Toastr.clear()
      BB.Toastr.options={};
      $(
        ".bb_listing_element #" +
        _this.data("listing_id") +
        " .listing-item-tools .printFlyer .loader"
      ).addClass("hide")
      $(
        ".bb_listing_element #" +
        _this.data("listing_id") +
        " .listing-item-tools .printFlyer span"
      ).removeClass("hide")
      if(result.status == 'error'){
        BB.Toastr.error(result.message);
      }else{ 
        let win = window.open(result.file, "_blank")
        win.focus()
      }
      //console.log(result)
    })

}

var downloadURI = function(uri, name) {
  var link = document.createElement("a")
  link.download = name
  link.href = uri
  link.click()
}

var bulkOps = {
  update: function() {
    var listings = getSelectedListings()
    var qStr = ""
    $.each(listings, function(i, nid) {
      qStr = qStr.concat("+" + nid)
    })
    qStr = qStr.substring(1)
    var url = window.LISTING_HOST + "listing_flyer/update_listhub_listing/" + qStr
    var alert = Toastr.info("Listings are being updated...", "", {
      positionClass: "toast-top-center",
      timeOut: "0"
    })
    $.get(url, function(data) {
      Toastr.clear(alert)
      $.each(listings, function(i, nid) {
        $("#" + nid).bbRefresh()
      })
      //location.reload();
      Toastr.success("Listings Updated", "", {
        positionClass: "toast-top-center",
        timeOut: "2000"
      })
    })
  },
  print_flyers: function() {
    var listings = getSelectedListings()
    var qStr = ""
    $.each(listings, function(i, nid) {
      qStr = qStr.concat("+" + nid)
    })
    qStr = qStr.substring(1)
    var url = "/listing_flyer/print_multiple/" + qStr
    var alert = Toastr.info("Flyers are being generated. Please be patient.", "", {
      positionClass: "toast-top-center",
      timeOut: "0"
    })
    $.get(url, function(result) {
        Toastr.clear(alert)
        Toastr.success("Your flyers are ready.", "", {
          positionClass: "toast-top-center",
          timeOut: "2000"
        })
        result.data.forEach(flyer=>{
          window.open(flyer.file, "_blank")
        })
      // var win = window.open(data.url, "_blank")
      // win.focus()
    })
  }
}

var refreshListing = function(listingElem) {
  var nid = $(listingElem).attr("id")

  $.get("my-listings.json?nid=" + nid, function(data) {
    console.log(data)
  })
}

var getSelectedListings = function() {
  var selected = []
  $(".bbListingCheckbox").each(function() {
    if (this.checked) {
      selected.push(
        $(this)
          .parent()
          .parent()
          .parent()
          .attr("id")
      )
    }
  })
  return selected
}

var convertNumber = function(number) {
  var result = ""
  if (number <= 999999) {
    result = Math.ceil(parseInt(number) / 1000)
    result += "K"
  } else {
    result = (number / 1000000).toFixed(2)
    result += "M"
  }
  return result
}

//var selectAllListings = function(){
//    if($.address.queryString()!==""){qStr="?"+$.address.queryString();}
//    console.log(qStr);
//}

var myListingsMap = function(center, zoom) {
  var InfoBox = require("./infobox.js")
  var iniLatLng = center
    ? { lat: parseFloat(center.lat, 10), lng: parseFloat(center.lng, 10) }
    : { lat: 33.9498917, lng: -98.7890625 }
  var iniZoom = zoom ? parseInt(zoom) : 5
  var iconBase = baseRoute + "images/location-icon.png"
  var markerBounds = new google.maps.LatLngBounds()
  var template = $(".mapGridEl").html()
  var zoomIndicator = $(".search-map__indicator--zoom")
  var loadingIndicator = $(".search-map__indicator--loading")
  var requestsCount = 0,
    markers = [],
    infowindows = [],
    currentInfoWindow = null,
    currentMarker = null

  var excludeParams = ["lat", "lng", "zoom"]

  function assignMarkers(map, data, mapData) {
    const MarkerWithLabel = require("markerwithlabel")(google.maps)
    //const iconPath = `${baseRoute}images/location-icon.png`;
    //const iconPathHover = `${baseRoute}images/location-icon-hover.png`;
    const mapBounds = new google.maps.LatLngBounds()

    currentInfoWindow = null
    currentMarker = null

    data.forEach((offer, index) => {
      if (offer.geo_lat && offer.geo_long) {
        const coords = new google.maps.LatLng(offer.geo_lat, offer.geo_long)
        const price = convertNumber(offer.listing_price)
        const infoWindowHTML = Mustache.to_html(mapData.template, {
          listing: offer,
          formattedPrice: function() {
            return parseInt(this.listing_price).toLocaleString()
          }
        })

        const marker = new MarkerWithLabel({
          position: coords,
          map: map,
          flat: true,
          title: offer.name,
          draggable: false,
          labelContent: `<span>${price}</span>`,
          labelAnchor: new google.maps.Point(30, 10),
          labelClass: `bb_map_label label-${offer.nid}`,
          labelStyle: { width: "60px" },
          zIndex: index,
          origZIndex: index
        })

        marker.addListener("mouseover", () => {
          marker.setZIndex(1000001)
          marker.setIcon(iconPathHover)
        })

        marker.addListener("mouseout", () => {
          if (currentMarker !== marker) {
            marker.setZIndex(marker.origZIndex)
            marker.setIcon(iconPath)
          }
        })

        const infoWindow = new InfoBox({
          content: infoWindowHTML,
          alignBottom: true,
          disableAutoPan: true,
          closeBoxURL: "",
          boxStyle: {
            width: "370px"
          },
          position: null,
          boxClass: "infowindow",
          enableEventPropagation: false
        })

        marker.addListener("click", () => {
          currentInfoWindow && currentInfoWindow.close()
          if (currentMarker) {
            currentMarker.setZIndex(currentMarker.origZIndex)
            currentMarker.setIcon(iconPath)
          }

          currentInfoWindow = infoWindow
          currentMarker = marker

          infoWindow.setPosition(marker.getPosition())

          const offset = calculateOffset(marker.getPosition())

          infoWindow.setOptions({
            pixelOffset: offset
          })

          marker.setZIndex(1000001)
          marker.setIcon(iconPathHover)

          infoWindow.open(map, marker)
        })

        marker.setIcon(iconPath)
        mapData.markers.push(marker)
        mapData.infoWindows.push(infoWindow)
      }
    })
  }

  function removeMarkers(markers) {
    markers.forEach(marker => marker.setMap(null))
    currentMarker = null
  }

  function getPixelFromLatLng(latlng) {
    const projection = map_results.getProjection()
    const topRight = projection.fromLatLngToPoint(map_results.getBounds().getNorthEast())
    const botLeft = projection.fromLatLngToPoint(map_results.getBounds().getSouthWest())
    const scale = Math.pow(2, map_results.getZoom())
    const worldPoint = projection.fromLatLngToPoint(latlng)
    return new google.maps.Point((worldPoint.x - botLeft.x) * scale, (worldPoint.y - topRight.y) * scale)
  }

  function calculateOffset(latlng) {
    let offset
    const defaultOffset = new google.maps.Size(-18, -35)

    const point = getPixelFromLatLng(latlng)
    const center = getPixelFromLatLng(map_results.getCenter())

    // info window size
    const isize = new google.maps.Size(320, 160)

    const mapWidth = map_results.getDiv().offsetWidth
    const mapHeight = map_results.getDiv().offsetHeight

    // find the quadrant the marker exists in, send the infobox towards the center of the screen
    let quadrant = ""
    quadrant += point.y > center.y ? "b" : "t"
    quadrant += point.x < center.x ? "l" : "r"

    switch (quadrant) {
      case "tl":
        offset = new google.maps.Size(defaultOffset.width, isize.height - defaultOffset.height)
        break
      case "tr":
        offset = new google.maps.Size(-isize.width - defaultOffset.width, isize.height - defaultOffset.height)
        break
      case "bl":
        offset = new google.maps.Size(defaultOffset.width, defaultOffset.height)
        break
      case "br":
        offset = new google.maps.Size(-isize.width - defaultOffset.width, defaultOffset.height)
        break
    }

    // EC-5067 : Keep on the screen no matter what ...
    const leftPoint = point.x + offset.width
    const rightPoint = leftPoint + isize.width

    if (leftPoint < 0) {
      offset.width -= leftPoint
    }

    if (rightPoint > mapWidth) {
      offset.width -= rightPoint - mapWidth
    }

    return offset
  }

  map_results = new google.maps.Map(document.getElementById("map_results"), {
    center: iniLatLng,
    zoom: iniZoom,
    mapTypeId: "terrain"
  })

  google.maps.event.addListenerOnce(map_results, "idle", function() {
    map_results.addListener(
      "bounds_changed",
      debounce(function() {
        const bounds = map_results.getBounds()

        $.address.parameter("lat", bounds.getCenter().lat())
        $.address.parameter("lng", bounds.getCenter().lng())
        $.address.parameter("zoom", map_results.getZoom())
        $.address.update()

        google.maps.event.trigger("refresh_data")
      }, 750)
    )

    map_results.addListener(
      "clear_data",
      debounce(function() {
        map_results.setZoom(iniZoom)
        map_results.setCenter(iniLatLng)
        removeMarkers(markers)
        markers = []
        infowindows = []
      }),
      250
    )

    map_results.addListener(
      "refresh_data",
      debounce(function() {
        const params = {}
        const paramNames = $.address.parameterNames()

        paramNames.forEach(paramName => {
          if (excludeParams.indexOf(paramName) === -1) {
            const val = $.address.parameter(paramName)

            if (typeof val !== "undefined") {
              params[paramName] = val
            }
          }
        })

        const bounds = map_results.getBounds()
        const limit = 100
        const data = {
          limit: limit,
          lat_min: bounds.getSouthWest().lat(),
          lat_max: bounds.getNorthEast().lat(),
          long_min: bounds.getNorthEast().lng(),
          long_max: bounds.getSouthWest().lng(),
          ...params
        }

        var apiURL = basePath.replace("bb-search", "listings")

        requestsCount++

        loadingIndicator.removeClass("hide")
        zoomIndicator.addClass("hide")

        fetch(`${apiURL}.json?${$.param(data)}`, {
          credentials: "same-origin"
        })
          .then(res => {
            requestsCount = requestsCount > 0 ? requestsCount - 1 : 0

            if (requestsCount === 0) {
              return res.json()
            }

            return Promise.reject("Request aborted")
          })
          .then(data => {
            removeMarkers(markers)
            markers = []
            infowindows = []

            assignMarkers(
              map_results,
              data.listings,
              {
                template: template,
                markers: markers,
                infoWindows: infowindows
              },
              currentInfoWindow
            )

            loadingIndicator.addClass("hide")

            if (data.results.total > data.results.count) {
              zoomIndicator.removeClass("hide")
            } else {
              zoomIndicator.addClass("hide")
            }
          })
          .catch(err => {
            loadingIndicator.addClass("hide")
            console.error(err)
          })
      }, 250)
    )

    map_results.addListener("click", function() {
      currentInfoWindow && currentInfoWindow.close()
      currentMarker && currentMarker.setZIndex(currentMarker.origZIndex)
      currentMarker && currentMarker.setIcon(iconPath)

      currentMarker = null
      currentInfoWindow = null
    })

    google.maps.event.trigger(map_results, "bounds_changed")
  })
} //myListingsMap eof

$(function() {
  $(".saveRealtorGuest").click(function(e) {
    var postData = new Object()
    postData.listing_id = $(this).data("listing_id")
    postData.owner = $(this).data("owner")
    postData.guest_id = $(this).data("guest_id")
    postData.agency_id = $(this).data("agency_id")
    var url = "/guest-record-manage.json"
    if (postData.guest_id) {
      $.ajax({
        url: url,
        type: "POST",
        contentType: "application/json",
        data: JSON.stringify(postData),
        dataType: "json",
        success: function(data) {
          //On ajax success do this
          $("#" + postData.listing_id + " .bb-has-guest").removeClass("hide")
        },
        error: function(xhr, ajaxOptions, thrownError) {
          //On error do this
          if (xhr.status == 200) {
          } else {
            //                    alert(xhr.status);
            //                    alert(thrownError);
          }
        }
      })
      //$('.bb-select-guest-form')[0].reset();
    }
  })
  $("#editModal").on("shown.bs.modal", function(e) {
    $(".alert .close").trigger("click")
    $("#editModal .load-more-pics-wrapper").addClass("hide")
    $(".pictureList").html("")
    //$('#editModal').modal('toggle');
    $("#editModal .load-more-pics-wrapper").addClass("hide")
    $("#editModal .pictureList").html("")
    $("#editModal .loader-circle").removeClass("hide")
    $("#editModal .loader-circle").css("display", "inline-block")
    $("#editModal .load-more-pics").data("mls_sc", $(this).data("mls_sc"))
    $("#editModal .load-more-pics").data("mls_id", $(this).data("mls_id"))
    //        $('#editModal .load-more-pics').data('mls_sc')=$(this).data('mls_sc');
    //        $('#editModal .load-more-pics').data('mls_id')=$(this).data('mls_id');
    $.get(
      "/get-picture-records.json?mls_id=" + $(this).data("mls_id") + "&mls_sc=" + $(this).data("mls_sc") + "&limit=15",
      function(data) {
        $("#editModal .loader-circle").addClass("hide")
        var count = 0
        $.each(data.picture_records, function(index, value) {
          $(".pictureList").append(
            "<li style=\"background-image:url('" +
              value.listhub_url +
              "')\" data-pid='" +
              value.pid +
              "'><div class='weightNumWrapper'></div><div class='weightNum'>" +
              (index + 1) +
              "</div></li>"
          )
          count = index
        })
        if (count == 14) {
          $("#editModal .load-more-pics-wrapper").removeClass("hide")
          $("#editModal .load-more-pics").data("offset", count)
        }
        $("#editModal .load-more-pics").click(function(e) {
          $("#editModal .load-more-pics-wrapper").addClass("hide")
          var offset = $("#editModal .load-more-pics").data("offset") + 1
          $.get(
            "/get-picture-records.json?mls_id=" +
              $(this).data("mls_id") +
              "&mls_sc=" +
              $(this).data("mls_sc") +
              "&limit=10000&offset=" +
              offset,
            function(data) {
              $.each(data.picture_records, function(index, value) {
                $(".pictureList").append(
                  "<li style=\"background-image:url('" +
                    value.listhub_url +
                    "')\" data-pid='" +
                    value.pid +
                    "'><div class='weightNumWrapper'></div><div class='weightNum'>" +
                    (index + offset + 1) +
                    "</div></li>"
                )
              })
            }
          )
        })
      }
    )
  })
  $(".inShare").click(function(e) {
    $("#inShareModal").data("share_url", $(this).data("share_url"))
  })
  $("#inShareModal").on("shown.bs.modal", function(e) {
    var url = "https://www.linkedin.com/shareArticle?mini=true&url=" + encodeURIComponent($(this).data("share_url"))
    // Prevent default anchor event
    e.preventDefault()

    // Set values for window
    var intWidth = "500"
    var intHeight = "400"
    var strResize = "yes"

    // Set title and open popup with focus on it
    var strTitle = "LinkedIn Share",
      strParam = "width=" + intWidth + ",height=" + intHeight + ",resizable=" + strResize,
      objWindow = window.open(url + "source=BankingBridge", strTitle, strParam).focus()
    //$(this).find('iframe').attr('src',url+"source=BankingBridge");
  })
  $(".sortable").sortable({
    out: function(event, ui) {
      var i = 0
      var newWeights = []
      $(".pictureList > li").each(function() {
        newWeights[i] = $(this).data("pid")
        i++
        $(this)
          .children(".weightNum")
          .html(i)
        //console.log($(this).data);
      })
      var url = "/picture-reorder.json?mls_sc="+$("#editModal .load-more-pics").data("mls_sc")+"&mls_id="+$("#editModal .load-more-pics").data("mls_id")
      var nid = $("#editModal").data("nid")
      $.ajax({
        url: url,
        type: "POST",
        contentType: "application/json",
        data: JSON.stringify(newWeights),
        dataType: "json",
        success: function(data) {
          //On ajax success do this
          $("#" + nid).bbRefresh()
          BB.Toastr.success("Pictures updated.");
        },
        error: function(xhr, ajaxOptions, thrownError) {
          //On error do this
          if (xhr.status == 200) {
            //alert(ajaxOptions);
          } else {
            //                    alert(xhr.status);
            //                    alert(thrownError);
          }
        }
      })
    }
  })
  $("#editModal .savePrice").click(function(e) {
    e.preventDefault()
    var postData = new Object()
    postData.id = $("#editModal .editDetails").data("nid")
    postData.listing_price = $("#editModal #details form #listingPrice").val()
    var url = "/manage_listings.json"
    if (!postData.listing_price) {
      $("#editModal .alert-wrapper").bbAlert({ message: "Enter valid listing price.", type: "danger" })
      return null
    }
    postData.listing_price=postData.listing_price.replace(",","")
    $.ajax({
      url: url,
      type: "POST",
      contentType: "application/json",
      data: JSON.stringify(postData),
      dataType: "json",
      success: function(data) {
        //On ajax success do this
        $("#editModal #details form")[0].reset()
        $("#editModal #details form #listingPrice").attr("placeholder", postData.listing_price)
        $("#" + postData.id + ".rounded-container .price").html("$" + parseInt(postData.listing_price).toLocaleString())
        $(".alert-wrapper").bbAlert({ message: "Price Updated", type: "success" })
      },
      error: function(xhr, ajaxOptions, thrownError) {
        //On error do this
        if (xhr.status == 200) {
          //alert(ajaxOptions);
        } else {
          //                    alert(xhr.status);
          //                    alert(thrownError);
        }
      }
    })
  })
  $("#editModal .saveLotSize").click(function(e) {
    e.preventDefault()
    var postData = new Object()
    postData.id = $("#editModal .editDetails").data("nid")
    postData.lot_size = $("#editModal #details form #lotSize").val()
    var url = "/manage_listings.json"
    if (!postData.lot_size) {
      $("#editModal .alert-wrapper").bbAlert({ message: "Enter valid lot size.", type: "danger" })
      return null
    }
    $.ajax({
      url: url,
      type: "POST",
      contentType: "application/json",
      data: JSON.stringify(postData),
      dataType: "json",
      success: function(data) {
        //On ajax success do this
        $("#editModal #details form")[0].reset()
        $("#editModal #details form #lotSize").attr("placeholder", postData.lot_size)
        //$("#"+postData.id+".rounded-container .price").html("$"+parseInt(postData.listing_price).toLocaleString());
        $("#" + postData.id + ".rounded-container .bb_listing_edit").data("lot_size", postData.lot_size)
        $(".alert-wrapper").bbAlert({ message: "Lot Size Updated", type: "success" })
      },
      error: function(xhr, ajaxOptions, thrownError) {
        //On error do this
        if (xhr.status == 200) {
          //alert(ajaxOptions);
        } else {
          //                    alert(xhr.status);
          //                    alert(thrownError);
        }
      }
    })
  })
  $("#editModal .saveSqft").click(function(e) {
    e.preventDefault()
    var postData = new Object()
    postData.id = $("#editModal .editDetails").data("nid")
    postData.sq_ft = $("#editModal #details form #sqft").val()
    var url = "/manage_listings.json"
    if (!postData.sq_ft) {
      $("#editModal .alert-wrapper").bbAlert({ message: "Enter valid square footage.", type: "danger" })
      return null
    }
    $.ajax({
      url: url,
      type: "POST",
      contentType: "application/json",
      data: JSON.stringify(postData),
      dataType: "json",
      success: function(data) {
        //On ajax success do this
        $("#editModal #details form")[0].reset()
        $("#editModal #details form #sqft").attr("placeholder", postData.sq_ft)
        $("#" + postData.id + ".rounded-container .bb_listing_edit").data("sqft", postData.sq_ft)
        $(".alert-wrapper").bbAlert({ message: "Square Footage Updated", type: "success" })
      },
      error: function(xhr, ajaxOptions, thrownError) {
        //On error do this
        if (xhr.status == 200) {
          //alert(ajaxOptions);
        } else {
          //                    alert(xhr.status);
          //                    alert(thrownError);
        }
      }
    })
  })
    $("#editModal .saveNumBaths").click(function(e) {
        e.preventDefault()
        var postData = new Object()
        postData.id = $("#editModal .editDetails").data("nid")
        postData.num_baths = $("#editModal #details form #numBaths").val()
        var url = "/manage_listings.json"
        if (!postData.num_baths) {
            BB.Toastr.error("Enter valid number of bathrooms.");
            return null
        }
        $.ajax({
            url: url,
            type: "POST",
            contentType: "application/json",
            data: JSON.stringify(postData),
            dataType: "json",
            success: function(data) {
                //On ajax success do this
                $("#editModal #details form")[0].reset()
                $("#editModal #details form #numBaths").attr("placeholder", postData.num_baths)
                $("#" + postData.id + ".rounded-container .bb_listing_edit").data("num_baths", postData.num_baths)
                BB.Toastr.success("Number of Baths Updated")
            },
            error: function(xhr, ajaxOptions, thrownError) {
                //On error do this
                if (xhr.status == 200) {
                    //alert(ajaxOptions);
                } else {
                    //                    alert(xhr.status);
                    //                    alert(thrownError);
                }
            }
        })
    })
  $("#editModal .saveHoaAmount").click(function(e) {
    e.preventDefault()
    var postData = new Object()
    postData.id = $("#editModal .editDetails").data("nid")
    postData.hoa = $("#editModal #details form #hoaAmount").val()
    var url = "/manage_listings.json"
    if (!postData.hoa) {
      $("#editModal .alert-wrapper").bbAlert({ message: "Enter HOA monthly amount.", type: "danger" })
      return null
    }
    $.ajax({
      url: url,
      type: "POST",
      contentType: "application/json",
      data: JSON.stringify(postData),
      dataType: "json",
      success: function(data) {
        //On ajax success do this
        $("#editModal #details form")[0].reset()
        $("#editModal #details form #hoaAmount").attr("placeholder", postData.hoa)
        $("#" + postData.id + ".rounded-container .bb_listing_edit").data("hoa", postData.hoa)
        $(".alert-wrapper").bbAlert({ message: "HOA amount Updated", type: "success" })
      },
      error: function(xhr, ajaxOptions, thrownError) {
        //On error do this
        if (xhr.status == 200) {
          //alert(ajaxOptions);
        } else {
          //                    alert(xhr.status);
          //                    alert(thrownError);
        }
      }
    })
  })
  const templates = $('.flyer-template-picker__template');
  const swatches = $('.flyer-template-picker__swatch');
  const swatchesList = $('.flyer-template-picker__swatch-list');
  const templateVal = [];
    // $("#flyerTemplateSelect").on("shown.bs.modal", function() {
    //  BB.realtorManage.getFlyerPreference($('body').data('user_id'))
    //     .then(function(data){
    //         const tplBtn=$(".flyer-template-picker__swatch--tpl"+data.template+"-"+data.swatch);
    //         tplBtn.click();
    //         //$(".flyer-template-picker__swatch--tpl"+data.template+"-"+data.swatch).addClass('flyer-template-picker__template--active');
    //  });
    // });
  templates.on('click', async function(e) {
      e.preventDefault();
      const $this = $(this);

      templates.removeClass('flyer-template-picker__template--active');
      $this.addClass('flyer-template-picker__template--active');

      templateVal[0] = $this.data('tplnum');
      swatchesList.hide();
      $(`.flyer-template-picker__swatch-list[data-tplnum="${$this.data('tplnum')}"]`).show();
      swatches.removeClass('flyer-template-picker__swatch--active');
      let newActiveSwatch=$(`.flyer-template-picker__swatch-list[data-tplnum="${$this.data('tplnum')}"] .flyer-template-picker__swatch:first-child`);
      newActiveSwatch.addClass('flyer-template-picker__swatch--active');
      let manageObj = BB.realtorManage;
      let updateObj = {uid: $('body').data('user_id'), template: templateVal[0] + "-0"};
      let context=$('.content-tools');
      let tpl = await BB.template.get({id: templateVal[0]})
      context.find('.flyerTemplateSelectBtn').css("background-image",'url("'+tpl.preview_url+'")');
      //context.find('.flyerTemplateSelectBtn').removeClass('tpl3').removeClass('tpl4').removeClass('tpl7');
      //context.find('.flyerTemplateSelectBtn').addClass('tpl' + templateVal[0]);
      //flyer-template-picker__swatch--
      if(tpl.settings && tpl.settings.swatches && tpl.settings.swatches[newActiveSwatch.data('swatch')]){
          let swatch=tpl.settings.swatches[newActiveSwatch.data('swatch')]
          context.find('#swatchPreview').removeClass();
          context.find('#swatchPreview').addClass('flyer-template-picker__swatch');
          context.find('#swatchPreview .leftSide').css("background",swatch.primaryColor);
          context.find('#swatchPreview .rightSide').css("background",( swatch.secondaryColor ? swatch.secondaryColor : swatch.primaryColor));
          context.find('#swatchPreview').addClass('flyer-template-picker__swatch--tpl' + templateVal[0]+ '-' + newActiveSwatch.data('swatch'));
      }

      if ($('body.bb-loan-officer').length > 0) {
          manageObj = BB.loManage;
          updateObj = {loid: $('body').data('user_id'), template: templateVal[0] + "-0"};
      }
      var updateFunc=debounce(function() {
        manageObj.update(updateObj).then(function (data) {
          BB.Toastr.success("Flyer Preference Updated")
        });
      },500);
      updateFunc();
  });

  swatches.on('click', async function(e) {
    e.preventDefault();
    const $this = $(this);
    const activeTpl = $('.flyer-template-picker__template--active');
    swatches.removeClass('flyer-template-picker__swatch--active');
    $this.addClass('flyer-template-picker__swatch--active');
    let manageObj = BB.realtorManage;
    let updateObj = {
      uid: $('body').data('user_id'),
      template: activeTpl.data('tplnum') + "-" + $this.data('swatch')
    };
    if ($('body.bb-loan-officer').length > 0) {
      manageObj = BB.loManage;
      updateObj = {
        loid: $('body').data('user_id'),
        template: activeTpl.data('tplnum') + "-" + $this.data('swatch')
      };
    }
    var updateFunc=debounce(function() {
        manageObj.update(updateObj).then(function (data) {
            BB.Toastr.success("Flyer Preference Updated")
        });
    },500);
    updateFunc();
    let context=$('.content-tools');
      let tpl = await BB.template.get({id: activeTpl.data('tplnum')})
      context.find('.flyerTemplateSelectBtn').css("background-image",'url("'+tpl.preview_url+'")');
      //context.find('.flyerTemplateSelectBtn').removeClass('tpl3').removeClass('tpl4').removeClass('tpl7');
      //context.find('.flyerTemplateSelectBtn').addClass('tpl' + templateVal[0]);
      //flyer-template-picker__swatch--
      if(tpl.settings && tpl.settings.swatches && tpl.settings.swatches[$this.data('swatch')]){
          let swatch=tpl.settings.swatches[$this.data('swatch')]
          context.find('#swatchPreview').removeClass();
          context.find('#swatchPreview').addClass('flyer-template-picker__swatch');
          context.find('#swatchPreview .leftSide').css("background",swatch.primaryColor);
          context.find('#swatchPreview .rightSide').css("background",( swatch.secondaryColor ? swatch.secondaryColor : swatch.primaryColor));
          context.find('#swatchPreview').addClass('flyer-template-picker__swatch--tpl' + activeTpl.data('tplnum')+ '-' + $this.data('swatch'));
      }
  });
  
  $('.preview-img').on('click', function(e){
    e.preventDefault();
    $('#previewImage .modal-body .flyer-template-picker__swatch').empty();
    const src = $('#flyerTemplateSelect .flyer-template-picker__template--active img').attr('src')
    const swatch = $('.flyer-template-picker__swatch--active').html();
    $('#previewImage .modal-body .img-div img').attr('src', src)
    $('#previewImage .modal-body .flyer-template-picker__swatch').html(swatch)
  })
  
	$('.tags-selctor .chk-tag').on('click', function(e){
    console.log('val',$(this).attr('data-id'));
    $('.item-template').addClass('d-none')
    $('.selected-tags').html('');
    let count = 0
    let selected_tag_ids = [];
    $('.tags-selctor .chk-tag').each(function(index){
      if($(this).is(':checked')){
        let selected_tag_id = $(this).attr('data-id');
        selected_tag_ids.push(selected_tag_id);       
        count++;
        $('.selected-tags').append('<span>'+$(this).attr('data-name')+'<a href="javascript:void" class="remove-tag" data-id="'+$(this).attr('data-id')+'">x</a></span>');
      }
    })

    $('.item-template').each(function(idx){
      let this_item_template = $(this)
      let current_template_tag_ids = [];      
      $(this).find('.template-tags').find('span').each(function(i){
        let current_template_tag_id = $(this).attr('data-id')
        current_template_tag_ids.push(current_template_tag_id)
      })
      const template_has_tag = current_template_tag_ids.filter(value => selected_tag_ids.includes(value));
      if(selected_tag_ids.sort().toString() == template_has_tag.sort().toString()){
        this_item_template.removeClass('d-none')
      }
    })

    if(count == 0) $('.item-template').removeClass('d-none')

  })

  $(document).on('click', '.remove-tag', function(){
    let remove_id = $(this).attr('data-id')
    $('.tags-selctor input[data-id='+remove_id+']').trigger('click')
  })
  
})

var inSuccess = function(data) {
  console.log(data)
}
var inError = function(error) {
  console.log(error)
}

var convertMongoListings = function(listings){
  let newListings=[];
  for(var a=0; a<listings.length; a++){
      newListings.push(convertMongoListing(listings[a]));
  }
  return newListings;
}

var convertMongoListing = function(oldListing){
    let newListing={};
    for (let [key, value] of Object.entries(oldListing)) {
        switch(key){
            case "realtor":
                newListing.realtor_name=oldListing.realtor.first_name+" "+oldListing.realtor.last_name;
                newListing.realtor_phone=oldListing.realtor.phone;
                newListing.realtor_email=oldListing.realtor.email;
                break;
            case "agency":
                newListing.realtor_agency=oldListing.agency.name;
                break;
            case "timemod":
                newListing.created=oldListing.timemod/1000;
                break;
            case "remarks":
                newListing.pub_remarks=oldListing.remarks;
                break;
            case "mls_sc":
                newListing.mls_shortcode=oldListing.mls_sc;
                break;
            case "id":
                newListing.vid=oldListing.id
                newListing.nid=oldListing.id
            case "pictures":
                if(!oldListing.pictures){
                  break;
                }
                let picsArray=[];
                for(var i=0; i<( oldListing.pictures.length<6 ? oldListing.pictures.length : 6 ); i++){
                    if(oldListing.pictures[i] && oldListing.pictures[i].url){
                      //picsArray.push(( $(".securePicUrl").data("path") ? $(".securePicUrl").data("path") : "" )+"?id="+oldListing.pictures[i].url.replace("http://","").split("/")[1]);
                      picsArray.push(oldListing.pictures[i].url);
                    }
                }
                newListing.picture=picsArray;
                break;
            default:
                newListing[key]=oldListing[key];
                break;
        }
    }
    return newListing;
}

function convertEpoch(epoch){
    if(epoch<9999999999){
        epoch=epoch*1000;
    }
    return epoch;
}

//var bbAlert = function(alertData){
//    var template=require('./tpl/bs-alert.html');
//    var parsed=template(alertData);
//    $(this).html(parsed);
//}

jQuery.fn.extend({
  bbAlert: function(alertData) {
    console.log(alertData)
  },
  bbRefresh: async function() {
    var nid = $(this).attr("id")
    let listing=await BB.listing.get({"_id":nid})
      $("#" + nid + " .listing-carousel-" + nid + " .flickity-slider")
          .children()
          .each(function(index) {
              $(this).css({ backgroundImage: "url(" + listing[0].pictures[index].url + ")" })
          })
      $("#" + nid + " .listing-carousel-nav-" + nid + " .flickity-slider")
          .children()
          .each(function(index) {
            $(this).css({ backgroundImage: "url(" + listing[0].pictures[index].url + ")" })
      })
  }
})

module.exports = {
  init: bbListings.init,
  getSelectedListings: getSelectedListings,
  Views: Views,
  bulkOps: bulkOps,
  getParamArray: getParamArray
}

// -----------------------------------------
//console.log("bb-listings <-" );
