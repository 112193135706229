<doc>
	Add/edit Companies Modal Vue.js component
</doc>

<template>
  <BaseModal
    ref="modal"
    v-model="showItemModal"
    :title="modalTitle"
    id="newPricingSetModal"
    @ok="submitForm"
    @cancel="hideModal"
    okLabel="Save"
    :isLoading="isLoading"
    @clickMask="hideModal"
  >
    <h5>
      Brand details
    </h5>
    <p class="intro">Set up the details of your new company.</p>

    <ImgUploader
      v-if="!isLoading"
      previewAlt="Company logo"
      :editMode="true"
      :previewSrc="logoSrc"
      previewClass="company-logo"
      label="Upload brand logo"
      :uploadCallback="uploadCallback"
    />

    <form id="new-brand-form" class="form" ref="brandForm" v-on:submit.prevent>
      <InputText v-model="brand.name" label="Name of brand" name="set-name" />
      <InputText
        v-model="brand.website"
        label="Website of brand"
        name="set-url"
        type="url"
      />
      <InputText v-model="brand.nmls" label="NMLS" name="set-nmls" />
      <div class="checkboxField">
        <input
            id="disable_subscription_emails"
            type="checkbox"
            v-model="brand.disable_subscription_emails"
        />
        <label for="disable_subscription_emails">Disable Subscription Emails</label>
      </div>
      <div class="checkboxField">
        <input
            id="disable_receipt_emails"
            type="checkbox"
            v-model="brand.disable_receipt_emails"
        />
        <label for="disable_receipt_emails">Disable Receipt Emails</label>
      </div>
      <div class="checkboxField">
        <input
            id="disable_itemized_fees"
            type="checkbox"
            v-model="brand.disable_itemized_fees"
        />
        <label for="disable_itemized_fees">Disable Itemized Fees</label>
      </div>
      <div class="checkboxField">
        <input
            id="non_bank"
            type="checkbox"
            v-model="brand.non_bank"
        />
        <label for="non_bank">Non Bank</label>
      </div>
      <RichTextEditor label="About" name="set-desc" v-model="brand.about" />

      <InputTextArea
        v-model="brand.disclaimer"
        label="General Disclaimer"
        name="set-disclaimer"
      />
      <InputTextArea
        v-model="brand.static_disclaimer"
        label="Static Flyer Disclaimer"
        name="set-static_disclaimer"
        v-bind:showLimit="false"
        :limit="1000"
      />
      <InputTextArea
        v-model="brand.social_disclaimer"
        label="Social Disclaimer"
        name="set-social_disclaimer"
        v-bind:showLimit="false"
        :limit="1000"
      />
      <InputTextArea
          v-model="brand.payment_details_disclaimer"
          label="Payment Details Disclaimer"
          name="set-static_disclaimer"
      />
      <InputTextArea
          v-model="brand.lead_capture_disclaimer"
          label="Lead Capture Disclaimer"
          name="set-static_disclaimer"
      />
      <InputTextArea
          v-model="brand.loan_calc_disclaimer"
          label="Loan Calc Disclaimer"
          name="set-static_disclaimer"
      />
      <div class="ac-wrapper hide" v-if="!obBrand">
        <label class="input-label autocomplete-label">
          Select OB Channel
        </label>
        <Autocomplete
          id="ob-channel-select"
          class="form-group material-input"
          input-class="form-control"
          :resultsFormatter="acResultsFormatter"
          :resultsDisplay="acResultsDisplay"
          :source="obBrandAutocomplete"
          @selected="selectOBBrand"
        />
      </div>
      <div v-else>
        <div style="margin-top:20px;">
          <img
            style="height:30px;margin-bottom:-10px;margin-right:10px;"
            src="https://ellisjm-private.s3.amazonaws.com/ob_logo.png"
          /><span>{{ obBrand.name }}</span
          ><i @click="clearOBBrand" class="fas fa-times-circle"></i>
        </div>
      </div>
      <div v-if="placeLoading" style="text-align: center;">
        <div
          class="loader vertical-align-middle loader-circle"
          style="font-size:28px;"
        ></div>
      </div>
      <div v-else>
        <div class="ac-wrapper" v-if="!brandPlace.place_id">
          <label class="input-label autocomplete-label">
            Select Google Reviews Brand
          </label>
          <Autocomplete
            id="brand-place-search"
            class="form-group material-input"
            input-class="form-control"
            :resultsFormatter="acPlaceResultsFormatter"
            :resultsDisplay="acPlaceResultsDisplay"
            :source="placeAutocomplete"
            @selected="selectBrandPlace"
          />
        </div>
        <div v-else>
          <div style="margin-top:20px;height:20px;display:block;">
            <div class="input-logo pull-left">
              <img
                style="height:30px"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
              />
            </div>
            <div class="place-details pull-left">
              <div class="place-label">{{ brandPlace.name }}</div>
              <div class="place-location">{{ brandPlace.location }}</div>
            </div>
            <i
              @click="clearBrandPlace"
              class="fas fa-times-circle pull-left"
            ></i>
          </div>
          <div v-if="googleReviews">
            <ManagerReviews
              :reviews="googleReviews.reviews"
              type="google"
            />
          </div>
        </div>
      </div>
      <div>
        <InputText :onBlur="setZillowReviews" v-model="brand.zillow_brand_name" label="Zillow Brand Name" name="set-zillow-brand-name" />
      </div>
      <div v-if="zillowReviews">
        <ManagerReviews
                :reviews="zillowReviews.reviews"
                type="zillow"
        />
      </div>
      <div>
        <InputText :onBlur="setExperienceReviews" v-model="brand.experience_id" label="Experience Account ID" name="set-experience-id" />
      </div>
      <div v-if="experienceReviews">
        <ManagerReviews
            :reviews="experienceReviews.reviews"
            type="experience"
        />
      </div>
      <div>
        <InputText
                v-model="brand.primary_color"
                label="Primary Color"
                name="primary_color"
                class="accent_color_field"
        />
        <span
                class="accent_color_preview"
                :style="{ 'background-color': brand.primary_color }"
        ></span>
      </div>
      <div>
        <InputText
                v-model="brand.secondary_color"
                label="Secondary Color"
                name="secondary_color"
                class="accent_color_field"
        />
        <span
                class="accent_color_preview"
                :style="{ 'background-color': brand.secondary_color }"
        ></span>
      </div>
      <div class="default-lo-field">
        <div v-if="!defaultLO">
          <InputText
              v-model="brand.default_loid"
              label="Default LOID"
              name="set-default-loid"
              type="text"
          />
        </div>
        <div v-else>
          <label>Default Loan Officer</label> {{defaultLO.name}}
          <i
              @click="clearDefaultLO"
              class="fas fa-times-circle"
          ></i>
        </div>
      </div>
      <div>
        <div v-if="rateflow">
          Rateflow is detected (id: {{rateflow.id}}) <button @click="expandRateflow" class="toggleBtn">Advanced</button>
          <div v-if="rateflowExpanded">
            <div class="checkboxField">
              <input
                  id="enable_mortech"
                  type="checkbox"
                  v-model="mortechEnabled"
                  @change="toggleMortech"
              />
              <label for="enable_mortech">Enable Mortech</label>
            </div>
            <div v-if="mortechEnabled">
              <InputText v-model="mortechConfig.customer_id" label="Customer ID" name="set-customer-id" />
              <InputText v-model="mortechConfig.email_address" label="Email" name="set-email" />
              <InputText v-model="brand.state" label="Default State" name="set-state" />
            </div>
            <InputText v-model="rateflow.settings.target_price" label="Target Price" name="set-target-price" />
            <div class="checkboxField">
              <input
                  id="set_waive_escrows"
                  type="checkbox"
                  v-model="rateflow.settings.waive_escrows"
                  @change="setWaiveEscrows"
              />
              <label for="set_waive_escrows">Waive Escrows</label>
            </div>
          </div>
        </div>
        <div v-else>
          <button @click="createRateflow()">Create Rateflow</button>
        </div>
      </div>
        <div style="margin-top:10px;">
            <div v-if="pricingProfiles">
                Pricing Profiles are detected
            </div>
            <div v-else>
                <button @click="createPricingProfiles()">Create Pricing Profiles</button>
            </div>
        </div>
    </form>
  </BaseModal>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import BaseModal from "../BaseModal"
import ImgUploader from "../ImgUploader"
import Autocomplete from "vuejs-auto-complete"
import ManagerReviews from "./ManagerReviews"
import RichTextEditor from "../Forms/RichTextEditor"

const { mapState, mapActions } = createNamespacedHelpers("organization")
export default {
  name: "CompanyModal",
  components: {
    ManagerReviews,
    BaseModal,
    ImgUploader,
    Autocomplete,
    RichTextEditor
  },
  data() {
    return {
        logo: null,
        logo_fid: null,
        obBrand: null,
        brandPlace: {
            rating: 0,
            name: "",
            location: "",
            place_id: ""
        },
        logoSrc: null,
        zillowReviews: null,
        googleReviews: null,
        experienceReviews: null,
        placeLoading: false,
        rateflow: null,
        pricingProfiles: null,
        runOnce: false,
        rateflowExpanded: false,
        mortechEnabled: false,
        mortechConfig: {},
        defaultLO: null
    }
  },
  async updated() {
    // checking to see if this is the first run
    if(this.brand.id && !this.runOnce){
        this.getRateflow()
        if(this.brand.place_id)this.setBrandPlace(this.brand.place_id)
        if(this.brand.zillow_brand_name)this.setZillowReviews()
        if(this.brand.experience_id)this.setExperienceReviews()
        if(this.brand.default_loid){
          this.defaultLO=this.getLO(this.brand.default_loid)
          console.log(this.defaultLO)
        }
        this.getPricingProfiles()
        this.runOnce=true;
    }
  },
  mounted() {
    // i dont think this code is needed
    // this.$nextTick(function () {
    //         this.logoSrc=this.currentEditedItem ? this.currentEditedItem.logo : ''
    //     	if(this.currentEditedItem && this.currentEditedItem.ob_channel_id)console.log('need to set obBrand');
    //     	if(this.currentEditedItem && this.currentEditedItem.place_id)console.log('need to set place_id');
    // })
  },
  created(){

  },
  computed: Object.assign(
    {},
    mapState(["loid", "originalItems", "currentEditedItem", "isEdit"]),
    {
      showItemModal: {
        get() {
          return this.$store.state.organization.showItemModal
        },
        set(value) {
          this.toggleItemModal(value)
        }
      },
      modalTitle() {
        const prefix = this.isEdit ? "Edit" : "New"
        return `${prefix} Brand`
      },
      brand() {
        let brand = { name: "", about: "" }
        if (this.currentEditedItem) {
          brand = Object.assign({}, this.currentEditedItem)
        }

        this.logoSrc = brand.logo || ""

        return brand
      },
      isLoading() {
        return !this.currentEditedItem && this.isEdit
      }
    }
  ),
  methods: Object.assign({}, mapActions(["toggleItemModal", "saveBrand"]), {
    uploadCallback(logo_fid, filename, url) {
      this.logo_fid = logo_fid
      this.logoSrc = url
    },
    hideModal() {
      this.toggleItemModal(false)
      Object.assign(this.$data, this.$options.data.call(this))
      this.clearOBBrand()
      this.clearBrandPlace()
      this.googleReviews = null
      this.zillowReviews = null
      this.experienceReviews = null
      this.rateflow = null
      this.mortechEnabled=false
      this.mortechConfig={}
      this.defaultLO=null
    },
    expandRateflow(){
      this.rateflowExpanded = !this.rateflowExpanded
    },
    submitForm: function() {
      const vm = this
      const editSetId = vm.brand.id || null
      const editSetWeight = vm.brand.weight || vm.originalItems.length
      const newData = {
        name: vm.brand.name,
        website: vm.brand.website ? vm.brand.website : "",
        about: vm.brand.about,
        parent_nid: this.$store.state.organization.orgID,
        disclaimer: vm.brand.disclaimer,
        static_disclaimer: vm.brand.static_disclaimer,
        social_disclaimer: vm.brand.social_disclaimer,
        loan_calc_disclaimer: vm.brand.loan_calc_disclaimer,
        payment_details_disclaimer: vm.brand.payment_details_disclaimer,
        lead_capture_disclaimer: vm.brand.lead_capture_disclaimer,
        nmls: vm.brand.nmls,
        ob_channel_id: vm.brand.ob_channel_id ? vm.brand.ob_channel_id : "",
        place_id: vm.brandPlace.place_id ? vm.brandPlace.place_id : "",
        zillow_brand_name: vm.brand.zillow_brand_name ? vm.brand.zillow_brand_name : "",
        primary_color: vm.brand.primary_color,
        secondary_color: vm.brand.secondary_color,
        disable_subscription_emails: vm.brand.disable_subscription_emails,
        disable_itemized_fees: vm.brand.disable_itemized_fees,
        disable_receipt_emails: vm.brand.disable_receipt_emails,
        non_bank: vm.brand.non_bank,
        state: vm.brand.state,
        experience_id: vm.brand.experience_id,
        default_loid: vm.brand.default_loid
      }

      const data = Object.assign({}, this.currentEditedItem, newData)

      if (this.logo_fid) {
        data.logo_fid = this.logo_fid
        data.logo = vm.logoSrc
      }

      if (editSetId) {
        data.id = editSetId
      }

      if(this.mortechConfig && this.mortechConfig.customerId && this.mortechConfig.email){
        this.rateflow.settings.api_config = {};
        this.rateflow.settings.api_config.customerId = this.mortechConfig.customerId;
        this.rateflow.settings.api_config.email = this.mortechConfig.email;
      }
      if(this.rateflow && this.rateflow.id)BB.rateflow.update(this.rateflow)
      this.saveBrand(data).then(this.hideModal)
    },
    toggleMortech(){
      if(this.rateflow && !this.rateflow.settings)this.rateflow.settings={}
      if(this.mortechEnabled){
        this.rateflow.settings.api_mode="mortech"
        BB.rateflow.update(this.rateflow)
      }
      else{
        this.rateflow.settings.api_mode="ob"
        BB.rateflow.update(this.rateflow)
        // this.mortechConfig={}
      }
    },
    obBrandAutocomplete(input) {
      let org_id = this.$store.state.organization.orgID
      return "/api/v1/find-ob-brand?name=" + input + "&org_id=" + org_id
    },
    acResultsFormatter(data) {
      return data
    },
    loAutoComplete(input){

    },
    acLOResultsDisplay(data) {
      return (
        `
					<div class='resultWrapper'>
						<div>
							<span class="name">` +
        data.name +
        `</span>
						</div>
						<div class='details'>
							<span style='font-weight:400;color:#888;'>OB ID: ` +
        data.index +
        `</span>
						</div>
						<div class='details'>
							<span style='font-weight:400;color:#888;'>Total LOs: ` +
        data.lo_count +
        `</span>
						</div>
					</div>
			`
      )
    },
    acResultsDisplay(data) {
      return (
          `
					<div class='resultWrapper'>
						<div>
							<span class="name">` +
          data.name +
          `</span>
						</div>
						<div class='details'>
							<span style='font-weight:400;color:#888;'>OB ID: ` +
          data.index +
          `</span>
						</div>
						<div class='details'>
							<span style='font-weight:400;color:#888;'>Total LOs: ` +
          data.lo_count +
          `</span>
						</div>
					</div>
			`
      )
    },
    selectOBBrand(data) {
      //debugger;
      //this.currentEditedItem.first_name=data.selectedObject.firstName;
      this.obBrand = data.selectedObject
      this.brand.ob_channel_id = data.selectedObject.index
      // run the following only if creating a new member
    },
    clearOBBrand() {
      this.obBrand = null
    },
    getOBBrand(ob_channel_id) {
      let org_id = this.$store.state.organization.orgID
      fetch(
        "/api/v1/find-ob-brand?biz_channel=" +
          ob_channel_id +
          "&org_id=" +
          org_id,
        {
          method: "GET",
          credentials: "same-origin"
        }
      )
        .then(res => {
          return res.json()
        })
        .then(data => {
          this.obBrand = data[0]
        })
        .catch(err => {
          console.error(err)
        })
    },
    getGooglePlace(place_id) {
      return fetch("/api/v1/places?place_id=" + place_id, {
        method: "GET",
        credentials: "same-origin"
      }).then(res => {
        return res.json()
      })
    },
    async setBrandPlace(place_id) {
      this.placeLoading = true
      let place_details = await this.getGooglePlace(place_id)
      this.brandPlace = {
        name: place_details.name,
        place_id: place_id,
        location: place_details.vicinity,
        rating: place_details.rating
      }

      this.googleReviews = {}
      let data = await BB.brandManage.reviews({ place_id })
      this.placeLoading = false
      this.googleReviews = data.google
    },
    async setZillowReviews(){
        if(!this.brand.nmls){
            BB.Toastr.error("NMLS is not set")
        }
        let data = await BB.brandManage.reviews({ zillow_brand_name: this.brand.zillow_brand_name, nmls: this.brand.nmls })
        this.zillowReviews = data.zillow
    },
    async setExperienceReviews(){
      let data = await BB.brandManage.reviews({ experience_id: this.brand.experience_id })
      this.experienceReviews = data.experience
    },
    placeAutocomplete(input) {
      return "/api/v1/places?input=" + input
    },
    acPlaceResultsFormatter(data) {
      return data
    },
    acPlaceResultsDisplay(data) {
      return (
        `
				<div class='resultWrapper'>
					<div>
						<span class="name">` +
        data.structured_formatting.main_text +
        `</span>
					</div>
					<div class='details'>
						<span style='font-weight:400;color:#888;'>` +
        data.structured_formatting.secondary_text +
        `</span>
					</div>
				</div>
			`
      )
    },
    async selectBrandPlace(data) {
      //debugger;
      if (!data.selectedObject) return
      if (!data.selectedObject.place_id) return

      this.brand.place_id = data.selectedObject.place_id
      await this.setBrandPlace(this.brand.place_id)
    },
    clearBrandPlace() {
      this.brandPlace = {
        rating: 0,
        name: "",
        location: "",
        place_id: ""
      }
      this.googleReviews = null
      this.zillowReviews = null
    },
    async getRateflow(){
        this.rateflowLoading=true
        let rateflows=await BB.rateflow.get({bank_id:this.brand.id})
        this.rateflowLoading=false
        if(!rateflows) {
          return
        }
        this.rateflow=rateflows[0]
        if(this.rateflow && !this.rateflow.settings)this.rateflow.settings={}
        if(this.rateflow.settings && this.rateflow.settings.api_mode==="mortech"){
          this.mortechEnabled=true
          if(this.rateflow.settings && this.rateflow.settings.api_config){
            this.mortechConfig=this.rateflow.settings.api_config
          }
        }

    },
    async createRateflow(){
        if(
            this.$store.state.organization &&
            this.$store.state.organization.members &&
            this.$store.state.organization.members[this.brand.id] &&
            this.$store.state.organization.members[this.brand.id].members &&
            this.$store.state.organization.members[this.brand.id].members[0] &&
            this.$store.state.organization.members[this.brand.id].members[0].uid
        ){
            let res=await BB.rateflow.add({
                loid: this.$store.state.organization.members[this.brand.id].members[0].uid,
                owner: this.$store.state.organization.members[this.brand.id].members[0].uid,
                name: this.brand.name+" Rateflow"
            })
            if(res.result && res.result.id){
                this.rateflow=await BB.rateflow.get({id:res.result.id})
                BB.Toastr.success("Rateflow created")
                return
            }
        }
        BB.Toastr.error("Rateflow not created")
        return
    },
    async getPricingProfiles(){
        let profiles=await $.get("/api/v1/profile",{brand_id:this.brand.id})
        if(profiles && profiles[0] && profiles[0].brand_id==this.brand.id){
            this.pricingProfiles=profiles
        }
    },
    async createPricingProfiles(){
        await $.ajax({
            url: "/api/v1/profile",
            type: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify({
                brand_id: this.brand.id,
                defaults: true
            }),
            success(data) {
                this.getPricingProfiles()
            },
            error(xhr, ajaxOptions, thrownError) {
            }
        })
    },
    async setWaiveEscrows(){
      if(this.rateflow && !this.rateflow.settings)this.rateflow.settings={}
      BB.rateflow.update(this.rateflow)
    },
    getLO(loid){
      let result=null;
      this.$store.state.members.originalItems.forEach(item=>{
        if(parseInt(item.nid)==parseInt(loid)){
          result = item;
          return
        }
      })
      return result
    },
    clearDefaultLO(){
      this.defaultLO=null;
    }
  })
}
</script>

<style lang="scss">
#autocomplete-loader {
  position: absolute;
  right: 10px;
  left: auto;
  font-size: 20px;
  top: 28px;
}
#brand-place-search {
  margin-top: 20px;
}
#newPricingSetModal {
  input,
  textarea,
  select {
    font-size: 16px;
  }
  select {
    height: 38px;
  }
  h5 {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .intro {
    margin-bottom: 25px;
  }

  .ui-autocomplete {
    position: absolute !important;
    background: #fff;
    box-shadow: 0 8px 16px 0 rgba(12, 0, 51, 0.1);
    margin-top: 1px;
    border-radius: 3px;
    .ui-menu-item {
      .ui-menu-item-wrapper {
        padding: 8px 16px;
      }
      .ui-state-active {
        background-color: #d5edf1;
      }

      &:first-child {
        .ui-state-active {
          border-radius: 3px 3px 0 0;
        }
      }
      &:last-child {
        .ui-state-active {
          border-radius: 0 0 3px 3px;
        }
      }
    }
  }
  .autocomplete-label {
    font-weight: 300;
    position: absolute;
    left: 2px;
    top: -20px;
    font-size: 16px;
    color: rgba(21, 28, 41, 0.48);
    transform-origin: left top;
    transition: color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
      transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    pointer-events: none;
    cursor: text;
    transform: translateY(0px) scale(0.8);
  }
  .ac-wrapper {
    position: relative;
    margin-top: 30px;
    .autocomplete {
      padding: 0px;
      background-color: #ffffff;
      box-shadow: none;
      .autocomplete__box {
        border: none;
        border-bottom: 1px solid #5dbec7;
        border-radius: 0px;
      }
      input {
        box-shadow: none;
        -webkit-box-shadow: none;
      }
    }
  }
}
.panel-heading {
  position: relative;
}
.reviews-wrapper {
  margin-top: 20px;
  .rating {
    position: absolute;
    top: 10px;
    right: 20px;
    .fa-star {
      color: gold;
    }
  }
}
.place-details {
  margin-left: 10px;
  .place-label {
    font-size: 120%;
    font-weight: 400;
  }
}
.fa-times-circle {
  margin-left: 10px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 3px;
}
.accent_color_field {
  width: 100%;
  display: inline-block;
  input.form-control {
    background-color: transparent;
  }
}
.accent_color_preview {
  width: 40px;
  height: 20px;
  display: inline-block;
  border-radius: 5px;
  position: absolute;
  right: 20px;
  margin-top: 30px;
}
.checkboxField {
  input {
    width: 1.2em;
    height: 1.2em;
    vertical-align: text-bottom;
    background: transparent;
    cursor: pointer;
  }
  label {
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
  }
  margin-bottom: 5px;
  .selectWrapper {
    display: inline-block;
    font-size: 12px;
    line-height: 1em;
  }
}
.default-lo-field{
  padding-bottom:20px;
  label{
    font-weight: bolder;
    font-size:110%;
  }
}
</style>
