var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "extraProfileDetails" } },
    [
      _vm._l(_vm.fields, function(value, key) {
        return _c(
          "div",
          { key: key, class: key + " form-group" },
          [
            _c("label", { staticClass: "control-label" }, [
              _vm._v(" " + _vm._s(value.label) + " ")
            ]),
            _vm._v(" "),
            value.widget.type == "text_textfield"
              ? [
                  _c("InputText", {
                    attrs: { readonly: "", name: value.field_name },
                    model: {
                      value: _vm.lo[value.cus_name],
                      callback: function($$v) {
                        _vm.$set(_vm.lo, value.cus_name, $$v)
                      },
                      expression: "lo[value.cus_name]"
                    }
                  })
                ]
              : _vm._e(),
            _vm._v(" "),
            value.widget.type == "options_onoff"
              ? [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.lo[value.field_name],
                        expression: "lo[value.field_name]"
                      }
                    ],
                    attrs: {
                      disabled: "",
                      type: "checkbox",
                      label: value.label,
                      name: value.field_name
                    },
                    domProps: {
                      checked: Array.isArray(_vm.lo[value.field_name])
                        ? _vm._i(_vm.lo[value.field_name], null) > -1
                        : _vm.lo[value.field_name]
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.lo[value.field_name],
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.lo,
                                value.field_name,
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.lo,
                                value.field_name,
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.lo, value.field_name, $$c)
                        }
                      }
                    }
                  })
                ]
              : _vm._e()
          ],
          2
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mobile-phone form-group" },
        [
          _c(
            "label",
            {
              staticClass: "control-label",
              attrs: { for: "inputMobilePhone" }
            },
            [_vm._v("Phone # for Signature Block")]
          ),
          _vm._v(" "),
          _c("InputText", {
            attrs: { readonly: "", name: "phone" },
            model: {
              value: _vm.lo.phone,
              callback: function($$v) {
                _vm.$set(_vm.lo, "phone", $$v)
              },
              expression: "lo.phone"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "office-phone form-group" },
        [
          _c(
            "label",
            {
              staticClass: "control-label",
              attrs: { for: "inputOfficePhone" }
            },
            [_vm._v("Office Phone")]
          ),
          _vm._v(" "),
          _c("InputText", {
            attrs: { readonly: "", name: "office_phone" },
            model: {
              value: _vm.lo.office_phone,
              callback: function($$v) {
                _vm.$set(_vm.lo, "office_phone", $$v)
              },
              expression: "lo.office_phone"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div", { staticClass: "btn-save-wrapper form-group" }, [
        _c(
          "button",
          { staticClass: "btn btn-modern", on: { click: _vm.save } },
          [_vm._v("Save")]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-8563af92", { render: render, staticRenderFns: staticRenderFns })
  }
}