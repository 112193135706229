/**
 * Static Flyer API wrapper
 */

const ENDPOINT = "/static_flyer/image.json"
const PRINT_ENDPOINT = "/static_flyer/print.json"
const EMAIL_ENDPOINT = "/static_flyer/email.json"

const bbStaticFlyer = {
  get(params, callback = null) {
    return $.get(ENDPOINT, params, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  update(flyerImgData, callback) {
    if (!flyerImgData.id) {
      console.log("static flyer image id missing")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }

    return $.ajax({
      url: ENDPOINT,
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(flyerImgData),
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  add(flyerImgData, callback) {
    return $.ajax({
      url: ENDPOINT,
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(flyerImgData),
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  delete(id, callback) {
    return $.ajax({
      url: ENDPOINT + "?id=" + id,
      type: "DELETE",
      contentType: "application/json",
      dataType: "json",
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  print(params, callback) {
    if (!params.id) {
      console.log("static flyer image id missing")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }
    if (!params.loid) {
      console.log("static flyer image loid missing")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }
    return $.get(PRINT_ENDPOINT, params, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  StaticFlyerSendEmail(params, callback) {
    if (!params.id) {
      console.log("static flyer image id missing")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }
    if (!params.loid) {
      console.log("static flyer image loid missing")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }
    return $.get(EMAIL_ENDPOINT, params, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  }
}

export default bbStaticFlyer
