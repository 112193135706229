<doc>
  Realtor search manual flyer form Vue.js component
</doc>

<template>
  <div>
    <grid-row>
      <grid-column size="4">
        <div>
          <ImgUploader
              :previewSrc="value.realtorData.picture"
              :editMode="!!value.realtorData.picture"
              id="picture"
              size="150"
              fullRounded
              type="thumbPrev"
              label="Realtor picture"
			  labelClassName="required"
              :uploadCallback="uploadCallback"
              :beforeUpload="disableSaveNext"
              onErrorShowAlert
              useS3
              :isHeadshot="true"
          />
        </div>
        <div class="realtor-headshot"><span>Realtor Headshot<span class="red-text"> *</span></span></div>
      </grid-column>
      <grid-column size="8">
        <InputText
          v-model.trim="value.realtorData.first_name"
          label="First Name"
          labelClassName="required"
          name="first_name"
        />
        <InputText
          v-model.trim="value.realtorData.last_name"
          label="Last Name"
          labelClassName="required"
          name="last_name"
        />
        <InputText
          v-model.trim="value.realtorData.title"
          label="Realtor Title"
          name="title"
        />
      </grid-column>
    </grid-row>
    <grid-row>
      <grid-column size="6">
        <InputText
          v-model.trim="value.realtorData.email"
          label="Email"
          labelClassName="required"
          name="email"
          type="email"
        />
        <div class="blue-text validate-helper-text-position"><span>* Validate realtor's email address is accurate</span></div>
      </grid-column>
      <grid-column size="6">
        <InputText
          v-model.trim="value.realtorData.phone"
          label="Phone"
          name="phone"
          type="tel"
        />
      </grid-column>
    </grid-row>
    <grid-row>
      <grid-column size="4">
        <div>
          <ImgUploader
            :previewSrc="value.realtorData.agency_logo"
            :editMode="!!value.realtorData.agency_logo"
            id="agency_logo"
            size="150"
            type="thumbPrev"
            label="Agency logo"
			labelClassName="required"
            :uploadCallback="uploadCallback"
            :beforeUpload="disableSaveNext"
            onErrorShowAlert
            useS3
          />
        </div>
        <div class="agency-logo"><span>Agency Logo</span><span class="red-text"> *</span></div>
      </grid-column>
      <grid-column size="8">
        <InputText
          v-model.trim="value.realtorData.agency_name"
          label="Agency Name"
          
          name="agency_name"
        />
        <StatesDropDown
          label="State"
          
          name="state"
          v-model="value.realtorData.state"
          :onInput="onInputStatesLocal"
        />
        <div><span class="file-type-helper">File types JPEG or PNG, size under 5 MB</span><span class="blue-text field-helper">* All fields required</span></div>
      </grid-column>
    </grid-row>
  </div>
</template>

<script>
import { StatesDropDown } from "../../Forms"
import ImgUploader from "../../ImgUploader"
/*
  required fields:
        //    first_name (string)
        //    last_name (string)
        //    email (string)
        //    phone (string)
        //    agency_name (string)
        //    agency_logo (string)
        //    picture (string)
        //    state (string)
  */

export default {
  name: "FormRealtor",
  props: {
    value: { type: Object },
    onInputStates: { type: Function },
    enableSaveNext: { type: Function },
    disableSaveNext: { type: Function },
    editMode: { type: Boolean, default: false }
  },
  components: { StatesDropDown, ImgUploader },
  methods: {
    onInputStatesLocal(val) {
      this.onInputStates(val)
    },
    uploadCallback(data, fieldID) {
      if (data.url) {
        this.$set(this.value.realtorData, fieldID, data.url)
      }
    },
    checkForm() {
      this.disableSaveNext()
      let count = 0
      const requiredFields = [
        "first_name",
        "last_name",
        "email",
        //"phone",
        //"agency_name",
        //"state",
        "agency_logo",
        "picture",
        //"title"
      ]
      requiredFields.forEach(field => {
        this.value.realtorData[field] &&
          this.value.realtorData[field].length &&
          count++
      })
      count === requiredFields.length && this.enableSaveNext()
    }
  },
  updated() {
    this.checkForm()
  },
  mounted() {
    //if (this.editMode) {
      this.checkForm()
    //}
  }
}
</script>

<style lang="scss" scoped></style>
<style>
  .red-text {
    color: red;
  }
  .blue-text {
    color: #19a8c3;
  }
  .validate-helper-text-position {
    position: absolute;
    bottom: 5px;
  }
  .file-type-helper {
    position: absolute;
    bottom: -84px;
    right: 270px;
  }
  .field-helper {
    position: absolute;
    bottom: -56px;
    right: 17px;
  }
  .required::after {
    content: " *";
    color: red;
  }

  .img-uploader__label-txt::after {
    /*content: " *";
    color: red;*/
  }
  .realtor-headshot {
    position: absolute;
    top: 166px;
    margin-left: 22px;
  }
  .agency-logo {
    position: absolute;
    top: 163px;
    margin-left: 34px;
  }
</style>
