var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c("LoaderPlaceholder", { attrs: { type: "circle" } })
    : _c(
        "FlexBox",
        { attrs: { size: "lg" } },
        [
          _c(
            "GridColumn",
            { staticClass: "mt-4 mt-md-0", attrs: { size: ["sm-12", "md-4"] } },
            [
              _c("CompanyCard"),
              _vm._v(" "),
              _c("CompanyAboutCard"),
              _vm._v(" "),
              _c("CompanyPeolple")
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "GridColumn",
            { attrs: { size: ["sm-12", "md-8"] } },
            [
              _c("ManagerBrands"),
              _vm._v(" "),
              _c("CompanyActivity", { attrs: { hideToolbar: "" } })
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5b124f13", { render: render, staticRenderFns: staticRenderFns })
  }
}