var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-tools" },
    [
      _c("div", { staticClass: "swatch-text" }, [
        _vm._v("Default Template Selection")
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "pull-right flyerTemplateSelectBtn",
          style: _vm.preview,
          attrs: {
            "data-toggle": "modal",
            "data-target": "#flyerTemplateSelect",
            "data-imgurl": _vm.imgurl,
            "data-backdrop": "static"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "flyer-template-picker__swatch",
              class: _vm.showborder,
              attrs: { id: "swatchPreview" }
            },
            [
              _c("div", { staticClass: "leftSide", style: _vm.primaryColor }),
              _vm._v(" "),
              _c("div", { staticClass: "rightSide", style: _vm.secondaryColor })
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm.isLoading
        ? _c("LoaderPlaceholder", { attrs: { type: "circle" } })
        : _c("SmartList", {
            attrs: {
              searchQuery: _vm.searchQuery,
              searchQueries: _vm.searchQueries,
              filteredItems: _vm.filteredItems,
              addQuery: _vm.addQuery,
              removeQuery: _vm.removeQuery,
              resetQuery: _vm.resetQuery,
              toggleListView: _vm.toggleListView,
              addItemAction: _vm.toggleItemModal,
              items: _vm.items,
              onDragEnd: _vm.onEnd,
              gridView: _vm.gridView,
              listType: "manual_flyer"
            }
          }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal jt-modal fade",
          attrs: { id: "flyerTemplateSelect", role: "dialog" }
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _vm.is_loading
                    ? _c("div", {
                        staticClass:
                          "loader vertical-align-middle loader-circle",
                        attrs: { "data-type": "default" }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("ThemeSelector", {
                    attrs: {
                      onThemeSelect: _vm.onThemeSelect,
                      theme: _vm.theme,
                      swatch: _vm.swatch
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm._m(1)
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _vm._m(2)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [
        _vm._v("Flyer Template Preference")
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "pull-right closeBtn",
          attrs: { "data-dismiss": "modal", "aria-hidden": "true" }
        },
        [_vm._v("×")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-default preview-img",
          attrs: {
            type: "button",
            "data-toggle": "modal",
            "data-target": "#previewImage",
            "data-backdrop": "static"
          }
        },
        [_vm._v("Preview")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-default",
          attrs: { type: "button", "data-dismiss": "modal" }
        },
        [_vm._v("Close")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "modal jt-modal fade", attrs: { id: "previewImage" } },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("h4", { staticClass: "modal-title" }, [_vm._v("Preview")]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "pull-right closeBtn",
                    attrs: { "data-dismiss": "modal", "aria-hidden": "true" }
                  },
                  [_vm._v("×")]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "img-div" }, [
                  _c("img", { attrs: { src: "", width: "100%" } })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flyer-template-picker__swatch" })
              ])
            ])
          ]
        )
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7e28cc5a", { render: render, staticRenderFns: staticRenderFns })
  }
}