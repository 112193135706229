<doc>
  Badge Vue.js component
</doc>

<template>
  <span :class="className" :style="style" v-on:click="$emit('click', $event)">
    <icon :name="icon" class="badge-icon" v-if="isIcon"></icon>
    {{ label }}
    <span class="btn-close" v-if="hasClose" v-on:click="$emit('removeBadge', { event: $event, query: label })">
      <icon name="times"></icon>
    </span>
  </span>
</template>

<script>
import "vue-awesome/icons"
export default {
  name: "Badge",
  props: {
    label: { type: String, required: true },
    theme: { type: String, default: "primary" },
    hasClose: { type: Boolean, default: false },
    pill: { type: Boolean, default: false },
    showIcon: { type: Boolean, default: false },
    icon: { type: String, default: "tag" },
    isTag: { type: Boolean, default: false },
    isCapitalize: { type: Boolean, default: true },
    mr: { type: Number, default: 0 },
    ml: { type: Number, default: 0 },
    mt: { type: Number, default: 0 },
    mb: { type: Number, default: 0 },
    ma: { type: Number, default: 0 }
  },
  computed: {
    className: function() {
      let pill = this.pill ? " badge-pill " : ""
      let capitalize = this.isCapitalize ? "text-capitalize" : ""
      return `${capitalize} badge${pill} badge-${this.theme}`
    },
    style: function() {
      if(this.ma > 0)
        return {
          margin: `${this.ma}px`
        }

      return {
        marginLeft: `${this.ml}px`,
        marginTop: `${this.mt}px`,
        marginRight: `${this.mr}px`,
        marginBottom: `${this.mb}px`
      }
    },
    isIcon: function() {
      if(this.showIcon && (this.isTag || this.icon))
        return true
    }
  }
}
</script>

<style lang="scss" src="./Badge.scss" scoped></style>
