<doc>
	Add/edit Brand Signatures Modal Vue.js component
</doc>

<template>
<div class="modal jt-modal fade" id="brandSignatureModal">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Add/Edit Brand Signature</h4>
                <span class="pull-right closeBtn" data-dismiss="modal" aria-hidden="true">&times;</span>
            </div>
            <div class="modal-body">
              <form id="new-brand-form" class="form" ref="brandForm" v-on:submit.prevent>
                <input type="hidden" class="hd-id" />
                <input type="hidden" class="hd-brand-id" />
                <InputTextArea
                    class="block1"
                    label="Signature Block-8-5 x 3"
                    name="set-signature-block-8-5-x-2"
                  />
                  <InputTextArea
                    class="block2"
                    label="Signature Block Custom 1"
                    name="set-signature-block-custom-1"
                  />
                  <InputTextArea
                    class="block3"
                    label="Signature Block Custom 2"
                    name="set-signature-block-custom-2"
                  />
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default btnSave">Save</button>
              <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>


</template>

<script>
import { createNamespacedHelpers } from "vuex"
import BaseModal from "../BaseModal"
const { mapState, mapActions } = createNamespacedHelpers("organization")
export default {
  name: "BrandSignatureModal",
  components: {
    BaseModal
  },
  data() {
    return {
    }
  },
  computed: Object.assign(
    {},
    mapState(["currentEditedItem", "isEdit"]),
    {
      showBrandSignatureItemModal: {

        get() {
          console.log('showBrandSignatureModal get');
          return this.$store.state.showBrandSignatureItemModal
        },
        set(value) {
          console.log('showBrandSignatureModal set');
          this.toggleBrandSignatureModal(value)
        }
      },
      modalTitle() {
        return 'Add/Edit Signatures'
      },
      brand() {
        let brand = { name: "", about: "" }
        if (this.currentEditedItem) {
          brand = Object.assign({}, this.currentEditedItem)
        }

        this.logoSrc = brand.logo || ""

        return brand
      },
      isLoading() {
        //return !this.currentEditedItem && this.isEdit
        return false
      }
    }
  ),
  methods: Object.assign(
    {},
    mapActions(["toggleBrandSignatureItemModal"]),
    {
      hideModal() {
        this.toggleBrandSignatureItemModal(false)
        //this.resetForm()
      },
      submitForm: function() {
        const vm = this
      }
    }
  ),
  created() {
    $(document).on('click', '.btnSave', function(){
      let id = $('.hd-id').val()
      let brandSignature = []
      brandSignature['brand_id'] = $('.hd-brand-id').val()
      brandSignature['block1'] = $('.block1 textarea').val()
      brandSignature['block2'] = $('.block2 textarea').val()
      brandSignature['block3'] = $('.block3 textarea').val()


      if(id){
        brandSignature['id'] = id
        let bbS = {
          'id':id,
        'brand_id':$('.hd-brand-id').val(),
        'block1':$('.block1 textarea').val(),
        'block2':$('.block2 textarea').val(),
        'block3':$('.block3 textarea').val()
      };
        BB.brandSignatureManage.update(bbS);
        $('#brandSignatureModal').modal('hide')
      }
      else{
        let bbS = {
          'brand_id':$('.hd-brand-id').val(),
          'block1':$('.block1 textarea').val(),
          'block2':$('.block2 textarea').val(),
          'block3':$('.block3 textarea').val()
        };
        BB.brandSignatureManage.add(bbS);
        $('#brandSignatureModal').modal('hide')
      }
	  BB.Toastr.success('Signature updated.');
    })
  }

}

</script>
<style>
	.block1.form-group label, .block2.form-group label, .block3.form-group label {
		top: -15px;
	}
	.block1.form-group.focus label, .block2.form-group.focus label, .block3.form-group.focus label {
		top: -15px;
		transform: unset;
	}
</style>
