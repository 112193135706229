<doc>
  Add/edit Program Modal Vue.js component
</doc>

<template>
  <BaseModal
    ref="modal"
    v-model="showItemModal"
    :title="modalTitle"
    id="newPricingSetModal"
    @beforeEnter="beforeEnter"
    @afterEnter="afterEnter"
    @ok="submitForm"
    @cancel="hideModal"
    okLabel="Save"
    :isLoading="isLoading"
  >
    <h5>
      Set your parameters
    </h5>
    <p class="intro">Set up the parameters of your new custom loan set.</p>
    <form
      id="new-pricing-set-form"
      class="form"
      ref="newPricingSetForm"
      v-on:submit.prevent
    >
      <InputText
        v-model="programSet.name"
        label="Name of program"
        name="set-name"
      />
      <InputTextArea
        v-model="programSet.description"
        label="Description"
        name="set-desc"
      />
	  <DropDown
        label="Type of filter"
        class="filterInputName"
        name="set-filter-name"
        v-model="filter.name"
        :options="options"
        v-on:dropDownChange="onInput"
        v-if="readonly"
      />
      <DropDown
        v-else
        label="Type of filter"
        class="filterInputName"
        name="set-filter-name"
        v-model="filter.name"
        :options="options"
        v-on:dropDownChange="onInput"
        :readonly="'readonly'"
      />

      <div class="form-preview disable" ref="formPreview">
        <template v-if="readonly">
        <InputText
                v-model="filter.value"
                label="Value"
                name="set-filter-value"
        />
      </template>
      <template v-else>
        <InputText 
                v-model="filter.value"
                label="Value"
                name="set-filter-value"
                :readonly="'readonly'"
        />
        </template>
        </div>
        <input type="hidden" name="set-id" />
        <input type="hidden" name="set-weight" />

        <div class="form-preview-overlay"></div>
    </form>
  </BaseModal>
</template>

<script>
import { mapState, mapActions } from "vuex"
import BaseModal from "../BaseModal"
export default {
  name: "ProgramModal",
  components: { BaseModal: BaseModal },
  data() {
    return {
      options: {
        neighborhood: "neighborhood",
        city: "city",
        zipcode: "zipcode",
      },
      filter: { name: "", description: "" }
    }
  },
  computed: Object.assign(
    {},
    mapState([
      "loid",
      "bank_id",
      "originalItems",
      "currentEditedItem",
      "isEdit"
    ]),
    {
      showItemModal: {
        get() {
          return this.$store.state.showItemModal
        },
        set(value) {
          this.toggleItemModal(value)
        }
      },
      modalTitle() {
        const prefix = this.isEdit ? "Edit" : "New"
        return `${prefix} Loan Program Set`
      },
      programSet() {
        let programSet = { name: "", description: "" }
        if (this.currentEditedItem) {
          programSet = Object.assign({}, this.currentEditedItem)
        } else if (!this.showItemModal) {
          // Reset values when the modal is closed
          programSet = { name: "", description: "" }
        }
        return programSet
      },
      isLoading() {
        return !this.currentEditedItem && this.isEdit
      },
      readonly(){
        //return true;
        console.log('programset', this.programSet.is_default == 1 ? true : false);
        return (this.programSet.is_default == 1) ? false : true
      }
    }
  ),
  watch: {
    "filter.name": function(val, oldVal) {
      if (val != oldVal) {
        const $formPreview = this.$refs.formPreview
        const $filterInputValue = $(".filterInputValue")
        $filterInputValue.data("context", val)
        // clear the filter value input if the filter type has changed
        if (oldVal) this.filter.value = ""
        $filterInputValue.autocomplete("disable")
        $filterInputValue.autocomplete("enable")
        $formPreview.classList.remove("disable")
      }
    },
    currentEditedItem: function(val, oldVal) {
      if (val && this.showItemModal) {
        const tmpFilter =
          this.currentEditedItem.rules &&
          JSON.parse(this.currentEditedItem.rules)[0]

        if (tmpFilter && tmpFilter.name) {
          this.$refs.formPreview.classList.remove("disable")
          this.filter = tmpFilter
        }
      } else if (!this.showItemModal) {
        // Reset values when the modal is closed
        this.filter = { name: "", value: "" }
      }
    }
  },
  methods: Object.assign(
    {},
    mapActions(["toggleItemModal", "saveProgramSet"]),
    {
      onInput(val) {
        this.filter.name = val
      },
      beforeEnter() {},
      afterEnter() {
        this.initAutocomplete()
      },
      hideModal() {
        this.$refs.newPricingSetForm.reset()
        this.$refs.formPreview.classList.add("disable")
        //this.toggleItemModal()
      },
      submitForm: function() {
        const vm = this
		if(vm.programSet.name === undefined) {
          BB.Toastr.error("Name of program is required")
          return;
        }
        const form = vm.$refs.newPricingSetForm
        const editSetId = vm.programSet.id || null
        const editSetWeight = vm.programSet.weight || vm.originalItems.length
        const filterValue = (vm.filter.value == undefined)? "":vm.filter.value 

        const data = {
          name: vm.programSet.name,
          description: vm.programSet.description,
          rules: `[{ "name":"${vm.filter.name}", "value": "${filterValue}" }]`,
          //rules: `[{ "name":"${vm.filter.name}", "value": "${vm.filter.filterValue}" }]`,
          owner: vm.loid,
          bank_id: vm.bank_id,
          weight: editSetWeight
        }

        if (editSetId) {
          data.id = parseInt(editSetId, 10)
        }

        this.saveProgramSet({ data: data, isEdit: editSetId })
      },
      // Autocomplete
      initAutocomplete: function() {
        const vm = this

        $(".filterInputValue").autocomplete({
          source: function(request, response) {
            $.get(
              "/get_listing_values.json?field=" +
                $("#set-filter-name")
                  .val()
                  .replace(" ", "_") +
                "&str=" +
                request.term +
                "&loid=" +
                $("body").data("user_id"),
              function(data) {
                response(data)
              }
            )
          },
          appendTo: "#autocomplete-wrapper",
          search: function(event, ui) {
            $("#autocomplete-loader").removeClass("hide")
          },
          response: function(event, ui) {
            $("#autocomplete-loader").addClass("hide")
          },
          change: function(event, ui) {
            if (ui.item) {
              vm.filter.value = ui.item.value
            } else {
              vm.filter.value = ""
            }
          }
        })
      }
    }
  )
}
</script>

<style lang="scss">
#autocomplete-loader {
  position: absolute;
  right: 10px;
  left: auto;
  font-size: 20px;
  top: 28px;
}
#newPricingSetModal {
  input,
  textarea,
  select {
    font-size: 16px;
  }
  select {
    height: 38px;
  }
  h5 {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .intro {
    margin-bottom: 25px;
  }

  .ui-autocomplete {
    position: absolute !important;
    background: #fff;
    box-shadow: 0 8px 16px 0 rgba(12, 0, 51, 0.1);
    margin-top: 1px;
    border-radius: 3px;
    .ui-menu-item {
      .ui-menu-item-wrapper {
        padding: 8px 16px;
      }
      .ui-state-active {
        background-color: #d5edf1;
      }

      &:first-child {
        .ui-state-active {
          border-radius: 3px 3px 0 0;
        }
      }
      &:last-child {
        .ui-state-active {
          border-radius: 0 0 3px 3px;
        }
      }
    }
  }
}
</style>
