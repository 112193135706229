const objectFitImages = require('object-fit-images');
const PASSWORD_MIN = 8;
const PASSWORD_MAX = 32;
const PASSWORD_REGEX = /^(?=.*\d)(?=.*[~!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/;
const VALIDATOR = {
  file_validate_size: [5242880], // 5MB
  file_validate_extensions: ["png jpg jpeg"],
}

$(function() {
  const step1 = $('#step-1');
  const step2 = $('#step-2');
  const step3 = $('#step-3');
  const submitButton = $('#password-form-submit-button');

  //console.log('SESSION_ID', window.SESSION_ID);

  step1.on('click', '[data-action="confirm-data"]', (e) => {
    e.preventDefault();

    step2.removeClass('hide');
    step1.addClass('hide');
  });

  step1.on('click', '[data-action="incorrect-data"]', (e) => {
    e.preventDefault()
    step1.find('.signup-flow__data').addClass('hide');
    step1.find('.signup-flow__info').removeClass('hide');
  });

  const passwordForm = step2.find('form');
  const picture = passwordForm.find('#picture');
  const agencyLogo = passwordForm.find('#agencyLogo');
  const photoInputs = {
    picture: {
      input: picture.find('input'),
      img: picture.find('img'),
      loader: picture.find('.loader'),
      text: picture.find('.form__photo-upload-text'),
      textHtml: ['+ Add Photo', 'Change Photo'],
      name: 'picture_fid',
    },
    agencyLogo: {
      input: agencyLogo.find('input'),
      img: agencyLogo.find('img'),
      loader: agencyLogo.find('.loader'),
      text: agencyLogo.find('.form__photo-upload-text'),
      textHtml: ['+ Add Agency Logo', 'Change Agency Logo'],
      name: 'agency_logo_fid',
    }
  }

  passwordForm.on('click', '#picture a', (e) => {
    // e.preventDefault();
    photoInputs.picture.input[0].click();
  });

  passwordForm.on('click', '#agencyLogo a', (e) => {
    // e.preventDefault();
    photoInputs.agencyLogo.input[0].click();
  });

  function uploadPhoto(event, photoUpload) {
    const file = (event.target.files && event.target.files[0]) ? event.target.files[0] : null;
 
    if(file) {
      const data = new FormData();
      const reader = new FileReader();

      data.append('files[img]', file);
      data.append('bb_sid', window.SESSION_ID);
      data.append('validator', JSON.stringify(VALIDATOR));

      reader.onload = (e) => {
        photoUpload.img.attr('src', e.target.result);
        objectFitImages(photoUpload.img);
      };
      reader.readAsDataURL(file);

      submitButton.attr('disabled', true);
      photoUpload.loader.removeClass('hide');
      photoUpload.text.html('Uploading');

      fetch('/bb-file-upload.json?bb_sid=' + window.SESSION_ID, {
        method: 'POST',
        body: data,
        credentials: 'same-origin'
      }).then(res => {
        return res.json();
      }).then(data => {
        if(data.status === "error") {
          photoUpload.img.attr('src', null);
          BB.Toastr.error(data.message.charAt(0).toUpperCase() + data.message.slice(1))
          photoUpload.loader.addClass('hide');
          photoUpload.text.html(photoUpload.textHtml[0]);
        }
        else {
          passwordForm[0][photoUpload.name].value = data.message.fid;
          submitButton.attr('disabled', !isFormValid());
          photoUpload.loader.addClass('hide');
          photoUpload.text.html(photoUpload.textHtml[1]);
        }
      }).catch(err => {
        console.error(err);
        BB.Toastr.error("Something went wrong. Please contact support!")
        photoUpload.loader.addClass('hide');
        photoUpload.text.html(photoUpload.textHtml[0]);
      });
    }
  }

  photoInputs.picture.input.on('change', (e) => {
    uploadPhoto(e, photoInputs.picture);
  });

  photoInputs.agencyLogo.input.on('change', (e) => {
    uploadPhoto(e, photoInputs.agencyLogo);
  });

  $('#phone, #email').on('keyup', () => {
    submitButton.attr('disabled', !isFormValid());
  });

  //verify password 
  const password = $('#password');
  const verifyPassword = $('#verifyPassword');
  const togglePassword = password.siblings('.toggle-password:first');
  const toggleVerifyPassword = verifyPassword.siblings('.toggle-password:first');
  var isPasswordValid = undefined;
  var isVerifyPasswordValid = undefined;

  password.on('keyup', () => {
    let passwordVal = password.val();
    let isValid = passwordVal.length >= PASSWORD_MIN 
      && passwordVal.length <= PASSWORD_MAX 
      && PASSWORD_REGEX.test(passwordVal);
    
    if(verifyPassword.val()) {
      let isVerifyValid = verifyPassword.val() === password.val();
      if(isVerifyValid !== isVerifyPasswordValid) {
        isVerifyPasswordValid = isValid;
        toggleValidIcon(verifyPassword, isValid);
      }
    }
    
    if(isValid !== isPasswordValid) {
      isPasswordValid = isValid;
      toggleValidIcon(password, isValid);
    }
  });

  verifyPassword.on('keyup', () => {
    if(verifyPassword.val()) {
      let isValid = verifyPassword.val() === password.val();
      if(isValid !== isVerifyPasswordValid) {
        isVerifyPasswordValid = isValid;
        toggleValidIcon(verifyPassword, isValid);
      }
    }
    else {
      verifyPassword.siblings('.fa-check-circle:first').addClass('hide');
      verifyPassword.siblings('.fa-times-circle:first').addClass('hide');
    }
  });

  togglePassword.on('click', (e) => {
    e.preventDefault();
    togglePasswordVisibility(password, togglePassword);
  });

  toggleVerifyPassword.on('click', (e) => {
    e.preventDefault();
    togglePasswordVisibility(verifyPassword, toggleVerifyPassword);
  });

  function toggleValidIcon(input, isValid) {
    if(isValid) {
      input.siblings('.fa-check-circle:first').removeClass('hide');
      input.siblings('.fa-times-circle:first').addClass('hide');
      submitButton.attr('disabled', !isFormValid());
    }
    else {
      input.siblings('.fa-times-circle:first').removeClass('hide');
      input.siblings('.fa-check-circle:first').addClass('hide');
      submitButton.attr('disabled', true);
    }
  }

  function togglePasswordVisibility(input, toggle) {
    if(input.attr('type') === 'password') {
      input.attr('type', 'text');
      toggle.html('Hide');
    }
    else {
      input.attr('type', 'password');
      toggle.html('Show');
    }
  };

  function isFormValid() {
    return isPasswordValid && isVerifyPasswordValid 
      // && passwordForm[0].picture_fid.value
      //&& passwordForm[0].agency_logo_fid.value
      && passwordForm[0].email.value
      && passwordForm[0].phone.value;
  }

  passwordForm.on('submit', (e) => {
    e.preventDefault();
    if(isFormValid()) {
      $('#password-form-submit-button').prop("disabled",true);
      const loader = step3.find('.signup-flow__loading');
      const successMessage = step3.find('.signup-flow__success');
      const defaultError = step3.find('[data-error="default"]');
      const accountExistError = step3.find('[data-error="account-exist"]');
      const data = new FormData();

      data.append('invite_hash', passwordForm[0].hash.value);
      data.append('bb_sid', window.SESSION_ID);
      data.append('picture_fid', passwordForm[0].picture_fid.value);
      data.append('agency_logo_fid', passwordForm[0].agency_logo_fid.value)
      data.append('email', passwordForm[0].email.value);
      data.append('phone', passwordForm[0].phone.value);
      data.append('password', passwordForm[0].password.value);

      step2.addClass('hide');
      step3.removeClass('hide');
      loader.removeClass('hide');
      successMessage.addClass('hide');
      defaultError.addClass('hide');
      accountExistError.addClass('hide');

      fetch('/create-realtor.json', {
        method: 'POST',
        body: data,
        credentials: 'same-origin'
      }).then(res => {
        return res.text();
      }).then(dataText => {
        return dataText.length ? JSON.parse(dataText) : {}
      }).then(data => {
        if (data.status && data.status === 'error') {
          BB.Toastr.error((data.message) ? data.message : "Something went wrong. Please contact support!")
          return Promise.reject((data.message) ? data.message : 'Error');
        }
        loader.addClass('hide');
        successMessage.removeClass('hide');
        $('#password-form-submit-button').prop("disabled",false);
      
        if(data.user && data.user.name){
          $('.uname').html(data.user.name);
          $('.signup-flow__success a').attr('href','/user/login?user='+data.user.name);
        }
      }).catch(err => {
        console.error(err);
        loader.addClass('hide');
        $('#password-form-submit-button').prop("disabled",false);

        if (err === 'user already exists') {
          accountExistError.removeClass('hide');
          return;
        }

        defaultError.removeClass('hide');
      });
    }
  });

  $('.msaCheckbox').on('click', function(e){
      if($(this).is(':checked')){
          $('[data-action="confirm-data"]').prop("disabled", false);
      }
      else{
          $('[data-action="confirm-data"]').prop("disabled", true);          
      }
  });
});
