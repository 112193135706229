<doc>
	Programs details Vue.js component
</doc>

<template>
  <div :class="className">
    <div class="program-details-img">
      <ProgramProfileThumbs :programProfiles="item.pricing_profiles" />
      <div class="program-overlay">
        <!--<div class="drag-handler d-none d-lg-flex">
          <icon name="ellipsis-v"></icon>
          <icon name="ellipsis-v"></icon>
          <icon name="ellipsis-v"></icon>
        </div>-->
        <BaseButton outline class="btn-view-program" v-on:click="showModal">View Set</BaseButton>
        <SmartListItemOptions
          :gridView="gridView"
          :itemIndex="itemIndex"
          :itemId="item.id"
          :onDragEnd="onDragEnd"
          :isLast="isLast"
          :cloneItem="cloneProgramSet"
          :showEditItemModal="showEditItemModal"
          :removeItem="removeItem"
          :options-list="['edit','delete', 'setDefault']"
          :item-list="itemList"
          :setDefault="setDefault"
        />
      </div>
    </div>
    <div :class="isDefault?'program-details org-default':'program-details'">
      <div class="program-details-title">{{ item.name }}</div>
      <div v-if="isDefault" class="default-tag">org default</div>
      <div v-if="isUserDefault" class="user-active-tag">Active</div>
      <div v-if="isUserDefault" class="user-default-tag">user default</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import SmartListItemOptions from "../SmartList/SmartListItemOptions"
import ProgramProfileThumbs from "./ProgramProfileThumbs"
import "vue-awesome/icons/ellipsis-v"
export default {
  name: "ProgramsDetails",
  components: {
    SmartListItemOptions: SmartListItemOptions,
    ProgramProfileThumbs: ProgramProfileThumbs
  },
  props: {
    item: { type: Object, required: true },
    itemIndex: { type: Number, required: true },
    isLast: { type: Boolean, default: false },
    onDragEnd: { type: Function }
  },
  computed: Object.assign({}, mapState(["gridView"]), {
    className: function() {
      let className = "program"
      if (!this.gridView) {
        className += " list-view d-flex"
      }
      if (this.$mq == "sm" || this.$mq == "md") {
        className += " not-desktop"
      }
      return className
    },
    imgSrc: function() {
      let src = [
        "/sites/all/themes/bankingbridge_ui/images/programs",
        "-mini",
        ".png"
      ]
      if (this.gridView) {
        src.splice(1, 1)
      }
      return src.join("")
    },
    isDefault: function() {
      if (!this.item || !this.$store.state.default_set) return false
      return this.item.id == this.$store.state.default_set.id
    },
    isUserDefault: function(){
      if (!this.item || !this.$store.state.user_default_set) return false
      return this.item.id == this.$store.state.user_default_set.id
    },
    itemList: function(){
      if(this.isDefault){
        if(this.$store.state.user_is_org_manager)
          return ['edit'];
        else
          return [];
      }
      return ['edit', 'delete']
      //return (user_is_org_manager == 1) ? ['edit', 'delete'] : [];
    }
  }),
  methods: Object.assign(
    {},
    mapActions([
      "showEditItemModal",
      "cloneProgramSet",
      "removeItem",
      "showEditProgramDetailsModal"
    ]),
    {
      showModal() {
        this.showEditProgramDetailsModal(this.item.id)
      },
      setDefault(itemId){
        console.log('ItemId:'+itemId);
        let formData = new FormData();
        formData.append('id', itemId);
        formData.append('is_default', 1);

        // fetch('/pricing-set-manage.json', {
        //     method: 'post',
        //     body: new URLSearchParams({id:itemId, is_default:1}),
        // })
        // .then(r => r.json())
        // .then((response)=>{
        //   console.log(response);
        // });
        let that = this
        const nameSpace = this.vuexNamespace ? `${this.vuexNamespace}/` : ""

        $.ajax({
          url: '/pricing-set-manage.json',
          type: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({id:itemId, is_default:1}),
          success(data) {
            console.log(data);
            if(data.status=='updated'){
              BB.Toastr.success('Default Set');
              //that.$store.dispatch('reloadItems',1);
              //that.$store.dispatch(`${nameSpace}reloadItems`, 1)
            }
            else{
              BB.Toastr.error('Program set with filters cannot be set to default');
            }
          },
          error(xhr, ajaxOptions, thrownError) {
           
          }
        })
      }
    }
  )
}
</script>

<style lang="scss" scoped>
.drag-handler {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  position: absolute;
  left: 8px;
  top: 10px;
  color: #fff;
  display: flex;
  // TODO: fix this
  .list-view & {
    display: none;
    color: #666;
    left: 25px;
  }
}

.program {
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.17);
  margin: 15px 0;

  &.list-view {
    border-left: 4px solid #58bec8;
    margin: 0 0 10px;
    padding: 8px 5px;
    background: #fff;
    &:hover {
      .drag-handler {
        display: flex;
      }
    }

    .program-details {
      padding: 0;
      display: flex;
      align-items: center;
      &-title {
        margin-bottom: 0;
        padding: 0 80px 0 40px;
        min-width: 185px;
      }

      &-img {
        position: unset;
      }
    }
    .program-overlay {
      opacity: 1;
      background: none;
    }
  }

  img {
    max-width: 100%;
    display: block;
  }
  &-details {
    background: #fff;
    padding: 25px 16px;
    position: relative;
    &-img {
      position: relative;
    }
    &-title {
      color: #595959;
      font-size: 14px;
      //margin-bottom: 6px;
    }
    &-meta {
      color: #abc0d3;
      font-size: 11px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  &-overlay {
    z-index: 6;
    opacity: 0;
    transition: opacity 0.25s ease;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    background: rgba(53, 64, 82, 0.35);
  }

  &:hover {
    .program-overlay {
      body:not(.dragging) & {
        opacity: 1;
      }
    }
  }
}
.btn-view-program {
  .list-view & {
    margin-top: 23px;
  }

  .grid-view & {
    top: calc(50% - 16px);
    position: relative;
    color: #fff;
    border-color: #fff;
    z-index: 2;
    &:hover {
      color: #fff;
    }
  }
}

.not-desktop {
  .program-overlay {
    opacity: 1;
    background: rgba(53, 64, 82, 0.66);
  }
}
.default-tag {
  top: 24px;
  position: absolute;
  right: 10px;
  font-size: 11px;
  background: #7e57c2;
  padding: 3px 7px;
  color: white;
  border-radius: 5px;
  font-weight: 400;
}

.user-default-tag {
  top: 24px;
  position: absolute;
  right: 85px;
  font-size: 11px;
  background: #7e57c2;
  padding: 3px 7px;
  color: white;
  border-radius: 5px;
  font-weight: 400;
}
.user-active-tag {
  top: 24px;
  position: absolute;
  right: 165px;
  font-size: 11px;
  background: #57c262;
  padding: 3px 7px;
  color: white;
  border-radius: 5px;
  font-weight: 400;
}
</style>
