import Toastr from "toastr"
import { arrayMove } from "../../helpers"
import _ from "lodash"

const ENDPOINT = "/manual-flyer-manage.json"

// Initial state
const state = {
  bank_id: null,
  uid: null,
  templates: null,
  showManualListingPrintModal: false,
  currentFlyerImage: null,
  loDetails: null,
  isBankAdmin: false,
  isLoading: true
}

const mutations = {
  SET_BANK_ID(state, bank_id) {
    state.bank_id = bank_id
  },
  SET_UID(state, uid) {
    state.uid = uid
  },
  SET_TEMPLATES(state, templates) {
    state.templates = templates
  },
  TOGGLE_PRINT_MODAL(state, condition) {
    state.showManualListingPrintModal = condition
  },
  SET_CURRENT_FLYER_IMAGE(state, item) {
    state.currentFlyerImage = item
  },
  SET_LO_DETAILS(state, details) {
    state.loDetails = details
  },
  SET_IS_BANK_ADMIN(state, value) {
    state.isBankAdmin = value
  },
  SET_LOADING(state, value) {
    state.isLoading = value
  }
}

const actions = {
  getTemplates({ commit, state }) {
    if (!state.templates) {
      BB.template.get({ type: "flyer" }).then(function(data) {
        let templates = []
        data.data.forEach(function(template) {
          if (template.active === "1") {
            templates.push(template)
          }
        })
        commit("SET_TEMPLATES", templates)
      })
    }
  },
  initManualListing({ commit, dispatch, state }, info) {
    commit("SET_BANK_ID", info.bank_id)
    commit("SET_UID", info.uid)
    commit("SET_IS_BANK_ADMIN", info.isBankAdmin)
    dispatch("getFlyers", info.uid)
    dispatch("getLODetails", info.uid)
  },
  upsertItem({ commit, dispatch, getters, state }, payload) {
    if (!payload.item.id) {
      return null
    }

    let currentItem = payload.item
    let items = [...state.originalItems]
    var index = _.findIndex(items, { id: currentItem.id })

    if (payload.existsItem) {
      currentItem.tags = getters.getTagsCurrentItem
    } else {
      currentItem.tags = state.savedTags
    }

    if (index == -1) {
      items[items.length] = currentItem
    } else {
      items.splice(index, 1, currentItem)
    }

    commit("UPDATE_ORIGINAL_ITEMS", items)
  },
  saveCustomFlyer({ commit, dispatch, state }, item) {
    Toastr.success("Saving listing…")
    let itemId = item.id
    if (itemId) {
      BB.manualListing
        .update(item)
        .then(data => {
          console.log(data)
          dispatch("upsertItem", { item, existsItem: true })
          dispatch("toggleItemModal", false)
          Toastr.success("Listing saved")
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      BB.manualListing
        .create(item)
        .then(data => {
          // add item
          console.log(data)
		  if(data.status ==  'error'){
            Toastr.error(data.message)
          }
		  else{
          let itemId = data.listing_id
          dispatch("toggleItemModal", false)
          item.id = itemId

          //dispatch("associateTag", itemId)
          dispatch("upsertItem", { item, existsItem: false })
          //dispatch("clearTags")
          Toastr.success("Listing saved")
		  }
        })
        .catch(err => {
          console.error(err)
        })
    }
  },
  getItemById({ commit, state }, ID) {
    // it isn't neccesary because we can get item by id from the store
    // var item=BB.manualFlyer
    //     .get({id:ID})
    //     .then(data=>{return data[0]});
    let item = state.originalItems.find(item => item.id === ID)

    return item
  },
  getFlyers({ commit, dispatch, state }, owner) {
    return BB.listing.manual
      .get({ owner })
      .then(data => {
        if (data.status !== "error") {
          let items = data
          /*
          BB.tag.association.get({ list_name: "custom_flyers" }).then(data => {
            let tagIndexedById = data.reduce(
              (acc, current) => ({
                ...acc,
                [current.item_id]: {
                  item_id: current.item_id,
                  value: []
                }
              }),
              {}
            )

            data.forEach(item => {
              tagIndexedById[item.item_id].value.push({
                icon: "default",
                id: state.tagsIndexed[item.value].id,
                list_name: "custom_flyers",
                text: item.value,
                type: "item"
              })
            })

            let itemsWithTags = items.map(item => {
              return {
                ...item,
                tags: tagIndexedById[item.id]
                  ? tagIndexedById[item.id].value
                  : []
              }
            })

            commit("UPDATE_ORIGINAL_ITEMS", itemsWithTags)
          })
*/
          commit("UPDATE_ORIGINAL_ITEMS", items)
          commit("SET_LOADING", false)
        }
      })
      .catch(err => {
        console.error(err)
      })
  },
  removeManualListing({ commit, dispatch, state }, info) {
    Toastr.success("Deleting Manual Listing…")
    BB.listing.manual.delete(info.id).then(data => {
      const itemToDelete = Object.assign({}, state.originalItems[info.index])
      const listTagsItemToDelete =
        itemToDelete.tags && itemToDelete.tags.length > 0
          ? itemToDelete.tags
          : null

      if (listTagsItemToDelete)
        listTagsItemToDelete.forEach(tag => {
          BB.tag.association.delete({ tag_id: tag.id, item_id: info.id })
        })

      commit("REMOVE_ITEM", info.index)
      Toastr.success("Listing deleted")
    })
  },
  togglePrintModal({ commit }, condition) {
    commit("TOGGLE_PRINT_MODAL", condition)
  },
  showPrintManualListingModal({ commit, dispatch }, id) {
    commit("TOGGLE_PRINT_MODAL", true)
    dispatch("getItemById", id).then(data => {
      commit("SET_CURRENT_FLYER_IMAGE", data)
    })
  },
  getLODetails({ commit, dispatch, state }, loid) {
    return BB.loManage.get(loid).then(data => {
      commit("SET_LO_DETAILS", data.lo)
    })
  }
}

const getters = {
  isLoading: state => _isEmpty(state.org)
}

export default {
  state,
  mutations,
  actions,
  getters
}
