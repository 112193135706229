var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Tabs",
        [
          _c(
            "Tab",
            { attrs: { name: "Contact Info" } },
            [_c("ContactInfo")],
            1
          ),
          _vm._v(" "),
          _c(
            "Tab",
            { attrs: { name: "Pricing Engine" } },
            [_c("PricingEngine")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-09e858f4", { render: render, staticRenderFns: staticRenderFns })
  }
}