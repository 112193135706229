<doc>
    Archive Vue.js component
</doc>
<template>
    <div id="extraProfileDetails">
        
        <div :class="`${key} form-group`" v-for="(value, key) in fields" :key="key">
            <label class="control-label" > {{ value.label }} </label>
            <template v-if="value.widget.type=='text_textfield'">
                <InputText
                readonly
                v-model="lo[value.cus_name]"
                
                :name="value.field_name"
            />
            </template>
            <template v-if="value.widget.type=='options_onoff'">
                <input
                disabled
                type="checkbox"
                v-model="lo[value.field_name]"
                :label="value.label"
                :name="value.field_name"
            />
            </template>
            
            <!--<span class="desc">Enter your {{ value.label }}</span>-->
        </div>
		<div class="mobile-phone form-group">
            <label class="control-label" for="inputMobilePhone">Phone # for Signature Block</label>
            <InputText
                readonly
                v-model="lo.phone"
                name="phone"
            />
            <!--<span class="desc">Enter your mobile phone number</span>-->
        </div>
        <div class="office-phone form-group">
            <label class="control-label" for="inputOfficePhone">Office Phone</label>
            <InputText
                readonly
                v-model="lo.office_phone"
                name="office_phone"
            />
            <!--<span class="desc">Enter your office phone number</span>-->
        </div>
        <div></div>        
        <div class="btn-save-wrapper form-group">
            <button class="btn btn-modern" @click="save">Save</button>
        </div>
    </div>
</template>
<script>
import _find from "lodash/find"
import { createNamespacedHelpers } from "vuex"
const { mapState, mapActions } = createNamespacedHelpers("user-profile-details")
export default {
    name: "MyNetworkArchive",
    components: {
    },
    data() {
        return {
            lo: [],
            fields:[]
        }
    },
    async mounted(){
        console.log('mounted', fields);
        this.fields = fields
        //let l = []
        for(var field in fields){
            let cname = fields[field].cus_name
            this.lo[cname] = fields[field].value
        }
        for(var field in extra_fields){
            this.lo[field] = extra_fields[field]
        }
        console.log(this.lo);
    },
  methods: Object.assign(
    {},
        mapActions([
            
        ]),
        {
            save(){
                console.log('lo', this.lo)
                let l = Object.assign({}, this.lo);
                l.loid = uid;
                l.active = 1;
                console.log(l);
                const data = l;
                $.ajax({
                type: 'POST',
                url: '/lo-manage.json',
                data: JSON.stringify(data),
                contentType: "application/json",
                dataType: "json",
                success: function (response) {
                    // Display errors.
                    if (response.status == 'success') {
                        console.log('ok');
                        BB.Toastr.success(response.message);
                    }
                    else{

                    }
                },
                error: function (xmlhttp) {
                    
                }
            });
            }
        }
    ),
    created : function() {
        //this.initTypeahead();
        let that = this;
        // jQuery.ajax({
        //     url: '/lo-manage.json?loid=29',
        //     type: "GET",
        //     contentType: "application/json",
        //     dataType: "json",
        //     success(data) {
        //         that.lo = data.lo.extra_details 
        //     },
        //     error(xhr, ajaxOptions, thrownError) { 
        //     }
        // })

        $(document).ready(function(){
        $('#field_lflop_impersonate_users').addClass('hide');
		$('#field_lflop_impersonate_users').after('<input class="text-full form-text display-alternative-text form-control" type="text"  value="" size="60" readonly="readonly" style="background:#eee;">');
		$.ajax({
			url: "/organization/members.json",
			type: "GET",
			contentType: "application/json",
			dataType: "json",
			success: function(res) {
				if(res.status== 'error'){
					$('#field_lflop_impersonate_users').after('<span style="color:red;font-size:12px;padding-bottom: 5px;display:block;">'+res.message+'</span>');
					return;
				}
				let prev = $('#field_lflop_impersonate_users').val().split(',');
				
				let html = '<div class="username-list" style="max-height:200px;overflow-y:auto;padding:10px;background:#fff;">';
				html += '<div><input type="text" class="search-username form-control" placeholder="Type to filter" /></div>';
				let members = res.members;
				let displayValues = '';
				for (let k in members){
					let mem = members[k].members;
					for(let i=0;i<=mem.length-1;i++){
						if(mem[i].uid == res.uid) continue;
						let checked = '';
						if(prev.includes(mem[i].username+':'+mem[i].uid)){ 
							checked = "checked='checked'"
							displayValues += mem[i].name+', '
						}
						html += '<label style="display: inline-block;width:100%;"><input type="checkbox" name="imp_users" class="imp-users" '+checked+' value="'+mem[i].username+'" data-id="'+mem[i].uid+'" data-name="'+mem[i].name+'" /><span>'+mem[i].name+'</span></label>';
					}
				}
				displayValues = displayValues.slice(0, -2);
				html += '</div>';
				$('.field_lflop_impersonate_users.form-group').append(html);
				$('.display-alternative-text').val(displayValues);
				//$('#field_lflop_impersonate_users').
			}
		})
		
		$(document).on('click', '.imp-users', function(){
			let displayCheckedValues = '';
			let saveCheckedValues = '';
			$('.field_lflop_impersonate_users.form-group .imp-users').each(function(index){
				if($(this).is(':checked')){
					displayCheckedValues += $(this).attr('data-name')+', ';
					saveCheckedValues += $(this).val()+':'+$(this).attr('data-id')+',';
				}
			});
			
			displayCheckedValues = displayCheckedValues.slice(0, -2);
			saveCheckedValues = saveCheckedValues.slice(0, -1);
			$('#field_lflop_impersonate_users').val(saveCheckedValues);
			$('.display-alternative-text').val(displayCheckedValues);
            that.lo.impersonate_user = saveCheckedValues
		});

		$(document).on('keyup', '.search-username', function(){
			let searchText = $(this).val().toLowerCase();
			$('.username-list label').removeClass('hide');
			$('.username-list label').each(function(index){
				if($(this).find('span').html().toLowerCase().startsWith(searchText)){
					$(this).removeClass('hide');
				}
				else{
					$(this).addClass('hide');
				}
			});
		})
    })
    }
}
</script>


<style>
	#extraProfileDetails .form-group div div.form-group {
		margin-top: 5px;
		margin-bottom: 5px;
	}
	label.control-label {
		font-size: 14px;
		color: #222;
	}
	#extraProfileDetails .form-group input, #extraProfileDetails .form-group textarea {
		font-size: 13px;
		padding: 4px 8px;
	}
	#extraProfileDetails .form-group textarea {
		height: 150px;
	}
	#extraProfileDetails .form-group span.desc {
		font-style: italic;
	}
</style>

<style lang="scss" scoped>
	#extraProfileDetails {
		display: flex;
		flex-wrap: wrap;
	}
	#extraProfileDetails .form-group {
		width: 40%;
		margin-right: 2%;
		margin-bottom: 30px;
	}
    .lflop_fullname, .lflop_phone, .lflop_applink, .lflop_flyer_preference, .lflop_originator_id, .lflop_biz_channel, .lflop_external, .field_lflop_manual_preference, .lflop_pricing_engine_name, .lflop_pricing_engine_connected {
        display:none;
    }
    .lflop_firstname {
        order:1;
    }
    .lflop_lastname {
        order:2;
    }
    .mobile-phone { 
        order:3;
    } 
    .office-phone {
        order: 4;
    }
    .lflop_email {
        order: 5;
    }
    .lflop_nmls {
        order: 6;
    } 
    .field_lflop_impersonate_users {
        order: 7;
    }
    .lflop_bankname {
        order: 8;
    }
    .btn-save-wrapper{
        order: 999;
    }
</style>