<doc>
  Step Overview manual flyer form Vue.js component
</doc>

<template>
	<div>
	  <grid-row>
		<grid-column>
		  <InputTextArea
			class="prop-overview__input"
			v-model="value.overview"
			label="Property Overview"
			name="overview"
			helperText
			:limit="500"
		  />
		</grid-column>
	  </grid-row>
	  <!-- <grid-row>
		<grid-column size="6">
          <InputText
			class="prop-overview-open-date-time"
            type="datetime-local"
            v-model="value.open_date"
            label="Open Date & Time"
            name="open_date"
            v-on:input="onInput"
          />
        </grid-column> -->
	  </grid-row>
	</div>
</template>

<script>
export default {
  name: "FormStepOverview",
  props: {
    value: { type: Object },
    onInput: { type: Function }
  },
  methods: {}
}
</script>

<style>
.prop-overview__input textarea {
  min-height: 175px;
}
.prop-overview-open-date-time {
	padding-top: 17px;
}
.prop-overview-open-date-time .input-label {
	top: -10px!important;
}
</style>
