var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition-group",
    {
      attrs: { name: "modal" },
      on: {
        "before-enter": _vm.beforeEnter,
        "after-enter": _vm.afterEnter,
        "after-leave": _vm.afterLeave
      }
    },
    [
      _c(
        "div",
        { key: "modal", staticClass: "flyer-modal", attrs: { tabindex: "-1" } },
        [
          _c("div", { staticClass: "modal-dialog flyer-modal-dialog" }, [
            _c("div", { staticClass: "modal-content flyer-modal-content" }, [
              _c("div", { staticClass: "modal-header flyer-modal-header" }, [
                _c("h4", { staticClass: "modal-title flyer-modal-title" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.title) +
                      "\n                    "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { theme: "", type: "button", "aria-label": "Close" },
                    on: { click: _vm.cancel }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "closeBtn",
                        attrs: { "aria-hidden": "true" }
                      },
                      [_vm._v("×")]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body flyer-modal-body" }, [
                _c("div", { staticClass: "create-tag-block" }, [
                  _c("div", { staticClass: "new-tag" }, [_vm._v("New Tag")]),
                  _vm._v(" "),
                  _c("div", [
                    _c("input", {
                      staticClass: "txt-create-tag",
                      attrs: { type: "text" }
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-modern",
                        on: { click: _vm.save }
                      },
                      [
                        _vm._v(
                          "\n                                Create Tag\n                            "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "tag-lists-wrapper" }, [
                    _c("div", { staticClass: "inactive-tags-wrapper" }, [
                      _c("h4", [_vm._v("Inactive Tags")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list-wrap" }, [
                        _c("div", { staticClass: "inactive-tag-list" }, [
                          _c(
                            "ul",
                            _vm._l(_vm.inactive_tags, function(tag, index) {
                              return _c(
                                "li",
                                {
                                  key: tag.id,
                                  class: {
                                    active:
                                      _vm.inactive_selected_index === index
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.setInActiveIndex(tag, index)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(tag.name))]
                              )
                            })
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "sort-buttons-wrapper" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-modern",
                              on: { click: _vm.inactive_move_up }
                            },
                            [_c("i", { staticClass: "fa-solid fa-caret-up" })]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-modern",
                              on: { click: _vm.inactive_move_down }
                            },
                            [_c("i", { staticClass: "fa-solid fa-caret-down" })]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-modern",
                              on: { click: _vm.move_to_active }
                            },
                            [
                              _c("i", {
                                staticClass: "fa-solid fa-caret-right"
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-modern",
                              on: { click: _vm.move_to_inactive }
                            },
                            [_c("i", { staticClass: "fa-solid fa-caret-left" })]
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "active-tags-wrapper" }, [
                      _c("h4", [_vm._v("Active Tags(Ordered)")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list-wrap" }, [
                        _c("div", { staticClass: "active-tag-list" }, [
                          _c(
                            "ul",
                            _vm._l(_vm.active_tags, function(tag, index) {
                              return _c(
                                "li",
                                {
                                  key: tag.id,
                                  class: {
                                    active: _vm.active_selected_index === index
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.setActiveIndex(tag, index)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(tag.name))]
                              )
                            })
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "sort-buttons-wrapper" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-modern",
                              on: { click: _vm.active_move_up }
                            },
                            [_c("i", { staticClass: "fa-solid fa-caret-up" })]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-modern",
                              on: { click: _vm.active_move_down }
                            },
                            [_c("i", { staticClass: "fa-solid fa-caret-down" })]
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer flyer-modal-footer" }, [
                _c(
                  "button",
                  { staticClass: "btn btn-modern", on: { click: _vm.update } },
                  [
                    _vm._v(
                      "\n                        Update\n                    "
                    )
                  ]
                )
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _vm.isVisible
        ? _c("div", { key: "backdrop", staticClass: "modal-backdrop in" })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-a6c46526", { render: render, staticRenderFns: staticRenderFns })
  }
}