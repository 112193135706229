var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DownloadExcel",
    {
      attrs: {
        fetch: _vm.fetchData,
        fields: _vm.dataFields,
        name: "my-archive"
      }
    },
    [_c("BaseButton", [_vm._v("Excel Export")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-e2444fe2", { render: render, staticRenderFns: staticRenderFns })
  }
}