<doc>
  Step parameters manual flyer form Vue.js component
</doc>

<template>
  <div>
    <grid-row>
      <grid-column size="6">
        <InputText
          type="number"
          v-model="value.mls"
          label="MLS #"
          name="mls"
          v-on:input="onInput"
        />
      </grid-column>
      <grid-column size="6">
        <InputText
          v-model.trim="value.elem_school"
          label="Elem School"
          name="elem_school"
          v-on:input="onInput"
        />
      </grid-column>
    </grid-row>
    <grid-row>
      <grid-column size="6">
        <InputText
          v-model.trim="value.middle_school"
          label="Middle School"
          name="middle_school"
          v-on:input="onInput"
        />
      </grid-column>
      <grid-column size="6">
        <InputText
          v-model.trim="value.high_school"
          label="High School"
          name="high_school"
          v-on:input="onInput"
        />
      </grid-column>
    </grid-row>
    <grid-row>
      <grid-column size="6">
        <InputText
          type="number"
          v-model.number="value.year_built"
          label="Year Built"
          name="year_built"
          min="1800"
          :max="new Date().getFullYear()"
          v-on:input="onInput"
        />
      </grid-column>
      <grid-column size="6">
        <DropDown
          label="Property Type"
          name="property_type"
          labelClassName="required"
          v-model="value.property_type"
          :options="property_type"
          v-on:dropDownChange="onInput"
        />
      </grid-column>
    </grid-row>
  </div>
</template>

<script>
export default {
  name: "FormStepParams1",
  props: {
    value: { type: Object },
    onInput: { type: Function }
  },
  data() {
    return {
      property_type: {
        single_family_home: "Single Family Home",
        townhome: "Townhome",
        condominium: "Condominium",
        multi_tenant_home: "Multi Unit Home",
        manufactured_home: "Manufactured/Mobile Home"
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
