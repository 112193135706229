<doc>
  Realtor search manual flyer form Vue.js component
</doc>

<template>
  <div class="buildModeSelect">
    <div class="row btnRow">
      <button @click="modeSelect('listingSearch')" type="button" class="btn btn-med-turquesa btn-lg">Listing Search</button>
      <button @click="modeSelect('manual')" type="button" class="btn btn-med-turquesa manualMode btn-lg ">Manual</button>
    </div>
    <div v-if="mode==='listingSearch'">   
      <div class="listing-left">
        
        <div class="relative-block">
          <div class="margin-top-bottom" v-if="!isSearchByMLSID">
              Type the Realtor's name in the search bar below and click the search icon. It will take a few moments to load the results.
          </div>
          <div class="margin-top-bottom" v-if="isSearchByMLSID">
              Type the Realtor MLS ID # in the search bar below and click the search icon. It will take a few moments to load the results.
          </div>
          <div class="realtor-wrapper">
            <div class="relative-block">
              <div class="form-build-icon">
                <i class="fas fa-search" @click="fetchOptionsHandler"></i>
              </div>
              <v-select label="combined" class="style-chooser"
                @input="setSelected"
                :filterable="false"
                :options="realtorList"
                :placeholder="'Search for a Realtor'"
                :components="{OpenIndicator}"
                ref="realtor"
                @search="saveSearch"
                :map-keydown="handlers"
                :clearSearchOnBlur="overrideClear"
                inputId="input"
              >
                <template slot="no-options">
                  type to search Realtors
                </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.combined }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.combined }}
                  </div>
                </template>
              </v-select>
            </div>
            <div class="listing-left">
              <label><input type="checkbox" @change="changeSearchRealtorByMLSID" />Search by Realtor MLS ID #</label>
            </div>
          </div>
        </div>  

        <div v-if="!selectedListing && listingList.length>0">
          <v-select label="fullstreetaddress" class="style-chooser" @input="setSelectedListing" :options="listingList" :placeholder="'Click on the down arrow to see the listings for the Realtor'">
            <template slot="no-options">
              type to search Listings
            </template>
            <template slot="option" slot-scope="option">
              <div class="selected d-center">
                {{ option.fullstreetaddress }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.fullstreetaddress }}
              </div>
            </template>
          </v-select>
        </div>
        
        <div class="relative-block">
          <div class="margin-top-bottom">
              Type the Listing MLS ID in the search bar below and click the search icon. It will take a few moments to load the results.
          </div>
          <div class="form-build-icon">
            <i class="fas fa-search" @click="fetchRealtorsByListingMLSID"></i>
          </div>
          <v-select label="combined" class="style-chooser"
            @input="setSelectedListing"
            :filterable="false"
            :options="listingRealtorList"
            :placeholder="'Search by MLS Listing ID'"
            :components="{OpenIndicator}"
            ref="listing"
            @search="saveListingMLSSearch"
            :map-keydown="handlerMLSListing"
            :clearSearchOnBlur="overrideClear"
            inputId="input1"
          >
            <template slot="no-options">
              type to search Realtors
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.fullstreetaddress }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.fullstreetaddress }}
              </div>
            </template>
          </v-select>
        </div>
        
        <div v-if="selectedListing">
          <div>
            <div class="row d-flex justify-content-center">
              <div class="col-sm-6 col-md-4">
                <div class="thumbnail">
                  <img :src="selectedListing.pictures[0]" alt="...">
                  <div class="caption">
                    <h3>{{ selectedListing.street_addr }}</h3>
                    <p>{{ selectedListing.overview.length > 150 ? `${selectedListing.overview.substring(0, 150)}...` : selectedListing.overview }}</p>
                    <p class="btnWrapper">
                      <a href="#" @click="clearListing" class="btn btn-default" role="button">Clear</a>
                      <a href="#" @click="nextStep" class="btn btn-primary" role="button">Select</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>      
    </div>    
    <LoaderPlaceholder v-if="isLoading" type="circle" />
  </div>
</div>
</template>

<script>
import { StatesDropDown } from "../../Forms"
import ImgUploader from "../../ImgUploader"
import _find from "lodash/find";
import _debounce from "lodash/debounce";
import { CustomDropdown } from "../../Forms"
import vSelect from "vue-select"

export default {
  name: "FormBuildMode",
  props: {
    value: { type: Object },
    onInputStates: { type: Function },
    enableSaveNext: { type: Function },
    editMode: { type: Boolean, default: false },
    isEdit: { type: Boolean, default: false }
  },
  data() {
    return {
      mode: "",
      realtorList: [],
      listingList: [],
      listingRealtorList:[],
      selectedRealtor: null,
      selectedListing: null,
      entry: "",
      entryListing: "",
      listingMLSID:"",
      isSearchByMLSID: false,
      OpenIndicator: {
        render: createElement => createElement("span")
      },
      isLoading: false
    }
  },
  components: { StatesDropDown, ImgUploader, CustomDropdown, vSelect },
  methods: {
    handlers: (map, vm) => ({
      map,
      13: (e) => {
        e.preventDefault()
        if(vm.$parent.selectedListing) vm.$parent.selectedListing = null
        vm.$parent.fetchOptionsHandler()
      },
    }),
    handlerListings: (map, vm) => ({
      map,
      13: (e) => {
        e.preventDefault()
        if(vm.$parent.selectedListing) vm.$parent.selectedListing = null
        vm.$parent.fetchListing()
      },
    }),
    handlerMLSListing: (map, vm) => ({
      map,
      13: (e) => {
        e.preventDefault()
        if(vm.$parent.selectedListing) vm.$parent.selectedListing = null
        vm.$parent.fetchRealtorsByListingMLSID()
      },
    }),
    changeSearchRealtorByMLSID(){
      console.log('changeSearchRealtorByMLSID');
      this.isSearchByMLSID = !this.isSearchByMLSID
    },
    
    modeSelect(mode){
      this.mode=mode
      if(this.mode==="manual"){
        this.$emit("onChooseManualMode" )
      }
    },
    saveSearch(search){
      if(search != "") {
        this.entry = search
      }
    },
    saveSearchListing(search){
      if(search != "") {
        this.entryListing = search
      }
    },
    saveListingMLSSearch(search){
      if(search != "") {
        this.listingMLSID = search
      }
    },

    async fetchListing(){
      this.$refs.listing.loading = true
      this.isLoading = true
      let response=await $.get("/api/v1/homescout-listing?agent_mls_id="+this.entryListing)
      if(response.status){
		BB.Toastr.error(response.message);
        this.listingList=[];
      }
      else{
        // this.listingList=response.sort(function(a, b) {
        //   return a.fullstreetaddress.localeCompare(b.fullstreetaddress)
        // })
console.log('response', response);
        response.forEach(realtor => {
          realtor.combined =  realtor.first_name + " " + realtor.last_name + " - " + realtor.agency_name
        })
        this.realtorList=response.sort(function(a, b) {
          return a.combined.localeCompare(b.combined)
        })
      }
      this.$refs.listing.loading = false
      this.isLoading = false
    },
    async fetchOptions(search,loading){
      loading(true)
      let response=''

      if(this.isSearchByMLSID){
        response=await $.get("/api/v1/homescout-realtors-by-mls-id?agent_mls_id="+search)
      }
      else{
        response=await $.get("/api/v1/homescout-realtors?name_str="+search)
      }

      if(response.status){
        this.realtorList=[];
      }
      else{
        this.realtorList=response
      }
      loading(false)
    },
    async fetchOptionsHandler() {
      this.$refs.realtor.loading = true
      this.isLoading = true
      let response=''

      if(this.isSearchByMLSID){
        response=await $.get("/api/v1/homescout-realtors-by-mls-id?agent_mls_id="+this.entry)
        if(response.status){
		  BB.Toastr.error(response.message);
          this.realtorList=[];
        }
        else{
          response.forEach(realtor => {
            realtor.combined =  realtor.first_name + " " + realtor.last_name + " - " + realtor.agency_name
          })
          this.realtorList=response.sort(function(a, b) {
            return a.combined.localeCompare(b.combined)
          })
        }
      }
    else{
      response=await $.get("/api/v1/homescout-realtors?name_str="+this.entry)
      if(response === undefined){
		BB.Toastr.error('Not Found');
		this.realtorList=[];
	  }
	  else if(response.status){
		BB.Toastr.error(response.message);
        this.realtorList=[];
      }
      else{
        console.log('re response', response);
        response.forEach(realtor => {
          realtor.combined =  realtor.first_name + " " + realtor.last_name + " - " + realtor.agency_name
        })
        this.realtorList=response.sort(function(a, b) {
          return a.combined.localeCompare(b.combined)
        })
      }
    }
      
      this.$refs.realtor.loading = false
      this.isLoading = false
      this.$refs.realtor.search = this.entry
      document.getElementById("input").focus();
    },
    async fetchRealtorsByListingMLSID(){
      this.$refs.listing.loading = true
      this.isLoading = true
      let response=await $.get("/api/v1/homescout-listing-by-listing-mls-id?listing_mls_id="+this.listingMLSID)
      //console.log('res',response);
      if(response.status){
		BB.Toastr.error(response.message);
        this.listingRealtorList=[];
      }
      else{
        // response.forEach(realtor => {
        //   realtor.combined =  realtor.first_name + " " + realtor.last_name + " - " + realtor.agency_name
        // })
        this.listingRealtorList=response.sort(function(a, b) {
          return a.fullstreetaddress.localeCompare(b.fullstreetaddress)
        })
      }
      //console.log(this.listingRealtorList);
      this.$refs.listing.loading = false
      this.isLoading = false
      this.$refs.listing.search = this.listingMLSID
      document.getElementById("input1").focus();
    },
    async setSelected(val){
      this.selectedRealtor=val
      let response=[];
      try{
        this.isLoading = true;
        this.realtorList = []
        response=await $.get("/api/v1/homescout-realtor-listings?realtor_id="+val.id)
        console.log(response);
      }
      catch (e) {
        console.log("GET failed")
      }
	  
	  if(response.status){
		this.listingList = [];
		BB.Toastr.error(response.message);
	  }
	  else{
		  this.listingList=response.sort(function(a, b) {
			return a.fullstreetaddress.localeCompare(b.fullstreetaddress)
		  })
	  }
      this.isLoading = false;
    },
    async setSelectedListing(val){
      console.log('val',val);
      this.selectedRealtor = val.realtor;
      if(!this.selectedRealtor){
        console.log("selected realtor is empty!")
        return
      }
      if(!val){
        return
      }
      let newRealtor={
        first_name: this.selectedRealtor.first_name,
        last_name: this.selectedRealtor.last_name,
        phone: (this.selectedRealtor.phone ? this.selectedRealtor.phone : ""),
        agency_name: (this.selectedRealtor.agency_name ? this.selectedRealtor.agency_name : ""),
        email: (this.selectedRealtor.email ? this.selectedRealtor.email : ""),
        picture: "",
        agency_logo: "",
        title: "",
        type: "homescout",
        homescout_id: this.selectedRealtor.id
      }
      this.value.realtorData=newRealtor

      let newListing={};
      //newListing.data={}
      newListing.street_addr=val.fullstreetaddress
      newListing.city=val.city
      newListing.state=val.state
      newListing.zipcode=val.zipcode
      newListing.county=val.county
      newListing.neighborhood=val.neighborhood
      newListing.num_beds=val.num_beds
      newListing.num_baths=val.num_baths
      newListing.num_half_baths=val.num_half_baths
      newListing.property_type=
        val.property_type.toLowerCase().includes("condo") ? "condominium" :
        val.property_type.toLowerCase().includes("town") ? "townhome" :
        val.property_type.toLowerCase().includes("multi") ? "multi_tenant_home" :
        val.property_type.toLowerCase().includes("manu") ? "manufactured_home" : "single_family_home"
      newListing.year_built=val.year_built
      newListing.sqft=val.sqft
      newListing.listing_price=val.listing_price
      newListing.hoa=val.hoa
      newListing.high_school=val.high_school
      newListing.middle_school=val.middle_school
      newListing.elem_school=val.elem_school
      newListing.mls=val.mls_id
      newListing.overview = val.remarks
      newListing.pictures=[];
      for(let i=0; i<val.pictures.length; i++){
        newListing.pictures.push(val.pictures[i].url)
      }
      this.selectedListing=newListing;
    },
    overrideClear() {
      return
    },
    clearListing(){
      this.selectedListing=null;
    },
    nextStep(){
      this.$emit("onListingSelect", {newListing:this.selectedListing, newRealtor:this.value.realtorData})
    }
  },
  created() {
    if(this.isEdit){
      this.modeSelect('manual')
    }
  },
  updated() { 
  }
}
</script>

<style lang="scss">
  .style-chooser {
      .vs__dropdown-menu {
        max-height: 200px;
      }
  }
  .buildModeSelect{
    min-height: 300px;
  }
  .manualMode{
    background-color: #7e57c2;
    &:hover{
      background-color: #65469b;
    }

  }
  .btnRow{
    text-align: center;
  }
  .caption {
    h3 {
      margin: 0px;
      font-weight: bolder;
    }
    .btnWrapper{
      margin-top:10px;
    }
  }
  .fa-search {
    transform: none !important;
  }
  .margin-top-bottom {
    margin: 1em 0
  }
  .form-build-icon {
    right: 5px;
    top: 35px;
    position: absolute;
    cursor: pointer;
    z-index: 100;
  }
  .vs__clear {
    margin-right: 24px;
  }
  .vs__spinner {
    margin-right: 24px;
  }
  .relative-block{
    position: relative;
  }

  .realtor-wrapper{
    display: flex;
  }
  .realtor-wrapper .relative-block {
    width: 50%;
  }
  .realtor-wrapper .listing-left {
    margin-left: 30px; 
    margin-top: 4px;
  }
  .realtor-wrapper .listing-left label input {
    margin-right: 4px;
  }
  .realtor-wrapper .form-build-icon{
    top: 9px;
  }
</style>