<template>
  <div class="p-3 county-editor">
    <div class="pb-3">
      <Autocomplete
        placeholder="Start typing to search for a county"
        class="form-group material-input"
        input-class="form-control"
        :resultsDisplay="acResultsDisplay"
        :source="getItems"
        @selected="selectCounty"
      />
    </div>
    <div>
      <div v-if="isLoading" class="text-center">
        <LoaderPlaceholder class="loader" type="circle" />
      </div>
      <Card v-if="county">
        <LoaderPlaceholder
          v-if="isSaving"
          class="loader county-save-loader"
          type="circle"
        />
        <h5>{{ county.county_name }}</h5>
        <form id="county-form" class="form" v-on:submit.prevent>
          <grid-row>
            <grid-column>
              <InputText
                v-model="county.effective_tax_rate"
                label="Effective tax rate"
                name="effective_tax_rate"
                v-on:input="onInput"
              /> </grid-column
          ></grid-row>
          <!--
             <InputText
            v-model="county.county_name"
            label="County Name"
            name="county_name"
            v-on:input="onInput"
          />
          <StatesDropDown
            label="State"
            name="state"
            v-model="county.state"
            :onInput="onInputSates"
          />-->

          <grid-row>
            <grid-column>
              <StatesDropDown
                :readonly="true"
                label="State"
                name="state"
                v-model="county.state"
                :onInput="onInputSates"
              />
            </grid-column>

            <grid-column>
              <InputText
                :readonly="true"
                v-model="county.fips_county_code"
                label="FIPS county code"
                name="fips_county_code"
                v-on:input="onInput"
              /> </grid-column
          ></grid-row>

          <grid-row>
            <grid-column>
              <InputText
                :readonly="true"
                v-model="county.fha_one_unit_limit"
                label="FHA one unit limit"
                name="fha_one_unit_limit"
                v-on:input="onInput"
              />
            </grid-column>

            <grid-column>
              <InputText
                :readonly="true"
                v-model="county.fips_state_code"
                label="FIPS state code"
                name="fips_state_code"
                v-on:input="onInput"/></grid-column
          ></grid-row>
          <grid-row>
            <grid-column>
              <InputText
                :readonly="true"
                v-model="county.one_unit_limit"
                label="One unit limit"
                name="one_unit_limit"
                v-on:input="onInput"
              />
            </grid-column>

            <grid-column>
              <InputText
                :readonly="true"
                v-model="county.two_unit_limit"
                label="Two unit limit"
                name="two_unit_limit"
                v-on:input="onInput"
              />
            </grid-column>
          </grid-row>

          <grid-row>
            <grid-column>
              <InputText
                :readonly="true"
                v-model="county.three_unit_limit"
                label="Three unit limit"
                name="three_unit_limit"
                v-on:input="onInput"
              />
            </grid-column>
            <grid-column>
              <InputText
                :readonly="true"
                v-model="county.four_unit_limit"
                label="Four unit limit"
                name="four_unit_limit"
                v-on:input="onInput"
              /> </grid-column
          ></grid-row>
          <div class="d-flex pt-3">
            <div class="ml-auto">
              <BaseButton v-if="changed" class="mr-2" @click="saveConfig">
                Save
              </BaseButton>
              <BaseButton outline @click="cancel">
                Cancel
              </BaseButton>
            </div>
          </div>
        </form>
      </Card>
    </div>
  </div>
</template>

<script>
import Autocomplete from "vuejs-auto-complete"
import { bbCountyManage } from "../../bb-api"
import { StatesDropDown } from "../Forms"
import Card from "../Card"

export default {
  name: "CountyAdmin",
  components: {
    Autocomplete,
    StatesDropDown,
    Card
  },
  data() {
    return {
      county: null,
      isLoading: false,
      isSaving: false,
      term: "",
      changed: false
    }
  },
  methods: {
    selectCounty({ selectedObject }) {
      this.county = null
      const [name, state] = selectedObject.split(", ")

      this.isLoading = true
      const that = this
      bbCountyManage.get({ name, state }, county => {
        that.county = county
        that.isLoading = false
      })
    },
    acResultsDisplay(option) {
      const re = new RegExp(this.term, "gi") // reg exp based on the input value
      const formatted = option.replace(re, "<strong>$&</strong>")
      return `
          <div class='resultWrapper'>
            <div>
              <span class="name">${formatted}</span>
            </div>
          </div>
      `
    },
    getItems(term) {
      this.term = term
      return bbCountyManage.SEARCH_ENDPOINT + term
    },
    onInput() {
      this.changed = true
    },
    onInputSates(val) {
      this.changed = true
      this.county = Object.assign({}, this.county, {
        state: val
      })
    },
    cancel() {
      this.county = null
    },
    saveConfig() {
      this.isSaving = true
      const that = this
      //const county = Object.assign({}, this.county)
      const county = {
        state: this.county.state,
        name: this.county.county_name,
        effective_tax_rate: this.county.effective_tax_rate
      }
      bbCountyManage.save(county, res => {
        if (res.status === "error") {
          BB.Toastr.error("Update error.")
        } else {
          BB.Toastr.success("County updated successfully.")
        }
        that.isSaving = false
        that.changed = false
      })
    }
  }
}
</script>

<style lang="scss" scoped src="./CountyAdmin.scss"></style>
