var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.modalTitle,
        id: "manualFlyerModal",
        isLoading: _vm.isLoading,
        large: ""
      },
      on: {
        ok: _vm.submitForm,
        cancel: _vm.hideModal,
        clickMask: _vm.hideModal
      },
      model: {
        value: _vm.showItemModalComp,
        callback: function($$v) {
          _vm.showItemModalComp = $$v
        },
        expression: "showItemModalComp"
      }
    },
    [
      _c(
        "form",
        {
          ref: "manualFlyerForm",
          staticClass: "form",
          attrs: { id: "manual-flyer-form" },
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "FormStep",
            {
              attrs: {
                title: _vm.formFields[_vm.currentStep].title,
                intro: _vm.formFields[_vm.currentStep].intro
              }
            },
            [
              _c("FormBuildMode", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentStep === 1,
                    expression: "currentStep === 1"
                  }
                ],
                attrs: {
                  onInputRealtor: _vm.onInputRealtor,
                  onInputStates: _vm.onInputStates,
                  showRealtorForm: _vm.showSaveNext,
                  toggleRealtorFields: _vm.toggleRealtorFields,
                  enableSaveNext: _vm.enableSaveNext,
                  isEdit: _vm.isEdit,
                  showEditMode: _vm.showEditMode
                },
                on: {
                  onListingSelect: _vm.onListingSelect,
                  onChooseManualMode: _vm.onChooseManualMode
                },
                model: {
                  value: _vm.form,
                  callback: function($$v) {
                    _vm.form = $$v
                  },
                  expression: "form"
                }
              }),
              _vm._v(" "),
              _c("FormStepRealtor", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentStep === 2,
                    expression: "currentStep === 2"
                  }
                ],
                ref: "formRef",
                attrs: {
                  onInputRealtor: _vm.onInputRealtor,
                  onInputStates: _vm.onInputStates,
                  showRealtorForm: _vm.showSaveNext,
                  toggleRealtorFields: _vm.toggleRealtorFields,
                  enableSaveNext: _vm.enableSaveNext,
                  disableSaveNext: _vm.disableSaveNextFn,
                  isEdit: _vm.isEdit,
                  showEditMode: _vm.showEditMode
                },
                model: {
                  value: _vm.form,
                  callback: function($$v) {
                    _vm.form = $$v
                  },
                  expression: "form"
                }
              }),
              _vm._v(" "),
              _c("FormStepAddress", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentStep === 3,
                    expression: "currentStep === 3"
                  }
                ],
                attrs: {
                  onInput: _vm.onInput,
                  onInputStates: _vm.onInputStates
                },
                on: { onInputPlaces: _vm.onInputPlaces },
                model: {
                  value: _vm.form,
                  callback: function($$v) {
                    _vm.form = $$v
                  },
                  expression: "form"
                }
              }),
              _vm._v(" "),
              _c("FormStepParams1", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentStep === 4,
                    expression: "currentStep === 4"
                  }
                ],
                attrs: { onInput: _vm.onInput },
                model: {
                  value: _vm.form,
                  callback: function($$v) {
                    _vm.form = $$v
                  },
                  expression: "form"
                }
              }),
              _vm._v(" "),
              _c("FormStepParams2", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentStep === 5,
                    expression: "currentStep === 5"
                  }
                ],
                attrs: { onInput: _vm.onInput },
                model: {
                  value: _vm.form,
                  callback: function($$v) {
                    _vm.form = $$v
                  },
                  expression: "form"
                }
              }),
              _vm._v(" "),
              _c("FormStepOverview", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentStep === 6,
                    expression: "currentStep === 6"
                  }
                ],
                attrs: { onInput: _vm.onInput },
                model: {
                  value: _vm.form,
                  callback: function($$v) {
                    _vm.form = $$v
                  },
                  expression: "form"
                }
              }),
              _vm._v(" "),
              _c("FormStepPictures", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentStep === 7,
                    expression: "currentStep === 7"
                  }
                ],
                attrs: {
                  pictures: _vm.form.pictures,
                  addPhoto: _vm.addPhoto,
                  removePhoto: _vm.removePhoto,
                  setCover: _vm.setCover,
                  movePhoto: _vm.movePhoto
                }
              }),
              _vm._v(" "),
              _c("FormStepPricing", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentStep === 8,
                    expression: "currentStep === 8"
                  }
                ],
                attrs: {
                  "set-pricing": _vm.setPricing,
                  "pricing-config": _vm.form.pricing ? _vm.form.pricing : {},
                  "loan-info": _vm.loanInfo,
                  "toggle-parent-btns": _vm.toggleFooterButtons
                }
              }),
              _vm._v(" "),
              _c("FinalStep", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentStep === 9,
                    expression: "currentStep === 9"
                  }
                ]
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "BaseButton",
            {
              staticClass: "btn-cancel",
              attrs: { theme: "link" },
              on: { click: _vm.hideModal }
            },
            [_vm._v("Cancel")]
          ),
          _vm._v(" "),
          _vm.showBack
            ? _c(
                "BaseButton",
                {
                  attrs: { outline: "", disabled: _vm.isLoading },
                  on: { click: _vm.backStep }
                },
                [_vm._v("Back")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showNext
            ? _c(
                "BaseButton",
                {
                  attrs: { theme: "modern", disabled: _vm.disableNext },
                  on: { click: _vm.nextStep }
                },
                [_vm._v("Next")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showSaveNext
            ? _c(
                "BaseButton",
                {
                  attrs: { theme: "modern", disabled: _vm.disableSaveNext },
                  on: { click: _vm.saveRealtor }
                },
                [_vm._v("Save & Continue")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showSubmit
            ? _c(
                "BaseButton",
                { attrs: { theme: "modern" }, on: { click: _vm.submitForm } },
                [_vm._v("\n      Save\n    ")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("AlertModal", {
        attrs: { showAlert: _vm.showAlert, text: _vm.confirmTxt },
        on: { modalConfirm: _vm.modalConfirm }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-13dbab21", { render: render, staticRenderFns: staticRenderFns })
  }
}