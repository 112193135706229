<template>
  <Tabs>
    <Tab name="Rate Table">
      <RateTableManager />
    </Tab>
    <Tab name="Lead Workflow">
      <LeadWorkflowManager />
    </Tab>
    <Tab name="Rateflow">
      <RateflowManager />
    </Tab>
    <Tab name="Contact Form">
      <ContactFormManager />
    </Tab>
    <Tab name="County Admin">
      <CountyAdmin />
    </Tab>
  </Tabs>
</template>

<script>
import {
  Tab,
  Tabs,
  RateTableManager,
  ConfigEditorWrapper,
  LeadWorkflowManager,
  RateflowManager,
  ContactFormManager,
  CountyAdmin
} from "../components"

export default {
  name: "ConfigEditorManager",
  components: {
    Tabs,
    Tab,
    RateTableManager,
    ConfigEditorWrapper,
    LeadWorkflowManager,
    RateflowManager,
    ContactFormManager,
    CountyAdmin
  }
}
</script>
