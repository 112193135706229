var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.className }, [
    _c("div", { staticClass: "program-details-img" }, [
      _c("img", {
        attrs: {
          src: _vm.details.pictures[0],
          alt: _vm.item.street_addr + " flyer thumb"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "program-overlay" },
        [
          _c(
            "BaseButton",
            {
              staticClass: "btn-view-program",
              attrs: { outline: "" },
              on: { click: _vm.showPrintModal }
            },
            [_vm._v("Print Flyer")]
          ),
          _vm._v(" "),
          _c("SmartListItemOptions", {
            attrs: {
              gridView: _vm.gridView,
              itemIndex: _vm.itemIndex,
              itemId: _vm.item.id,
              onDragEnd: _vm.onDragEnd,
              isLast: _vm.isLast,
              showEditItemModal: _vm.showEditItemModal,
              "options-list": _vm.optList,
              removeItem: _vm.removeItem
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { class: ["program-details", this.programDetailsStyle] },
      [
        _c("div", { staticClass: "program-details-title mb-3" }, [
          _c("h4", [_vm._v(_vm._s(_vm.item.street_addr))]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex" }, [
            _c("p", [
              _vm._v(
                "$" +
                  _vm._s(
                    new Intl.NumberFormat("en-EN").format(
                      _vm.details.listing_price
                    )
                  )
              )
            ]),
            _vm._v(" "),
            _c("p", [_vm._v("MLSID: " + _vm._s(_vm.details.mls))])
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(_vm.details.realtorData && _vm.details.realtorData.label)
            )
          ])
        ]),
        _vm._v(" "),
        _c("ManageBadge", {
          attrs: {
            toggleable: "",
            items: _vm.tagsItem,
            showIcon: "",
            isTag: "",
            limit: 3
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "manual-share" }, [
          _c("div", { staticClass: "dropup" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "ul",
              {
                staticClass: "dropdown-menu dropdown-menu-left",
                attrs: { "aria-labelledby": "dropdownMenuShare" }
              },
              [
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: {
                        role: "button",
                        "data-toggle": "modal",
                        "data-target": "#social_preview_modal"
                      },
                      on: {
                        click: function($event) {
                          _vm.shareListing(
                            _vm.item.id,
                            _vm.item.owner,
                            "facebook"
                          )
                        }
                      }
                    },
                    [_vm._v("Share on Facebook")]
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: {
                        role: "button",
                        "data-toggle": "modal",
                        "data-target": "#social_preview_modal"
                      },
                      on: {
                        click: function($event) {
                          _vm.shareListing(
                            _vm.item.id,
                            _vm.item.owner,
                            "linkedin"
                          )
                        }
                      }
                    },
                    [_vm._v("Share on Linkedin")]
                  )
                ])
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "agentconnect-extra-tools" }, [
          _c("div", { staticClass: "dropup" }, [
            _c("a", {
              staticClass:
                "btn btn-small-white glyphicon glyphicon-option-vertical dropdown-toggle",
              attrs: {
                type: "button",
                id: "dropdownMenu2",
                "data-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "false"
              }
            }),
            _vm._v(" "),
            _c(
              "ul",
              {
                staticClass: "dropdown-menu dropdown-menu-right",
                attrs: { "aria-labelledby": "dropdownMenu2" }
              },
              [
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { role: "button" },
                      on: {
                        click: function($event) {
                          _vm.copyListingUrl(
                            "/listing?manual_listing_id=" +
                              _vm.item.id +
                              "&lo=" +
                              _vm.item.owner
                          )
                        }
                      }
                    },
                    [_vm._v("Copy Listing Url")]
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "/listing?manual_listing_id=" +
                          _vm.item.id +
                          "&lo=" +
                          _vm.item.owner,
                        target: "_blank"
                      }
                    },
                    [_vm._v("View Listing")]
                  )
                ])
              ]
            )
          ])
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal jt-modal fade",
        attrs: { id: "social_preview_modal", role: "dialog" }
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h4", { staticClass: "modal-title" }, [
                _c("span", { class: "social-square-logo " + _vm.share_for }),
                _vm._v("Preview Share")
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "pull-right closeBtn",
                  attrs: { "data-dismiss": "modal", "aria-hidden": "true" }
                },
                [_vm._v("×")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "row share-wrapper" }, [
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-12 image-wrapper" }, [
                  _c("img", {
                    staticClass: "share-preview",
                    staticStyle: { width: "100%" },
                    attrs: { src: _vm.download_url }
                  })
                ]),
                _vm._v(" "),
                _vm._m(3)
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-modern btn-flat social-cancel",
                  attrs: { type: "button", "data-dismiss": "modal" }
                },
                [_vm._v("Close")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-modern btn-flat downloadShareImg",
                  attrs: {
                    href: _vm.download_url,
                    type: "button",
                    download: "Social Share"
                  }
                },
                [_vm._v("Download")]
              )
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass:
          "btn btn-small-white glyphicon glyphicon-share dropdown-toggle",
        attrs: {
          type: "button",
          id: "dropdownMenuShare",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false"
        }
      },
      [
        _c("div", {
          staticClass: "loader vertical-align-middle loader-circle hide",
          staticStyle: { top: "2px", "font-size": "14px", display: "none" },
          attrs: { "data-type": "default" }
        }),
        _c("span", [_vm._v("Share")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-lg-12", staticStyle: { "margin-bottom": "20px" } },
        [
          _c("p", [
            _vm._v("Below is the share image that you can post to your "),
            _c("span", { staticClass: "share-type" }),
            _vm._v(" newsfeed.")
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "inner-loading",
        staticStyle: { "text-align": "center", display: "block" }
      },
      [
        _c("div", {
          staticClass: "loader vertical-align-middle loader-circle",
          attrs: { "data-type": "default" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-12" }, [
      _c("p", { staticClass: "share-desc" })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5869ad94", { render: render, staticRenderFns: staticRenderFns })
  }
}