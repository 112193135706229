<doc>
  "Company & Brands" tab content Vue.js component
</doc>

<template>
  <LoaderPlaceholder v-if="isLoading" type="circle" />
  <FlexBox v-else size="lg">
    <GridColumn class="mt-4 mt-md-0" :size="['sm-12', 'md-4']">
      <CompanyCard />
      <CompanyAboutCard />
      <CompanyPeolple />
    </GridColumn>
    <GridColumn :size="['sm-12', 'md-8']">
      <ManagerBrands />
      <CompanyActivity hideToolbar />
    </GridColumn>
  </FlexBox>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import CompanyCard from "./CompanyCard"
import CompanyActivity from "./CompanyActivity"
import ManagerBrands from "./ManagerBrands"
import CompanyAboutCard from "./CompanyAboutCard"
import CompanyPeolple from "./CompanyPeolple"

const { mapGetters } = createNamespacedHelpers("organization")

export default {
  name: "ManagerOverview",
  components: {
    CompanyCard,
    CompanyActivity,
    CompanyAboutCard,
    CompanyPeolple,
    ManagerBrands
  },
  computed: Object.assign({}, mapGetters(["isLoading"]))
}
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 25px;
}
</style>
