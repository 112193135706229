<template>
  <div class="app-wrapper">
    <LoaderPlaceholder v-if="isLoading" type="circle" />
    <div v-else>
      <div class="btn-group dateRanges" role="group" aria-label="...">
        <button type="button" @click="setRange(999)" class="btn btn-default" :class="{active : rangeDays===999}">All Time</button>
        <button type="button" @click="setRange(30)" class="btn btn-default" :class="{active : rangeDays===30}">30 days</button>
        <button type="button" @click="setRange(7)" class="btn btn-default" :class="{active : rangeDays===7}">7 days</button>
        <button type="button" @click="setRange(1)" class="btn btn-default" :class="{active : rangeDays===1}">1 day</button>
      </div>
      <div style="margin-bottom:10px;">
        <span @click="openStats" style="font-size:20px; cursor: pointer; color: #6652ff"><i class="far fa-chart-bar"></i></span>
        <button style="float:right;cursor:pointer;" @click="addNew">Add New</button>
        <div style="clear:both;"></div>
        <div v-if="viewStats" class="subscription-stats-wrapper">
          <LoaderPlaceholder v-if="topSubsData===null" type="circle" />
          <div v-else>
            <div class="container">
              <div class="row">
                <div class="col-md-4">
                  <h3>Top engagements in last 90 days</h3>
                  <ul class="list-group">
                    <button type="button" @click="openTopSub(item.subscription_id)" class="list-group-item" style="cursor: pointer;" v-for="item in topSubsData">
                      <span>{{item.email}}</span><span style="margin-left:5px;" :style="{'background-color': generateBackgroundColor(item.tally,topSubsData[0].tally)}" class="badge">{{item.tally}}</span>
                    </button>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :row-style-class="rowStyleClassFn"
        :pagination-options="pagination_options"
        :search-options="{
          enabled: true,
          skipDiacritics: true
        }"
      >
        styleClass="vgt-table striped" >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'after'">
            <span class="viewDetails" @click="showPanelFn(props.row)"
              ><i class="fas fa-info-circle"></i
            ></span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
    <slideout-panel></slideout-panel>
    <BaseModal
      ref="modal"
      v-model="showModal"
      title="New Subscription"
      id="new-subscription-modal"
      @ok="submitForm"
      @cancel="cancelModal"
      @clickMask="hideModal"
      okLabel="Save"
      :isLoading="false"
    >
      <div v-if="isBankAdmin" style="margin-bottom:15px;">
        <label>Select Loan Officer</label>
        <select
          class="select-lo"
          v-model="loid"
          @change="selectLo"
          id="select-lo"
        >
          <option disabled value="">Please select one</option>
          <option
            v-for="lo in los"
            :value="lo.uid"
            :key="lo.name"
          >
            {{ lo.name }}
          </option>
        </select>
      </div>
      <Card>
        <h5>Contact Info</h5>
        <div class="fieldset">
          <InputText
            v-model="newSubscription.first_name"
            label="First Name"
            name="first_name"
            v-on:input="onInput"
          />
          <InputText
            v-model="newSubscription.last_name"
            label="Last Name"
            name="last_name"
            v-on:input="onInput"
          />
          <InputText
            v-model="newSubscription.email"
            label="Email Address"
            name="email"
            type="email"
            v-on:input="onInput"
          />
          <InputText
              v-model="newSubscription.phone"
              label="Phone Number"
              name="phone"
              type="phone"
              v-on:input="onInput"
          />
        </div>
      </Card>
      <Card>
        <h5>Property Location</h5>
        <div class="fieldset">
          <InputText
            v-model="location.zipcode"
            label="Zip Code"
            name="zipcode"
            v-on:input="onZipInput"
          />
          <InputText
            v-model="location.street_address"
            label="Street Address"
            name="street_address"
            v-on:input="onInput"
          />
          <InputText
            v-model="location.city"
            label="City"
            name="city"
            v-on:input="onInput"
          />
          <InputText
            v-model="location.state"
            label="State"
            name="state"
            v-on:input="onInput"
          />
          <InputText
            v-model="location.county"
            label="County"
            name="county"
            v-on:input="onInput"
          />
        </div>
      </Card>
      <Card>
        <h5>Loan Scenario</h5>
        <div class="fieldset">
          <div class="form-group material-input">
            <label class="v-select">Loan Purpose</label>
            <vSelect
              v-model="quote_info.loan_purpose"
              :options="[
                {label:'Purchase',code:'purchase'},
                {label:'Refinance',code:'refinance'},
              ]"
              :reduce="item => item.code"
            />
          </div>
          <InputText
              v-model="quote_info.cash_out"
              label="Cash Out"
              name="cash_out"
              v-on:input="onInput"
              v-if="quote_info.loan_purpose=='refinance'"
          />
          <div class="form-group material-input">
            <label class="v-select">Loan Type</label>
            <vSelect
                v-model="quote_info.loan_type"
                :options="[
                  {label:'Conventional',code:'conventional'},
                  {label:'VA',code:'VA'},
                  {label:'USDA',code:'USDA'},
                  {label:'FHA',code:'FHA'},
                  {label:'ARM',code:'ARM'}
                ]"
                :reduce="item => item.code"
            />
          </div>
          <div  v-if="quote_info.loan_type && quote_info.loan_type.toLowerCase()!=='arm'" class="form-group material-input">
            <label class="v-select">Fixed Loan Term</label>
            <vSelect
                v-model="quote_info.loan_term"
                :options="[
                {label:'30 Year',code:30},
                {label:'20 Year',code:20},
                {label:'15 Year',code:15},
              ]"
                :reduce="item => item.code"
            />
          </div>
          <div v-if="quote_info.loan_type && quote_info.loan_type.toLowerCase()==='arm'" class="form-group material-input">
            <label class="v-select">ARM Term</label>
            <vSelect
                v-model="quote_info.arm_term"
                :options="[
                {label:'10',code:10},
                {label:'7',code:7},
                {label:'5',code:5},
              ]"
                :reduce="item => item.code"
            />
          </div>
          <InputText
            v-model="quote_info.loan_amount"
            label="Loan Amount"
            name="loan_amount"
            v-on:input="onInput"
          />
          <div style="color: #888; font-size: 12px;" v-if="conformingLimit && conformingLimit>0">Conforming Limit: ${{conformingLimit.toLocaleString("en")}}</div>
          <InputText
            v-model="quote_info.credit_score"
            label="Credit Score"
            name="credit_score"
            v-on:input="onInput"
          />
          <InputText
            v-model="quote_info.list_price"
            label="Property Value"
            name="list_price"
            v-on:input="onInput"
          />
          <div style="color: #888; font-size: 12px;" v-if="ltv && ltv>0">LTV: {{ltv.toFixed(2)*100}}%</div>
          <div class="form-group material-input">
            <label class="v-select">Property Type</label>
            <vSelect
              v-model="quote_info.property_type"
              :options="[
                {label:'Single Family Home',code:'single_family_home'},
                {label:'Town Home',code:'townhome'},
                {label:'Condominium',code:'condominium'},
                {label:'Multi Family',code:'multi_tenant_home'}
              ]"
              :reduce="item => item.code"
            />
          </div>
          <div class="form-group material-input">
            <label class="v-select">Property Usage</label>
            <vSelect
              v-model="quote_info.residency_type"
              :options="[
                {label:'Primary Home',code:'primary_home'},
                {label:'Second Home',code:'secondary_home'},
                {label:'Rental',code:'rental_home'},
              ]"
              :reduce="item => item.code"
            />
          </div>
        </div>
      </Card>
      <Card>
        <h5>Rate Updates</h5>
        <div style="margin-top:10px;" class="fieldset">
          <input type="checkbox" v-model="newSubscription.active" />
          <label>Send Rate Update Emails</label>
        </div>
      </Card>
      <Card>
        <h5>CRM</h5>
        <div style="margin-top:10px;" class="fieldset">
          <input type="checkbox" v-model="sendToCRM" />
          <label>Send to CRM</label>
        </div>
      </Card>
      <Card>
        <h5>Loan Officer Fields</h5>
        <div style="margin-top:10px;" class="fieldset">
          <div class="row">
            <div class="col-sm-6">
              <InputText
                  v-model="quote_info.admin_fields.taxes"
                  label="Taxes"
                  name="taxes"
                  v-on:input="onInput"
                  help-text="Percentage of property value"
              />
            </div>
            <div class="col-sm-6" style="padding-top:40px;">
              <span v-if="quote_info.admin_fields && quote_info.admin_fields.taxes">$<span>{{(Number((quote_info.admin_fields.taxes/100*quote_info.list_price)).toFixed(2))}}/yr</span></span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <InputText
                  v-model="quote_info.admin_fields.insurance"
                  label="Insurance"
                  name="insurance"
                  v-on:input="onInput"
                  help-text="Percentage of property value"
              />
            </div>
            <div class="col-sm-6" style="padding-top:40px;">
              <span v-if="quote_info.admin_fields && quote_info.admin_fields.insurance">$<span>{{(Number((quote_info.admin_fields.insurance/100*quote_info.list_price)).toFixed(2))}}/yr</span></span>
            </div>
          </div>
        </div>
      </Card>
    </BaseModal>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table"
import { SubscriptionPanel } from "../components/Panel"
import BaseModal from "../components/BaseModal"
import vSelect from "vue-select"
import Card from "../components/Card"


export default {
  name: "SubscriptionLog",
  components: {
    VueGoodTable,
    SubscriptionPanel,
    BaseModal,
    vSelect,
    Card
  },
  async mounted() {
    this.loid=loid
    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.get("source")){
      this.filters.source=urlParams.get("source")
    }
    await this.setRange(this.rangeDays)
    await this.getLOs();
    this.getTopSubs();
  },
  computed:{
      isBankAdmin(){
          return $('body').hasClass('bb-bank-admin')
      },
      ltv(){
          if(!this.quote_info)return null;
          if(!this.quote_info.loan_amount)return null;
          if(!this.quote_info.list_price)return null;
          let cash_out = parseInt(this.quote_info.cash_out ? this.quote_info.cash_out : 0)
          let amt_total = parseInt(this.quote_info.loan_amount) + cash_out
          // console.log("ltv: "+amt_total/this.quote_info.list_price+", loan amount: "+amt_total+", list price: "+this.quote_info.list_price+", cash out: "+cash_out)
          return amt_total/this.quote_info.list_price
      },
      columns(){
          let cols=[];
          if(!this.loid){
              cols.push({
                  label: "Brand",
                  field: "brand_name"
              })
              cols.push({
                  label: "Subscription Owner",
                  field: "owner_email"
              })
          }
          cols.push({
              label: "Email",
              field: "email"
          })
          cols.push({
              label: "Date/Time",
              field: "created",
              type: "date",
              dateInputFormat: "t",
              dateOutputFormat: "MMM do yyyy, h:mm:ss a"
          })
          cols.push({
              label: "",
              field: "after",
              sortable: false,
              globalSearchDisabled: true,
              tdClass: "infoColumn"
          })
          return cols;
      }
  },
  async updated(){
  },
  data() {
    return {
      isLoading: false,
      rangeDays: 1,
      jsonOptions: {
        height: "500px",
        mode: "view",
        plus: true
      },
      rows: [],
      pagination_options:{
        enabled: true,
        mode: 'records',
        perPage: 25,
        position: 'bottom',
        perPageDropdown: [25, 50, 100],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All',
      },
      brandsCache:[],
      loCache:[],
      showModal: false,
      newSubscription: {},
      quote_info: {
        admin_fields:{}
      },
      location: {},
      conformingLimit: null,
      los: null,
      loid: null,
      overAllLoading: false,
      sendToCRM: false,
      filters: {},
      viewStats: false,
      topSubsData: null
    }
  },
  methods: {
    generateBackgroundColor(count,max){
      let temp = 100-(count/max*100);
      if(max<50){
        temp = 100-(count/50*100);
      }
      if(max<25){
        temp = 100-(count/25*100);
      }
      temp=(temp>25 ? temp : 25)
      temp=(temp<90 ? temp : 90)
      return `hsl(196deg 36% ${temp}%)`
    },
    rowStyleClassFn(row) {
      if(parseInt(row.deleted) === 1)return "deleted";
      if(parseInt(row.active) === 0)return "inactive";
      return "active";
    },
    onError(e) {},
    async showPanelFn(item) {
      this.$showPanel({
        component: SubscriptionPanel,
        openOn: "right",
        props: {
          options: this.jsonOptions,
          onCreated: async () => {
            return this.getRowDetails(item)
          }
        }
      })
    },
    async getRowDetails(row) {
      if(!row){
        console.log("row data is empty")
        return;
      }
      if(!row.loid){
        console.log("row.loid is empty")
        return;
      }
      let lo = await this.getLO(row.loid)
      let quote=null;
      let fullSub = await BB.subscription.get({id:row.id})
      if(fullSub && fullSub.details && fullSub.details.quote_id)quote = await BB.rateflow.log(fullSub.details.quote_id)
      let result = fullSub
      result.lo = lo
      result.quote=quote
      if(!this.loid){
        row.owner=lo.name;
        let brand=await this.getBrand(lo.bank_nid)
        row.brand_name=brand.name
      }
      return result
    },
    async setRange(rangeDays){
      // TODO: reuse existing rows from previous calls
      this.rows = [];
      this.rangeDays=rangeDays
      this.isLoading = true
      let batchSize=999999
      let search_criteria={
        min_created: Math.round(((+ new Date())/1000)-(24*3600*rangeDays)),
        limit:batchSize,
        offset: 0
      }
      Object.assign(search_criteria,this.filters)
      if(brand_id)search_criteria.brand_id=brand_id
      if(this.loid && !is_bank_admin)search_criteria.loid=this.loid
      let results = []
      let i = 0
      this.overAllLoading=true
      this.rows = await BB.subscription.getLog(search_criteria)
      this.overAllLoading=false
      this.isLoading=false
      return
    },
    async getBrand(brand_id){
      if(this.brandsCache && this.brandsCache[brand_id]){
        console.log("Brand cache hit")
        return this.brandsCache[brand_id]
      }
      let brand_data=await BB.brandManage.get(brand_id)
      this.brandsCache[brand_data.id]=brand_data;
      return brand_data;
    },
    async getLO(loid){
      if(this.loCache && this.loCache[loid]){
        console.log("LO cache hit")
        return this.loCache[loid]
      }
      let {lo}=await BB.loManage.get(loid)
      this.loCache[loid]=lo;
      return lo;
    },
    addNew(){
      this.showModal=true;
    },
    hideModal(){
      this.showModal=false;
    },
    cancelModal(){
      this.clearForm();
      this.hideModal()
    },
    clearForm(){
      this.newSubscription={}
      this.location={}
      this.quote_info={admin_fields: {}}
      this.conformingLimit=null
    },
    async submitForm(){
      if(!this.loid){
        BB.Toastr.error("No LO assigned.")
        return;
      }
      this.newSubscription.active = !!this.newSubscription.active
      let submitBody=this.newSubscription
      if(this.quote_info.list_price)this.quote_info.list_price=parseInt(this.quote_info.list_price);
      if(this.quote_info.loan_amount)this.quote_info.loan_amount=parseInt(this.quote_info.loan_amount);
      submitBody.quote_info=this.quote_info
      submitBody.quote_info.location=this.location;
      submitBody.loid=this.loid;
      submitBody.created=Math.ceil((+ new Date())/1000)
      submitBody.send_to_crm = (this.sendToCRM ? true : false)
      let result=await BB.subscription.add(submitBody)
      if(result.subscription_id){
        let newRow = await this.getRowDetails(submitBody)
        this.rows.unshift(newRow)
        this.clearForm()
        this.hideModal()
      }else{
        BB.Toastr.error("Subscription not created")
      }
    },
    onInput(){
    },
    async onZipInput(val){
      if(val.length==5){
        let result=await $.get("/api/v1/conforming-limit?zipcode="+val)
        this.conformingLimit=( result.conforming_limit ? result.conforming_limit : -1 )
        if(!this.location.county)this.location.county=( result.county ? result.county : "" )
        if(!this.location.state)this.location.state=( result.state ? result.state : "" )
      }
      else{
        this.conformingLimit=null
      }
    },
    async getLOs() {
      if(!brand_id)return
      let result = null
      try {
        result = await BB.brandManage.getMembers(
            brand_id
        )
      } catch (e) {
        console.log("something went wrong getting available owners")
        return
      }
      this.los = result.members
    },
    selectLo(val){
      console.log(val.currentTarget)
    },
    openStats(e){
      e.preventDefault();
      this.viewStats=!this.viewStats
    },
    async openTopSub(id){
      let fullSub = await BB.subscription.get({id})
      this.showPanelFn(fullSub)
    },
    async getTopSubs(){
      let chartData=[]
      if($('body').hasClass('bb-admin') && !brand_id){
        let res=(await $.get("/api/v1/subscription-chart-data/top-subscribers"))
        if(res.data.length){
          chartData=res.data
        }
      }
      else if(brand_id){
        let res=(await $.get("/api/v1/subscription-chart-data/top-subscribers?brand_id="+brand_id))
        if(res.data.length){
          chartData=res.data
        }
      }
      else if(loid){
        let res=(await $.get("/api/v1/subscription-chart-data/top-subscribers?loid="+loid))
        if(res.data.length){
          chartData=res.data
        }
      }
      this.topSubsData=chartData
    }
  }
}
</script>

<style lang="scss">
  @import "../../node_modules/vue-good-table/dist/vue-good-table.css";
  .app-wrapper {
    margin-top: 10px;
  }
  tr.failed {
    background-color: #ffebee;
  }
  .viewDetails {
    cursor: pointer;
  }
  .infoColumn {
    text-align: center;
  }
  .slideout-panel {
    z-index: 1000;
    position: relative;
  }
  .dateRanges{
    float: right;
    margin-top: -75px;
  }
  @media (max-width: 991px) {
    .dateRanges {
      margin-top: -65px;
    }
  }
  label.v-select {
    font-weight: 300;
    font-size: 12px;
    color: rgba(21, 28, 41, 0.48);
    transform-origin: left top;
    transition: color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    pointer-events: none;
    cursor: text;
    margin-top:20px;
  }
  .vs__dropdown-toggle {
    border: none;
    border-bottom: 1px solid #5dbec7;
    border-radius: 0px;
  }
  .vs__selected {
    font-size: 16px;
    font-weight: 400;
    color: #495057;
    margin-left: 0px;
    padding-left: 0px;
  }
  .vs__search {
    font-size: 16px;
    font-weight: 400;
    margin-left: 0px;
    padding-left: 0px;
  }
  .card{
    margin-bottom: 10px;
  }
  tr.active .viewDetails i{
    color: rgb(76, 175, 80);
  }
  tr.inactive .viewDetails i{
    color: rgb(255, 193, 7);
  }
  tr.deleted .viewDetails i{
    color: rgb(244, 67, 54);
  }
</style>
