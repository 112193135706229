<template>
  <div class="config-name">
    <label class="config-name__label sr-only">{{ label }}</label>
    <span
      v-if="hasInUse"
      :class="[{ active: inUse }, 'config-name__in-use']"
    ></span>
    <span class="config-name__input"
      ><input
        type="text"
        v-bind:value="value"
        v-on:input="$emit('input', $event.target.value)"
    /></span>
  </div>
</template>

<script>
export default {
  name: "ConfigNameInput",
  props: {
    label: { type: String, default: "Config name" },
    inUse: { type: Boolean, default: false },
    hasInUse: { type: Boolean, default: false },
    value: { type: String, default: "" }
  }
}
</script>

<style lang="scss" scoped src="./ConfigNameInput.scss"></style>
