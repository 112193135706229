const Mustache = require("mustache")
const Moment = require("moment")

function initRealtorInfoModal() {
	const $loading = $(".realtor-info-modal_loading")
	const $noResults = $(".realtor-info-modal_no-results")
	const $realtorInfoModal = $("#realtor-info-modal")
	const $listingList = $("#listing-list")
	const listingTpl = `<div class="network-table__row">
        <div class="network-table__column network-table__picture">
          <img src="{{imgSrc}}" alt="Activity thumb" style="border-radius:5px;">
        </div>
      <div class="network-table__column network-table__user-info">
        <span style="color:#585858;" class="network-table__user-info-title">{{title}}</span>
        <div><span class="network-table__user-info-subtitle">{{city}}, {{state}}</span></div>
        <div><span class="network-table__user-info-subtitle">{{list_price_str}}</span></div>
        <div><span class="network-table__user-info-subtitle">Added On {{time}}</span></div>
      </div>
    </div>`

	$(".network-table__pair-modal").click(function() {
        $loading.removeClass("hidden")
        $loading.removeClass("hide")
        $loading.css("display","block")
		$noResults.addClass("hidden")
        $listingList.addClass("hidden").empty()
        $realtorInfoModal.modal("show")
        const mls_sc = $(this.parentElement).data("mls-sc")
        const agent_id = $(this.parentElement).data("realtor-id")
		BB.listing.get({limit:25,mls_sc,agent_id:agent_id},showData)
	})

	function showData(data) {
		var listings=data;
		if (!listings.length) {
			$noResults.removeClass("hidden")
		} else {
			listings.forEach(function(element) {
				console.log(element)
                element.title = element.fullstreetaddress
				//element.time = makeTime(element.created/1000)
				element.time = newMakeTime(element.created)
				element.imgSrc = element.pictures[0].url
				//element.imgSrc = makeImg(element.pictures)
                element.link = "/listing"+"?mls_sc="+element.mls_sc+"&mls_id"+element.mls_id
				element.list_price_str = "$"+parseInt(element.listing_price).toLocaleString();
				const rendered = Mustache.render(listingTpl, element)
                $listingList.append(rendered)
			})
            $listingList.removeClass("hidden")
		}
		$loading.addClass("hidden")
	}
}

const makeTime = timestamp => {
	return Moment.unix(timestamp).calendar(null, {
		sameElse: "MMM D, YYYY"
	})
}
const newMakeTime = date => {

	let myDate = (date.split(' ')[0]).split("-");
	var newDate = new Date( '2024', '05', '28');
	return Moment(date.split(' ')[0]).format('MMM D, YYYY')

	
}
const makeImg = function(picture) {
	let imgSrc = ( picture && picture[0] ? picture[0].url : null)
	return ( $(".securePicUrl").data("path") ? $(".securePicUrl").data("path") : "" )+"?url="+( imgSrc ? imgSrc : "");
}

// Render Listings
const bbRealtorInfoModal = {
	init: () => {
        initRealtorInfoModal()
	}
}

module.exports = bbRealtorInfoModal
