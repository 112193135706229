import Vue from "vue"
import _orderBy from "lodash/orderBy"
import { MyArchive } from "./pages"

export default function() {
  if (!document.getElementById("vue-my-archive-app")) {
    return
  }
  // Vuejs setup
  new Vue({
    el: "#vue-my-archive-app",
    components: {
      MyArchive
    }
  })
}
