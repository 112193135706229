<template>
  <div v-if="isLoading" class="text-center">
    <LoaderPlaceholder class="loader" type="circle" />
  </div>
  <div v-else>
    <div class="d-flex">
      <div class="d-flex form-inline" v-if="!addingNew && !config">
        <div class="form-group">
          <label for="bank_id">Brand:</label>
          <select
            id="bank_id"
            class="form-control input-sm"
            v-model="bank_id"
            @change="changeSelectedBrand"
          >
            <option disabled value="">Please select one</option>
            <option
              v-for="brand in availableBrands"
              :value="brand.id"
              :key="brand.id"
              >{{ brand.name }}</option
            >
          </select>
        </div>

        <div
          class="form-group ml-3"
          v-if="bank_id && availableRateflows && !selectedConfigId"
        >
          <label>Rateflow:</label>
          <select
            class="form-control input-sm"
            v-model="selectedConfigId"
            @change="changeSelectedConfig"
          >
            <option disabled value="">Please select one</option>
            <option
              v-for="rateflow in availableRateflows"
              :value="rateflow.id"
              :key="rateflow.id"
              >{{ rateflow.name }}</option
            >
          </select>
        </div>
      </div>
      <ConfigNameInput
        v-else
        v-model.trim="configName"
        :hasInUse="false"
        @input="configChanged"
      />

      <AddCancelBtn
        class="ml-auto"
        :onClick="addNew"
        :isCancel="addingNew || !!config"
      />
    </div>

    <div v-if="isLoadingConfig" class="text-center">
      <LoaderPlaceholder class="loader" type="circle" />
    </div>
    <div v-else-if="config">
      <div class="form-inline d-flex p-3">
        <div class="form-group">
          <label for="bank_id">Brand: </label>
          <select class="form-control input-sm" v-model="bank_id" id="bank_id">
            <option disabled value="">Please select one</option>
            <option
              v-for="brand in availableBrands"
              :value="brand.id"
              :key="brand.id"
              >{{ brand.name }}</option
            >
          </select>
        </div>
        <div class="form-group settingsTplSelect">
          <label for="pricing_engine_tpl">Settings Template: </label>
          <select @change="addSettingsTpl" v-model="selectedEngineTpl" class="form-control input-sm" id="pricing_engine_tpl">
            <option disabled value="">Select settings tpl</option>
            <option
                v-for="engineTpl in engineTpls"
                :value="engineTpl.value"
                :key="engineTpl.value"
            >{{ engineTpl.label }}</option
            >
          </select>
        </div>
        <div class="checkbox ml-3 pt-1">
          <label
            ><input
              type="checkbox"
              v-model="isDefault"
              @change="configChanged"
            />
            Default</label
          >
        </div>
      </div>

      <vjsoneditor
        v-model="config"
        :options="options"
        :plus="true"
        :height="height"
        @error="onError"
      />

      <div class="d-flex pt-3">
        <div class="ml-auto">
          <BaseButton
            v-show="changed || (addingNew && bank_id)"
            class="mr-2"
            @click="saveConfig"
          >
            {{ addingNew ? "Add" : "Save" }}
          </BaseButton>

          <!-- <BaseButton
            v-show="config || addingNew"
            @click="deleteWorkflow"
            outline
          >
            Delete
          </BaseButton>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bbRateflow } from "../../bb-api"
import vjsoneditor from "v-jsoneditor"
import ConfigNameInput from "../ConfigNameInput"
import AddCancelBtn from "../AddCancelBtn"

let vm

const DEFAULT_CONFIG = {
  pricing_set_id: 0,
  settings: {},
  default: 0
}

export default {
  name: "RateflowManager",
  components: { ConfigNameInput, AddCancelBtn, vjsoneditor },
  data() {
    return {
      height: "400px",
      isLoading: true,
      isDefault: false,
      isLoadingConfig: false,
      addingNew: false,
      config: null,
      bank_id: "",
      configName: "",
      availableRateflows: null,
      selectedConfigId: "",
      changed: false,
      options: {
        onChangeText(configJson) {
          vm.changed = true
        }
      },
      engineTpls: [
        {
          "value":"polly",
          "label":"Polly"
        },
        {
          "value":"mortech",
          "label":"Mortech"
        },
        {
          "value":"ob",
          "label":"Optimal Blue"
        },
        {
          "value":"loan_sifter",
          "label":"Loan Sifter"
        },
        {
          "value":"lender_price",
          "label":"Lender Price"
        },
      ],
      selectedEngineTpl: null
    }
  },
  updated(){
  },
  methods: {
    configChanged() {
      this.changed = true
    },
    async getAvailableBrands() {
      this.availableBrands = await BB.brandManage.find({})
      this.isLoading = false
    },
    async changeSelectedBrand() {
      await this.getAvailableRateflows()
    },
    async getAvailableRateflows() {
      if (!this.bank_id) return
      let result = await bbRateflow.find({ bank_id: this.bank_id })
      this.availableRateflows = result
    },
    changeSelectedConfig() {
      this.getConfig(this.selectedConfigId)
      window.history.pushState(
        {},
        null,
        `?id=${this.selectedConfigId}${window.location.hash}`
      )
    },
    getAllConfig() {
      return bbRateflow.get({}, res => res)
    },
    async getConfig(id) {
      this.isLoadingConfig = true
      const rateflow = await bbRateflow.get({ id })
      this.config = rateflow
      this.isDefault = rateflow.default == 1

      this.configName = rateflow.name ? rateflow.name : "New Rateflow"
      this.bank_id = rateflow.bank_id
      this.isLoadingConfig = false
      this.changed = false
    },
    addConfig(payload, callBack) {
      // validate payload before saving
      if (!payload.name) return BB.Toastr.error("Please complete the name.")
      if (!payload.bank_id) return BB.Toastr.error("Please select brand.")

      bbRateflow.add(payload, function({ id }) {
        callBack(id)
      })
    },
    updateConfig(payload, callBack) {
      const action = this.addingNew ? "add" : "update"

      bbRateflow[action](payload, res => {
        callBack(res)
      })
    },
    saveCallback(msg) {
      BB.Toastr.success(msg)
      this.getAllConfig()
    },
    async deleteConfig(id) {
      await bbRateflow.delete(id)
    },
    saveConfig() {
      const saveCallback = this.saveCallback
      let payload = {
        bank_id: this.bank_id,
        name: this.configName,
        default: this.isDefault ? 1 : 0
      }

      if (this.selectedConfigId) {
        payload.id = this.selectedConfigId
      }

      if (!this.config.owner) {
        payload.owner = $("body").data("user_id")
        payload.loid = payload.owner
      }

      // merge config with payload
      payload = Object.assign({}, this.config, payload)

      this.changed = false
      this.updateConfig(payload, res => {
        if (this.addingNew && res.result.id) {
          this.config.id = res.result.id
        }
        this.changed = false
        this.addingNew = false
        saveCallback("Config saved successfully.")
      })
    },
    async addNew(e) {
      if (this.addingNew || this.config) {
        this.clearRateflow()
      } else {
        this.addingNew = true
        //DEFAULT_CONFIG
        this.config = JSON.parse(JSON.stringify(DEFAULT_CONFIG))
        this.changed = false
        this.configName = "New Rateflow"
      }
    },
    clearRateflow() {
      this.changed = false
      this.addingNew = false
      this.config = null
      this.bank_id = ""
      this.selectedConfigId = ""
      window.history.pushState({}, null, `/config-admin${window.location.hash}`)
    },
    onError() {
      console.log("error")
    },
    async deleteWorkflow() {
      if (confirm("Do you really want to delete this?")) {
        //await bbRateflow.delete(this.selectedConfigId)
        console.log("ADD DELETE LOGIC HERE")
        this.clearRateflow()
      }
    },
    async addSettingsTpl(){
      if(!this.selectedEngineTpl){
        return null
      }
      let newConfig = JSON.parse(JSON.stringify(this.config))
      newConfig.settings=Object.assign(newConfig.settings,{
        "api_config":{},
        "api_mode": ""
      })
      switch (this.selectedEngineTpl) {
        case "polly":
          newConfig.settings=Object.assign(newConfig.settings,{
            "api_mode":"polly",
            "api_config":{
              "audience_id":"",
              "api_username":"",
              "api_password":"",
              "client_id":"",
              "client_secret":""
            }
          });
          break;
        case "lender_price":
          newConfig.settings=Object.assign(newConfig.settings,newConfig.settings={
            "api_mode":"lender_price",
            "api_config":{
              "user_id":"",
              "company_id":"",
              "api_key":""
            }
          })
          break;
        case "loan_sifter":
          newConfig.settings=Object.assign(newConfig.settings,newConfig.settings={
            "api_mode":"loan_sifter",
            "api_config":{
              'user_id':'',
              'company_id':'',
              'client_id':'',
              'client_secret':''
            }
          })
          break;
        case "ob":
          newConfig.settings=Object.assign(newConfig.settings,newConfig.settings={
            "api_mode":"optimal_blue",
            "api_config":{
              "biz_channel":"",
              "originator_id":""
            }
          })
          break;
        case "mortech":
          newConfig.settings=Object.assign(newConfig.settings,newConfig.settings={
            "api_mode":"mortech",
            "api_config":{
              "customer_id":"",
              "email":""
            }
          })
          break;
      }
      //this.config.updated = Math.round((+ new Date())/1000)
      this.config=newConfig
      this.configChanged()
      return null;
    }
  },
  async mounted() {
    vm = this
    const urlParams = new URLSearchParams(window.location.search)

    if (urlParams.has("bank_id")) {
      this.bank_id = urlParams.get("bank_id")
      await this.getAvailableRateflows()
    }

    await this.getAvailableBrands()

    if (urlParams.has("id")) {
      this.selectedConfigId = urlParams.get("id")
      await this.getConfig(this.selectedConfigId)
      this.addingNew = false
    }
  }
}
</script>

<style lang="scss" src="./RateflowManager.scss" scoped></style>
