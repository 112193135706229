<template>
  <div class="basePagination">
    <ul class="basePagination__list-container">
      <li class="basePagination__list-item" @click="back">
        <i class="fas fa-chevron-left"></i>
      </li>
      <li
        class="basePagination__list-item"
        :class="{
          'basePagination__list-item--active': page.value === currentNumber + 1
        }"
        v-for="(page, index) in pagesToShow"
        :key="index"
        @click="select(page)"
      >
        {{ page.label }}
      </li>
      <li class="basePagination__list-item" @click="next">
        <i class="fas fa-chevron-right"></i>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    total: { type: Number, required: true },
    maxItemsPerPage: { type: Number, required: true },
    toOffset: { type: Number, required: true }
  },
  data() {
    return {
      currentNumber: 0,
      pages: 0,
      maxButtonsToShow: 10,
      firstNumberShow: -1,
      lastNumberShow: -1,
      pagesShow: [],
    }
  },
  created() {
    this.pages = Math.ceil(this.total / this.maxItemsPerPage)
  },
  methods: {
    select(page) {
      this.currentNumber = page.value - 1;
      this.submitEvent()
    },
    back() {
      if (this.currentNumber === 0) return false

      this.currentNumber = this.currentNumber - 1
      this.submitEvent()
    },
    next() {
      if (this.currentNumber === this.pages - 1) return false

      this.currentNumber = this.currentNumber + 1
      this.submitEvent()
    },
    submitEvent() {
      this.$emit("onPagination", {
        maxItemsPerPage: this.maxItemsPerPage,
        offset: this.toOffset * this.currentNumber
      })
    }
  },
  computed: { 
    pagesToShow() {
      if(this.currentNumber <= this.lastNumberShow && this.currentNumber >= this.firstNumberShow) {
        return this.pagesShow
      }

      const numButtonsToShow = Math.min(this.maxButtonsToShow, this.pages)

      let first = this.currentNumber > this.lastNumberShow
        ? this.currentNumber + 1
        : this.currentNumber - numButtonsToShow + 2
      first = Math.max(first, 1)
      first = Math.min(first, this.pages - numButtonsToShow + 1)
      
      let pages = []
      if(first >= numButtonsToShow) {
        pages.push({label: '...', value: first - 1})
      }

      for(let i = 0; i < numButtonsToShow; i++) {
        pages.push({label: i + first, value: i + first})
      }

      if(numButtonsToShow + first <= this.pages) {
        pages.push({ label: '...', value: numButtonsToShow + first })
      }
      
      this.firstNumberShow = pages[0].label === '...' 
        ? pages[1].value - 1 
        : pages[0].value - 1
      this.lastNumberShow = pages[pages.length - 1].label === '...' 
        ? pages[pages.length - 2].value - 1
        : pages[pages.length - 1].value - 1
      this.pagesShow = pages

      return pages
    }
  }
}
</script>

<style scoped lang="scss" src="./BasePagination.scss"></style>
