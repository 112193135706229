<doc>
  Image uploader Vue.js component
</doc>

<template>
  <div>
    <label v-if="editMode && labelTop" for="img-file">{{ label }}</label>
    <div class="img-uploader__prev">
      <span
        class="btn-close"
        v-if="hasRemove && imgSrc"
        v-on:click="removePhoto"
      >
        <icon name="times"></icon>
      </span>
      <img
        v-show="imgSrc"
        ref="preview"
        :src="imgSrc"
        :alt="previewAlt"
        :class="previewClass"
      />
    </div>
    <div class="file-input" v-if="editMode">
      <label v-if="!labelTop" for="img-file">{{ label }}</label>
      <input
        :id="idLocal"
        type="file"
        v-on:change="uploadPhoto"
        accept="image/png, image/jpeg, image/svg+xml"
      />
    </div>
    <label>File types JPEG or PNG, size under 2 MB</label>
  </div>
</template>

<script>
export default {
  name: "DefaultImgUploader",
  props: {
    editMode: { type: Boolean },
    labelTop: { type: Boolean },
    hasRemove: { type: Boolean },
    label: { type: String },
    previewAlt: { type: String },
    previewClass: { type: String },
    uploadPhoto: { type: Function },
    removePhoto: { type: Function },
    imgSrc: { type: [String, Boolean] },
    id: { type: [String, Boolean] },
    isHeadshot: { type: Boolean }
  },
  computed: {
    idLocal: function() {
      return this.id || `img-file-${Date.now()}`
    }
  }
}
</script>

<style lang="scss" scoped src="./ImgUploader.scss"></style>
