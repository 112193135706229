var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FlexBox",
    { staticClass: "my-archive-search-wrapper", attrs: { alignment: "end" } },
    [
      _c("BaseAutoComplete", {
        attrs: {
          showListOptions: true,
          showSearchButton: true,
          listOptions: _vm.listOptions,
          listItem: [],
          name: "myarchive-autocomplete-search",
          hideLabel: true,
          placeHolder: this.placeholder,
          float: true,
          marTop: "0",
          marBottom: "0",
          paddLeft: true,
          isMaterial: false,
          fluidList: false
        },
        on: {
          submit: _vm.onSubmit,
          changePlaceholderByType: _vm.changePlaceholderByType
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-46bff9e2", { render: render, staticRenderFns: staticRenderFns })
  }
}