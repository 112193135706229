<doc>
	Date picker Range Vue.js component
</doc>

<template>
	<div class="datepicker-range no-input">
		<icon class="datepicker-icon" name="regular/calendar-alt"></icon>
		<v2-datepicker-range
			lang="en"
			:placeholder="placeholder"
			v-model="theModel"
			:format="dateFormat"
			:picker-options="pickerOptions"
			@change="onChange" />
		<icon class="datepicker-chevron" name="chevron-down"></icon>
  </div>
</template>

<script>
import Vue from "vue"
import moment from "moment"
import { DatePickerRange } from "v2-datepicker"
Vue.use(DatePickerRange)

export default {
	name: "DatePickerRange",
	props: {
		//dateFormat: { type: String, default: "MM dd, yy" },
		dateFormat: { type: String, default: "MM/dd/yyyy" },
		placeholder: { type: String, default: "Choose a date range…" },
		defaultValue: { type: Array },
		onChange: { type: Function }
	},
	data() {
		return {
			theModel: this.value,
			pickerOptions: {
				shortcuts: [
					{
						text: "Today",
						onClick(picker) {
							const start = new Date(moment().startOf('day'))
							const end = new Date(moment().endOf('day'))
							picker.$emit("pick", [start, end])
						}
					},
					{
						text: "Yesterday",
						onClick(picker) {
							const start = new Date(moment().subtract(1, 'days').startOf('day'))
							const end = new Date(moment().subtract(1, 'days').endOf('day'))
							picker.$emit("pick", [start, end])
						}
					},
					{
						text: "This Week",
						onClick(picker) {
							const start = new Date(moment().startOf('week'))
							const end = new Date(moment().endOf('week'))
							picker.$emit("pick", [start, end])
						}
					},
					{
						text: "Last Week",
						onClick(picker) {
							const start = new Date(moment().subtract(7, 'days').startOf('week'))
							const end = new Date(moment().subtract(7, 'days').endOf('week'))
							picker.$emit("pick", [start, end])
						}
					},
					{
						text: "This Month",
						onClick(picker) {
							const start = new Date(moment().startOf('month'))
							const end = new Date(moment().endOf('month'))
							picker.$emit("pick", [start, end])
						}
					},
					{
						text: "Last Month",
						onClick(picker) {
							const start = new Date(moment().subtract(1, 'month').startOf('month'))
							const end = new Date(moment().subtract(1, 'month').endOf('month'))
							picker.$emit("pick", [start, end])
						}
					},
					{
						text: "This Year",
						onClick(picker) {
							const start = new Date(moment().startOf('year'))
							const end = new Date(moment().endOf('year'))
							picker.$emit("pick", [start, end])
						}
					},
					{
						text: "All Time",
						onClick(picker) {
							const start = new Date('1990-01-01T00:00:00.000')
							const end = new Date()
							picker.$emit("pick", [start, end])
						}
					},
				]
			}
		}
	},
	watch: {
		theModel(val) {
			this.$emit("input", val)
		}
	},
	mounted: function() {
		if(this.defaultValue) {
			this.theModel = this.defaultValue
		}
	}
}
</script>

<style lang="scss" src="./DatePickerStyle.scss">
</style>
