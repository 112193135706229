<doc>
    Add/edit Program Modal Vue.js component
  </doc>
  
  <template>
    <transition-group
        name="modal"
        v-on:before-enter="beforeEnter"
        v-on:after-enter="afterEnter"
        v-on:after-leave="afterLeave"
    >
        <div 
            key="modal" 
            class="flyer-modal"
            tabindex="-1"
        >
            <div class="modal-dialog flyer-modal-dialog">
                <div class="modal-content flyer-modal-content">
                    <div class="modal-header flyer-modal-header">
                        <h4 class="modal-title flyer-modal-title">
                            {{title}}
                        </h4>
                        <button
                            class="close"
                            theme=""
                            type="button"
                            aria-label="Close"
                            v-on:click="cancel"
                            >
                            <span class="closeBtn" aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body flyer-modal-body">
                        <div class="create-tag-block">
                            <div class="new-tag">New Tag</div>
                            <div>
                                <input type="text" class="txt-create-tag" />
                                <button class="btn btn-modern" @click="save">
                                    Create Tag
                                </button>
                            </div>
                            <div class="tag-lists-wrapper">
                                <div class="inactive-tags-wrapper">
                                    <h4>Inactive Tags</h4>
                                    <div class="list-wrap">
                                        <div class="inactive-tag-list">
                                            <ul>
                                                <li v-for="(tag, index) in inactive_tags" 
                                                :class="{ 'active': inactive_selected_index === index }"
                                                :key="tag.id"
                                                @click="setInActiveIndex(tag, index)">{{ tag.name }}</li>
                                            </ul>
                                        </div>
                                        <div class="sort-buttons-wrapper">
                                            <button class="btn btn-modern" @click="inactive_move_up">
                                                <i class="fa-solid fa-caret-up"></i>
                                            </button>
                                            <button class="btn btn-modern" @click="inactive_move_down">
                                                <i class="fa-solid fa-caret-down"></i>
                                            </button>
                                            <button class="btn btn-modern" @click="move_to_active">
                                                <i class="fa-solid fa-caret-right"></i>
                                            </button>
                                            <button class="btn btn-modern" @click="move_to_inactive">
                                                <i class="fa-solid fa-caret-left"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="active-tags-wrapper">
                                    <h4>Active Tags(Ordered)</h4>
                                    <div class="list-wrap">
                                        <div class="active-tag-list">
                                            <ul>
                                                <li v-for="(tag, index) in active_tags" 
                                                :class="{ 'active': active_selected_index === index }"
                                                :key="tag.id"
                                                @click="setActiveIndex(tag, index)">{{ tag.name }}</li>
                                            </ul>
                                        </div>
                                        <div class="sort-buttons-wrapper">
                                            <button class="btn btn-modern" @click="active_move_up">
                                                <i class="fa-solid fa-caret-up"></i>
                                            </button>
                                            <button class="btn btn-modern" @click="active_move_down">
                                                <i class="fa-solid fa-caret-down"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="modal-footer flyer-modal-footer">
                        <button class="btn btn-modern" @click="update">
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div key="backdrop" class="modal-backdrop in" v-if="isVisible"></div>
    </transition-group>
</template>

<script>
  import { createNamespacedHelpers } from "vuex"

  
  const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  "flyerTemplateTagsModal"
)

export default {
    name: "FlyerTemplateTagsModal",
    components: {  },
    data() {
        return {
            tag_name: null,
            active_tags: [],
            inactive_tags: [],
            isVisible: true,
            inactive_selected_index: null,
            active_selected_index: null
        }
    },
    props: {
        title: { type: String }
    },
    mounted(){
        this.isVisible = true;
    },
    async created() {
        console.log('created flyer template tags modal');
        let that = this
        $.ajax({
                type: 'GET',
                url: '/api/v1/template-tag',
                contentType: "application/json",
                dataType: "json",
                success: function (response) {
                    // Display errors.
                    if (response.status != 'error') {
                        // that.active_tags = response.active_tags;
                        // that.inactive_tags = response.inactive_tags;
                        that.set_order_tags(response)
                    }
                    else{

                    }
                },
                error: function (xmlhttp) {
                    
                }
            });


    },
    methods: Object.assign(
    {
        cancel() {
            this.$emit("hideTagsModal")
            this.isVisible = false;
        },
    },
    {
        setInActiveIndex(tags, index) { 
            this.inactive_selected_index = index;
        },
        setActiveIndex(tags, index) { 
            this.active_selected_index = index;
        },
        async save(){
            let tag_name = $('.txt-create-tag').val();
            let that = this
            $.ajax({
                type: 'POST',
                url: '/api/v1/template-tag',
                data: JSON.stringify({
                    name: tag_name
                }),
                contentType: "application/json",
                dataType: "json",
                success: function (response) {
                    // Display errors.
                    if (response.status != 'error') {
                        console.log('ok');
                        let newtag = response.tag;
                        let temp = that.inactive_tags;
                        temp.push(newtag);
                        that.inactive_tags = temp
                        BB.Toastr.success('New Tag Created');
                    }
                    else{

                    }
                },
                error: function (xmlhttp) {
                    
                }
            });
        },
        array_move(arr, fromIndex, toIndex) {
            var element = arr[fromIndex];
            arr.splice(fromIndex, 1);
            arr.splice(toIndex, 0, element);
            return arr;
        },
        async inactive_move_up(){
            if(this.inactive_selected_index != null && this.inactive_selected_index >= 1){
                let temp = this.inactive_tags;
                let fromIndex = this.inactive_selected_index;
                let toIndex = this.inactive_selected_index - 1;
                this.inactive_tags = this.array_move(temp, fromIndex, toIndex);
                this.inactive_selected_index = toIndex;
            }
        },
        async inactive_move_down(){
            if(this.inactive_selected_index != null && this.inactive_selected_index < this.inactive_tags.length){
                let temp = this.inactive_tags;
                let fromIndex = this.inactive_selected_index;
                let toIndex = this.inactive_selected_index + 1;
                this.inactive_tags = this.array_move(temp, fromIndex, toIndex);
                this.inactive_selected_index = toIndex;
            }
        },
        async move_to_active(){
            if(this.inactive_selected_index != null && this.inactive_tags.length>=1){
                let tag_to_move = this.inactive_tags[this.inactive_selected_index]
                let temp_inactive_tags = this.inactive_tags;
                temp_inactive_tags = temp_inactive_tags.filter((item)=>item.id!==tag_to_move.id)
                this.inactive_tags = temp_inactive_tags;
                let temp_active_tags = this.active_tags;
                temp_active_tags.push(tag_to_move)
                this.active_tags = temp_active_tags;
            }
        },
        async move_to_inactive(){
            if(this.active_selected_index != null && this.active_tags.length>=1){
                let tag_to_move = this.active_tags[this.active_selected_index]
                let temp_active_tags = this.active_tags;
                temp_active_tags = temp_active_tags.filter((item)=>item.id!==tag_to_move.id)
                this.active_tags = temp_active_tags;
                let temp_inactive_tags = this.inactive_tags;
                temp_inactive_tags.push(tag_to_move)
                this.inactive_tags = temp_inactive_tags;
            }
        },
        async active_move_up(){
            if(this.active_selected_index != null && this.active_selected_index >= 1){
                let temp = this.active_tags;
                let fromIndex = this.active_selected_index;
                let toIndex = this.active_selected_index - 1;
                this.active_tags = this.array_move(temp, fromIndex, toIndex);
                this.active_selected_index = toIndex;
            }
        },
        async active_move_down(){
            if(this.active_selected_index != null && this.active_selected_index < this.active_tags.length){
                let temp = this.active_tags;
                let fromIndex = this.active_selected_index;
                let toIndex = this.active_selected_index + 1;
                this.active_tags = this.array_move(temp, fromIndex, toIndex);
                this.active_selected_index = toIndex;
            }
        },
        async update(){
			let that = this
            $.ajax({
                type: 'POST',
                url: '/api/v1/template-tag-order-save',
                data: JSON.stringify({
                    active_tags: this.active_tags,
                    inactive_tags: this.inactive_tags
                }),
                contentType: "application/json",
                dataType: "json",
                success: function (response) {
                    console.log(response);
                    // Display errors.

                    if (response === 'ok') {
						that.$emit('setTags', that.active_tags)
                        BB.Toastr.success('Update Successful');
                    }
                    else{
                        BB.Toastr.error('Update Failed');
                    }
                },
                error: function (xmlhttp) {
                    BB.Toastr.error('Update Failed');
                }
            });
            
        },
        set_order_tags(tags){
            this.active_tags = tags.filter((item)=>item.status ==1)
            this.inactive_tags = tags.filter((item)=>item.status ==0)
        },
        beforeEnter() {
            this.$emit("beforeEnter")
            document.body.classList.add("modal-open")
        },
        afterEnter() {
            this.$emit("afterEnter")
        },
        afterLeave() {
            document.body.classList.remove("modal-open")
        },
    }
    ),
    watch: {
        
    }
}
</script>
<style lang="scss">
.inactive-tags-wrapper, .active-tags-wrapper{
    width: 47%;
    float: left;
}

.inactive-tag-list, .active-tag-list{
    height: 300px;
    width: 80%;
    border: 1px solid #ccc;
    margin-right: 5px;
	overflow: hidden;
    overflow-y: auto;
}
.list-wrap{
    display: flex;
    align-items: center;
}
.sort-buttons-wrapper{
    width: 10%;
}
.txt-create-tag{
    padding: 6px 12px;
}

.tag-lists-wrapper button{
    margin-bottom: 6px;
}
li.active{
    background: #1398b1;
    color: #fff;
    cursor: pointer;
}
.new-tag {
	margin-bottom: 10px;
	font-weight: bold;
}	
div.inactive-tags-wrapper, div.active-tags-wrapper {
	width: 50%;
}
.txt-create-tag {
	width: calc(100% - 156px);
}
</style>