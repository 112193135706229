<doc>
    Impersonate Vue.js component
</doc>
<template>
    <div class="main-wrapper">
        <div class="header">
            User
        </div>
        <div class="body">
            <select class="form-control user-to-impersonate" v-model="theUser">
                <option>Select User to Impersonate</option>
                <option v-bind:key="user.uid" v-for="user in users" :value="user.uid">{{ user.name }}</option>
            </select>
        </div>
        <div class="footer">
            <button class="btn btn-modern" @click="impersonateNow">Submit</button>
        </div>
    </div>
</template>

<script>
import _find from "lodash/find"
import { createNamespacedHelpers } from "vuex"
const { mapState, mapActions } = createNamespacedHelpers("impersonate-users")

export default {
  name: "Impersonate",
  
  async mounted(){
    
  },
  data() {
    return {
        users: null,
        theUser: null
    }
  },
  methods: Object.assign(
    {},
        mapActions([
            
        ]),
        {
            impersonateNow(){
                let userobj = _find(this.users, ["uid", this.theUser])
                document.location = userobj.impersonate_url;
            }
        }
    ),
    created : function() {
        //this.initTypeahead();
        let that = this;
        jQuery.ajax({
            url: '/api/v1/impersonated-users',
            type: "GET",
            contentType: "application/json",
            dataType: "json",
            success(data) {
                  that.users = data              
            },
            error(xhr, ajaxOptions, thrownError) { 
            }
        }
        )
    }
}
</script>
<style>
.main-wrapper{
    background: #fff;
}
.header{
    background:#45c2c7;
    padding: 15px;
}
.body{
    padding: 15px;
}
.footer{
    padding: 15px;
    text-align: center;
}
</style>