window.FooTable = {}

const Toastr = require("toastr")
const footable = require("./footable.js")
const bbPairModal = require("./bb-network-pair-modal")
const Mustache = require("mustache")

const imgPH = "/sites/all/modules/listing_flyer/images/placeholder.png"

// Render Listings
const bbNetwork = {
  init: function() {
    initbbNetwork()
    bbPairModal.init()
  }
}

function initbbNetwork(bbNetwork) {
  $(".external-invite-info-button").click(function(e) {
    const inviteInfoTpl=$('#invite-info-tpl').html();
    let modal=$("#invite-info-modal");
    let invite_id=$(this).data("iid");
    modal.modal('show');
    modal.find('.loader').removeClass('hide');
    BB.inviteManage.external.get(invite_id)
    .then(data=>{
        modal.find('.loader').addClass('hide');
        console.log(data.sent_timestamp);
        let email_recipient="";
        if(data.email_details){
            email_recipient=data.email_details.recipient;
        }
        let infoObj={
            date: new Date(data.sent_timestamp*1000).toLocaleDateString("en-US"),
            time: new Date(data.sent_timestamp*1000).toLocaleTimeString("en-US"),
            send_type: ( data.sms ? "SMS/Text" : "Email" ),
            recipient: ( data.sms ? data.sms : email_recipient )
        };
        const body=Mustache.render(inviteInfoTpl, infoObj);
        modal.find('.modal-body .content').html(body);
    });
  });
  $('#invite-info-modal').on('hidden.bs.modal', function (e) {
    $(this).find('.loader').removeClass('hide');
    $(this).find('.modal-body .content').html('');
  });
  $(".col-checkbox input").click(function(e) {
    if (
      !$(this)
        .parent()
        .parent()
        .hasClass("default")
    ) {
      $(".default").removeClass("default")
      $(this)
        .parent()
        .parent()
        .addClass("default")
      $.get("/listing_flyer/rlopair/default/" + $(this).data("pid") + "/1", function(data) {
        console.log(data)
        Toastr.success("New Default Set")
      })
    } else {
    }
  })
  //load add realtor modal
  if ($(".page-my-bb-network #find-realtor-table").length) {
    $.get("/listing_flyer/footable_realtor?loid=" + $("#find-realtor-table").data("loid"), function(data) {
      $("#find-realtor-table")
        .footable({
          useParentWidth: true,
          columns: data.columns,
          rows: data.users
        })
        .on("postdraw.ft.table", function(e, ft, current, previous) {
          setTimeout(function() {
            $(".footable-filtering-search .dropdown-menu li")
              .last()
              .find("input")
              .prop("checked", false)
              .change()
            $(".footable-filtering-search .dropdown-menu li")
              .last()
              .hide()
            //do things
            $(".addRealtor").click(function(e) {
              var loid = $(".my-realtor-network").data("loid")
              var addBtn = this
              confirmMSA(loid, $(addBtn).data("uid"), -1)
              //            $.get("/listing_flyer/rlopair/send_invite/"+$(this).data("uid")+"/"+loid,function(data){
              //                alert("Invite Sent!");
              //                $(addBtn).parent().parent().hide();
              //            });
            })
          }, 300)
        })
    })
  }
  //load add lender modal
  if ($(".page-my-bb-network #find-lender-table").length) {
    $.get("/listing_flyer/footable_lenders?rid=" + $("#find-lender-table").data("rid"), function(data) {
      $("#find-lender-table")
        .footable({
          useParentWidth: true,
          columns: data.columns,
          rows: data.users
        })
        .on("postdraw.ft.table", function(e, ft) {
          setTimeout(function() {
            $(".footable-filtering-search .dropdown-menu li")
              .last()
              .find("input")
              .prop("checked", false)
              .change()
            $(".footable-filtering-search .dropdown-menu li")
              .last()
              .hide()
            //do things
            $(".addLender").click(function(e) {
              var rid = $(".my-lending-network").data("rid")
              var addBtn = this
              confirmMSA($(addBtn).data("uid"), rid, -1)
            })
          }, 300)
        })
    })
  }
  //on realtor modal load...
  $("#myRealtorModal").on("shown.bs.modal", function(e) {
    //                $('.footable-filtering-search .dropdown-menu li').last().find('input').prop("checked",false).change();
    //                $('.footable-filtering-search .dropdown-menu li').last().hide();
  })
  //on lender modal load
  $("#myLenderModal").on("shown.bs.modal", function(e) {
    //                $('.footable-filtering-search .dropdown-menu li').last().find('input').prop("checked",false).change();
    //                $('.footable-filtering-search .dropdown-menu li').last().hide();
    //                $(".addLender").click(function(e){
    //                    var rid=$('.my-lending-network').data('rid');
    //                    var addBtn=this;
    //                    confirmMSA($(addBtn).data("uid"),rid,-1);
    //                });
  })

  //approve invite
  $('[data-action="approve-invite"]').on("click", function(e) {
    e.preventDefault()
    var $button = $(this)
    var inviteId = $button.data("iid")
    var loid = $button.data("loid")
    var rid = $button.data("rid")

    confirmMSA(loid, rid, inviteId)
  })

  //delete/reject invite
  $('.internalInvites [data-action="delete-invite"]').on("click", function(e) {
    e.preventDefault()

    var $button = $(this)
    var inviteId = $button.data("iid")
    var currentBtn = this

    $.get(`/listing_flyer/rlopair/decline_invite/${inviteId}`, function(data) {
      Toastr.success("Invite Deleted")
      $button.closest(".network-row").slideUp(function() {
        $(this).remove()
      })
    })
  })

  $('#externalTabContent [data-action="delete-invite"]').on("click", function(e) {
    e.preventDefault()

    var $button = $(this)
    var inviteId = $button.data("iid")
    var currentBtn = this

    var url = "/manage-external-invites.json?id=" + inviteId
    $.ajax({
      url: url,
      type: "DELETE",
      contentType: "application/json",
      dataType: "json",
      success: function(data) {
        Toastr.success("Invite Deleted")
        $("#externalTabContent #network-invite-" + inviteId).hide()
      },
      error: function(xhr, ajaxOptions, thrownError) {
        //On error do this
        if (xhr.status == 200) {
          //alert(ajaxOptions);
        } else {
          //                    alert(xhr.status);
          //                    alert(thrownError);
        }
      }
    })
  })

  $('#externalTabContent [data-action="resend-invite"]').on("click", function(e) {
    e.preventDefault()

    var $button = $(this)
    var inviteId = $button.data("iid")
    var currentBtn = this
    $button.find(".glyphicon").addClass("gly-spin")
    var url = "/manage-external-invites.json?id=" + inviteId + "&action=resend"
    $.ajax({
      url: url,
      type: "GET",
      contentType: "application/json",
      dataType: "json",
      success: function(data) {
        Toastr.success("Invite Resent")
        $button.find(".glyphicon").removeClass("gly-spin")
      },
      error: function(xhr, ajaxOptions, thrownError) {
        //On error do this
        if (xhr.status == 200) {
          $button.find(".glyphicon").removeClass("gly-spin")
          //alert(ajaxOptions);
        } else {
          $button.find(".glyphicon").removeClass("gly-spin")
          //                    alert(xhr.status);
          //                    alert(thrownError);
        }
      }
    })
  })

  //delete pair
  $('[data-action="delete-pair"]').on("click", function(e) {
    e.preventDefault()

    var $button = $(this)
    var pairId = $button.data("pid")
    var currentBtn = this
    $.get(`/listing_flyer/rlopair/delete/${pairId}`, function(data) {
      Toastr.success("Invite Deleted")
      $button.closest(".network-row").slideUp(function() {
        $(this).remove()
      })
    })
  })

  const confirmMsaModal = $("#confirm_msa_modal")

  $(".msaConfirm").click(function(e) {
    const button = $(this)
    let iid = button.data("iid"),
      loid = button.data("loid"),
      rid = button.data("rid"),
      from = "lo"

    const inviteRowEl = $(`[data-invite-id="${iid}"]`)

    if ($(".my-lending-network").length) {
      from = "realtor"
    }

    button.attr("disabled", true)
    confirmMsaModal.find(".loader").show()

    if (iid == -1) {
      $.get("/listing_flyer/rlopair/send_invite/" + rid + "/" + loid + "/" + from, function(data) {
        Toastr.success("Invite Sent!")
        confirmMsaModal.modal("toggle")
        button.attr("disabled", false)
        confirmMsaModal.find(".loader").hide()
        inviteRowEl
          .find(".network-table__actions")
          .html('<span class="network-table__action network-table__action--invitation-tag">Invitation sent</span>')
      })
    } else {
      $.get("listing_flyer/rlopair/accept_invite.json/" + iid, function() {
        Toastr.success("Invite Accepted!")
        confirmMsaModal.modal("toggle")
        button.attr("disabled", false)
        confirmMsaModal.find(".loader").hide()
        inviteRowEl
          .find(".network-table__actions")
          .html('<span class="network-table__action network-table__action--invitation-tag">Invite Accepted</span>')
      })
    }
  })

  $("#confirm_msa_modal").on("hide.bs.modal", function(e) {
    $("#confirm_msa_modal").removeClass("acceptInvite")
    $("#confirm_msa_modal").removeClass("internalInvite")
    $("#confirm_msa_modal").removeClass("externalInvite")
  })

  // Typeahead
  initTypeahead()
}

async function confirmMSA(loid, rid, iid) {
  if (iid !== -1) {
    $("#confirm_msa_modal").addClass("acceptInvite")
  } else {
    $("#confirm_msa_modal").addClass("sendInvite")
  }
  const modalEl = $("#confirm_msa_modal")
  const nextButton = modalEl.find(".msaNext")
  const confirmBtn = modalEl.find(".msaConfirm")
  //nextButton.addClass('msaConfirm')
  const content = modalEl.find(".msa_content")
  //make sure the MSA confirm button is showing
  $(".msaConfirm").removeClass("hide")
  nextButton.data("iid", iid)
  nextButton.data("rid", rid)
  nextButton.data("loid", loid)
  nextButton.attr("disabled", true)
  confirmBtn.data("iid", iid)
  confirmBtn.data("rid", rid)
  confirmBtn.data("loid", loid)
  confirmBtn.attr("disabled", true)

  modalEl.modal("show")
  modalEl.find(".loader").show()
  content.html("")

  let data=await $.get(`/listing_flyer/get_msa/${loid}/${rid}`)
  if(data["msa_final"].length==0){
    confirmBtn.trigger("click")
      return;
  }
  modalEl.find(".loader").hide()
  content.html(data["msa_final"].replace(/(?:\r\n|\r|\n)/g, "<br />"))
  confirmBtn.attr("disabled", false)
}

function capName(str) {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

// Typeahead search
function initTypeahead() {
  var networksBH = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace("value"),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: "/realtor-network-autocomplete.json?q_str=%QUERY",
      wildcard: "%QUERY",
      cache: true,
      filter: function(data) {
        let agencies = data.agency_results.agencies,
          mls = data.mls_results.mls_entries,
          realtors = data.realtor_results.realtors,
          results = []
        if (agencies.length) {
          for (let i = 0; i < agencies.length; i++) {
            agencies[i].type = "agency"
            results.push(agencies[i])
          }
        }
        if (mls.length) {
          for (let i = 0; i < mls.length; i++) {
            mls[i].type = "mls"
            results.push(mls[i])
          }
        }
        if (realtors.length) {
          for (let i = 0; i < realtors.length; i++) {
            realtors[i].type = "realtor"
            results.push(realtors[i])
          }
        }
        return results
      }
    }
  })

  $(".network-typeahead").typeahead(
    {
      minLength: 2,
      hint: true,
      highlight: true,
      limit: Infinity,
      classNames: {
        menu: "autocomplete",
        dataset: "autocomplete__dataset"
      },
      templates: {
        empty: `
          <div class="empty-message">
            unable to find any result that match the current query
          </div>
        `
      }
    },
    {
      name: "networksBH",
      source: networksBH,
      limit: Infinity,
      display: function(data) {
        return data.name || data.mls_name
      },
      classNames: {
        menu: "autocomplete",
        dataset: "autocomplete__dataset"
      },
      templates: {
        suggestion: function(data) {
          const type = data.type
          let image = ""
          let info = ""

          switch (data.type) {
            case "realtor":
              image = `
                <div class="autocomplete__picture">
                  <img src="${data.picture || imgPH}" alt="${data.name} thumb" />
                </div>
              `
              info = `
                <p class="autocomplete__info-title">${data.name}</p>
                <p class="autocomplete__info-subtitle">${data.agency_name}</p>
              `
              break
            case "agency":
              image = data.logo
                ? `
                <div class="autocomplete__picture autocomplete__picture--agency">
                  <img src="${( $(".securePicUrl").data("path") ? $(".securePicUrl").data("path") : "" )+"?url="+data.logo}" alt="${data.name} thumb" />
                </div>
              `
                : ""
              info = `
                <p class="autocomplete__info-title">${data.name}</p>
                <p class="autocomplete__info-subtitle">${data.address}</p>
              `
              break
            default:
              info = `
                <p class="autocomplete__info-title">${data.mls_name}</p>
              `
          }

          const str = `
            <div class="autocomplete__row">
              ${image}
              <div class="autocomplete__info">
                ${info}
              </div>
            </div>
          `

          return str
        }
      }
    }
  )

  $(".network-typeahead")
    .bind("typeahead:asyncrequest", function() {
      $(".tt-loading").removeClass("hide")
    })
    .bind("typeahead:asyncreceive", function() {
      $(".tt-loading").addClass("hide")
    })
    .bind("typeahead:select", function(ev, suggestion) {
      const key = suggestion.name ? "name" : "mls_name",
        origin = document.location.origin

      document.location.assign(
        origin + "/my-bb-network/search?" + key + "=" + suggestion[key] + "&type=" + suggestion["type"]
      )
    })

  $("#tt-search-btn").click(function() {
    const val = $(this)
      .parent(".network-search")
      .find(".tt-input")
      .typeahead("val")

    const search_mls = $(this)
      .parent(".network-search")
      .find(".search-mls")
      .is(':checked')
    doSearch(val, search_mls)
  })
  $('.search-mls').on('click', function(){
    if($(this).is(":checked")){
      $('#tt-search-input').attr('placeholder', 'Search by Realtor Last Name')
    }
    else{
      $('#tt-search-input').attr('placeholder', 'Search by Realtor MLS ID#')
    }
  })
  function AddEventSearch() {
    const ttSearchInput = document.getElementById("tt-search-input")
    const OnSearch = function(e) {
      doSearch(e.target.value)
    }
    ttSearchInput.addEventListener("search", OnSearch, false)
  }

  //setTimeout(AddEventSearch, 1000)
  $(document).on('keyup', '#tt-search-input', function(e){
    var code = e.key; // recommended to use e.key, it's normalized across devices and languages
    if(code==="Enter") {
      doSearch($(this).val());
    }
  });

  const doSearch = function(val, search_mls=false) {
    const origin = document.location.origin
	let selected_state = $('#selected-state').val();
    if(!selected_state){
      //alert('Please select state');
	  Toastr.error('Please select state');
      return;
    }
	search_mls = $(".network-search .search-mls").is(':checked')
    if(search_mls){
      document.location.assign(origin + "/my-bb-network/search?name=" + val + "&type=all&search_mls=1&state="+selected_state)
    }
    else{
      document.location.assign(origin + "/my-bb-network/search?name=" + val + "&type=all&state="+selected_state)
    }
  }
}

module.exports = bbNetwork

// -----------------------------------------
//console.log("bb-network <-" );
